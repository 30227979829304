import { ApolloAuth0Provider } from '@unpublished/apollo-auth0'
import {
  createRender,
  UserProfile,
  WhenAuthenticated,
  WhenUnauthenticated,
} from '@unpublished/auth0-helpers'
import { AppContainer, GlobalStyle } from '@unpublished/common-components'
import React, { useMemo } from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import { FixedWidthPageContainer } from '../common/common-components'
import { PointAnnotations } from '../datasets/annotations'
import { CheckoutGeometries } from '../datasets/checkout-geometries'
import { InspectSubjects } from '../datasets/inspect-subjects'
import { ListDatasets } from '../datasets/list-datasets'
import { ViewDataset } from '../datasets/view-dataset'
import { ImportLandmarkPage } from '../extras/import-landmarks'
import { SupportedFeaturePage } from '../extras/supported-features'
import { AutoAssignSubjectID } from '../import/assign-subject-id'
import { ChooseDatasetForImport } from '../import/choose-dataset'
import { NewDataset } from '../import/new-dataset'
import { SetProperties } from '../import/set-properties'
import { TooltipGlobalStyle } from '../import/tooltip-global-style'
import { AddGeometriesToMeasurementStudy } from '../measurement-studies/add-geometries'
import { ComparePerformance } from '../measurement-studies/compare-performance'
import { DownloadMeasurements } from '../measurement-studies/download-measurements'
import { DuplicateMeasurementStudy } from '../measurement-studies/duplicate'
import { ListArchivedMeasurementStudies } from '../measurement-studies/list-archived-studies'
import { ListMeasurementStudies } from '../measurement-studies/list-measurement-studies'
import { MeasureAgain } from '../measurement-studies/measure-again'
import { MeasurementErrors } from '../measurement-studies/measurement-errors'
import { CreateNewMeasurementStudy } from '../measurement-studies/new-study'
import { RegenerateViews } from '../measurement-studies/regenerate-views'
import { RenameMeasurementStudy } from '../measurement-studies/rename-study'
import { ReviewMeasurements } from '../measurement-studies/review-measurements'
import { ViewMeasurementStudy } from '../measurement-studies/view-study'
import { WhatsChanged } from '../measurement-studies/whats-changed'
import { ImportExportViews } from '../views'
import { loadConfig } from './config'

// TODO: Find a way to detect missing 'write:data' scope, and in that case, hide
// relevant UI elements

const NavContainer = styled.nav`
  a {
    margin: 5px;
  }
`

const UserProfileContainer = styled.div`
  float: right;
  width: 250px;
`

function PrimaryNav(): JSX.Element {
  return (
    <NavContainer>
      <Link to="/studies">Measurement studies</Link>
      <Link to="/datasets">Datasets</Link>
      <Link to="/import">Import geometries</Link>
      <Link to="/views">Views</Link>
    </NavContainer>
  )
}

function Home({ authEnabled }: { authEnabled?: boolean }): JSX.Element {
  return (
    <FixedWidthPageContainer>
      {authEnabled ? (
        <>
          <UserProfileContainer>
            <UserProfile />
          </UserProfileContainer>
          <WhenUnauthenticated>Please log in.</WhenUnauthenticated>
          <WhenAuthenticated>
            <PrimaryNav />
          </WhenAuthenticated>
        </>
      ) : (
        <PrimaryNav />
      )}
    </FixedWidthPageContainer>
  )
}

export function App(): JSX.Element {
  const { dataLayerUrl, authEnabled, auth0 } = useMemo(loadConfig, [])

  const render = createRender(authEnabled)

  return (
    <BrowserRouter>
      <ApolloAuth0Provider
        withAuth0Credentials={authEnabled}
        url={dataLayerUrl}
        authConfig={auth0}
        scope="write:data"
      >
        <AppContainer>
          <GlobalStyle />
          <TooltipGlobalStyle />
          <Routes>
            <Route path="/" element={<Home authEnabled={authEnabled} />} />
            <Route path="/import" element={render(ChooseDatasetForImport)} />
            <Route
              path="/import/:selectedDataset"
              element={render(ChooseDatasetForImport)}
            />
            <Route
              path="/import/:selectedDataset/set-properties"
              element={render(SetProperties)}
            />
            <Route path="/import/new-dataset" element={render(NewDataset)} />
            <Route
              path="/datasets/:selectedDataset/subjects"
              element={render(ViewDataset)}
            />
            <Route
              path="/datasets/:selectedDataset/annotations"
              element={render(PointAnnotations)}
            />
            <Route
              path="/datasets/:selectedDataset/checkout"
              element={render(() => CheckoutGeometries(dataLayerUrl))}
            />
            <Route
              path="/import/:selectedDataset/assign-subject-id"
              element={render(AutoAssignSubjectID)}
            />
            <Route path="/datasets" element={render(ListDatasets)} />
            <Route
              path="/datasets/:selectedDataset/subjects/:selectedSubjectName/:selectedPoseId"
              element={render(InspectSubjects)}
            />
            <Route path="/studies" element={render(ListMeasurementStudies)} />
            <Route
              path="/studies/archive"
              element={render(ListArchivedMeasurementStudies)}
            />
            <Route
              path="/studies/:selectedStudy"
              element={render(ViewMeasurementStudy)}
            />
            <Route
              path="/studies/:selectedStudy/add-geometries"
              element={render(AddGeometriesToMeasurementStudy)}
            />
            <Route
              path="/studies/:selectedStudy/performance"
              element={render(ComparePerformance)}
            />
            <Route
              path="/studies/:selectedStudy/measure-again"
              element={render(MeasureAgain)}
            />
            <Route
              path="/studies/:selectedStudy/regenerate-views"
              element={render(RegenerateViews)}
            />
            <Route
              path="/studies/:selectedStudy/whats-changed"
              element={render(WhatsChanged)}
            />
            <Route
              path="/studies/:selectedStudy/review-measurements/:selectedMeasurement?/:selectedInvocationId?/:compare?/:compareToInvocationId?"
              element={render(ReviewMeasurements)}
            />
            <Route
              path="/studies/:selectedStudy/errors"
              element={render(MeasurementErrors)}
            />
            <Route
              path="/studies/:selectedStudy/duplicate"
              element={render(DuplicateMeasurementStudy)}
            />
            <Route
              path="/studies/:selectedStudy/rename"
              element={render(RenameMeasurementStudy)}
            />
            <Route
              path="/studies/:selectedStudy/download"
              element={render(DownloadMeasurements)}
            />
            <Route
              path="/studies/new"
              element={render(CreateNewMeasurementStudy)}
            />
            <Route path="/views" element={render(ImportExportViews)} />
            <Route
              path="/extras/supported-features"
              element={render(SupportedFeaturePage)}
            />
            <Route
              path="/extras/import-landmarks"
              element={render(ImportLandmarkPage)}
            />
          </Routes>
        </AppContainer>
      </ApolloAuth0Provider>
    </BrowserRouter>
  )
}
