import { gql } from '@apollo/client'
import { Gender } from '@curvewise/common-types'
import { stringify } from 'csv-stringify/browser/esm/sync'

import { DownloadMeasurementsQuery } from '../../common/generated'

export interface SubjectData {
  subjectName: string
  gender: Gender
  measurements: Record<string, number>
}

export const DOWNLOAD_MEASUREMENTS_QUERY = gql`
  query DownloadMeasurements(
    $measurementStudyId: Int!
    $measurementInvocationIteration: Int!
  ) {
    allMeasurementInvocationIterations(
      condition: {
        measurementStudyId: $measurementStudyId
        measurementInvocationIteration: $measurementInvocationIteration
      }
    ) {
      nodes {
        commitByMeasurementInvocationCommitId {
          measurementInvocationsByCommitId {
            nodes {
              measurementName
              resultUnits
              resultValue
              geometryByGeometryId {
                geometrySeriesByGeometrySeriesId {
                  subjectBySubjectId {
                    name
                    gender
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export function transform(data: DownloadMeasurementsQuery): SubjectData[] {
  const nodes =
    data.allMeasurementInvocationIterations.nodes[0]
      .commitByMeasurementInvocationCommitId.measurementInvocationsByCommitId
      .nodes
  const results = new Map<string, SubjectData>()
  nodes.forEach((node: any) => {
    const { measurementName, resultValue } = node

    if (measurementName === null || resultValue === null) {
      return
    }

    const { name, gender } =
      node.geometryByGeometryId.geometrySeriesByGeometrySeriesId
        .subjectBySubjectId

    if (!results.has(name)) {
      results.set(name, { subjectName: name, gender, measurements: {} })
    }
    const subject = results.get(name) as SubjectData

    subject.measurements[measurementName] = resultValue
  })
  return Array.from(results)
    .sort()
    .map(item => item[1])
}

export function toCsv(subjectData: SubjectData[]): string {
  const records = subjectData.map(({ subjectName, gender, measurements }) => ({
    subjectName,
    gender,
    ...measurements,
  }))

  return stringify(records, { header: true })
}
