import { gql } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'

import { RegenerateViewsQuery } from '../../common/generated'
import { Option } from '../../common/toggleable-button-list'
import { MeasurementName } from '../measure-again/view-model'

export const REGENERATE_VIEWS_QUERY = gql`
  query RegenerateViews($measurementStudyId: Int!) {
    measurementStudy: measurementStudyById(id: $measurementStudyId) {
      name
      id
      measurementInvocationIterationsByMeasurementStudyId(
        orderBy: MEASUREMENT_INVOCATION_COMMIT_ID_DESC
      ) {
        nodes {
          measurementInvocationIteration
          commitByMeasurementInvocationCommitId {
            measurementInvocationsByCommitId {
              nodes {
                measurementName
                measurementScreenshotInvocationsByMeasurementInvocationId {
                  nodes {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type MeasurementInvocationIteration = number

export function createViewModel({
  data,
  measurementInvocationIteration,
  measurementNames,
}: {
  data: RegenerateViewsQuery
  measurementInvocationIteration?: MeasurementInvocationIteration
  measurementNames: Set<MeasurementName>
}): {
  measurementStudyName: string
  measurementNamesForSelectedMeasurementInvocationIteration: Option<string>[]
  numScreenshotsToRegenerate: number
  numSelectedMeasurementInvocations: number
} {
  const selectedMeasurementInvocations =
    data.measurementStudy.measurementInvocationIterationsByMeasurementStudyId.nodes
      .filter(
        item =>
          item.measurementInvocationIteration === measurementInvocationIteration
      )
      .flatMap(item =>
        item.commitByMeasurementInvocationCommitId.measurementInvocationsByCommitId.nodes.filter(
          measurementInvocation =>
            measurementNames.has(measurementInvocation.measurementName)
        )
      )

  return {
    measurementStudyName: data.measurementStudy.name,
    measurementNamesForSelectedMeasurementInvocationIteration:
      measurementInvocationIteration
        ? Array.from(
            new Set(
              data.measurementStudy.measurementInvocationIterationsByMeasurementStudyId.nodes
                .filter(
                  item =>
                    item.measurementInvocationIteration ===
                    measurementInvocationIteration
                )
                .flatMap(item =>
                  item.commitByMeasurementInvocationCommitId.measurementInvocationsByCommitId.nodes.map(
                    measurementInvocation =>
                      measurementInvocation.measurementName
                  )
                )
            )
          ).map(measurementName => ({
            label: humanizeMeasurementName({
              name: measurementName,
              index: 0,
            }),
            value: measurementName,
          }))
        : [],
    numSelectedMeasurementInvocations: selectedMeasurementInvocations.length,
    numScreenshotsToRegenerate: selectedMeasurementInvocations.flatMap(
      measurementInvocation =>
        measurementInvocation
          .measurementScreenshotInvocationsByMeasurementInvocationId.nodes
    ).length,
  }
}
