import { gql } from '@apollo/client'

import { MeasurementStudyNameQuery } from '../../common/generated'

export const MEASUREMENT_STUDY_NAME_QUERY = gql`
  query MeasurementStudyName($measurementStudyId: Int!) {
    measurementStudyById(id: $measurementStudyId) {
      id
      name
    }
  }
`

export function createViewModel({
  data,
  measurementInvocationIteration,
}: {
  data?: MeasurementStudyNameQuery
  measurementInvocationIteration?: number
}): {
  measurementStudyName?: string
  canDownload: boolean
} {
  return {
    measurementStudyName: data?.measurementStudyById.name,
    canDownload:
      data !== undefined && measurementInvocationIteration !== undefined,
  }
}
