import { gql, useMutation, useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  DatasetChooser,
  DatasetId,
  useDatasetChooserState,
} from '../../common/choose-datasets'
import {
  Breadcrumb,
  ErrorText,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  NextButton,
} from '../../common/common-components'
import {
  AddGeometriesToStudyQuery,
  AddGeometriesToStudyQueryVariables,
  AddRemoveGeometriesToMeasurementStudyMutation,
  AddRemoveGeometriesToMeasurementStudyMutationVariables,
} from '../../common/generated'
import {
  MaleFemaleToggleableButtonListTable,
  useMaleFemaleToggleableButtonListTableState,
} from '../../common/male-female-toggleable-button-list-table'
import { useNumericParam } from '../../common/use-numeric-param'
import { GeometryId } from '../measure-again/view-model'
import {
  ADD_GEOMETRIES_TO_STUDY_QUERY,
  createViewModel,
  SubjectName,
  ViewModel,
} from './view-model'

const GenderHeading = styled.h4`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.56);
`

function ExistingGeometries({
  gender,
  subjectNames,
}: {
  gender: string
  subjectNames: SubjectName[]
}): JSX.Element | null {
  if (!subjectNames.length) {
    return null
  }
  return (
    <>
      <GenderHeading>{gender}</GenderHeading>
      <ul>
        {subjectNames.map(subjectName => (
          <li>{subjectName}</li>
        ))}
      </ul>
    </>
  )
}

export function AddGeometriesToMeasurementStudy(): JSX.Element {
  const selectedStudy = useNumericParam('selectedStudy')
  const navigate = useNavigate()

  const [
    [chosenDatasetIds, setChosenDatasetIds],
    [datasetIdBeingEdited, setDatasetIdBeingEdited],
  ] = useDatasetChooserState()

  const {
    maleState,
    setMaleState,
    femaleState,
    setFemaleState,
    updateSelectedMaleGeometries,
    updateSelectedFemaleGeometries,
  } = useMaleFemaleToggleableButtonListTableState(datasetIdBeingEdited)

  function getLabelForDatasetRow(chosenDatasetId: DatasetId): JSX.Element {
    return (
      <div>
        <p>
          {viewModel?.datasetToExistingGeometriesCount[chosenDatasetId]}{' '}
          existing geometries
        </p>
        {viewModel?.datasetToAddedGeometriesCount[chosenDatasetId] !==
        undefined ? (
          <p>
            {viewModel.datasetToAddedGeometriesCount[chosenDatasetId]} added
            geometries
          </p>
        ) : undefined}{' '}
      </div>
    )
  }

  function getGeometriesToAdd(
    viewModel: ViewModel,
    gender: 'male' | 'female'
  ): Set<GeometryId> {
    return new Set(
      Object.values(
        viewModel?.geometriesStateGroupedByPoseAndTopologyLabel || {}
      ).flatMap(v =>
        v.geometriesToAdd[gender].map(([subjectName, geometryId]) => geometryId)
      )
    )
  }

  function performAddDataset(selectedDatasetId: DatasetId): void {
    if (!data) {
      throw new Error('Data should be defined here')
    }
    const refreshedViewModel = createViewModel({
      data,
      datasetIdBeingEdited: selectedDatasetId,
      maleState,
      femaleState,
    })
    setMaleState({
      ...maleState,
      [selectedDatasetId]: getGeometriesToAdd(refreshedViewModel, 'male'),
    })
    const selectedFemaleSubject = getGeometriesToAdd(
      refreshedViewModel,
      'female'
    )
    setFemaleState({
      ...femaleState,
      [selectedDatasetId]: selectedFemaleSubject,
    })
    setDatasetIdBeingEdited(selectedDatasetId)
  }

  const { error, loading, data } = useQuery<
    AddGeometriesToStudyQuery,
    AddGeometriesToStudyQueryVariables
  >(ADD_GEOMETRIES_TO_STUDY_QUERY, {
    variables: {
      selectedMeasurementStudy: selectedStudy,
    },
  })

  const viewModel =
    data &&
    createViewModel({
      data,
      datasetIdBeingEdited,
      maleState,
      femaleState,
    })

  const [addRemoveGeometries, { error: addRemoveGeometriesError }] =
    useMutation<
      AddRemoveGeometriesToMeasurementStudyMutation,
      AddRemoveGeometriesToMeasurementStudyMutationVariables
    >(
      gql`
        mutation AddRemoveGeometriesToMeasurementStudy(
          $measurementStudyId: Int!
          $geometryIdsToAdd: [Int!]!
          $geometryIdsToRemove: [Int!]!
        ) {
          addRemoveGeometriesToMeasurementStudy(
            input: {
              measurementStudyId: $measurementStudyId
              geometryIdsToAdd: $geometryIdsToAdd
              geometryIdsToRemove: $geometryIdsToRemove
            }
          ) {
            commit {
              id
            }
          }
        }
      `,
      {
        variables: {
          measurementStudyId: selectedStudy,
          geometryIdsToAdd: viewModel ? viewModel.allGeometriesToAdd : [],
          geometryIdsToRemove: [],
        },
        onCompleted: data => {
          // success
          navigate(`/studies/${selectedStudy}`)
        },
        onError() {},
      }
    )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <Link to={`/studies/${selectedStudy}`}>
          {viewModel && viewModel.measurementStudyName}
        </Link>{' '}
        {'>'} <span>Add Geometries</span>
      </Breadcrumb>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {viewModel && (
        <>
          <h1>{viewModel.measurementStudyName}</h1>
          <DatasetChooser
            studyBodyPart={viewModel.studyBodyPart}
            datasetIdBeingEdited={datasetIdBeingEdited}
            chosenDatasetIds={chosenDatasetIds}
            setChosenDatasetIds={setChosenDatasetIds}
            getLabelForDatasetRow={getLabelForDatasetRow}
            performAddDataset={performAddDataset}
            setDatasetIdBeingEdited={setDatasetIdBeingEdited}
          />
          {viewModel.geometriesStateGroupedByPoseAndTopologyLabel &&
            Object.entries(
              viewModel.geometriesStateGroupedByPoseAndTopologyLabel
            ).map(
              ([
                label,
                {
                  existingGeometries: {
                    male: existingGeometriesMale,
                    female: existingGeometriesFemale,
                  },
                  geometriesToAdd: {
                    male: geometriesToAddMale,
                    female: geometriesToAddFemale,
                  },
                },
              ]) => (
                <div>
                  <h2>{label}</h2>
                  <h3>Existing geometries</h3>
                  <ExistingGeometries
                    subjectNames={existingGeometriesMale}
                    gender="Female"
                  />
                  <ExistingGeometries
                    subjectNames={existingGeometriesFemale}
                    gender="Male"
                  />
                  <h3>Geometries to add</h3>
                  {chosenDatasetIds.length &&
                  datasetIdBeingEdited !== undefined ? (
                    <MaleFemaleToggleableButtonListTable
                      currentFemaleSubjectNameGeometryIdPairs={
                        geometriesToAddFemale
                      }
                      currentMaleSubjectNameGeometryIdPairs={
                        geometriesToAddMale
                      }
                      updateSelectedFemaleGeometries={
                        updateSelectedFemaleGeometries
                      }
                      updateSelectedMaleGeometries={
                        updateSelectedMaleGeometries
                      }
                      selectedFemaleOptionValue={
                        femaleState[datasetIdBeingEdited]
                      }
                      selectedMaleOptionValue={maleState[datasetIdBeingEdited]}
                    />
                  ) : undefined}
                </div>
              )
            )}
        </>
      )}
      {addRemoveGeometriesError && (
        <ErrorText>Error: {addRemoveGeometriesError.message}</ErrorText>
      )}
      <FixedWidthFooterNavContainer>
        <Button onClick={() => navigate(`/studies/${selectedStudy}`)}>
          Cancel
        </Button>
        <NextButton
          onClick={() => {
            addRemoveGeometries()
          }}
          disabled={!viewModel?.allAddedGeometriesCount}
        >
          Add {viewModel?.allAddedGeometriesCount} geometries
        </NextButton>
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
