import { useApolloClient, useQuery } from '@apollo/client'
import { Button, FlexRow } from '@unpublished/common-components'
import { downloadContent, downloadJsonContent } from '@unpublished/victorinox'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthPageContainer,
  SpanIndentedWrappingFlexDiv,
} from '../../common/common-components'
import { FixedWidthTable, TableRow } from '../../common/flex-table'
import {
  DownloadMeasurementsQuery,
  DownloadMeasurementsQueryVariables,
  MeasurementStudyNameQuery,
  MeasurementStudyNameQueryVariables,
} from '../../common/generated'
import { useNumericParam } from '../../common/use-numeric-param'
import { useIterationPicker } from '../iteration-picker'
import {
  DOWNLOAD_MEASUREMENTS_QUERY,
  toCsv,
  transform,
} from './measurement-data'
import { createViewModel, MEASUREMENT_STUDY_NAME_QUERY } from './view-model'

const SelectContainer = styled.div`
  width: 15em;
  display: inline-block;
`

export function DownloadMeasurements(): JSX.Element {
  const apolloClient = useApolloClient()

  const measurementStudyId = useNumericParam('selectedStudy')

  const { measurementInvocationIteration, renderIterationPicker } =
    useIterationPicker({ measurementStudyId })

  const { data, error } = useQuery<
    MeasurementStudyNameQuery,
    MeasurementStudyNameQueryVariables
  >(MEASUREMENT_STUDY_NAME_QUERY, {
    variables: { measurementStudyId: measurementStudyId as number },
    skip: measurementStudyId === undefined,
  })

  const viewModel = createViewModel({ data, measurementInvocationIteration })

  async function performDownload(format: 'json' | 'csv'): Promise<void> {
    if (
      measurementStudyId === undefined ||
      measurementInvocationIteration === undefined
    ) {
      throw Error('Expected study and iteration to be set')
    }

    const { data, error } = await apolloClient.query<
      DownloadMeasurementsQuery,
      DownloadMeasurementsQueryVariables
    >({
      query: DOWNLOAD_MEASUREMENTS_QUERY,
      variables: { measurementStudyId, measurementInvocationIteration },
    })

    if (error) {
      throw Error(error.message)
    }

    const transformed = transform(data)

    const basename = `measurements_i${measurementInvocationIteration}`
    if (format === 'json') {
      downloadJsonContent({
        data: transformed,
        filename: `${basename}.json`,
      })
    } else {
      downloadContent({
        contents: toCsv(transformed),
        filename: `${basename}.csv`,
      })
    }
  }

  return (
    <FixedWidthPageContainer>
      <FlexRow>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${measurementStudyId}`}>
            {viewModel.measurementStudyName}
          </Link>{' '}
          {'>'} Download measurements
        </Breadcrumb>
      </FlexRow>
      <h1>{viewModel.measurementStudyName}</h1>
      {error && <p>Error: {error.message}</p>}
      <h2>Download measurements</h2>
      <FixedWidthTable>
        <TableRow>
          <SpanIndentedWrappingFlexDiv>
            <span>Iteration</span>
            <span>
              <SelectContainer>{renderIterationPicker()}</SelectContainer>
            </span>
          </SpanIndentedWrappingFlexDiv>
        </TableRow>
      </FixedWidthTable>{' '}
      <Button
        disabled={!viewModel.canDownload}
        onClick={() => performDownload('json')}
      >
        Download JSON
      </Button>
      <Button
        disabled={!viewModel.canDownload}
        onClick={() => performDownload('csv')}
      >
        Download CSV
      </Button>
    </FixedWidthPageContainer>
  )
}
