import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  DatasetChooser,
  useDatasetChooserState,
} from '../../../common/choose-datasets'
import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  Table,
} from '../../../common/common-components'
import { CreateNewMeasurementStudyChooseSubjectsQuery } from '../../../common/generated'
import {
  flattenedState,
  MaleFemaleToggleableButtonListTable,
  useMaleFemaleToggleableButtonListTableState,
} from '../../../common/male-female-toggleable-button-list-table'
import { MeasurerVersionDisplay } from '../../measurer-version-display'
import { HomePagePayload, PageTransitionActionType, TransitionAction } from '..'
import {
  CREATE_NEW_MEASUREMENT_STUDY_QUERY,
  createViewModel,
  DatasetId,
} from './view-model'

const NextButton = styled(Button)`
  float: right;
`

function flatten<ValueType>(
  record: Record<DatasetId, Set<ValueType>>
): ValueType[] {
  return Array.from(
    new Set(Object.values(record).flatMap(set => Array.from(set)))
  )
}

export function CreateNewMeasurementStudyChooseSubjects({
  dispatchPageTransitionAction,
  homePagePayload: {
    studyName,
    studyBodyPart,
    measurerVersion,
    pose: selectedPose,
    topology: selectedTopology,
  },
}: {
  dispatchPageTransitionAction: React.Dispatch<TransitionAction>
  homePagePayload: HomePagePayload
}): JSX.Element {
  const navigate = useNavigate()
  const [selectedDatasetId, setSelectedDatasetId] = useState<number>()
  const [
    [chosenDatasetIds, setChosenDatasetIds],
    [datasetIdBeingEdited, setDatasetIdBeingEdited],
  ] = useDatasetChooserState()

  const {
    maleState,
    setMaleState,
    femaleState,
    setFemaleState,
    updateSelectedMaleGeometries,
    updateSelectedFemaleGeometries,
  } = useMaleFemaleToggleableButtonListTableState(datasetIdBeingEdited)

  function getLabelForDatasetRow(chosenDatasetId: DatasetId): JSX.Element {
    return (
      <div>
        {femaleState[chosenDatasetId].size + maleState[chosenDatasetId].size}{' '}
        out of {viewModel.numberOfAllSubjectsForDataset[chosenDatasetId]}{' '}
        subjects selected
      </div>
    )
  }

  function performAddDataset(selectedDatasetId: DatasetId): void {
    const {
      currentMaleSubjectNameGeometryIdPairs,
      currentFemaleSubjectNameGeometryIdPairs,
    } = createViewModel({
      data,
      datasetIdBeingEdited: selectedDatasetId,
      chosenDatasetIds,
      studyBodyPart,
      selectedPose,
      selectedTopology,
    })

    setMaleState({
      ...maleState,
      [selectedDatasetId]: new Set(
        currentMaleSubjectNameGeometryIdPairs.map(subject => subject[1])
      ),
    })
    setFemaleState({
      ...femaleState,
      [selectedDatasetId]: new Set(
        currentFemaleSubjectNameGeometryIdPairs.map(subject => subject[1])
      ),
    })
    setDatasetIdBeingEdited(selectedDatasetId)
  }

  const { loading, error, data } =
    useQuery<CreateNewMeasurementStudyChooseSubjectsQuery>(
      CREATE_NEW_MEASUREMENT_STUDY_QUERY
    )

  const selectedGeometryIds = [
    ...flattenedState(femaleState),
    ...flattenedState(maleState),
  ]

  const viewModel = createViewModel({
    data,
    datasetIdBeingEdited,
    chosenDatasetIds,
    studyBodyPart,
    selectedPose,
    selectedTopology,
  })

  if (
    viewModel &&
    !selectedDatasetId &&
    viewModel.allAvailableDatasetOptions.length
  ) {
    setSelectedDatasetId(viewModel.allAvailableDatasetOptions[0].value)
  }

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <a // eslint-disable-line jsx-a11y/anchor-is-valid
          href="" // TODO: clean this up - use a reusable, styled <span> instead
          onClick={() => {
            dispatchPageTransitionAction({
              type: PageTransitionActionType.GO_HOME,
            })
          }}
        >
          New Measurement Study
        </a>{' '}
        {'>'} <span>Choose Subjects</span>
      </Breadcrumb>
      <h1>New measurement study</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <Table>
        <thead>
          <tr>
            <th>Measurement Study</th>
            <th>Body part</th>
            <th>Measurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{studyName}</td>
            <td>{studyBodyPart}</td>
            <td>
              {measurerVersion.measurerName} @{' '}
              <MeasurerVersionDisplay measurerVersion={measurerVersion} />
            </td>
          </tr>
          <thead>
            <tr>
              <th>Pose</th>
              <th>Topology</th>
            </tr>
          </thead>
          <tr>
            <td>{selectedPose}</td>
            <td>{selectedTopology}</td>
          </tr>
        </tbody>
      </Table>
      <div>
        <h2>Choose subjects</h2>
        <DatasetChooser
          selectedPose={selectedPose}
          selectedTopology={selectedTopology}
          studyBodyPart={studyBodyPart}
          datasetIdBeingEdited={datasetIdBeingEdited}
          chosenDatasetIds={chosenDatasetIds}
          setChosenDatasetIds={setChosenDatasetIds}
          getLabelForDatasetRow={getLabelForDatasetRow}
          performAddDataset={performAddDataset}
          setDatasetIdBeingEdited={setDatasetIdBeingEdited}
        />
        {chosenDatasetIds.length && datasetIdBeingEdited !== undefined ? (
          <MaleFemaleToggleableButtonListTable
            currentFemaleSubjectNameGeometryIdPairs={
              viewModel.currentFemaleSubjectNameGeometryIdPairs
            }
            currentMaleSubjectNameGeometryIdPairs={
              viewModel.currentMaleSubjectNameGeometryIdPairs
            }
            updateSelectedFemaleGeometries={updateSelectedFemaleGeometries}
            updateSelectedMaleGeometries={updateSelectedMaleGeometries}
            selectedFemaleOptionValue={femaleState[datasetIdBeingEdited]}
            selectedMaleOptionValue={maleState[datasetIdBeingEdited]}
          />
        ) : undefined}

        <FixedWidthFooterNavContainer>
          <Button
            onClick={() => {
              dispatchPageTransitionAction({
                type: PageTransitionActionType.CHOOSE_SUBJECTS_GO_BACK,
              })
            }}
          >
            Back
          </Button>
          <Button onClick={() => navigate('/studies')}>Cancel</Button>
          <NextButton
            onClick={() => {
              //TODO: validate
              if (selectedGeometryIds) {
                dispatchPageTransitionAction({
                  type: PageTransitionActionType.CHOOSE_SUBJECTS_GO_NEXT,
                  payload: {
                    geometryIds: selectedGeometryIds,
                    femaleSubjects: flatten(femaleState),
                    maleSubjects: flatten(maleState),
                    pose: selectedPose,
                    topology: selectedTopology,
                  },
                })
              }
            }}
            disabled={!(selectedGeometryIds && selectedGeometryIds.length)}
          >
            Next: Choose Measurements
          </NextButton>
        </FixedWidthFooterNavContainer>
      </div>
    </FixedWidthPageContainer>
  )
}
