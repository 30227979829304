import { gql } from '@apollo/client'
import { CameraState, KnownBodyTopology } from '@curvewise/common-types'
import { PRESET_VIEWS } from '@unpublished/scene'
import crc32 from 'crc/crc32'
import { Vector } from 'hyla'
import { groupBy, keyBy, pick } from 'lodash'
import { Vector3 } from 'polliwog-types'
import { useMemo } from 'react'

import {
  Gender,
  topologyTypeFromData,
  transformCoordinatesToTHREEVector3,
  uniqueValues,
} from '../../common/data-transforms'
import {
  ResultValueType,
  ReviewMeasurementsDetailQuery,
  UnitsType,
} from '../../common/generated'
import { SortBy } from '../../common/use-query-params'
import { PATH_DIFFERENCE_RATIO_EPSILON } from '../comparison'
import {
  BLANK_APPROVED_LABEL,
  Label,
  labelMatches,
  listContainsLabel,
  orderLabels,
  sortedUniqueLabels,
} from '../labels'
import { MeasurerVersion, transformMeasurerVersion } from '../measurer-version'
import {
  Accordioned,
  createAccordion,
  State as AccordionState,
} from './accordion'
import {
  applyFilter,
  Filter,
  FilterOption,
  generateFilterOptions,
} from './filters'
import { LabelDeltasForMeasurementInvocation } from './local-storage-reducer'

const Y_BASIS: Vector3 = [0, 1, 0]

function withHash<T extends Object>(obj: T): T & { hash: number } {
  return { ...obj, hash: crc32(JSON.stringify(obj)) }
}

export const REVIEW_MEASUREMENTS_HEADER_QUERY = gql`
  query ReviewMeasurementsHeader($measurementStudyId: Int!) {
    measurementStudy: measurementStudyById(id: $measurementStudyId) {
      name
      id
      allMeasurementNames: measurementStudyMeasurementsByMeasurementStudyId(
        orderBy: MEASUREMENT_NAME_ASC
      ) {
        nodes {
          measurementName
        }
      }
    }
  }
`

export const REVIEW_MEASUREMENTS_DETAIL_QUERY = gql`
  query ReviewMeasurementsDetail(
    $measurementStudyId: Int!
    $measurementName: String
  ) {
    measurementStudy: measurementStudyById(id: $measurementStudyId) {
      commitByHeadCommitId {
        commitsGeometriesLookupsByCommitId {
          nodes {
            geometryByGeometryId {
              id
              geometrySeriesByGeometrySeriesId {
                topology
                subjectBySubjectId {
                  id
                  name
                  gender
                  datasetByDatasetId {
                    anteriorDirectionCoordinates
                    superiorDirectionCoordinates
                    units
                  }
                }
                poseTypeByPoseTypeId {
                  name
                  id
                }
              }
              s3Key
              signedURL
            }
          }
        }
      }
      measurementInvocationIterationsByMeasurementStudyId(
        orderBy: MEASUREMENT_INVOCATION_COMMIT_ID_DESC
      ) {
        nodes {
          measurementInvocationIteration
          commitByMeasurementInvocationCommitId {
            measurerVersionByMeasurerVersionId {
              measurerByMeasurerId {
                name
                functionName
                repoSlug
              }
              sha1
              tagName
              commitDate
              commitMessage
            }
            measurementInvocationsByCommitId(
              condition: { measurementName: $measurementName }
            ) {
              nodes {
                id
                stateMachineStateId
                measurementName
                geometryId
                resultValue
                resultUnits
                measurementScreenshotInvocationsByMeasurementInvocationId {
                  nodes {
                    viewIndex
                    s3Key
                    signedURL
                    translatedViewByTranslatedViewId {
                      viewByTargetViewId {
                        id
                        position
                        target
                        zoom
                      }
                    }
                  }
                }
                measurementInvocationDifferencesByInvocationId {
                  nodes {
                    pathDifferenceRatio
                    valueDifferenceRatio
                    previousInvocationId
                  }
                }
              }
            }
          }
        }
      }
      labels: labelsOfHeadCommitsByMeasurementStudyId {
        nodes {
          measurementInvocationId
          labelByLabelId {
            name
            isFailure
          }
        }
      }
    }
  }
`

export type LabelCommitState = 'committed' | 'added' | 'removed' | 'none'

// Given committed labels and label deltas, emit a list of labels, marked as
// either committed, added, or removed.
function resolveLabelDeltas({
  committedLabels,
  added = [],
  removed = [],
}: {
  committedLabels: Label[]
  added?: Label[]
  removed?: Label[]
}): { label: Label; commitState: LabelCommitState }[] {
  return committedLabels
    .map<{ label: Label; commitState: LabelCommitState }>(label => ({
      label,
      commitState: removed.some(removedLabel =>
        labelMatches(removedLabel, label)
      )
        ? 'removed'
        : 'committed',
    }))
    .concat(added.map(label => ({ label, commitState: 'added' })))
}

function sortBySubjectNameAsc(
  first: { subjectName: string },
  second: { subjectName: string }
): number {
  return first.subjectName > second.subjectName ? 1 : -1
}

function sortByComparisonMetricDesc(
  sortKey: SortBy,
  first: {
    compareTo?: CompareTo
    subjectName: string
  },
  second: {
    compareTo?: CompareTo
    subjectName: string
  }
): number {
  const firstMetric = first.compareTo && first.compareTo[sortKey]
  const secondMetric = second.compareTo && second.compareTo[sortKey]
  if (firstMetric !== undefined && secondMetric !== undefined) {
    if (firstMetric > secondMetric) {
      return -1
    } else if (firstMetric < secondMetric) {
      return 1
    }
  } else if (firstMetric === undefined && secondMetric !== undefined) {
    return -1
  } else if (secondMetric === undefined && firstMetric !== undefined) {
    return 1
  }
  return sortBySubjectNameAsc(first, second)
}

const sortByComparisonMetricSortKeyDesc = sortByComparisonMetricDesc.bind(
  null,
  SortBy.PERCENT_CHANGED
)
const sortByValueDifferenceSortKeyDesc = sortByComparisonMetricDesc.bind(
  null,
  SortBy.VALUE_DIFFERENCE
)

export interface GeometryViewModel {
  geometryId: number
  subjectId: number
  s3Key: string
  signedURL: string
  poseName: string
  poseId: number
  superiorAxis?: Vector
  anteriorAxis?: Vector
  units?: UnitsType
  topology?: KnownBodyTopology
}

function useAllGeometriesViewModel({
  geometries,
}: {
  geometries?: ReviewMeasurementsDetailQuery['measurementStudy']['commitByHeadCommitId']['commitsGeometriesLookupsByCommitId']['nodes']
}): Record<string, GeometryViewModel> {
  const allGeometries = useMemo(
    () =>
      geometries
        ?.map(item => item.geometryByGeometryId)
        .map(geometry => {
          const datasetDetail =
            geometry.geometrySeriesByGeometrySeriesId.subjectBySubjectId
              .datasetByDatasetId

          const result: GeometryViewModel = {
            geometryId: geometry.id,
            subjectId:
              geometry.geometrySeriesByGeometrySeriesId.subjectBySubjectId.id,
            poseName:
              geometry.geometrySeriesByGeometrySeriesId.poseTypeByPoseTypeId
                .name,
            poseId:
              geometry.geometrySeriesByGeometrySeriesId.poseTypeByPoseTypeId.id,
            s3Key: geometry.s3Key,
            signedURL: geometry.signedURL,
            superiorAxis: datasetDetail?.superiorDirectionCoordinates
              ? transformCoordinatesToTHREEVector3(
                  datasetDetail?.superiorDirectionCoordinates
                )
              : undefined,
            anteriorAxis: datasetDetail?.anteriorDirectionCoordinates
              ? transformCoordinatesToTHREEVector3(
                  datasetDetail.anteriorDirectionCoordinates
                )
              : undefined,
            units: datasetDetail?.units,
            topology: geometry.geometrySeriesByGeometrySeriesId.topology
              ? topologyTypeFromData(
                  geometry.geometrySeriesByGeometrySeriesId.topology
                )
              : undefined,
          }

          return result
        }) ?? [],
    [geometries]
  )

  return useMemo(() => keyBy(allGeometries, 'geometryId'), [allGeometries])
}

const DEFAULT_VIEW = {
  viewIndex: 0,
  view: PRESET_VIEWS.views[0],
  screenshotSignedURL: null,
}

export interface IterationViewModel {
  measurementInvocationId: number
  measurementInvocationIteration: number | null
  subjectId: number
  geometry: GeometryViewModel
  measurerVersion: MeasurerVersion
  labels: {
    label: Label
    commitState: LabelCommitState
  }[]
  haveScreenshots: boolean
  views: {
    viewIndex: number
    view: CameraState
    screenshotSignedURL: string | null
  }[]
  value: number | null
  stateMachineStateId: string
  units: ResultValueType | null
  comparisonMetrics: ComparisonMetrics[]
  hash: number
}

function useAllInvocationsViewModel({
  measurementStudy,
  labelDeltas,
}: {
  measurementStudy?: ReviewMeasurementsDetailQuery['measurementStudy']
  labelDeltas: LabelDeltasForMeasurementInvocation[]
}): IterationViewModel[] {
  // Group and key as an optimization. This avoids iterating for each of the
  // arrays for each geometry / invocation,
  const committedLabelsByInvocation = useMemo(
    () =>
      groupBy(measurementStudy?.labels.nodes ?? [], 'measurementInvocationId'),
    [measurementStudy?.labels]
  )

  const labelDeltasByInvocation = useMemo(
    () => keyBy(labelDeltas, item => item.measurementInvocationId),
    [labelDeltas]
  )

  const allGeometries = useAllGeometriesViewModel({
    geometries:
      measurementStudy?.commitByHeadCommitId.commitsGeometriesLookupsByCommitId
        .nodes,
  })

  const allInvocations: IterationViewModel[] = useMemo(() => {
    // Based on the GraphQL query, the flattened invocations are already:
    // - Filtered by measurement
    // - Sorted by invocation (newest first)
    return (
      measurementStudy?.measurementInvocationIterationsByMeasurementStudyId.nodes
        .flatMap(iteration =>
          iteration.commitByMeasurementInvocationCommitId.measurementInvocationsByCommitId.nodes.map(
            invocation => ({
              ...invocation,
              measurementInvocationIteration:
                iteration.measurementInvocationIteration,
              measurerVersionByMeasurerVersionId:
                iteration.commitByMeasurementInvocationCommitId
                  .measurerVersionByMeasurerVersionId,
            })
          )
        )
        .map(invocation => {
          // Look up the committed labels and label deltas in their respective maps.
          const committedLabels =
            committedLabelsByInvocation[invocation.id]?.map(
              label => label.labelByLabelId
            ) ?? []
          const { added, removed } =
            labelDeltasByInvocation[invocation.id] ?? {}
          const labels = resolveLabelDeltas({
            committedLabels,
            added,
            removed,
          })
            .sort((first, second) => orderLabels(first.label, second.label))
            .sort(
              (first, second) =>
                Number(first.commitState === 'added') -
                Number(second.commitState === 'added')
            )

          const customViews =
            invocation.measurementScreenshotInvocationsByMeasurementInvocationId.nodes
              // filter out cases where viewByTargetViewId is null
              .filter(
                screenshot =>
                  screenshot.translatedViewByTranslatedViewId.viewByTargetViewId
              )
              .map(screenshot => {
                if (
                  !screenshot.translatedViewByTranslatedViewId
                    .viewByTargetViewId
                )
                  throw new Error('Expected viewByTargetViewId to be defined')
                const { position, target, zoom } =
                  screenshot.translatedViewByTranslatedViewId.viewByTargetViewId
                return {
                  viewIndex: screenshot.viewIndex,
                  screenshotSignedURL: screenshot.signedURL,
                  view: {
                    // `number[]` is not assignable to `Vector3`.
                    position: position as Vector3,
                    target: target as Vector3,
                    up: Y_BASIS,
                    zoom,
                  },
                }
              })

          const comparisonMetrics: ComparisonMetrics[] = (
            invocation.measurementInvocationDifferencesByInvocationId.nodes.filter(
              node =>
                node.pathDifferenceRatio !== null &&
                node.valueDifferenceRatio !== null
            ) as {
              previousInvocationId: number
              pathDifferenceRatio: number
              valueDifferenceRatio: number
            }[]
          ).map(
            ({
              previousInvocationId,
              pathDifferenceRatio,
              valueDifferenceRatio,
            }) => {
              // If the value is greater the PATH_DIFFERENCE_RATIO_EPSILON but
              // less than one, return a sort key of .005, otherwise return the
              // comparisonMetric rounded to two decimal places. This is to
              // produce sort behavior which matches the displayed values.
              let pathDifferenceSortKey, pathDifferenceDisplayValue
              if (pathDifferenceRatio <= PATH_DIFFERENCE_RATIO_EPSILON) {
                pathDifferenceSortKey = 0
                pathDifferenceDisplayValue = '0'
              } else if (pathDifferenceRatio < 0.01) {
                pathDifferenceSortKey = 0.00005
                pathDifferenceDisplayValue = '< 1'
              } else {
                pathDifferenceSortKey = pathDifferenceRatio
                pathDifferenceDisplayValue = (
                  pathDifferenceRatio * 100
                ).toFixed(0)
              }

              return {
                previousInvocationId,
                [SortBy.PERCENT_CHANGED]: pathDifferenceSortKey,
                pathDifferenceDisplayValue,
                [SortBy.VALUE_DIFFERENCE]: Math.abs(valueDifferenceRatio),
                valueDifferenceDisplayValue: (
                  valueDifferenceRatio * 100
                ).toFixed(0),
              }
            }
          )

          const geometry = allGeometries[invocation.geometryId]

          // Annotate type here for more readable type errors.
          const result: Omit<IterationViewModel, 'hash'> = {
            measurementInvocationId: invocation.id,
            measurementInvocationIteration:
              invocation.measurementInvocationIteration,
            subjectId: geometry.subjectId,
            geometry: allGeometries[invocation.geometryId],
            measurerVersion: transformMeasurerVersion(
              invocation.measurerVersionByMeasurerVersionId
            ),
            stateMachineStateId: invocation.stateMachineStateId,
            value: invocation.resultValue,
            units: invocation.resultUnits,
            labels,
            haveScreenshots: customViews.some(
              view => view.screenshotSignedURL !== null
            ),
            views: customViews.length > 0 ? customViews : [DEFAULT_VIEW],
            comparisonMetrics,
          }

          return withHash(result)
        }) ?? []
    )
  }, [
    measurementStudy?.measurementInvocationIterationsByMeasurementStudyId,
    committedLabelsByInvocation,
    labelDeltasByInvocation,
    allGeometries,
  ])

  return allInvocations
}

interface ComparisonMetrics extends Record<SortBy, number> {
  previousInvocationId: number
  pathDifferenceDisplayValue: string
  valueDifferenceDisplayValue: string
}

export type ComparisonKind = 'earliest' | 'latestLabeled' | 'specificIteration'

interface CompareTo extends ComparisonMetrics {
  kind: ComparisonKind
  iteration: IterationViewModel
}

export interface SubjectViewModel {
  subjectId: number
  subjectName: string
  iterations: IterationViewModel[]
  previousLabeledIteration?: IterationViewModel
  hasLabels: boolean
  compareTo?: CompareTo
  gender: Gender
  hasMultiplePoses: boolean
}

function useAllSubjectsViewModel({
  measurementStudy,
  compareToIteration,
  labelDeltas,
  sortBy,
}: {
  measurementStudy?: ReviewMeasurementsDetailQuery['measurementStudy']
  compareToIteration?: number
  labelDeltas: LabelDeltasForMeasurementInvocation[]
  sortBy?: SortBy
}): SubjectViewModel[] {
  const allInvocations = useAllInvocationsViewModel({
    measurementStudy,
    labelDeltas,
  })

  // Group and key as an optimization. This avoids iterating for each of the
  // arrays for each geometry / invocation,
  const invocationsBySubject = useMemo(() => {
    return groupBy(allInvocations, 'subjectId')
  }, [allInvocations])

  const geometriesBySubject = useMemo(
    () =>
      groupBy(
        measurementStudy?.commitByHeadCommitId
          .commitsGeometriesLookupsByCommitId.nodes,
        'geometryByGeometryId.geometrySeriesByGeometrySeriesId.subjectBySubjectId.id'
      ),
    [measurementStudy?.commitByHeadCommitId]
  )

  const allSubjects: SubjectViewModel[] = useMemo(
    () =>
      Object.entries(geometriesBySubject)
        .map(([subjectId, geometries]) => {
          const iterations = invocationsBySubject[subjectId] ?? []

          const previousLabeledIteration = iterations
            .slice(1)
            .find(iteration => iteration.labels.length > 0)

          let compareTo

          if (iterations.length > 1) {
            let kind: ComparisonKind
            let iteration: IterationViewModel | undefined
            if (compareToIteration !== undefined) {
              kind = 'specificIteration'
              iteration = iterations.find(
                iteration =>
                  iteration.measurementInvocationIteration ===
                  compareToIteration
              )
            } else if (previousLabeledIteration === undefined) {
              kind = 'earliest'
              iteration = iterations[iterations.length - 1]
            } else {
              kind = 'latestLabeled'
              iteration = previousLabeledIteration
            }

            const comparisonMetric = iterations[0].comparisonMetrics.find(
              metric =>
                metric.previousInvocationId ===
                iteration?.measurementInvocationId
            )

            if (comparisonMetric && iteration) {
              compareTo = {
                kind,
                iteration,
                ...comparisonMetric,
              }
            }
          }

          const subjectDetail =
            geometries[0].geometryByGeometryId.geometrySeriesByGeometrySeriesId
              .subjectBySubjectId

          // Annotate type here for better type errors.
          const subject: SubjectViewModel = {
            subjectId: subjectDetail.id,
            subjectName: subjectDetail.name,
            iterations,
            previousLabeledIteration,
            hasLabels: iterations.some(i => i.labels.length > 0),
            compareTo,
            gender: subjectDetail.gender as Gender, // TODO: handle nulls?
            hasMultiplePoses:
              uniqueValues(iterations.map(i => i.geometry.poseId)).length > 1,
          }

          return subject
        })
        // Omit geometries having no invocations for this measurement.
        .filter(geometry => geometry.iterations.length > 0) ?? [],
    [geometriesBySubject, invocationsBySubject, compareToIteration]
  )

  return useMemo(
    () =>
      allSubjects
        .slice()
        .sort(
          sortBy === SortBy.PERCENT_CHANGED
            ? sortByComparisonMetricSortKeyDesc
            : sortBy === SortBy.VALUE_DIFFERENCE
            ? sortByValueDifferenceSortKeyDesc
            : sortBySubjectNameAsc
        ),
    [allSubjects, sortBy]
  )
}

export type MeasurementInvocationIteration = number

export interface FilterChoice {
  filter: FilterOption
  count: number
}

export interface AccordionedSubjectViewModel extends SubjectViewModel {
  visibleIterations: Accordioned<IterationViewModel>[]
}

interface Selection {
  subject?: SubjectViewModel
  iteration?: IterationViewModel
  previousIteration?: IterationViewModel
  nextIteration?: IterationViewModel
  nextLabeledIteration?: IterationViewModel
  sameIterationOnPreviousSubject?: IterationViewModel
  sameIterationOnNextSubject?: IterationViewModel
  labelChoices: {
    label: Label
    commitState: LabelCommitState
    isFresh: boolean
  }[]
}

export interface ViewModel {
  allSubjects: SubjectViewModel[]
  visibleSubjects: AccordionedSubjectViewModel[]
  selection: Selection
  iterationChoices: MeasurementInvocationIteration[]
  filterChoices: FilterChoice[]
  deltaCount: number
  studyHasMultiplePoses: boolean
}

function findMeasurementInvocation({
  subjects,
  measurementInvocationId,
}: {
  subjects: AccordionedSubjectViewModel[]
  measurementInvocationId: number
}):
  | {
      subjectIndex: number
      subject: AccordionedSubjectViewModel
      iterationIndex: number
      iteration: IterationViewModel
    }
  | undefined {
  for (let subjectIndex = 0; subjectIndex < subjects.length; ++subjectIndex) {
    const subject = subjects[subjectIndex]
    const iterationIndex = subject.visibleIterations.findIndex(
      iteration =>
        iteration.item.measurementInvocationId === measurementInvocationId
    )
    if (iterationIndex !== -1) {
      return {
        subjectIndex,
        subject,
        iterationIndex,
        iteration: subject.visibleIterations[iterationIndex].item,
      }
    }
  }
}

export type AccordionMode = 'show-latest' | 'show-labeled' | 'show-all'

function accordionIterations({
  iterations,
  accordionState,
  accordionMode,
}: {
  iterations: IterationViewModel[]
  accordionState: AccordionState
  accordionMode: AccordionMode
}): Accordioned<IterationViewModel>[] {
  let itemShouldNest: (iteration: IterationViewModel) => boolean
  switch (accordionMode) {
    case 'show-latest':
      itemShouldNest = () => true
      break
    case 'show-labeled':
      itemShouldNest = iteration => iteration.labels.length === 0
      break
    case 'show-all':
      itemShouldNest = () => false
      break
    default:
      throw Error(`Unknown mode: ${accordionMode}`)
  }
  return createAccordion({
    items: iterations,
    itemShouldNest,
    itemShouldOverrideNesting(iteration: IterationViewModel): boolean {
      return accordionState.expandedItems.has(iteration.measurementInvocationId)
    },
  })
}

export function useViewModel({
  detailData,
  compareToIteration,
  labelDeltas,
  freshLabels,
  selectedMeasurementInvocationId,
  activeFilter,
  sortBy,
  accordionState,
  accordionMode,
  selectedGenders,
}: {
  detailData?: ReviewMeasurementsDetailQuery
  compareToIteration?: number
  labelDeltas: LabelDeltasForMeasurementInvocation[]
  freshLabels: Label[]
  selectedMeasurementInvocationId?: number
  activeFilter: Filter
  sortBy?: SortBy
  accordionState: AccordionState
  accordionMode: AccordionMode
  selectedGenders: Set<Gender>
}): ViewModel {
  const allSubjects = useAllSubjectsViewModel({
    measurementStudy: detailData?.measurementStudy,
    compareToIteration,
    labelDeltas,
    sortBy,
  })

  const iterationChoices = useMemo(
    () =>
      uniqueValues(
        allSubjects.flatMap(subject =>
          subject.iterations.map(
            iteration => iteration.measurementInvocationIteration as number
          )
        )
      ),
    [allSubjects]
  )

  const accordionedSubjects = useMemo(
    () =>
      allSubjects
        .filter(subject => selectedGenders.has(subject.gender))
        .map(geometry => ({
          ...geometry,
          visibleIterations: accordionIterations({
            iterations: geometry.iterations,
            accordionState,
            accordionMode,
          }),
        })),
    [allSubjects, selectedGenders, accordionState, accordionMode]
  )

  // Here, `activeFilter` is applied, likely for the second time. This result
  // might already have been computed for `filterChoices`, but it might not have
  // -- such as when a label filter is active and the last instance of the label
  // is removed.
  const visibleSubjects = useMemo(
    () => applyFilter(accordionedSubjects, activeFilter),
    [accordionedSubjects, activeFilter]
  )

  // Choose from any label appearing on any visible invocation for this
  // measurement.
  const uniqueVisibleLabels = useMemo(
    () =>
      sortedUniqueLabels(
        // A blank "success" label is always included in the list of options.
        [BLANK_APPROVED_LABEL].concat(
          visibleSubjects.flatMap(subject =>
            subject.visibleIterations.flatMap(iteration =>
              iteration.item.labels.map(({ label }) =>
                pick(label, ['name', 'isFailure'])
              )
            )
          )
        )
      ),

    [visibleSubjects]
  )

  const studyHasMultiplePoses =
    uniqueValues(
      allSubjects.flatMap(subject =>
        subject.iterations.map(iteration => iteration.geometry.poseId)
      )
    ).length > 1

  const selection: Selection = useMemo(() => {
    if (!selectedMeasurementInvocationId) {
      return { labelChoices: [] }
    }

    const found = findMeasurementInvocation({
      subjects: visibleSubjects,
      measurementInvocationId: selectedMeasurementInvocationId,
    })
    if (!found) {
      return { labelChoices: [] }
    }

    const { subjectIndex, subject, iterationIndex, iteration } = found

    const isSameIteration = (thisIteration: IterationViewModel): boolean =>
      thisIteration.measurementInvocationIteration ===
      iteration.measurementInvocationIteration

    let sameIterationOnPreviousSubject: IterationViewModel | undefined
    for (
      let targetGeometryIndex = subjectIndex - 1;
      targetGeometryIndex >= 0 && sameIterationOnPreviousSubject === undefined;
      --targetGeometryIndex
    ) {
      sameIterationOnPreviousSubject =
        visibleSubjects[targetGeometryIndex].iterations.find(isSameIteration)
    }

    let sameIterationOnNextSubject: IterationViewModel | undefined
    for (
      let targetGeometryIndex = subjectIndex + 1;
      targetGeometryIndex < visibleSubjects.length &&
      sameIterationOnNextSubject === undefined;
      ++targetGeometryIndex
    ) {
      sameIterationOnNextSubject =
        visibleSubjects[targetGeometryIndex].iterations.find(isSameIteration)
    }

    const labelChoices = uniqueVisibleLabels
      .map(label => ({
        label,
        commitState:
          iteration.labels.find(item => labelMatches(item.label, label))
            ?.commitState ?? 'none',
        isFresh: listContainsLabel(freshLabels, label),
      }))
      .sort((first, second) => Number(first.isFresh) - Number(second.isFresh))

    return {
      subject,
      iteration,
      labelChoices,
      previousIteration:
        subject.visibleIterations[iterationIndex - 1]?.item ??
        visibleSubjects[subjectIndex - 1]?.visibleIterations.slice(-1)[0].item,
      nextIteration:
        subject.visibleIterations[iterationIndex + 1]?.item ??
        visibleSubjects[subjectIndex + 1]?.visibleIterations[0].item,
      nextLabeledIteration: subject.iterations
        .slice(iterationIndex)
        .find(iteration => iteration.labels.length > 0),
      sameIterationOnPreviousSubject,
      sameIterationOnNextSubject,
    }
  }, [
    visibleSubjects,
    selectedMeasurementInvocationId,
    uniqueVisibleLabels,
    freshLabels,
  ])

  return useMemo(() => {
    return {
      allSubjects,
      visibleSubjects,
      selection,
      iterationChoices,
      filterChoices: generateFilterOptions(uniqueVisibleLabels).map(filter => ({
        filter,
        count: applyFilter(accordionedSubjects, filter).length,
      })),
      deltaCount: labelDeltas
        .flatMap(item => item.added.length + item.removed.length)
        .reduce((accum, current) => accum + current, 0),
      studyHasMultiplePoses,
    }
  }, [
    allSubjects,
    accordionedSubjects,
    visibleSubjects,
    selection,
    iterationChoices,
    uniqueVisibleLabels,
    labelDeltas,
    studyHasMultiplePoses,
  ])
}

export interface CompareToViewModel {
  invocation?: IterationViewModel
}

export function useCompareToInvocationViewModel({
  viewModel,
  compareToIteration,
  compareToInvocationId,
}: {
  viewModel: ViewModel
  compareToIteration?: number
  compareToInvocationId?: number
}): CompareToViewModel {
  const compareToInvocation: IterationViewModel | undefined = useMemo(() => {
    const iterations = viewModel.selection.subject?.iterations ?? []
    if (compareToIteration !== undefined) {
      return iterations.find(
        iteration =>
          iteration.measurementInvocationIteration === compareToIteration
      )
    } else if (compareToInvocationId !== undefined) {
      return iterations.find(
        iteration => iteration.measurementInvocationId === compareToInvocationId
      )
    } else {
      return undefined
    }
  }, [viewModel, compareToIteration, compareToInvocationId])

  return { invocation: compareToInvocation }
}
