import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, FlexRow } from '@unpublished/common-components'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select, { ActionMeta, GroupBase } from 'react-select'
import styled from 'styled-components'

import {
  Breadcrumb,
  ButtonContainer,
  FixedWidthPageContainer,
  FloatRightButton,
  NarrowSelect,
  SpanIndentedWrappingFlexDiv,
} from '../../common/common-components'
import { FixedWidthTable, TableRow } from '../../common/flex-table'
import {
  DataLayerMeasurerName,
  MeasureAgainQuery,
  MeasureAgainQueryVariables,
  RemeasureBranchMutation,
  RemeasureBranchMutationVariables,
  RemeasureTagMutation,
  RemeasureTagMutationVariables,
  SupportedMeasurementsByMeasurerSha1Query,
  SupportedMeasurementsByMeasurerSha1QueryVariables,
  SupportedMeasurementsByMeasurerTagNameQuery,
  SupportedMeasurementsByMeasurerTagNameQueryVariables,
} from '../../common/generated'
import {
  SelectAllPosesAndTopologiesComponent,
  useSelectedPoseAndTopologyState,
} from '../../common/select-pose-topology'
import { ToggleableButtonList } from '../../common/toggleable-button-list'
import { useNumericParam } from '../../common/use-numeric-param'
import {
  BranchMeasurerVersionType,
  createViewModel,
  MEASURE_AGAIN_QUERY,
  MeasurementName,
  MeasurerId,
  MeasurerName,
  MeasurerVersion,
  SUPPORTED_MEASUREMENTS_BY_MEASURER_SHA1_QUERY,
  SUPPORTED_MEASUREMENTS_BY_MEASURER_TAG_NAME_QUERY,
  TagMeasurerVersionType,
} from './view-model'

interface MeasurerVersionOption {
  label: string
  value: MeasurerVersion
}

const MeasurerVersionSelect = styled(Select)`
  width: 22em;
  display: inline-block;
` as Select

export function MeasureAgain(): JSX.Element {
  const navigate = useNavigate()
  const selectedStudy = useNumericParam('selectedStudy')

  const [selectedMeasurerId, setSelectedMeasurerId] = useState<MeasurerId>()
  const [selectedMeasurerName, setSelectedMeasurerName] =
    useState<MeasurerName>()
  const [selectedMeasurerVersion, setSelectedMeasurerVersion] =
    useState<MeasurerVersion>()
  const [selectedMeasurementNames, setSelectedMeasurementNames] =
    useState<Set<MeasurementName>>()
  const {
    selectedPose,
    setSelectedPose,
    selectedTopology,
    setSelectedTopology,
  } = useSelectedPoseAndTopologyState()

  let selectedTagName, selectedSha1
  switch (selectedMeasurerVersion?.type) {
    case BranchMeasurerVersionType:
      selectedSha1 = selectedMeasurerVersion.sha1
      break
    case TagMeasurerVersionType:
      selectedTagName = selectedMeasurerVersion.tagName
      break
  }

  const { data } = useQuery<MeasureAgainQuery, MeasureAgainQueryVariables>(
    MEASURE_AGAIN_QUERY,
    {
      variables: {
        measurementStudyId: selectedStudy,
      },
    }
  )

  const { data: supportedMeasurementsByMeasurerTagNameData } = useQuery<
    SupportedMeasurementsByMeasurerTagNameQuery,
    SupportedMeasurementsByMeasurerTagNameQueryVariables
  >(SUPPORTED_MEASUREMENTS_BY_MEASURER_TAG_NAME_QUERY, {
    variables: {
      tagName: selectedTagName || '',
      measurerName: (selectedMeasurerName || '') as DataLayerMeasurerName,
    },
    skip: !(selectedTagName && selectedMeasurerName),
  })

  const { data: supportedMeasurementsByMeasurerSha1Data } = useQuery<
    SupportedMeasurementsByMeasurerSha1Query,
    SupportedMeasurementsByMeasurerSha1QueryVariables
  >(SUPPORTED_MEASUREMENTS_BY_MEASURER_SHA1_QUERY, {
    variables: {
      sha1: selectedSha1 || '',
      measurerName: (selectedMeasurerName || '') as DataLayerMeasurerName,
    },
    skip: !(selectedSha1 && selectedMeasurerName),
  })

  const viewModel = useMemo(() => {
    return data
      ? createViewModel({
          data,
          supportedMeasurementsByMeasurerTagNameData,
          supportedMeasurementsByMeasurerSha1Data,
          selectedPose,
          selectedTopology,
        })
      : undefined
  }, [
    supportedMeasurementsByMeasurerTagNameData,
    supportedMeasurementsByMeasurerSha1Data,
    data,
    selectedPose,
    selectedTopology,
  ])

  const [remeasureUsingTag, { error: remeasureUsingTagError }] = useMutation<
    RemeasureTagMutation,
    RemeasureTagMutationVariables
  >(
    gql`
      mutation RemeasureTag($input: RemeasureTagInput!) {
        remeasureUsingTag(input: $input) {
          commit: commit {
            id
          }
        }
      }
    `,
    {
      variables: {
        input: {
          geometryIds: viewModel?.allUniqueGeometryIds,
          measurementNames: selectedMeasurementNames
            ? Array.from(selectedMeasurementNames)
            : [],
          measurementStudyId: selectedStudy,
          measurerId: selectedMeasurerId || 0,
          tagName: selectedTagName || '',
        },
      },
      onCompleted() {
        navigate(`/studies/${selectedStudy}`)
      },
    }
  )

  const [remeasureUsingBranch, { error: remeasureUsingBranchError }] =
    useMutation<RemeasureBranchMutation, RemeasureBranchMutationVariables>(
      gql`
        mutation RemeasureBranch($input: RemeasureBranchInput!) {
          remeasureUsingCommit(input: $input) {
            commit {
              id
            }
          }
        }
      `,
      {
        variables: {
          input: {
            geometryIds: viewModel?.allUniqueGeometryIds,
            measurementNames: selectedMeasurementNames
              ? Array.from(selectedMeasurementNames)
              : [],
            measurementStudyId: selectedStudy,
            measurerId: selectedMeasurerId as number,
            sha1: selectedSha1 as string,
          },
        },
        onCompleted() {
          navigate(`/studies/${selectedStudy}`)
        },
      }
    )

  useEffect(() => {
    if (!selectedMeasurerId && viewModel) {
      const newSelectedMeasurerId = parseInt(
        Object.keys(viewModel.measurerIdToName)[0]
      )
      const newSelectedMeasurerName =
        viewModel.measurerIdToName[newSelectedMeasurerId]
      setSelectedMeasurerId(newSelectedMeasurerId)
      setSelectedMeasurerName(newSelectedMeasurerName)
    }
  }, [selectedMeasurerId, viewModel])

  useEffect(() => {
    // By default select all available measurement names
    if (
      viewModel?.allUniqueMeasurementNames &&
      selectedMeasurementNames === undefined
    ) {
      setSelectedMeasurementNames(new Set(viewModel?.allUniqueMeasurementNames))
    }

    // If changing the measurer changes the available options, remove invalid options from selectedMeasurementNames
    const choices = viewModel?.allUniqueMeasurementNames
    const newSelectedMeasurementNames = new Set(selectedMeasurementNames)
    newSelectedMeasurementNames.forEach(name => {
      if (!choices?.includes(name)) {
        newSelectedMeasurementNames.delete(name)
      }
    })
    if (
      selectedMeasurementNames &&
      newSelectedMeasurementNames.size !== selectedMeasurementNames.size
    ) {
      setSelectedMeasurementNames(newSelectedMeasurementNames)
    }
  }, [viewModel?.allUniqueMeasurementNames, selectedMeasurementNames])

  function onSelectMeasurer(option: any, { action }: { action: string }): void {
    if (option && action === 'select-option') {
      const newSelectedMeasurerId = option.value
      const newSelectedMeasurerName = option.label
      if (newSelectedMeasurerId !== selectedMeasurerId) {
        setSelectedMeasurerVersion(undefined)
        setSelectedMeasurerId(newSelectedMeasurerId)
        setSelectedMeasurerName(newSelectedMeasurerName)
      }
    }
  }

  function handleRemeasure(): void {
    switch (selectedMeasurerVersion?.type) {
      case BranchMeasurerVersionType:
        remeasureUsingBranch()
        break
      case TagMeasurerVersionType:
        remeasureUsingTag()
        break
      default:
        break
    }
  }

  function cancel(): void {
    navigate(`/studies/${selectedStudy}`)
  }

  const measurerSelectOptions = useMemo(
    () =>
      Object.entries(viewModel?.measurerIdToName ?? []).map(
        ([measurerId, measurerName]) => ({
          value: parseInt(measurerId),
          label: measurerName,
        })
      ),
    [viewModel?.measurerIdToName]
  )

  const measurerVersionOptions: GroupBase<MeasurerVersionOption>[] = useMemo(
    () =>
      selectedMeasurerId && viewModel?.measurerVersionsByMeasurerId
        ? [
            {
              label: 'Branches',
              options: viewModel.measurerVersionsByMeasurerId[
                selectedMeasurerId
              ].branchVersions.map(o => ({
                value: o,
                label: o.branchName,
              })),
            },
            {
              label: 'Tags',
              options: viewModel.measurerVersionsByMeasurerId[
                selectedMeasurerId
              ].tagVersions.map(o => ({
                value: o,
                label: o.tagName,
              })),
            },
          ]
        : [],
    [selectedMeasurerId, viewModel?.measurerVersionsByMeasurerId]
  )

  const selectedNarrowSelectOptions =
    measurerSelectOptions &&
    measurerSelectOptions.find(option => option.value === selectedMeasurerId)

  const isRemeasureEnabled =
    selectedMeasurementNames?.size &&
    viewModel?.allUniqueGeometryIds.length &&
    selectedPose &&
    selectedTopology

  return (
    <FixedWidthPageContainer>
      <FlexRow>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${selectedStudy}`}>
            {viewModel?.measurementStudyName}
          </Link>{' '}
          {'>'} Measure again
        </Breadcrumb>
      </FlexRow>
      <h1>{viewModel?.measurementStudyName}</h1>
      <h2>Measure again</h2>
      {viewModel ? (
        <>
          <FixedWidthTable>
            <TableRow>
              <SpanIndentedWrappingFlexDiv>
                <span>Measurer</span>
                <span>
                  <NarrowSelect
                    value={selectedNarrowSelectOptions ?? null}
                    onChange={onSelectMeasurer}
                    options={measurerSelectOptions}
                  />
                </span>
                <span>@</span>
                <span>
                  <MeasurerVersionSelect
                    isMulti={false}
                    onChange={(
                      newValue: MeasurerVersionOption | null,
                      { action }: ActionMeta<MeasurerVersionOption>
                    ) => {
                      if (newValue && action === 'select-option') {
                        setSelectedMeasurerVersion(newValue.value)
                      }
                    }}
                    options={measurerVersionOptions}
                  />
                </span>
              </SpanIndentedWrappingFlexDiv>
            </TableRow>
          </FixedWidthTable>{' '}
          <SelectAllPosesAndTopologiesComponent
            {...{
              selectedPose,
              setSelectedPose,
              selectedTopology,
              setSelectedTopology,
              posesAndTopologiesPair: viewModel?.posesAndTopologiesPair,
            }}
          />
          <h2>Choose measurements</h2>
          {!!(
            selectedMeasurerVersion && // user has selected a measurer version, AND
            // data has resolved for supported measurements query, AND
            (supportedMeasurementsByMeasurerTagNameData ||
              supportedMeasurementsByMeasurerSha1Data)
          ) &&
            (viewModel.buildForMeasurerVersionIsReady ? (
              <p>
                <em>
                  The selected measurer{' '}
                  {selectedMeasurerVersion.type === 'tagMeasurerVersion'
                    ? 'version'
                    : 'commit'}{' '}
                  supports the following measurements.
                </em>
              </p>
            ) : (
              <>
                <p>
                  <em>
                    You can remeasure this commit, although its build isn't
                    ready yet, so the measurement choices may be out of date.
                  </em>
                </p>
                <p>
                  <em>
                    To measure a newly added measurement, reload this screen
                    once the build completes.
                  </em>
                </p>
              </>
            ))}
          {viewModel.allUniqueMeasurementNamesOptions &&
            selectedMeasurementNames && (
              <ToggleableButtonList
                options={viewModel.allUniqueMeasurementNamesOptions}
                onChange={setSelectedMeasurementNames}
                selectedOptionValues={selectedMeasurementNames}
              />
            )}
        </>
      ) : (
        <div>Loading...</div>
      )}
      <ButtonContainer>
        <Button onClick={cancel}>Cancel</Button>
        <FloatRightButton
          onClick={handleRemeasure}
          disabled={isRemeasureEnabled ? undefined : true}
        >
          Start Measuring {viewModel?.allUniqueGeometryIds.length} geometr
          {viewModel?.allUniqueGeometryIds.length === 1 ? 'y' : 'ies'}
        </FloatRightButton>
      </ButtonContainer>
      {remeasureUsingTagError && <p>Error: {remeasureUsingTagError.message}</p>}
      {remeasureUsingBranchError && (
        <p>Error: {remeasureUsingBranchError.message}</p>
      )}
    </FixedWidthPageContainer>
  )
}
