import { gql } from '@apollo/client'
import { LegacyMeasuredBody, MeasuredBodyViews } from '@curvewise/common-types'
import { Vector3 } from 'polliwog-types'

import { unflattenVertices } from '../common/data-transforms'
import { ViewsQuery } from '../common/generated'

export const VIEWS_QUERY = gql`
  query Views {
    allPresetViews(orderBy: VIEW_INDEX_ASC) {
      nodes {
        measurementName
        viewIndex
        viewByReferenceViewId {
          position
          target
          zoom
          translatedViewsByTargetViewId {
            nodes {
              viewByTargetViewId {
                position
                target
                zoom
                id
              }
            }
          }
        }
        curveByReferenceCurveId {
          isClosed
          tapeWidth
          vertices
        }
      }
    }
    allReferenceGeometryForViews {
      nodes {
        geometryByGeometryId {
          s3Key
          signedURL
        }
      }
    }
  }
`

interface ViewModel {
  exportedMeasurements: LegacyMeasuredBody
  exportedViews: MeasuredBodyViews
  signedUrlForReferenceObj: string
}

function getMeasurements(
  allPresetViews: ViewsQuery['allPresetViews']['nodes']
): LegacyMeasuredBody {
  return {
    values: allPresetViews.reduce((values, node) => {
      values[node.measurementName] = {
        value: -1,
        units: 'cm',
      }
      return values
    }, {} as LegacyMeasuredBody['values']),
    curves: allPresetViews.reduce((curves, node) => {
      curves[node.measurementName] = {
        vertices: unflattenVertices(
          node.curveByReferenceCurveId.vertices || []
        ),
        is_closed: !!node.curveByReferenceCurveId.isClosed,
      }
      return curves
    }, {} as LegacyMeasuredBody['curves']),
    tape_width: allPresetViews[0]?.curveByReferenceCurveId.tapeWidth || 0,
    landmarks: {},
    computed_points: {},
  }
}

function getViews(
  allPresetViews: ViewsQuery['allPresetViews']['nodes']
): MeasuredBodyViews {
  return {
    schema: 'curvewise.MeasuredBodyViews',
    schemaVersion: 3,
    measurements: allPresetViews.map(
      ({
        measurementName,
        viewIndex,
        viewByReferenceViewId: { position, target, zoom },
      }) => {
        return {
          name: measurementName,
          index: viewIndex,
          view: {
            position: position as Vector3,
            target: target as Vector3,
            up: [0, 1, 0],
            zoom,
          },
        }
      }
    ),
  }
}

export function createViewModel({
  data,
}: {
  data?: ViewsQuery
}): ViewModel | undefined {
  return (
    data && {
      exportedMeasurements: getMeasurements(data.allPresetViews.nodes),
      exportedViews: getViews(data.allPresetViews.nodes),
      signedUrlForReferenceObj:
        data.allReferenceGeometryForViews.nodes[0].geometryByGeometryId
          .signedURL,
    }
  )
}
