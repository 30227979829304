import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client'
import { AnyUnits, LengthUnits } from '@curvewise/common-types'
import { bodyPartNamesForTopology } from '@unpublished/body-mesh'
import {
  Button,
  CheckboxToggle,
  FlexColumn,
  FlexRow,
  FlexRowWithPadding,
  StatisticalDiv,
} from '@unpublished/common-components'
import { adaptView, CanvasContextProvider } from '@unpublished/scene'
import { downloadJsonContent, maybePluralize } from '@unpublished/victorinox'
import { isEqual } from 'lodash'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { useStorageReducer } from 'react-storage-hooks'
import styled, { css } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import {
  Breadcrumb,
  Confirm,
  FixedWidthPageContainer,
  FlexRowWithMargin,
  IterationDisplay,
} from '../../common/common-components'
import { TABLE_BACKGROUND_AND_BORDER } from '../../common/common-styles'
import {
  Gender,
  initSetOfGenderOptions,
  toggleableButtonListGenderOptions,
} from '../../common/data-transforms'
import {
  CommitLabelsMutation,
  CommitLabelsMutationVariables,
  ReviewMeasurementsDetailQuery,
  ReviewMeasurementsDetailQueryVariables,
  ReviewMeasurementsHeaderQuery,
  ReviewMeasurementsHeaderQueryVariables,
} from '../../common/generated'
import { ReactComponent as _AscendingIcon } from '../../common/images/ascending.svg'
import { ReactComponent as CopyIcon } from '../../common/images/copy.svg'
import { ReactComponent as _FilterIcon } from '../../common/images/filter.svg'
import { ReactComponent as LoadingIcon } from '../../common/images/loading.svg'
import { ReactComponent as _Settings } from '../../common/images/settings.svg'
import { MeasurementPicker } from '../../common/measurement-picker'
import { PartsPicker } from '../../common/parts-picker'
import { ToggleableButtonList } from '../../common/toggleable-button-list'
import {
  NavigateToParameters,
  useNavigateToReviewMeasurements,
} from '../../common/use-navigate-to'
import {
  useNumericParam,
  useOptionalNumericParam,
} from '../../common/use-numeric-param'
import {
  SortBy,
  useNumericQueryParam,
  useSortByQueryParam,
  useStringArrayQueryParam,
} from '../../common/use-query-params'
import { useScrollIntoView } from '../../common/use-scroll-into-view'
import { Viewer } from '../../common/viewer'
import { IterationPicker } from '../iteration-picker'
import {
  BLANK_APPROVED_LABEL,
  hashKeyForLabel,
  Label,
  labelMatches,
} from '../labels'
import {
  initialState as accordionInitialState,
  reducer as accordionReducer,
} from './accordion'
import { MetricComparisonWithIcon } from './comparison-display'
import { LabelCreationComponent } from './create-label'
import { findMeasurementInvocation } from './data'
import { DisplayLabel } from './display-label'
import { Filter, FILTER_ALL, hashKeyForFilter } from './filters'
import { createHeaderViewModel } from './header-view-model'
import {
  AddOrRemoveLabel,
  INITIAL_STATE,
  localStateReducer,
} from './local-storage-reducer'
import { generateLegacyMeasuredBody } from './measured-body-json'
import { Subjects } from './subjects'
import { useGeometryLoading } from './use-geometry-loading'
import { useKeyboardShortcuts } from './use-keyboard-shortcuts'
import {
  AccordionMode,
  FilterChoice,
  IterationViewModel,
  REVIEW_MEASUREMENTS_DETAIL_QUERY,
  REVIEW_MEASUREMENTS_HEADER_QUERY,
  SubjectViewModel,
  useCompareToInvocationViewModel,
  useViewModel,
} from './view-model'
import { ViewerFooter } from './viewer-add-ons'

const Settings = styled(_Settings)`
  margin-right: 1em;
  cursor: pointer;
`

const SettingsDialog = styled.div`
  width: 300px;
  position: absolute;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
  top: 0;
  border: 1px solid black;
  text-align: center;
  left: -320px;
`

const PageContainer = styled(FixedWidthPageContainer)`
  padding-top: 20px;
  overflow: hidden;
`
const InterfaceGrid = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  grid-template-rows: fit-content(100%) auto;
  height: 100%;
`
const StatisticalLabel = styled.label`
  color: rgba(0, 0, 0, 0.56);
  font-weight: bold;
  font-size: 14px;
  margin: 8px 0 4px;
`
const StatisticalLabelWithSmallerFont = styled.label`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.56);
`
const SubjectsColumn = styled(FlexColumn)`
  overflow: hidden;
  height: calc(100% - 10px);
`
const Header = styled(FlexRow)`
  justify-content: space-between;
`
const NewLabelContainer = styled(FlexColumn)`
  padding-left: 7.5px;
  > div {
    width: calc(100% - 7px);
  }
`
const NavContainer = styled(FlexColumn)`
  position: relative;
  margin-bottom: 10px;
`
const ButtonContainer = styled.div`
  padding-top: 10px;
  text-align: center;
`
const BottomAlignedFlexRow = styled(FlexRow)`
  align-items: flex-end;
`

const CenterAlignedFlexRowWithMargin = styled(FlexRow)`
  align-items: center;
  > * {
    margin-right: 10px;
  }
`

const FlexContainer = styled.div<{ flexDirection: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  position: relative;
`

// when formatting Select directly, TS has trouble checking the types, so
// it is enclosed in a div to apply formatting.
const SubjectListHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`
const StatisticalDivBold = styled(StatisticalDiv)`
  font-weight: bold;
  font-size: 14px;
`
const CenterAlignedStatisticalDiv = styled(StatisticalDiv)`
  display: flex;
  height: 19px;
  * {
    margin-left: 10px;
  }
`
const StatisticalDivBoldWithTopPadding = styled(StatisticalDivBold)`
  padding-top: 6px;
  padding-bottom: 6px;
`
const SortByPercentChangedIcon = styled(_AscendingIcon)`
  cursor: pointer;
`
const SortByValueDifferenceIcon = styled.span<{
  shouldSortByValueDifference: boolean
}>`
  font-size: 1.7em;
  padding-left: 0.3em;
  cursor: pointer;
  opacity: ${({ shouldSortByValueDifference }) =>
    shouldSortByValueDifference ? 1 : 0.56};
`

const FilterIcon = styled(_FilterIcon)`
  cursor: pointer;
  margin-right: 10px;
`
const ScrollingSubjectContainer = styled(FlexColumn)`
  border-bottom: none;
  overflow: auto;
`

const ScrollingFlexRow = styled(FlexRow)`
  overflow: auto;
  height: 100%;
  width: 100%;
`

const AvailableLabelsContainer = styled.div`
  ${TABLE_BACKGROUND_AND_BORDER}
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 500px;
  min-height: 8vh;
  padding: 3.5px;
`

const FilterContainer = styled(FlexColumn)`
  position: absolute;
  top: calc(100% + 38px);
  left: 340px;
  z-index: 1;
  border: 0.5px solid black;
  font-size: 14px;
  background: white;
  max-height: 60vh;
  overflow: scroll;
`
const FilterOption = styled.div<{ selected: boolean; disabled: boolean }>`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  padding: 5px;
  white-space: pre;
  border: 0.5px solid black;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: '';
      color: grey;
    `};
`
const LabelsContainer = styled(FlexColumn)`
  padding-left: 20px;
`
const InlineButton = styled(Button)`
  margin: 0;
`

const ViewerHeader = styled(FlexRowWithPadding)`
  height: 21.5px;
  justify-content: center;
  align-content: center;
  font-size: 11px;
  margin-bottom: 10px;
  > div {
    width: max-content;
    margin-right: 0px;
  }
`
const DivWithButtonandText = styled.div`
  font-size: 11px;
  cursor: pointer;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.65);
`

const viewerCSS = css`
  height: 584px;
  border: 1px solid grey;
`

function FilterMenu({
  filterChoices,
  activeFilter,
  setActiveFilter,
  onRequestClose,
  selectedGenders,
  setSelectedGenders,
}: {
  filterChoices: FilterChoice[]
  activeFilter: Filter
  setActiveFilter: (filter: Filter) => void
  onRequestClose: () => void
  selectedGenders: Set<Gender>
  setSelectedGenders: React.Dispatch<React.SetStateAction<Set<Gender>>>
}): JSX.Element {
  const hashKeyForActiveFilter = activeFilter
    ? hashKeyForFilter(activeFilter)
    : undefined
  return (
    <FilterContainer>
      <FilterOption selected={false} disabled={false}>
        <ToggleableButtonList
          options={toggleableButtonListGenderOptions}
          onChange={setSelectedGenders}
          selectedOptionValues={selectedGenders}
          showSelectAllNoneButtons={false}
          direction="row"
        />
      </FilterOption>
      {filterChoices.map(({ filter, count }) => (
        <FilterOption
          selected={hashKeyForFilter(filter) === hashKeyForActiveFilter}
          disabled={count === 0}
          key={hashKeyForFilter(filter)}
          onClick={() => {
            setActiveFilter(filter)
            onRequestClose()
          }}
        >
          {filter.type === 'specificLabel' ? (
            <DisplayLabel label={filter.label} disabled={false} />
          ) : (
            filter.displayName
          )}
          {` (${count})`}
        </FilterOption>
      ))}
    </FilterContainer>
  )
}

export function ReviewMeasurements(): JSX.Element {
  const selectedStudy = useNumericParam('selectedStudy')
  const { selectedMeasurement } = useParams<{ selectedMeasurement: string }>()
  const partsToHide = useStringArrayQueryParam('hide-parts')

  const selectedCompareToIteration = useNumericQueryParam(
    'compare-to-iteration'
  )
  const sortBy = useSortByQueryParam('sorted')

  const selectedMeasurementInvocationId = useNumericParam(
    'selectedInvocationId',
    { safe: false }
  )
  const selectedComparisonInvocationId = useOptionalNumericParam(
    'compareToInvocationId'
  )

  const [accordionState, dispatchAccordionAction] = useReducer(
    accordionReducer,
    accordionInitialState
  )

  const [selectedGenders, setSelectedGenders] = useState(
    initSetOfGenderOptions()
  )

  const [inCombinedViewMode, setInCombinedViewMode] = useState<boolean>(false)

  const [showingSettingsDialog, setShowingSettingsDialog] =
    useState<boolean>(false)
  const [compareUsingGradient, setCompareUsingGradient] = useLocalStorageState(
    'compareUsingGradient',
    { defaultValue: false }
  )
  const [accordionMode, setAccordionMode] = useLocalStorageState<AccordionMode>(
    'accordionMode',
    { defaultValue: 'show-labeled' }
  )
  const [enableOptimisticLoading, setEnableOptimisticLoading] =
    useLocalStorageState('enableOptimisticLoading', { defaultValue: false })
  const [activeFilter, setActiveFilter] = useState(FILTER_ALL)
  const [showFilterOptions, setShowFilterOptions] = useState(false)

  const [committedChangeCount, setCommittedChangeCount] = useState<number>()
  const [show3DView, setShow3DView] = useState(false)

  // The order of the bodyPartNames corresponds to the order of the mesh groups,
  // and so must be preserved.
  const [bodyPartNames, setBodyPartNames] = useState<ReadonlyArray<string>>([])

  const [localStorageState, dispatchLocalStorageAction] = useStorageReducer(
    localStorage,
    `newLabelsOnTimeline${selectedStudy}`,
    localStateReducer,
    INITIAL_STATE
  )
  const headerQuery = useQuery<
    ReviewMeasurementsHeaderQuery,
    ReviewMeasurementsHeaderQueryVariables
  >(REVIEW_MEASUREMENTS_HEADER_QUERY, {
    variables: { measurementStudyId: selectedStudy },
  })
  const detailQuery = useQuery<
    ReviewMeasurementsDetailQuery,
    ReviewMeasurementsDetailQueryVariables
  >(REVIEW_MEASUREMENTS_DETAIL_QUERY, {
    variables: {
      measurementStudyId: selectedStudy,
      measurementName: selectedMeasurement,
    },
    fetchPolicy: 'no-cache',
    // since the signedURLs expire after 5 min, refetch at 4.5 min
    pollInterval: 4.5 * 60e3,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      dispatchLocalStorageAction({
        type: 'discardDeltasWhichHaveAlreadyBeenCommitted',
        detailData: data,
      })
      setCommittedChangeCount(undefined)
    },
  })

  const headerViewModel = createHeaderViewModel({
    headerData: headerQuery.data,
    selectedMeasurement,
  })

  const viewModel = useViewModel({
    selectedGenders,
    detailData: detailQuery.data,
    compareToIteration: selectedCompareToIteration,
    labelDeltas: localStorageState.allLocalChangesToWorkingCopy,
    freshLabels: localStorageState.freshLabels ?? [],
    selectedMeasurementInvocationId,
    activeFilter,
    sortBy,
    accordionState,
    accordionMode,
  })

  const compareToViewModel = useCompareToInvocationViewModel({
    viewModel,
    compareToIteration: selectedCompareToIteration,
    compareToInvocationId: selectedComparisonInvocationId,
  })

  // These specific clauses reduce the need for certain nullability checks
  // further down.
  const isComparing = compareToViewModel.invocation !== undefined

  useEffect(() => {
    if (!show3DView) {
      setInCombinedViewMode(false)
    }
  }, [show3DView])

  useEffect(() => {
    if (inCombinedViewMode && isComparing && !show3DView) {
      setShow3DView(true)
    }
  }, [inCombinedViewMode, isComparing, show3DView])

  const { selectionGeometry, compareToGeometry } = useGeometryLoading({
    selectedMeasurementInvocationId,
    compareToMeasurementInvocationId:
      compareToViewModel.invocation?.measurementInvocationId ?? undefined,
    selectedGeometry: viewModel.selection.iteration?.geometry,
    compareToGeometry: compareToViewModel.invocation?.geometry,
    shouldLoadBody: show3DView,
    allMeasurementInvocationIds: viewModel.allSubjects
      .flatMap(geometry => geometry.iterations)
      .map(iteration => iteration.measurementInvocationId),
    enableOptimisticLoading,
  })
  useEffect(() => {
    loadBodyPartNames().catch(console.error)
    async function loadBodyPartNames(): Promise<void> {
      if (viewModel.selection.iteration?.geometry.topology) {
        const loadedPartNames = await bodyPartNamesForTopology(
          viewModel.selection.iteration?.geometry.topology
        )
        setBodyPartNames(loadedPartNames)
      } else {
        setBodyPartNames([])
      }
    }
  }, [viewModel.selection.iteration?.geometry.topology])

  const partsToShow = useMemo(
    () =>
      bodyPartNames.map(partName => ({
        partName,
        isVisible: !partsToHide.includes(partName),
      })),
    [partsToHide, bodyPartNames]
  )

  const scrollIntoView = useScrollIntoView()
  const _navigateToReviewMeasurements = useNavigateToReviewMeasurements({
    measurementStudyId: selectedStudy,
    measurementName: selectedMeasurement,
    measurementInvocationId: selectedMeasurementInvocationId,
    compareToIteration: selectedCompareToIteration,
    sortBy,
    partsToHide,
  })
  const selectedCompareToIterationRef = useRef<number | undefined>()
  selectedCompareToIterationRef.current = selectedCompareToIteration
  const allSubjectsRef = useRef<SubjectViewModel[]>(viewModel.allSubjects)
  allSubjectsRef.current = viewModel.allSubjects
  const navigateToReviewMeasurements = useCallback(
    function navigateToReviewMeasurements(params: NavigateToParameters): void {
      _navigateToReviewMeasurements(params)

      scrollIntoView()

      const { measurementInvocationId, measurementName } = params
      if (
        measurementName === undefined &&
        !selectedCompareToIterationRef.current &&
        measurementInvocationId
      ) {
        const target = findMeasurementInvocation(
          allSubjectsRef.current,
          measurementInvocationId
        )
        if (target && target.haveScreenshots) {
          setShow3DView(false)
        }
      }
    },
    [_navigateToReviewMeasurements, scrollIntoView]
  )

  useKeyboardShortcuts({
    viewModel,
    show3DView,
    inCombinedViewMode,
    setInCombinedViewMode,
    setShow3DView,
    navigateToReviewMeasurements,
    performApproveOrUnapprove,
    dispatchAccordionAction,
  })

  function expandInvocationIfHidden(invocationId: number): void {
    if (!accordionState.expandedItems.has(invocationId)) {
      dispatchAccordionAction({
        type: 'expandItems',
        items: [invocationId],
      })
    }
  }

  // Ensure the selected measurement invocations are visible.
  useEffect(() => {
    selectedMeasurementInvocationId &&
      expandInvocationIfHidden(selectedMeasurementInvocationId)
    selectedComparisonInvocationId &&
      expandInvocationIfHidden(selectedComparisonInvocationId)
  })

  // Default to first measurement if none is selected, or measurement is
  // invalid.
  if (
    headerViewModel.measurementChoices.length > 0 &&
    !headerViewModel.measurementSelectionIsValid
  ) {
    navigateToReviewMeasurements({
      measurementName: headerViewModel.measurementChoices[0],
      measurementInvocationId: null,
    })
  }

  // Default to first measurement invocation if none is selected.
  useEffect(() => {
    if (
      !selectedMeasurementInvocationId &&
      viewModel.visibleSubjects.length > 0 &&
      // Work around https://github.com/apollographql/apollo-client/issues/9135
      // which means we get one render with stale data. In @apollo/client@3.5.5
      // we can check for this condition by inspecting `detailQuery.variables`.
      // The next render will have an empty view model, and then once the data
      // is fetched we'll have the right data.
      // TODO: Fix when upgrading @apollo/client to 3.5.7?
      // https://github.com/apollographql/apollo-client/issues/9135#issuecomment-988846529
      isEqual(detailQuery.variables, {
        measurementStudyId: selectedStudy,
        measurementName: selectedMeasurement,
      })
    ) {
      const { measurementInvocationId } =
        viewModel.visibleSubjects[0].iterations[0]
      navigateToReviewMeasurements({ measurementInvocationId })
    }
  })

  // When first mounting the component, ensure the selected measurement is in
  // view.
  useEffect(scrollIntoView, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleAccordionModeChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setAccordionMode(event.target.value as AccordionMode)
    dispatchAccordionAction({ type: 'reset' })
  }

  const [isCommitting, setIsCommitting] = useState(false)
  const [commitLabels, { error: commitLabelsError }] = useMutation<
    CommitLabelsMutation,
    CommitLabelsMutationVariables
  >(
    gql`
      mutation CommitLabels(
        $measurementStudyId: Int!
        $measurementInvocationLabelDeltas: [MeasurementInvocationAndLabels!]!
      ) {
        performCommit(
          input: {
            measurementStudyId: $measurementStudyId
            measurementInvocationLabelDeltas: $measurementInvocationLabelDeltas
          }
        ) {
          commit {
            id
            parentCommitId
          }
        }
      }
    `,
    {
      variables: {
        measurementStudyId: selectedStudy,
        measurementInvocationLabelDeltas:
          localStorageState.allLocalChangesToWorkingCopy.map(
            ({ measurementInvocationId, added, removed }) => {
              return {
                measurementInvocationId,
                labelDelta: { added, removed },
              }
            }
          ),
      },
      refetchQueries: [REVIEW_MEASUREMENTS_DETAIL_QUERY],
      awaitRefetchQueries: true,
      onCompleted() {
        setIsCommitting(false)
        setCommittedChangeCount(viewModel.deltaCount)
        dispatchLocalStorageAction({ type: 'resetDeltas' })
      },
      onError() {
        setIsCommitting(false)
      },
    }
  )

  async function handleCommit(): Promise<void> {
    setIsCommitting(true)

    // Wait for the reconciling to occur.
    const { data } = await detailQuery.refetch()
    dispatchLocalStorageAction({
      type: 'discardDeltasWhichHaveAlreadyBeenCommitted',
      detailData: data,
    })

    try {
      await commitLabels()
    } catch {}
  }

  function performAddOrRemoveLabel({
    type,
    label,
    isFresh,
  }: {
    type: AddOrRemoveLabel
    label: Label
    isFresh?: boolean
  }): void {
    if (!selectedMeasurementInvocationId) {
      throw Error('geometry must be selected')
    }
    dispatchLocalStorageAction({
      type,
      measurementInvocationId: selectedMeasurementInvocationId,
      label,
      isFresh,
    })
    scrollIntoView()
  }

  function performApproveOrUnapprove(kind: 'approve' | 'unapprove'): void {
    if (!viewModel.selection.iteration) {
      return
    }

    const approvedLabel = viewModel.selection.iteration?.labels.find(item =>
      labelMatches(item.label, BLANK_APPROVED_LABEL)
    )
    const isApproved =
      approvedLabel &&
      ['committed', 'added'].includes(approvedLabel.commitState)

    if (
      (kind === 'approve' && !isApproved) ||
      (kind === 'unapprove' && isApproved)
    ) {
      performAddOrRemoveLabel({
        type: kind === 'approve' ? 'addLabel' : 'removeLabel',
        label: BLANK_APPROVED_LABEL,
      })
    }
  }

  function performCopyAll(): void {
    if (selectedCompareToIteration) {
      dispatchLocalStorageAction({
        type: 'copyAllLabels',
        fromIteration: selectedCompareToIteration,
        subjects: viewModel.visibleSubjects,
      })
    }
  }

  function performApproveAll(): void {
    dispatchLocalStorageAction({
      type: 'addLabelToEachInvocation',
      label: BLANK_APPROVED_LABEL,
      invocations: viewModel.visibleSubjects.map(
        subject => subject.iterations[0]
      ),
    })
  }

  const initialValue = viewModel.selection.iteration?.value ?? undefined
  const compareValue = compareToViewModel.invocation?.value ?? undefined
  const units = viewModel.selection.iteration?.units ?? undefined

  // When no screenshots are present, revert to the 3D view. When initial
  // loading is in progress, this does nothing.
  useEffect(() => {
    if (viewModel.selection.iteration?.haveScreenshots === false) {
      setShow3DView(true)
    }
  }, [setShow3DView, viewModel.selection.iteration?.haveScreenshots])

  const canCommit = viewModel.deltaCount > 0 && !isCommitting
  const canEdit =
    headerViewModel.measurementSelectionIsValid &&
    selectedMeasurementInvocationId !== undefined &&
    !isCommitting
  const [isConfirmingDiscardChanges, setIsConfirmingDiscardChanges] =
    useState(false)

  const commonViewerAttributes = {
    parts: partsToShow,
    viewerCSS,
    show3DView,
    setShow3DView,
  }

  return (
    <PageContainer>
      <Header>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'}{' '}
          <Link to="/studies">Measurement studies</Link> {'>'}{' '}
          <Link to={`/studies/${selectedStudy}`}>
            {headerQuery.data?.measurementStudy.name}
          </Link>{' '}
          {'>'} Review measurements
        </Breadcrumb>
        {commitLabelsError && commitLabelsError.message}
        {selectionGeometry.bodyLoadingErrorMessage}
        {isComparing && compareToGeometry.bodyLoadingErrorMessage}
        {committedChangeCount !== undefined &&
          `${committedChangeCount} ${maybePluralize(
            'change',
            committedChangeCount
          )} committed.`}
        <BottomAlignedFlexRow>
          <CenterAlignedFlexRowWithMargin>
            <StatisticalLabelWithSmallerFont htmlFor="parts">
              Parts to Hide
            </StatisticalLabelWithSmallerFont>

            <PartsPicker
              partsToHide={partsToHide}
              navigateToReviewMeasurements={navigateToReviewMeasurements}
              allPartNames={bodyPartNames}
            />
          </CenterAlignedFlexRowWithMargin>
          <FlexContainer flexDirection="row">
            <Settings
              onClick={e => setShowingSettingsDialog(!showingSettingsDialog)}
            />
            {showingSettingsDialog && (
              <SettingsDialog>
                <CheckboxToggle
                  name="compareUsingGradient"
                  checked={compareUsingGradient}
                  setChecked={setCompareUsingGradient}
                  disabled={!inCombinedViewMode}
                >
                  Compare using color gradient
                </CheckboxToggle>
                <div>
                  Iterations to show
                  <input
                    type="radio"
                    id="show-latest"
                    value="show-latest"
                    checked={accordionMode === 'show-latest'}
                    onChange={handleAccordionModeChange}
                  />
                  <label htmlFor="show-latest">Latest</label>
                  <input
                    type="radio"
                    id="show-labeled"
                    value="show-labeled"
                    checked={accordionMode === 'show-labeled'}
                    onChange={handleAccordionModeChange}
                  />
                  <label htmlFor="show-labeled">Labeled</label>
                  <input
                    type="radio"
                    id="show-all"
                    value="show-all"
                    checked={accordionMode === 'show-all'}
                    onChange={handleAccordionModeChange}
                  />
                  <label htmlFor="show-all">All</label>
                </div>
                <CheckboxToggle
                  name="enableOptimisticLoading"
                  checked={enableOptimisticLoading}
                  setChecked={setEnableOptimisticLoading}
                >
                  Preload measurement invocations
                </CheckboxToggle>
              </SettingsDialog>
            )}
          </FlexContainer>
          <InlineButton disabled={!canCommit} onClick={() => handleCommit()}>
            Commit {viewModel.deltaCount}{' '}
            {maybePluralize('change', viewModel.deltaCount)}
          </InlineButton>
        </BottomAlignedFlexRow>
      </Header>
      {headerQuery.error && <p>Oh no! {headerQuery.error.message}</p>}
      {detailQuery.error && <p>Oh no! {detailQuery.error.message}</p>}
      {selectionGeometry.curveLoadingError && (
        <p>
          Oh no! Error loading curve:{' '}
          {selectionGeometry.curveLoadingError.message}
        </p>
      )}
      {compareToGeometry.curveLoadingError && (
        <p>
          Oh no! Error loading comparison curve:
          {compareToGeometry.curveLoadingError.message}
        </p>
      )}
      <InterfaceGrid>
        <NavContainer>
          <StatisticalLabel htmlFor="measurement">Measurement</StatisticalLabel>
          <MeasurementPicker
            measurementChoices={headerViewModel.measurementChoices}
            selectedMeasurement={selectedMeasurement}
            onChange={measurementName => {
              navigateToReviewMeasurements({
                measurementName,
                measurementInvocationId: null,
              })
            }}
          />
          <StatisticalLabel htmlFor="compareTo">Compare to</StatisticalLabel>
          <IterationPicker
            iterationChoices={viewModel.iterationChoices}
            selectedIteration={selectedCompareToIteration}
            onChange={iteration =>
              navigateToReviewMeasurements({
                measurementInvocationId: selectedMeasurementInvocationId,
                compareToIteration: iteration,
              })
            }
            onClear={() => {
              navigateToReviewMeasurements({
                measurementInvocationId: selectedMeasurementInvocationId,
                compareToIteration: null,
                compareToInvocationId: selectedComparisonInvocationId,
              })
            }}
            isClearable
          />
          {showFilterOptions && (
            <FilterMenu
              filterChoices={viewModel.filterChoices}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              onRequestClose={() => setShowFilterOptions(false)}
              selectedGenders={selectedGenders}
              setSelectedGenders={setSelectedGenders}
            />
          )}
        </NavContainer>
        <LabelsContainer>
          <StatisticalDivBoldWithTopPadding>
            Labels
          </StatisticalDivBoldWithTopPadding>
          <FlexRow>
            <AvailableLabelsContainer>
              {viewModel.selection.labelChoices.map(
                ({ label, commitState }) => {
                  const isSelected =
                    commitState === 'committed' || commitState === 'added'
                  return (
                    <DisplayLabel
                      key={hashKeyForLabel(label)}
                      label={label}
                      $isUpdated={false}
                      $isSelected={isSelected}
                      onClick={() => {
                        dispatchLocalStorageAction({
                          type: isSelected ? 'removeLabel' : 'addLabel',
                          measurementInvocationId:
                            selectedMeasurementInvocationId,
                          label,
                        })
                        scrollIntoView()
                      }}
                      disabled={!canEdit}
                    />
                  )
                }
              )}
            </AvailableLabelsContainer>
            <NewLabelContainer>
              <LabelCreationComponent
                onCreate={(label: Label) => {
                  performAddOrRemoveLabel({
                    type: 'addLabel',
                    label,
                    isFresh: true,
                  })
                }}
                disabled={!canEdit}
                labelChoices={viewModel.selection.labelChoices.map(
                  label => label.label
                )}
              />
              {isComparing &&
                viewModel.selection.iteration?.labels.length === 0 &&
                compareToViewModel.invocation?.labels.length !== 0 && (
                  <DivWithButtonandText
                    onClick={() => {
                      if (compareToViewModel.invocation) {
                        dispatchLocalStorageAction({
                          type: 'addLabels',
                          labels: compareToViewModel.invocation.labels
                            .filter(label => label.commitState !== 'removed')
                            .map(label => label.label),
                          measurementInvocationId:
                            selectedMeasurementInvocationId,
                        })
                      }
                    }}
                  >
                    <FlexRowWithPadding>
                      <CopyIcon /> Copy
                    </FlexRowWithPadding>
                  </DivWithButtonandText>
                )}
            </NewLabelContainer>
          </FlexRow>
        </LabelsContainer>
        <SubjectsColumn>
          {detailQuery.loading &&
            detailQuery.networkStatus !== NetworkStatus.poll && (
              <p>Loading &hellip;</p>
            )}
          <SubjectListHeading>
            <CenterAlignedStatisticalDiv>
              Subjects
              {detailQuery.networkStatus === NetworkStatus.poll && (
                <LoadingIcon height={20} width={20} />
              )}
            </CenterAlignedStatisticalDiv>
            <div>
              <FilterIcon
                fillOpacity={
                  hashKeyForFilter(activeFilter) ===
                  hashKeyForFilter(FILTER_ALL)
                    ? '0.56'
                    : '1.0'
                }
                onClick={() => setShowFilterOptions(!showFilterOptions)}
              />
              <SortByPercentChangedIcon
                fillOpacity={sortBy === SortBy.PERCENT_CHANGED ? '1.0' : '0.56'}
                onClick={() =>
                  navigateToReviewMeasurements({
                    sortBy:
                      sortBy === SortBy.PERCENT_CHANGED
                        ? undefined
                        : SortBy.PERCENT_CHANGED,
                  })
                }
              />
              <SortByValueDifferenceIcon
                shouldSortByValueDifference={sortBy === SortBy.VALUE_DIFFERENCE}
                onClick={() =>
                  navigateToReviewMeasurements({
                    sortBy:
                      sortBy === SortBy.VALUE_DIFFERENCE
                        ? undefined
                        : SortBy.VALUE_DIFFERENCE,
                  })
                }
              >
                #
              </SortByValueDifferenceIcon>
            </div>
          </SubjectListHeading>
          <ScrollingSubjectContainer>
            <Subjects
              subjects={viewModel.visibleSubjects}
              displayPoses={viewModel.studyHasMultiplePoses}
              {...{
                compareToViewModel,
                selectedMeasurementInvocationId,
                navigateToReviewMeasurements,
                dispatchLocalStorageAction,
                dispatchAccordionAction,
                sortBy,
              }}
            />
          </ScrollingSubjectContainer>
          <ButtonContainer>
            {isConfirmingDiscardChanges ? (
              <Confirm
                message={`${viewModel.deltaCount} ${maybePluralize(
                  'change',
                  viewModel.deltaCount
                )} will be discarded.`}
                actionLabel="Discard"
                stacked
                onCancel={() => setIsConfirmingDiscardChanges(false)}
                onConfirm={() => {
                  dispatchLocalStorageAction({ type: 'resetDeltas' })
                  setIsConfirmingDiscardChanges(false)
                }}
              />
            ) : (
              <>
                <Button
                  isFixedHeight
                  disabled={
                    !canEdit || selectedCompareToIteration === undefined
                  }
                  onClick={performCopyAll}
                >
                  <CopyIcon />
                  &nbsp;all
                </Button>
                <Button
                  isFixedHeight
                  disabled={!canEdit}
                  onClick={performApproveAll}
                >
                  <DisplayLabel label={BLANK_APPROVED_LABEL} disabled />
                  &nbsp;all
                </Button>
                <Button
                  disabled={!canCommit}
                  onClick={() => setIsConfirmingDiscardChanges(true)}
                >
                  Discard changes
                </Button>
              </>
            )}
          </ButtonContainer>
        </SubjectsColumn>
        <ScrollingFlexRow>
          <FlexContainer flexDirection="row">
            <FlexContainer flexDirection="column">
              {viewModel.selection.iteration?.views.map((view, index) => {
                const isFirstView = index === 0
                const comparisonMetric =
                  viewModel.selection.iteration?.comparisonMetrics.find(
                    comparison =>
                      comparison.previousInvocationId ===
                      compareToViewModel.invocation?.measurementInvocationId
                  )
                return (
                  <CanvasContextProvider>
                    <Viewer
                      {...commonViewerAttributes}
                      superiorAxis={
                        viewModel.selection.iteration?.geometry.superiorAxis
                      }
                      anteriorAxis={
                        viewModel.selection.iteration?.geometry.anteriorAxis
                      }
                      units={
                        viewModel.selection.iteration?.geometry.units?.toLowerCase() as LengthUnits
                      }
                      containerCSS={css`
                        width: 650px;
                        margin: 10px 20px 20px 20px;
                      `}
                      body={selectionGeometry.body}
                      bodyS3Key={viewModel.selection.iteration?.geometry.s3Key}
                      flatViewUrl={view.screenshotSignedURL ?? undefined}
                      curve={selectionGeometry.curve}
                      compareToCurve={
                        inCombinedViewMode ? compareToGeometry.curve : undefined
                      }
                      tapeWidth={
                        selectionGeometry.curveData?.tapeWidth ?? undefined
                      }
                      initialView={adaptView({
                        view: view.view,
                        height: 582,
                        width: 648,
                      })}
                      url={viewModel.selection.iteration?.geometry.signedURL}
                      key={view.viewIndex}
                      renderHeader={() => (
                        <ViewerHeader>
                          {isComparing &&
                            isFirstView &&
                            viewModel.selection.iteration && (
                              <FlexRowWithMargin>
                                {isComparing &&
                                  isFirstView &&
                                  inCombinedViewMode &&
                                  compareToViewModel.invocation && (
                                    <IterationDisplay
                                      isLight={inCombinedViewMode}
                                      iteration={
                                        compareToViewModel.invocation
                                          .measurementInvocationIteration
                                      }
                                    />
                                  )}
                                <IterationDisplay
                                  iteration={
                                    viewModel.selection.iteration
                                      .measurementInvocationIteration
                                  }
                                />
                                {comparisonMetric && (
                                  <MetricComparisonWithIcon
                                    displayMetric={
                                      comparisonMetric.pathDifferenceDisplayValue
                                    }
                                  />
                                )}
                              </FlexRowWithMargin>
                            )}
                        </ViewerHeader>
                      )}
                      renderFooter={
                        isFirstView && initialValue && units
                          ? () => (
                              <ViewerFooter
                                displayValue={initialValue}
                                units={units}
                                compareTo={compareValue}
                                displayCompareValue={
                                  inCombinedViewMode &&
                                  isFirstView &&
                                  compareValue
                                    ? compareValue
                                    : undefined
                                }
                              />
                            )
                          : undefined
                      }
                      onRequestClose={
                        isFirstView &&
                        selectedComparisonInvocationId !== undefined
                          ? () => {
                              if (compareToViewModel.invocation) {
                                const { measurementInvocationId } =
                                  compareToViewModel.invocation
                                navigateToReviewMeasurements({
                                  measurementInvocationId,
                                })
                              }
                            }
                          : undefined
                      }
                      onDownload={() => {
                        if (
                          selectedMeasurement !== undefined &&
                          initialValue !== undefined &&
                          units !== undefined &&
                          selectionGeometry.curve !== undefined &&
                          selectionGeometry.curveData !== null &&
                          viewModel.selection.subject !== undefined &&
                          viewModel.selection.iteration !== undefined
                        ) {
                          const data = generateLegacyMeasuredBody({
                            measurementName: selectedMeasurement,
                            value: initialValue,
                            units: units.toLowerCase() as AnyUnits,
                            curve: selectionGeometry.curve,
                            tapeWidth: selectionGeometry.curveData.tapeWidth,
                          })
                          const filename = [
                            selectedMeasurement,
                            viewModel.selection.subject?.subjectName,
                            `i${viewModel.selection.iteration.measurementInvocationIteration}.json`,
                          ].join('_')
                          downloadJsonContent({ data, filename })
                        }
                      }}
                      disabled={
                        viewModel.selection?.iteration?.stateMachineStateId !==
                        'SUCCESS'
                      }
                      toggleCombinedView={e => {
                        setInCombinedViewMode(!inCombinedViewMode)
                      }}
                      showToggleCombinedViewControls={isComparing}
                      inCombinedViewMode={inCombinedViewMode}
                      compareCurvesUsingColorGradient={compareUsingGradient}
                    />
                  </CanvasContextProvider>
                )
              })}
            </FlexContainer>
            {isComparing && !inCombinedViewMode && (
              <FlexContainer flexDirection="column">
                {compareToViewModel.invocation?.views.map((view, index) => {
                  const isFirstView = index === 0

                  return (
                    <CanvasContextProvider>
                      <Viewer
                        {...commonViewerAttributes}
                        superiorAxis={
                          compareToViewModel.invocation?.geometry.superiorAxis
                        }
                        anteriorAxis={
                          compareToViewModel.invocation?.geometry.anteriorAxis
                        }
                        units={
                          compareToViewModel.invocation?.geometry.units?.toLowerCase() as LengthUnits
                        }
                        containerCSS={css`
                          width: 650px;
                          margin: 10px 0px 20px 20px;
                        `}
                        body={compareToGeometry.body}
                        bodyS3Key={
                          compareToViewModel.invocation?.geometry.s3Key
                        }
                        flatViewUrl={view.screenshotSignedURL ?? undefined}
                        curve={compareToGeometry.curve}
                        tapeWidth={
                          compareToGeometry.curveData?.tapeWidth ?? undefined
                        }
                        initialView={adaptView({
                          view: view.view,
                          height: 582,
                          width: 648,
                        })}
                        url={compareToViewModel.invocation?.geometry.signedURL}
                        key={view.viewIndex}
                        renderHeader={() => (
                          <ViewerHeader>
                            {isComparing && isFirstView && (
                              <IterationDisplay
                                iteration={
                                  (
                                    compareToViewModel.invocation as IterationViewModel
                                  ).measurementInvocationIteration
                                }
                              />
                            )}
                          </ViewerHeader>
                        )}
                        renderFooter={
                          isFirstView && compareValue && units
                            ? () => (
                                <ViewerFooter
                                  displayValue={compareValue}
                                  units={units}
                                />
                              )
                            : undefined
                        }
                        onRequestClose={
                          isFirstView
                            ? () =>
                                navigateToReviewMeasurements({
                                  measurementInvocationId:
                                    selectedMeasurementInvocationId,
                                  compareToIteration: null,
                                })
                            : undefined
                        }
                        disabled={
                          compareToViewModel.invocation?.stateMachineStateId !==
                          'SUCCESS'
                        }
                        toggleCombinedView={e => {
                          setInCombinedViewMode(!inCombinedViewMode)
                        }}
                      />
                    </CanvasContextProvider>
                  )
                })}
              </FlexContainer>
            )}
          </FlexContainer>
        </ScrollingFlexRow>
      </InterfaceGrid>
    </PageContainer>
  )
}
