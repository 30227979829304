import { gql, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  ButtonStyledInput,
  FlexColumn,
} from '@unpublished/common-components'
import { maybePluralize } from '@unpublished/victorinox'
import copy from 'copy-to-clipboard'
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  ErrorText,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  StyledInput,
  Table,
} from '../common/common-components'
import {
  deriveGeometryIdsFromSubjects,
  getUniquePoseNamesFromDataset,
  mapNumberNodeToOption,
} from '../common/data-transforms'
import {
  CreateCheckoutMutation,
  CreateCheckoutMutationVariables,
  ViewGeometriesFromDatasetQuery,
  ViewGeometriesFromDatasetQueryVariables,
} from '../common/generated'
import { Option, ToggleableButtonList } from '../common/toggleable-button-list'
import { useNumericParam } from '../common/use-numeric-param'
import { ContainerWithSpaceAround } from '../measurement-studies/duplicate'

const NextButton = styled(ButtonStyledInput)`
  float: right;
`
const ContainerWithAddedPadding = styled(ContainerWithSpaceAround)`
  margin-top: 30px;
  width: 800px;
`
const TextAreaInput = styled.textarea`
  border: 1px solid #bdbdbd;
  width: 350px;
  height: 60px;
  padding: 10px;
  font-family: sans-serif;
  font-size: 14px;
`
const RightAlignedColumn = styled(FlexColumn)`
  align-items: flex-end;
  float: right;
  div {
    margin: 15px;
  }
`

export function CheckoutGeometries(dataLayerUrl: string): JSX.Element {
  const selectedDataset = useNumericParam('selectedDataset')

  const { loading, error, data } = useQuery<
    ViewGeometriesFromDatasetQuery,
    ViewGeometriesFromDatasetQueryVariables
  >(
    gql`
      query ViewGeometriesFromDataset($datasetId: Int!) {
        datasetById(id: $datasetId) {
          bodyPart
          id
          name
          activeTopology
          subjectsByDatasetId(orderBy: NAME_ASC) {
            nodes {
              gender
              name
              id
              geometrySeriesBySubjectId {
                nodes {
                  topology
                  poseTypeId
                  poseTypeByPoseTypeId {
                    name
                  }
                  geometriesByGeometrySeriesId {
                    nodes {
                      version
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { datasetId: selectedDataset } }
  )

  interface ViewModel {
    poseOptions: Option<string>[]
    femaleSubjectOptions: Option<number>[]
    maleSubjectOptions: Option<number>[]
  }

  const [selectedPoses, setSelectedPoses] = useState<Set<string>>(new Set())
  const [selectedMaleSubjects, setMaleState] = useState<Set<number>>(new Set())
  const [selectedFemaleSubjects, setFemaleState] = useState<Set<number>>(
    new Set()
  )
  const [email, setEmail] = useState<string>('')
  const emailRef = useRef<HTMLInputElement>(null)
  const [purpose, setPurpose] = useState<string>('')
  const [isCreatingCheckout, setIsCreatingCheckout] = useState<boolean>(false)
  const [checkedOutGeometryCount, setCheckedOutGeometryCount] =
    useState<number>(0)
  const hasCreatedCheckout = checkedOutGeometryCount > 0
  const { getAccessTokenSilently } = useAuth0()
  const [hasCopiedCommand, setHasCopiedCommand] = useState<boolean>(false)

  const navigate = useNavigate()

  const subjectNodes = data?.datasetById.subjectsByDatasetId.nodes

  function createViewModel(data?: ViewGeometriesFromDatasetQuery): ViewModel {
    return {
      maleSubjectOptions: subjectNodes
        ? subjectNodes
            .filter(subject => subject.gender === 'M')
            .map(mapNumberNodeToOption)
        : [],
      femaleSubjectOptions: subjectNodes
        ? subjectNodes
            .filter(subject => subject.gender === 'F')
            .map(mapNumberNodeToOption)
        : [],
      poseOptions: data
        ? getUniquePoseNamesFromDataset(data.datasetById).map(poseName => ({
            label: poseName,
            value: poseName,
          }))
        : [],
    }
  }

  const viewModel = createViewModel(data)

  const selectedGeometryIds = deriveGeometryIdsFromSubjects({
    subjects: subjectNodes ?? [],
    selectedFemaleSubjects,
    selectedMaleSubjects,
    selectedPoses,
  })

  const humanizedGeometryCount = `${
    selectedGeometryIds.length
  } ${maybePluralize('geometry', selectedGeometryIds.length)}`

  let buttonText: string
  if (isCreatingCheckout) {
    buttonText = `Checking out ${humanizedGeometryCount}`
  } else if (selectedGeometryIds.length) {
    buttonText = `Check out ${humanizedGeometryCount}`
  } else {
    buttonText = 'Check out geometries'
  }

  const [
    createCheckout,
    { error: createCheckoutError, data: createCheckoutData },
  ] = useMutation<CreateCheckoutMutation, CreateCheckoutMutationVariables>(
    gql`
      mutation CreateCheckout($input: NewCheckoutInput!) {
        createCheckout(input: $input) {
          checkout {
            id
            checkoutsGeometriesLookupsByCheckoutId {
              totalCount
            }
          }
        }
      }
    `,
    {
      variables: {
        input: {
          email,
          purpose,
          geometryIds: selectedGeometryIds,
        },
      },
      onCompleted: data => {
        setIsCreatingCheckout(false)
        setCheckedOutGeometryCount(
          data.createCheckout?.checkout?.checkoutsGeometriesLookupsByCheckoutId
            .totalCount ?? 0
        )
      },
      onError() {
        setIsCreatingCheckout(false)
      },
    }
  )

  async function handleCopyToClipboard(): Promise<void> {
    const accessToken = await getAccessTokenSilently()
    const downloadCommand = [
      'npx',
      'ts-node',
      'src/scripts/checkout-geometries-downloader.ts',
      '-b',
      accessToken,
      dataLayerUrl,
      createCheckoutData?.createCheckout?.checkout?.id,
    ].join(' ')
    copy(downloadCommand)
    setHasCopiedCommand(true)
  }

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'> '} <Link to="/datasets">Datasets</Link>{' '}
        {'>'}{' '}
        <Link to={`/datasets/${selectedDataset}/subjects`}>
          {data?.datasetById.name}
        </Link>{' '}
        {'>'} Checkout
      </Breadcrumb>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      {data && (
        <div>
          <h1>{data.datasetById.name}</h1>
          <h2>Choose geometries</h2>
          <Table>
            <thead>
              <tr>
                <th>Poses</th>
                <th>Female</th>
                <th>Male</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ToggleableButtonList
                    options={viewModel.poseOptions}
                    onChange={newSet => setSelectedPoses(newSet)}
                    selectedOptionValues={selectedPoses}
                    disabled={hasCreatedCheckout}
                  />
                </td>
                <td>
                  <ToggleableButtonList
                    options={viewModel.femaleSubjectOptions}
                    onChange={newSet => setFemaleState(newSet)}
                    selectedOptionValues={selectedFemaleSubjects}
                    disabled={hasCreatedCheckout}
                  />
                </td>
                <td>
                  <ToggleableButtonList
                    options={viewModel.maleSubjectOptions}
                    onChange={newSet => setMaleState(newSet)}
                    selectedOptionValues={selectedMaleSubjects}
                    disabled={hasCreatedCheckout}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <ContainerWithAddedPadding>
            <div>email</div>
            <StyledInput
              type="email"
              id="email"
              ref={emailRef}
              placeholder="email"
              required
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              disabled={hasCreatedCheckout}
            />
            <div>purpose</div>
            <TextAreaInput
              id="purpose"
              placeholder="These geometries will be used for..."
              required
              value={purpose}
              onChange={e => setPurpose(e.currentTarget.value)}
              disabled={hasCreatedCheckout}
            />
          </ContainerWithAddedPadding>
          <FixedWidthFooterNavContainer>
            <FlexColumn>
              <div>
                <Button
                  onClick={() =>
                    navigate(`/datasets/${selectedDataset}/subjects`)
                  }
                >
                  Cancel
                </Button>
                <NextButton
                  type="submit"
                  onClick={e => {
                    setIsCreatingCheckout(true)
                    createCheckout()
                    e.preventDefault()
                  }}
                  value={buttonText}
                  disabled={
                    selectedGeometryIds?.length === 0 ||
                    emailRef.current?.validity.valid === false ||
                    !purpose ||
                    isCreatingCheckout ||
                    checkedOutGeometryCount > 0
                  }
                />
              </div>

              {createCheckoutError && (
                <ErrorText>Error: {createCheckoutError.message}</ErrorText>
              )}
            </FlexColumn>
            {hasCreatedCheckout && (
              <RightAlignedColumn>
                <div>{humanizedGeometryCount} checked out</div>
                <Button onClick={handleCopyToClipboard}>
                  Copy command to Clipboard
                </Button>
                {hasCopiedCommand && <div>Copied</div>}
              </RightAlignedColumn>
            )}
          </FixedWidthFooterNavContainer>
        </div>
      )}
    </FixedWidthPageContainer>
  )
}
