export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
  Cursor: any
  Date: any
  Datetime: any
}

export type AddRemoveGeometriesToMeasurementStudyInput = {
  geometryIdsToAdd?: InputMaybe<Array<Scalars['Int']>>
  geometryIdsToRemove?: InputMaybe<Array<Scalars['Int']>>
  measurementStudyId: Scalars['Int']
}

export type AddRemoveGeometriesToMeasurementStudyResponse = {
  __typename?: 'AddRemoveGeometriesToMeasurementStudyResponse'
  commit: Maybe<Commit>
}

export enum AxisType {
  NegX = 'NEG_X',
  NegXNegY = 'NEG_X_NEG_Y',
  NegY = 'NEG_Y',
  NegZ = 'NEG_Z',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum BodyPartType {
  Body = 'BODY',
  Foot = 'FOOT',
  Hand = 'HAND',
}

export type CheckedUpload = Node & {
  __typename?: 'CheckedUpload'
  eTag: Scalars['String']
  isValidObj: Scalars['Boolean']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  predictedBodyUnits: Maybe<UnitsType>
  predictedHandUnits: Maybe<UnitsType>
  topology: Maybe<TopologyType>
}

/**
 * A condition to be used against `CheckedUpload` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CheckedUploadCondition = {
  /** Checks for equality with the object’s `eTag` field. */
  eTag?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `isValidObj` field. */
  isValidObj?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `predictedBodyUnits` field. */
  predictedBodyUnits?: InputMaybe<UnitsType>
  /** Checks for equality with the object’s `predictedHandUnits` field. */
  predictedHandUnits?: InputMaybe<UnitsType>
  /** Checks for equality with the object’s `topology` field. */
  topology?: InputMaybe<TopologyType>
}

/** An input for mutations affecting `CheckedUpload` */
export type CheckedUploadInput = {
  eTag: Scalars['String']
  isValidObj: Scalars['Boolean']
  predictedBodyUnits?: InputMaybe<UnitsType>
  predictedHandUnits?: InputMaybe<UnitsType>
  topology?: InputMaybe<TopologyType>
}

/** Represents an update to a `CheckedUpload`. Fields that are set will be updated. */
export type CheckedUploadPatch = {
  eTag?: InputMaybe<Scalars['String']>
  isValidObj?: InputMaybe<Scalars['Boolean']>
  predictedBodyUnits?: InputMaybe<UnitsType>
  predictedHandUnits?: InputMaybe<UnitsType>
  topology?: InputMaybe<TopologyType>
}

/** A connection to a list of `CheckedUpload` values. */
export type CheckedUploadsConnection = {
  __typename?: 'CheckedUploadsConnection'
  /** A list of edges which contains the `CheckedUpload` and cursor to aid in pagination. */
  edges: Array<CheckedUploadsEdge>
  /** A list of `CheckedUpload` objects. */
  nodes: Array<CheckedUpload>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `CheckedUpload` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `CheckedUpload` edge in the connection. */
export type CheckedUploadsEdge = {
  __typename?: 'CheckedUploadsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `CheckedUpload` at the end of the edge. */
  node: CheckedUpload
}

/** Methods to use when ordering `CheckedUpload`. */
export enum CheckedUploadsOrderBy {
  ETagAsc = 'E_TAG_ASC',
  ETagDesc = 'E_TAG_DESC',
  IsValidObjAsc = 'IS_VALID_OBJ_ASC',
  IsValidObjDesc = 'IS_VALID_OBJ_DESC',
  Natural = 'NATURAL',
  PredictedBodyUnitsAsc = 'PREDICTED_BODY_UNITS_ASC',
  PredictedBodyUnitsDesc = 'PREDICTED_BODY_UNITS_DESC',
  PredictedHandUnitsAsc = 'PREDICTED_HAND_UNITS_ASC',
  PredictedHandUnitsDesc = 'PREDICTED_HAND_UNITS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TopologyAsc = 'TOPOLOGY_ASC',
  TopologyDesc = 'TOPOLOGY_DESC',
}

export type Checkout = Node & {
  __typename?: 'Checkout'
  /** Reads and enables pagination through a set of `CheckoutAccess`. */
  checkoutAccessesByCheckoutId: CheckoutAccessesConnection
  /** Reads and enables pagination through a set of `CheckoutsGeometriesLookup`. */
  checkoutsGeometriesLookupsByCheckoutId: CheckoutsGeometriesLookupsConnection
  dateCreated: Scalars['Datetime']
  email: Scalars['String']
  id: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  purpose: Scalars['String']
}

export type CheckoutCheckoutAccessesByCheckoutIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutAccessCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutAccessesOrderBy>>
}

export type CheckoutCheckoutsGeometriesLookupsByCheckoutIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
}

export type CheckoutAccess = Node & {
  __typename?: 'CheckoutAccess'
  accessDate: Scalars['Datetime']
  /** Reads a single `Checkout` that is related to this `CheckoutAccess`. */
  checkoutByCheckoutId: Checkout
  checkoutId: Scalars['Int']
  id: Scalars['Int']
  ipAddresses: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

/**
 * A condition to be used against `CheckoutAccess` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CheckoutAccessCondition = {
  /** Checks for equality with the object’s `accessDate` field. */
  accessDate?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `checkoutId` field. */
  checkoutId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `ipAddresses` field. */
  ipAddresses?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `CheckoutAccess` values. */
export type CheckoutAccessesConnection = {
  __typename?: 'CheckoutAccessesConnection'
  /** A list of edges which contains the `CheckoutAccess` and cursor to aid in pagination. */
  edges: Array<CheckoutAccessesEdge>
  /** A list of `CheckoutAccess` objects. */
  nodes: Array<CheckoutAccess>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `CheckoutAccess` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `CheckoutAccess` edge in the connection. */
export type CheckoutAccessesEdge = {
  __typename?: 'CheckoutAccessesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `CheckoutAccess` at the end of the edge. */
  node: CheckoutAccess
}

/** Methods to use when ordering `CheckoutAccess`. */
export enum CheckoutAccessesOrderBy {
  AccessDateAsc = 'ACCESS_DATE_ASC',
  AccessDateDesc = 'ACCESS_DATE_DESC',
  CheckoutIdAsc = 'CHECKOUT_ID_ASC',
  CheckoutIdDesc = 'CHECKOUT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IpAddressesAsc = 'IP_ADDRESSES_ASC',
  IpAddressesDesc = 'IP_ADDRESSES_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** An input for mutations affecting `CheckoutAccess` */
export type CheckoutAccessInput = {
  accessDate?: InputMaybe<Scalars['Datetime']>
  checkoutId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  ipAddresses: Scalars['String']
}

/** Represents an update to a `CheckoutAccess`. Fields that are set will be updated. */
export type CheckoutAccessPatch = {
  accessDate?: InputMaybe<Scalars['Datetime']>
  checkoutId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  ipAddresses?: InputMaybe<Scalars['String']>
}

/**
 * A condition to be used against `Checkout` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CheckoutCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `purpose` field. */
  purpose?: InputMaybe<Scalars['String']>
}

/** Represents an update to a `Checkout`. Fields that are set will be updated. */
export type CheckoutPatch = {
  dateCreated?: InputMaybe<Scalars['Datetime']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  purpose?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `Checkout` values. */
export type CheckoutsConnection = {
  __typename?: 'CheckoutsConnection'
  /** A list of edges which contains the `Checkout` and cursor to aid in pagination. */
  edges: Array<CheckoutsEdge>
  /** A list of `Checkout` objects. */
  nodes: Array<Checkout>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Checkout` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Checkout` edge in the connection. */
export type CheckoutsEdge = {
  __typename?: 'CheckoutsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Checkout` at the end of the edge. */
  node: Checkout
}

export type CheckoutsGeometriesLookup = Node & {
  __typename?: 'CheckoutsGeometriesLookup'
  /** Reads a single `Checkout` that is related to this `CheckoutsGeometriesLookup`. */
  checkoutByCheckoutId: Checkout
  checkoutId: Scalars['Int']
  /** Reads a single `Geometry` that is related to this `CheckoutsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

/**
 * A condition to be used against `CheckoutsGeometriesLookup` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CheckoutsGeometriesLookupCondition = {
  /** Checks for equality with the object’s `checkoutId` field. */
  checkoutId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `CheckoutsGeometriesLookup` */
export type CheckoutsGeometriesLookupInput = {
  checkoutId: Scalars['Int']
  geometryId: Scalars['Int']
}

/** Represents an update to a `CheckoutsGeometriesLookup`. Fields that are set will be updated. */
export type CheckoutsGeometriesLookupPatch = {
  checkoutId?: InputMaybe<Scalars['Int']>
  geometryId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `CheckoutsGeometriesLookup` values. */
export type CheckoutsGeometriesLookupsConnection = {
  __typename?: 'CheckoutsGeometriesLookupsConnection'
  /** A list of edges which contains the `CheckoutsGeometriesLookup` and cursor to aid in pagination. */
  edges: Array<CheckoutsGeometriesLookupsEdge>
  /** A list of `CheckoutsGeometriesLookup` objects. */
  nodes: Array<CheckoutsGeometriesLookup>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `CheckoutsGeometriesLookup` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `CheckoutsGeometriesLookup` edge in the connection. */
export type CheckoutsGeometriesLookupsEdge = {
  __typename?: 'CheckoutsGeometriesLookupsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `CheckoutsGeometriesLookup` at the end of the edge. */
  node: CheckoutsGeometriesLookup
}

/** Methods to use when ordering `CheckoutsGeometriesLookup`. */
export enum CheckoutsGeometriesLookupsOrderBy {
  CheckoutIdAsc = 'CHECKOUT_ID_ASC',
  CheckoutIdDesc = 'CHECKOUT_ID_DESC',
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** Methods to use when ordering `Checkout`. */
export enum CheckoutsOrderBy {
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PurposeAsc = 'PURPOSE_ASC',
  PurposeDesc = 'PURPOSE_DESC',
}

export type Commit = Node & {
  __typename?: 'Commit'
  /** Reads a single `Commit` that is related to this `Commit`. */
  commitByParentCommitId: Commit
  /** Reads and enables pagination through a set of `Commit`. */
  commitsByParentCommitId: CommitsConnection
  /** Reads and enables pagination through a set of `CommitsGeometriesLookup`. */
  commitsGeometriesLookupsByCommitId: CommitsGeometriesLookupsConnection
  created: Scalars['Datetime']
  /** Reads and enables pagination through a set of `FlattenedCommit`. */
  flattenedCommitsByCommitId: FlattenedCommitsConnection
  /** Reads and enables pagination through a set of `FlattenedCommit`. */
  flattenedCommitsByHeadCommitId: FlattenedCommitsConnection
  /** Reads and enables pagination through a set of `FlattenedCommit`. */
  flattenedCommitsByParentCommitId: FlattenedCommitsConnection
  /** Reads and enables pagination through a set of `HeadCommit`. */
  headCommitsByCommitId: HeadCommitsConnection
  /** Reads and enables pagination through a set of `HeadCommit`. */
  headCommitsByHeadCommitId: HeadCommitsConnection
  /** Reads and enables pagination through a set of `HeadCommit`. */
  headCommitsByParentCommitId: HeadCommitsConnection
  id: Scalars['Int']
  /** Reads and enables pagination through a set of `LabelsOfHeadCommit`. */
  labelsOfHeadCommitsByCommitId: LabelsOfHeadCommitsConnection
  /** Reads and enables pagination through a set of `LatestIterationsByMeasurement`. */
  latestIterationsByMeasurementsByMeasurementInvocationCommitId: LatestIterationsByMeasurementsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationIteration`. */
  measurementInvocationIterationsByMeasurementInvocationCommitId: MeasurementInvocationIterationsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  measurementInvocationsByCommitId: MeasurementInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurementStudy`. */
  measurementStudiesByHeadCommitId: MeasurementStudiesConnection
  /** Reads a single `MeasurerVersion` that is related to this `Commit`. */
  measurerVersionByMeasurerVersionId: MeasurerVersion
  measurerVersionId: Maybe<Scalars['Int']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  parentCommitId: Maybe<Scalars['Int']>
}

export type CommitCommitsByParentCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

export type CommitCommitsGeometriesLookupsByCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
}

export type CommitFlattenedCommitsByCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedCommitsOrderBy>>
}

export type CommitFlattenedCommitsByHeadCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedCommitsOrderBy>>
}

export type CommitFlattenedCommitsByParentCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedCommitsOrderBy>>
}

export type CommitHeadCommitsByCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<HeadCommitsOrderBy>>
}

export type CommitHeadCommitsByHeadCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<HeadCommitsOrderBy>>
}

export type CommitHeadCommitsByParentCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<HeadCommitsOrderBy>>
}

export type CommitLabelsOfHeadCommitsByCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelsOfHeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOfHeadCommitsOrderBy>>
}

export type CommitLatestIterationsByMeasurementsByMeasurementInvocationCommitIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<LatestIterationsByMeasurementCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<LatestIterationsByMeasurementsOrderBy>>
  }

export type CommitMeasurementInvocationIterationsByMeasurementInvocationCommitIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationIterationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationIterationsOrderBy>>
  }

export type CommitMeasurementInvocationsByCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

export type CommitMeasurementStudiesByHeadCommitIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementStudyCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementStudiesOrderBy>>
}

/** A condition to be used against `Commit` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CommitCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurerVersionId` field. */
  measurerVersionId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `parentCommitId` field. */
  parentCommitId?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `Commit` */
export type CommitInput = {
  created?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['Int']>
  measurerVersionId?: InputMaybe<Scalars['Int']>
  parentCommitId?: InputMaybe<Scalars['Int']>
}

/** Represents an update to a `Commit`. Fields that are set will be updated. */
export type CommitPatch = {
  created?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['Int']>
  measurerVersionId?: InputMaybe<Scalars['Int']>
  parentCommitId?: InputMaybe<Scalars['Int']>
}

export type CommitResponse = {
  __typename?: 'CommitResponse'
  commit: Maybe<Commit>
}

/** A connection to a list of `Commit` values. */
export type CommitsConnection = {
  __typename?: 'CommitsConnection'
  /** A list of edges which contains the `Commit` and cursor to aid in pagination. */
  edges: Array<CommitsEdge>
  /** A list of `Commit` objects. */
  nodes: Array<Commit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Commit` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Commit` edge in the connection. */
export type CommitsEdge = {
  __typename?: 'CommitsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Commit` at the end of the edge. */
  node: Commit
}

export type CommitsGeometriesLookup = Node & {
  __typename?: 'CommitsGeometriesLookup'
  /** Reads a single `Commit` that is related to this `CommitsGeometriesLookup`. */
  commitByCommitId: Commit
  commitId: Scalars['Int']
  /** Reads a single `Geometry` that is related to this `CommitsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  measurementInvocationsByGeometryIdAndCommitId: MeasurementInvocationsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type CommitsGeometriesLookupMeasurementInvocationsByGeometryIdAndCommitIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
  }

/**
 * A condition to be used against `CommitsGeometriesLookup` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CommitsGeometriesLookupCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `CommitsGeometriesLookup` */
export type CommitsGeometriesLookupInput = {
  commitId: Scalars['Int']
  geometryId: Scalars['Int']
}

/** Represents an update to a `CommitsGeometriesLookup`. Fields that are set will be updated. */
export type CommitsGeometriesLookupPatch = {
  commitId?: InputMaybe<Scalars['Int']>
  geometryId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `CommitsGeometriesLookup` values. */
export type CommitsGeometriesLookupsConnection = {
  __typename?: 'CommitsGeometriesLookupsConnection'
  /** A list of edges which contains the `CommitsGeometriesLookup` and cursor to aid in pagination. */
  edges: Array<CommitsGeometriesLookupsEdge>
  /** A list of `CommitsGeometriesLookup` objects. */
  nodes: Array<CommitsGeometriesLookup>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `CommitsGeometriesLookup` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `CommitsGeometriesLookup` edge in the connection. */
export type CommitsGeometriesLookupsEdge = {
  __typename?: 'CommitsGeometriesLookupsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `CommitsGeometriesLookup` at the end of the edge. */
  node: CommitsGeometriesLookup
}

/** Methods to use when ordering `CommitsGeometriesLookup`. */
export enum CommitsGeometriesLookupsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** Methods to use when ordering `Commit`. */
export enum CommitsOrderBy {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeasurerVersionIdAsc = 'MEASURER_VERSION_ID_ASC',
  MeasurerVersionIdDesc = 'MEASURER_VERSION_ID_DESC',
  Natural = 'NATURAL',
  ParentCommitIdAsc = 'PARENT_COMMIT_ID_ASC',
  ParentCommitIdDesc = 'PARENT_COMMIT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** All input for the create `CheckedUpload` mutation. */
export type CreateCheckedUploadInput = {
  /** The `CheckedUpload` to be created by this mutation. */
  checkedUpload: CheckedUploadInput
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** The output of our create `CheckedUpload` mutation. */
export type CreateCheckedUploadPayload = {
  __typename?: 'CreateCheckedUploadPayload'
  /** The `CheckedUpload` that was created by this mutation. */
  checkedUpload: Maybe<CheckedUpload>
  /** An edge for our `CheckedUpload`. May be used by Relay 1. */
  checkedUploadEdge: Maybe<CheckedUploadsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `CheckedUpload` mutation. */
export type CreateCheckedUploadPayloadCheckedUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckedUploadsOrderBy>>
}

/** All input for the create `CheckoutAccess` mutation. */
export type CreateCheckoutAccessInput = {
  /** The `CheckoutAccess` to be created by this mutation. */
  checkoutAccess: CheckoutAccessInput
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** The output of our create `CheckoutAccess` mutation. */
export type CreateCheckoutAccessPayload = {
  __typename?: 'CreateCheckoutAccessPayload'
  /** The `CheckoutAccess` that was created by this mutation. */
  checkoutAccess: Maybe<CheckoutAccess>
  /** An edge for our `CheckoutAccess`. May be used by Relay 1. */
  checkoutAccessEdge: Maybe<CheckoutAccessesEdge>
  /** Reads a single `Checkout` that is related to this `CheckoutAccess`. */
  checkoutByCheckoutId: Checkout
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `CheckoutAccess` mutation. */
export type CreateCheckoutAccessPayloadCheckoutAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckoutAccessesOrderBy>>
}

export type CreateCheckoutResponse = {
  __typename?: 'CreateCheckoutResponse'
  checkout: Maybe<Checkout>
}

/** All input for the create `CheckoutsGeometriesLookup` mutation. */
export type CreateCheckoutsGeometriesLookupInput = {
  /** The `CheckoutsGeometriesLookup` to be created by this mutation. */
  checkoutsGeometriesLookup: CheckoutsGeometriesLookupInput
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** The output of our create `CheckoutsGeometriesLookup` mutation. */
export type CreateCheckoutsGeometriesLookupPayload = {
  __typename?: 'CreateCheckoutsGeometriesLookupPayload'
  /** Reads a single `Checkout` that is related to this `CheckoutsGeometriesLookup`. */
  checkoutByCheckoutId: Checkout
  /** The `CheckoutsGeometriesLookup` that was created by this mutation. */
  checkoutsGeometriesLookup: Maybe<CheckoutsGeometriesLookup>
  /** An edge for our `CheckoutsGeometriesLookup`. May be used by Relay 1. */
  checkoutsGeometriesLookupEdge: Maybe<CheckoutsGeometriesLookupsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `CheckoutsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `CheckoutsGeometriesLookup` mutation. */
export type CreateCheckoutsGeometriesLookupPayloadCheckoutsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
  }

/** All input for the create `Commit` mutation. */
export type CreateCommitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Commit` to be created by this mutation. */
  commit: CommitInput
}

/** The output of our create `Commit` mutation. */
export type CreateCommitPayload = {
  __typename?: 'CreateCommitPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Commit` that was created by this mutation. */
  commit: Maybe<Commit>
  /** Reads a single `Commit` that is related to this `Commit`. */
  commitByParentCommitId: Commit
  /** An edge for our `Commit`. May be used by Relay 1. */
  commitEdge: Maybe<CommitsEdge>
  /** Reads a single `MeasurerVersion` that is related to this `Commit`. */
  measurerVersionByMeasurerVersionId: MeasurerVersion
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Commit` mutation. */
export type CreateCommitPayloadCommitEdgeArgs = {
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

/** All input for the create `CommitsGeometriesLookup` mutation. */
export type CreateCommitsGeometriesLookupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `CommitsGeometriesLookup` to be created by this mutation. */
  commitsGeometriesLookup: CommitsGeometriesLookupInput
}

/** The output of our create `CommitsGeometriesLookup` mutation. */
export type CreateCommitsGeometriesLookupPayload = {
  __typename?: 'CreateCommitsGeometriesLookupPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `CommitsGeometriesLookup`. */
  commitByCommitId: Commit
  /** The `CommitsGeometriesLookup` that was created by this mutation. */
  commitsGeometriesLookup: Maybe<CommitsGeometriesLookup>
  /** An edge for our `CommitsGeometriesLookup`. May be used by Relay 1. */
  commitsGeometriesLookupEdge: Maybe<CommitsGeometriesLookupsEdge>
  /** Reads a single `Geometry` that is related to this `CommitsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `CommitsGeometriesLookup` mutation. */
export type CreateCommitsGeometriesLookupPayloadCommitsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
  }

/** All input for the create `Curve` mutation. */
export type CreateCurveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Curve` to be created by this mutation. */
  curve: CurveInput
}

/** The output of our create `Curve` mutation. */
export type CreateCurvePayload = {
  __typename?: 'CreateCurvePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Curve` that was created by this mutation. */
  curve: Maybe<Curve>
  /** An edge for our `Curve`. May be used by Relay 1. */
  curveEdge: Maybe<CurvesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Curve` mutation. */
export type CreateCurvePayloadCurveEdgeArgs = {
  orderBy?: InputMaybe<Array<CurvesOrderBy>>
}

/** All input for the create `Dataset` mutation. */
export type CreateDatasetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Dataset` to be created by this mutation. */
  dataset: DatasetInput
}

/** The output of our create `Dataset` mutation. */
export type CreateDatasetPayload = {
  __typename?: 'CreateDatasetPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Dataset` that was created by this mutation. */
  dataset: Maybe<Dataset>
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Dataset` mutation. */
export type CreateDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>
}

/** All input for the create `GeometryAnnotation` mutation. */
export type CreateGeometryAnnotationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `GeometryAnnotation` to be created by this mutation. */
  geometryAnnotation: GeometryAnnotationInput
}

/** The output of our create `GeometryAnnotation` mutation. */
export type CreateGeometryAnnotationPayload = {
  __typename?: 'CreateGeometryAnnotationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometryAnnotation` that was created by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `GeometryAnnotation` mutation. */
export type CreateGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the create `GeometryAnnotationType` mutation. */
export type CreateGeometryAnnotationTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `GeometryAnnotationType` to be created by this mutation. */
  geometryAnnotationType: GeometryAnnotationTypeInput
}

/** The output of our create `GeometryAnnotationType` mutation. */
export type CreateGeometryAnnotationTypePayload = {
  __typename?: 'CreateGeometryAnnotationTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  /** The `GeometryAnnotationType` that was created by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `GeometryAnnotationType` mutation. */
export type CreateGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** All input for the create `Geometry` mutation. */
export type CreateGeometryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Geometry` to be created by this mutation. */
  geometry: GeometryInput
}

/** The output of our create `Geometry` mutation. */
export type CreateGeometryPayload = {
  __typename?: 'CreateGeometryPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Geometry` that was created by this mutation. */
  geometry: Maybe<Geometry>
  /** An edge for our `Geometry`. May be used by Relay 1. */
  geometryEdge: Maybe<GeometriesEdge>
  /** Reads a single `GeometrySeries` that is related to this `Geometry`. */
  geometrySeriesByGeometrySeriesId: GeometrySeries
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Geometry` mutation. */
export type CreateGeometryPayloadGeometryEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometriesOrderBy>>
}

/** All input for the create `GeometryScreenshotInvocation` mutation. */
export type CreateGeometryScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `GeometryScreenshotInvocation` to be created by this mutation. */
  geometryScreenshotInvocation: GeometryScreenshotInvocationInput
}

/** The output of our create `GeometryScreenshotInvocation` mutation. */
export type CreateGeometryScreenshotInvocationPayload = {
  __typename?: 'CreateGeometryScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `GeometryScreenshotInvocation`. */
  geometryByGeometryId: Geometry
  /** The `GeometryScreenshotInvocation` that was created by this mutation. */
  geometryScreenshotInvocation: Maybe<GeometryScreenshotInvocation>
  /** An edge for our `GeometryScreenshotInvocation`. May be used by Relay 1. */
  geometryScreenshotInvocationEdge: Maybe<GeometryScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `GeometryScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `GeometryScreenshotInvocation` mutation. */
export type CreateGeometryScreenshotInvocationPayloadGeometryScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
  }

/** All input for the create `GeometrySeries` mutation. */
export type CreateGeometrySeriesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `GeometrySeries` to be created by this mutation. */
  geometrySeries: GeometrySeriesInput
}

/** The output of our create `GeometrySeries` mutation. */
export type CreateGeometrySeriesPayload = {
  __typename?: 'CreateGeometrySeriesPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometrySeries` that was created by this mutation. */
  geometrySeries: Maybe<GeometrySeries>
  /** An edge for our `GeometrySeries`. May be used by Relay 1. */
  geometrySeriesEdge: Maybe<GeometrySeriesEdge>
  /** Reads a single `PoseType` that is related to this `GeometrySeries`. */
  poseTypeByPoseTypeId: PoseType
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `Subject` that is related to this `GeometrySeries`. */
  subjectBySubjectId: Subject
}

/** The output of our create `GeometrySeries` mutation. */
export type CreateGeometrySeriesPayloadGeometrySeriesEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

/** All input for the create `Label` mutation. */
export type CreateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Label` to be created by this mutation. */
  label: LabelInput
}

/** The output of our create `Label` mutation. */
export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Label` that was created by this mutation. */
  label: Maybe<Label>
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge: Maybe<LabelsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Label` mutation. */
export type CreateLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>
}

/** All input for the create `MeasurementInvocationDifference` mutation. */
export type CreateMeasurementInvocationDifferenceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `MeasurementInvocationDifference` to be created by this mutation. */
  measurementInvocationDifference: MeasurementInvocationDifferenceInput
}

/** The output of our create `MeasurementInvocationDifference` mutation. */
export type CreateMeasurementInvocationDifferencePayload = {
  __typename?: 'CreateMeasurementInvocationDifferencePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByInvocationId: MeasurementInvocation
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByPreviousInvocationId: MeasurementInvocation
  /** The `MeasurementInvocationDifference` that was created by this mutation. */
  measurementInvocationDifference: Maybe<MeasurementInvocationDifference>
  /** An edge for our `MeasurementInvocationDifference`. May be used by Relay 1. */
  measurementInvocationDifferenceEdge: Maybe<MeasurementInvocationDifferencesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `MeasurementInvocationDifference` mutation. */
export type CreateMeasurementInvocationDifferencePayloadMeasurementInvocationDifferenceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
  }

/** All input for the create `MeasurementInvocation` mutation. */
export type CreateMeasurementInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `MeasurementInvocation` to be created by this mutation. */
  measurementInvocation: MeasurementInvocationInput
}

/** The output of our create `MeasurementInvocation` mutation. */
export type CreateMeasurementInvocationPayload = {
  __typename?: 'CreateMeasurementInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `MeasurementInvocation`. */
  commitByCommitId: Commit
  /** Reads a single `CommitsGeometriesLookup` that is related to this `MeasurementInvocation`. */
  commitsGeometriesLookupByGeometryIdAndCommitId: CommitsGeometriesLookup
  /** Reads a single `Curve` that is related to this `MeasurementInvocation`. */
  curveByCurveId: Curve
  /** Reads a single `Geometry` that is related to this `MeasurementInvocation`. */
  geometryByGeometryId: Geometry
  /** The `MeasurementInvocation` that was created by this mutation. */
  measurementInvocation: Maybe<MeasurementInvocation>
  /** An edge for our `MeasurementInvocation`. May be used by Relay 1. */
  measurementInvocationEdge: Maybe<MeasurementInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `MeasurementInvocation` mutation. */
export type CreateMeasurementInvocationPayloadMeasurementInvocationEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

/** All input for the create `MeasurementScreenshotInvocation` mutation. */
export type CreateMeasurementScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `MeasurementScreenshotInvocation` to be created by this mutation. */
  measurementScreenshotInvocation: MeasurementScreenshotInvocationInput
}

/** The output of our create `MeasurementScreenshotInvocation` mutation. */
export type CreateMeasurementScreenshotInvocationPayload = {
  __typename?: 'CreateMeasurementScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementScreenshotInvocation`. */
  measurementInvocationByMeasurementInvocationId: MeasurementInvocation
  /** The `MeasurementScreenshotInvocation` that was created by this mutation. */
  measurementScreenshotInvocation: Maybe<MeasurementScreenshotInvocation>
  /** An edge for our `MeasurementScreenshotInvocation`. May be used by Relay 1. */
  measurementScreenshotInvocationEdge: Maybe<MeasurementScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `TranslatedView` that is related to this `MeasurementScreenshotInvocation`. */
  translatedViewByTranslatedViewId: TranslatedView
}

/** The output of our create `MeasurementScreenshotInvocation` mutation. */
export type CreateMeasurementScreenshotInvocationPayloadMeasurementScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

export type CreateMeasurementStudyResponse = {
  __typename?: 'CreateMeasurementStudyResponse'
  measurementStudy: Maybe<MeasurementStudy>
}

/** All input for the create `Measurer` mutation. */
export type CreateMeasurerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Measurer` to be created by this mutation. */
  measurer: MeasurerInput
}

/** The output of our create `Measurer` mutation. */
export type CreateMeasurerPayload = {
  __typename?: 'CreateMeasurerPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Measurer` that was created by this mutation. */
  measurer: Maybe<Measurer>
  /** An edge for our `Measurer`. May be used by Relay 1. */
  measurerEdge: Maybe<MeasurersEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `Measurer` mutation. */
export type CreateMeasurerPayloadMeasurerEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurersOrderBy>>
}

/** All input for the create `MeasurerVersion` mutation. */
export type CreateMeasurerVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `MeasurerVersion` to be created by this mutation. */
  measurerVersion: MeasurerVersionInput
}

/** The output of our create `MeasurerVersion` mutation. */
export type CreateMeasurerVersionPayload = {
  __typename?: 'CreateMeasurerVersionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Measurer` that is related to this `MeasurerVersion`. */
  measurerByMeasurerId: Measurer
  /** The `MeasurerVersion` that was created by this mutation. */
  measurerVersion: Maybe<MeasurerVersion>
  /** An edge for our `MeasurerVersion`. May be used by Relay 1. */
  measurerVersionEdge: Maybe<MeasurerVersionsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurerVersion`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `MeasurerVersion` mutation. */
export type CreateMeasurerVersionPayloadMeasurerVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
}

/** All input for the create `PoseType` mutation. */
export type CreatePoseTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `PoseType` to be created by this mutation. */
  poseType: PoseTypeInput
}

/** The output of our create `PoseType` mutation. */
export type CreatePoseTypePayload = {
  __typename?: 'CreatePoseTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `PoseType` that was created by this mutation. */
  poseType: Maybe<PoseType>
  /** An edge for our `PoseType`. May be used by Relay 1. */
  poseTypeEdge: Maybe<PoseTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `PoseType` mutation. */
export type CreatePoseTypePayloadPoseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<PoseTypesOrderBy>>
}

/** All input for the create `PresetView` mutation. */
export type CreatePresetViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `PresetView` to be created by this mutation. */
  presetView: PresetViewInput
}

/** The output of our create `PresetView` mutation. */
export type CreatePresetViewPayload = {
  __typename?: 'CreatePresetViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Curve` that is related to this `PresetView`. */
  curveByReferenceCurveId: Curve
  /** The `PresetView` that was created by this mutation. */
  presetView: Maybe<PresetView>
  /** An edge for our `PresetView`. May be used by Relay 1. */
  presetViewEdge: Maybe<PresetViewsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `View` that is related to this `PresetView`. */
  viewByReferenceViewId: View
}

/** The output of our create `PresetView` mutation. */
export type CreatePresetViewPayloadPresetViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

/** All input for the create `PromiseStateMachineStatesEnum` mutation. */
export type CreatePromiseStateMachineStatesEnumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `PromiseStateMachineStatesEnum` to be created by this mutation. */
  promiseStateMachineStatesEnum: PromiseStateMachineStatesEnumInput
}

/** The output of our create `PromiseStateMachineStatesEnum` mutation. */
export type CreatePromiseStateMachineStatesEnumPayload = {
  __typename?: 'CreatePromiseStateMachineStatesEnumPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `PromiseStateMachineStatesEnum` that was created by this mutation. */
  promiseStateMachineStatesEnum: Maybe<PromiseStateMachineStatesEnum>
  /** An edge for our `PromiseStateMachineStatesEnum`. May be used by Relay 1. */
  promiseStateMachineStatesEnumEdge: Maybe<PromiseStateMachineStatesEnumsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our create `PromiseStateMachineStatesEnum` mutation. */
export type CreatePromiseStateMachineStatesEnumPayloadPromiseStateMachineStatesEnumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PromiseStateMachineStatesEnumsOrderBy>>
  }

/** All input for the create `ReferenceGeometryForView` mutation. */
export type CreateReferenceGeometryForViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `ReferenceGeometryForView` to be created by this mutation. */
  referenceGeometryForView: ReferenceGeometryForViewInput
}

/** The output of our create `ReferenceGeometryForView` mutation. */
export type CreateReferenceGeometryForViewPayload = {
  __typename?: 'CreateReferenceGeometryForViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `ReferenceGeometryForView`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `ReferenceGeometryForView` that was created by this mutation. */
  referenceGeometryForView: Maybe<ReferenceGeometryForView>
  /** An edge for our `ReferenceGeometryForView`. May be used by Relay 1. */
  referenceGeometryForViewEdge: Maybe<ReferenceGeometryForViewsEdge>
}

/** The output of our create `ReferenceGeometryForView` mutation. */
export type CreateReferenceGeometryForViewPayloadReferenceGeometryForViewEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ReferenceGeometryForViewsOrderBy>>
  }

/** All input for the create `Subject` mutation. */
export type CreateSubjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Subject` to be created by this mutation. */
  subject: SubjectInput
}

/** The output of our create `Subject` mutation. */
export type CreateSubjectPayload = {
  __typename?: 'CreateSubjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `Subject`. */
  datasetByDatasetId: Dataset
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `Subject` that was created by this mutation. */
  subject: Maybe<Subject>
  /** An edge for our `Subject`. May be used by Relay 1. */
  subjectEdge: Maybe<SubjectsEdge>
}

/** The output of our create `Subject` mutation. */
export type CreateSubjectPayloadSubjectEdgeArgs = {
  orderBy?: InputMaybe<Array<SubjectsOrderBy>>
}

/** All input for the create `TranslatedView` mutation. */
export type CreateTranslatedViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `TranslatedView` to be created by this mutation. */
  translatedView: TranslatedViewInput
}

/** The output of our create `TranslatedView` mutation. */
export type CreateTranslatedViewPayload = {
  __typename?: 'CreateTranslatedViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `TranslatedView`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `TranslatedView` that was created by this mutation. */
  translatedView: Maybe<TranslatedView>
  /** An edge for our `TranslatedView`. May be used by Relay 1. */
  translatedViewEdge: Maybe<TranslatedViewsEdge>
  /** Reads a single `View` that is related to this `TranslatedView`. */
  viewByTargetViewId: View
}

/** The output of our create `TranslatedView` mutation. */
export type CreateTranslatedViewPayloadTranslatedViewEdgeArgs = {
  orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
}

/** All input for the create `View` mutation. */
export type CreateViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `View` to be created by this mutation. */
  view: ViewInput
}

/** The output of our create `View` mutation. */
export type CreateViewPayload = {
  __typename?: 'CreateViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `View` that was created by this mutation. */
  view: Maybe<View>
  /** An edge for our `View`. May be used by Relay 1. */
  viewEdge: Maybe<ViewsEdge>
}

/** The output of our create `View` mutation. */
export type CreateViewPayloadViewEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewsOrderBy>>
}

export type Curve = Node & {
  __typename?: 'Curve'
  id: Scalars['Int']
  isClosed: Scalars['Boolean']
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  measurementInvocationsByCurveId: MeasurementInvocationsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `PresetView`. */
  presetViewsByReferenceCurveId: PresetViewsConnection
  tapeWidth: Scalars['Float']
  vertices: Array<Scalars['Float']>
}

export type CurveMeasurementInvocationsByCurveIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

export type CurvePresetViewsByReferenceCurveIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<PresetViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

/** A condition to be used against `Curve` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CurveCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `isClosed` field. */
  isClosed?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `tapeWidth` field. */
  tapeWidth?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `vertices` field. */
  vertices?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** An input for mutations affecting `Curve` */
export type CurveInput = {
  id?: InputMaybe<Scalars['Int']>
  isClosed: Scalars['Boolean']
  tapeWidth: Scalars['Float']
  vertices: Array<InputMaybe<Scalars['Float']>>
}

/** Represents an update to a `Curve`. Fields that are set will be updated. */
export type CurvePatch = {
  id?: InputMaybe<Scalars['Int']>
  isClosed?: InputMaybe<Scalars['Boolean']>
  tapeWidth?: InputMaybe<Scalars['Float']>
  vertices?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** A connection to a list of `Curve` values. */
export type CurvesConnection = {
  __typename?: 'CurvesConnection'
  /** A list of edges which contains the `Curve` and cursor to aid in pagination. */
  edges: Array<CurvesEdge>
  /** A list of `Curve` objects. */
  nodes: Array<Curve>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Curve` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Curve` edge in the connection. */
export type CurvesEdge = {
  __typename?: 'CurvesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Curve` at the end of the edge. */
  node: Curve
}

/** Methods to use when ordering `Curve`. */
export enum CurvesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsClosedAsc = 'IS_CLOSED_ASC',
  IsClosedDesc = 'IS_CLOSED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TapeWidthAsc = 'TAPE_WIDTH_ASC',
  TapeWidthDesc = 'TAPE_WIDTH_DESC',
  VerticesAsc = 'VERTICES_ASC',
  VerticesDesc = 'VERTICES_DESC',
}

export enum DataLayerGender {
  F = 'F',
  M = 'M',
}

export enum DataLayerMeasurerName {
  Anatomia = 'anatomia',
  AnatomiaPy39 = 'anatomia_py39',
  Armscyence = 'armscyence',
  ArmscyencePy39 = 'armscyence_py39',
}

export type Dataset = Node & {
  __typename?: 'Dataset'
  activeTopology: Maybe<TopologyType>
  anteriorDirection: Maybe<AxisType>
  anteriorDirectionCoordinates: Maybe<Array<Scalars['Float']>>
  bodyPart: BodyPartType
  /** Reads and enables pagination through a set of `GeometryAnnotationType`. */
  geometryAnnotationTypesByDatasetId: GeometryAnnotationTypesConnection
  id: Scalars['Int']
  lastModified: Scalars['Datetime']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `Subject`. */
  subjectsByDatasetId: SubjectsConnection
  superiorDirection: Maybe<AxisType>
  superiorDirectionCoordinates: Maybe<Array<Scalars['Float']>>
  units: UnitsType
}

export type DatasetGeometryAnnotationTypesByDatasetIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryAnnotationTypeCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
}

export type DatasetSubjectsByDatasetIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<SubjectCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SubjectsOrderBy>>
}

/** A condition to be used against `Dataset` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DatasetCondition = {
  /** Checks for equality with the object’s `activeTopology` field. */
  activeTopology?: InputMaybe<TopologyType>
  /** Checks for equality with the object’s `anteriorDirection` field. */
  anteriorDirection?: InputMaybe<AxisType>
  /** Checks for equality with the object’s `bodyPart` field. */
  bodyPart?: InputMaybe<BodyPartType>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `lastModified` field. */
  lastModified?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `superiorDirection` field. */
  superiorDirection?: InputMaybe<AxisType>
  /** Checks for equality with the object’s `units` field. */
  units?: InputMaybe<UnitsType>
}

/** An input for mutations affecting `Dataset` */
export type DatasetInput = {
  activeTopology?: InputMaybe<TopologyType>
  anteriorDirection?: InputMaybe<AxisType>
  bodyPart: BodyPartType
  id?: InputMaybe<Scalars['Int']>
  lastModified?: InputMaybe<Scalars['Datetime']>
  name: Scalars['String']
  superiorDirection?: InputMaybe<AxisType>
  units: UnitsType
}

/** Represents an update to a `Dataset`. Fields that are set will be updated. */
export type DatasetPatch = {
  activeTopology?: InputMaybe<TopologyType>
  anteriorDirection?: InputMaybe<AxisType>
  bodyPart?: InputMaybe<BodyPartType>
  id?: InputMaybe<Scalars['Int']>
  lastModified?: InputMaybe<Scalars['Datetime']>
  name?: InputMaybe<Scalars['String']>
  superiorDirection?: InputMaybe<AxisType>
  units?: InputMaybe<UnitsType>
}

/** A connection to a list of `Dataset` values. */
export type DatasetsConnection = {
  __typename?: 'DatasetsConnection'
  /** A list of edges which contains the `Dataset` and cursor to aid in pagination. */
  edges: Array<DatasetsEdge>
  /** A list of `Dataset` objects. */
  nodes: Array<Dataset>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Dataset` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Dataset` edge in the connection. */
export type DatasetsEdge = {
  __typename?: 'DatasetsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Dataset` at the end of the edge. */
  node: Dataset
}

/** Methods to use when ordering `Dataset`. */
export enum DatasetsOrderBy {
  ActiveTopologyAsc = 'ACTIVE_TOPOLOGY_ASC',
  ActiveTopologyDesc = 'ACTIVE_TOPOLOGY_DESC',
  AnteriorDirectionAsc = 'ANTERIOR_DIRECTION_ASC',
  AnteriorDirectionDesc = 'ANTERIOR_DIRECTION_DESC',
  BodyPartAsc = 'BODY_PART_ASC',
  BodyPartDesc = 'BODY_PART_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastModifiedAsc = 'LAST_MODIFIED_ASC',
  LastModifiedDesc = 'LAST_MODIFIED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SuperiorDirectionAsc = 'SUPERIOR_DIRECTION_ASC',
  SuperiorDirectionDesc = 'SUPERIOR_DIRECTION_DESC',
  UnitsAsc = 'UNITS_ASC',
  UnitsDesc = 'UNITS_DESC',
}

/** All input for the `deleteCheckedUploadByETag` mutation. */
export type DeleteCheckedUploadByETagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  eTag: Scalars['String']
}

/** All input for the `deleteCheckedUpload` mutation. */
export type DeleteCheckedUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckedUpload` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `CheckedUpload` mutation. */
export type DeleteCheckedUploadPayload = {
  __typename?: 'DeleteCheckedUploadPayload'
  /** The `CheckedUpload` that was deleted by this mutation. */
  checkedUpload: Maybe<CheckedUpload>
  /** An edge for our `CheckedUpload`. May be used by Relay 1. */
  checkedUploadEdge: Maybe<CheckedUploadsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedCheckedUploadId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `CheckedUpload` mutation. */
export type DeleteCheckedUploadPayloadCheckedUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckedUploadsOrderBy>>
}

/** All input for the `deleteCheckoutAccessById` mutation. */
export type DeleteCheckoutAccessByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteCheckoutAccess` mutation. */
export type DeleteCheckoutAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckoutAccess` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `CheckoutAccess` mutation. */
export type DeleteCheckoutAccessPayload = {
  __typename?: 'DeleteCheckoutAccessPayload'
  /** The `CheckoutAccess` that was deleted by this mutation. */
  checkoutAccess: Maybe<CheckoutAccess>
  /** An edge for our `CheckoutAccess`. May be used by Relay 1. */
  checkoutAccessEdge: Maybe<CheckoutAccessesEdge>
  /** Reads a single `Checkout` that is related to this `CheckoutAccess`. */
  checkoutByCheckoutId: Checkout
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedCheckoutAccessId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `CheckoutAccess` mutation. */
export type DeleteCheckoutAccessPayloadCheckoutAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckoutAccessesOrderBy>>
}

/** All input for the `deleteCheckoutsGeometriesLookupByCheckoutIdAndGeometryId` mutation. */
export type DeleteCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdInput = {
  checkoutId: Scalars['Int']
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
}

/** All input for the `deleteCheckoutsGeometriesLookup` mutation. */
export type DeleteCheckoutsGeometriesLookupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckoutsGeometriesLookup` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `CheckoutsGeometriesLookup` mutation. */
export type DeleteCheckoutsGeometriesLookupPayload = {
  __typename?: 'DeleteCheckoutsGeometriesLookupPayload'
  /** Reads a single `Checkout` that is related to this `CheckoutsGeometriesLookup`. */
  checkoutByCheckoutId: Checkout
  /** The `CheckoutsGeometriesLookup` that was deleted by this mutation. */
  checkoutsGeometriesLookup: Maybe<CheckoutsGeometriesLookup>
  /** An edge for our `CheckoutsGeometriesLookup`. May be used by Relay 1. */
  checkoutsGeometriesLookupEdge: Maybe<CheckoutsGeometriesLookupsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedCheckoutsGeometriesLookupId: Maybe<Scalars['ID']>
  /** Reads a single `Geometry` that is related to this `CheckoutsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `CheckoutsGeometriesLookup` mutation. */
export type DeleteCheckoutsGeometriesLookupPayloadCheckoutsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
  }

/** All input for the `deleteCommitById` mutation. */
export type DeleteCommitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteCommit` mutation. */
export type DeleteCommitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Commit` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Commit` mutation. */
export type DeleteCommitPayload = {
  __typename?: 'DeleteCommitPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Commit` that was deleted by this mutation. */
  commit: Maybe<Commit>
  /** Reads a single `Commit` that is related to this `Commit`. */
  commitByParentCommitId: Commit
  /** An edge for our `Commit`. May be used by Relay 1. */
  commitEdge: Maybe<CommitsEdge>
  deletedCommitId: Maybe<Scalars['ID']>
  /** Reads a single `MeasurerVersion` that is related to this `Commit`. */
  measurerVersionByMeasurerVersionId: MeasurerVersion
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Commit` mutation. */
export type DeleteCommitPayloadCommitEdgeArgs = {
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

/** All input for the `deleteCommitsGeometriesLookupByGeometryIdAndCommitId` mutation. */
export type DeleteCommitsGeometriesLookupByGeometryIdAndCommitIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  commitId: Scalars['Int']
  geometryId: Scalars['Int']
}

/** All input for the `deleteCommitsGeometriesLookup` mutation. */
export type DeleteCommitsGeometriesLookupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CommitsGeometriesLookup` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `CommitsGeometriesLookup` mutation. */
export type DeleteCommitsGeometriesLookupPayload = {
  __typename?: 'DeleteCommitsGeometriesLookupPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `CommitsGeometriesLookup`. */
  commitByCommitId: Commit
  /** The `CommitsGeometriesLookup` that was deleted by this mutation. */
  commitsGeometriesLookup: Maybe<CommitsGeometriesLookup>
  /** An edge for our `CommitsGeometriesLookup`. May be used by Relay 1. */
  commitsGeometriesLookupEdge: Maybe<CommitsGeometriesLookupsEdge>
  deletedCommitsGeometriesLookupId: Maybe<Scalars['ID']>
  /** Reads a single `Geometry` that is related to this `CommitsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `CommitsGeometriesLookup` mutation. */
export type DeleteCommitsGeometriesLookupPayloadCommitsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
  }

/** All input for the `deleteCurveById` mutation. */
export type DeleteCurveByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteCurve` mutation. */
export type DeleteCurveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Curve` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Curve` mutation. */
export type DeleteCurvePayload = {
  __typename?: 'DeleteCurvePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Curve` that was deleted by this mutation. */
  curve: Maybe<Curve>
  /** An edge for our `Curve`. May be used by Relay 1. */
  curveEdge: Maybe<CurvesEdge>
  deletedCurveId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Curve` mutation. */
export type DeleteCurvePayloadCurveEdgeArgs = {
  orderBy?: InputMaybe<Array<CurvesOrderBy>>
}

/** All input for the `deleteDatasetById` mutation. */
export type DeleteDatasetByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteDataset` mutation. */
export type DeleteDatasetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Dataset` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Dataset` mutation. */
export type DeleteDatasetPayload = {
  __typename?: 'DeleteDatasetPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Dataset` that was deleted by this mutation. */
  dataset: Maybe<Dataset>
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>
  deletedDatasetId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Dataset` mutation. */
export type DeleteDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>
}

/** All input for the `deleteGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeId` mutation. */
export type DeleteGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    geometryAnnotationTypeId: Scalars['Int']
    geometryId: Scalars['Int']
  }

/** All input for the `deleteGeometryAnnotationById` mutation. */
export type DeleteGeometryAnnotationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteGeometryAnnotation` mutation. */
export type DeleteGeometryAnnotationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `GeometryAnnotation` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `GeometryAnnotation` mutation. */
export type DeleteGeometryAnnotationPayload = {
  __typename?: 'DeleteGeometryAnnotationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedGeometryAnnotationId: Maybe<Scalars['ID']>
  /** The `GeometryAnnotation` that was deleted by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `GeometryAnnotation` mutation. */
export type DeleteGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the `deleteGeometryAnnotationTypeById` mutation. */
export type DeleteGeometryAnnotationTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteGeometryAnnotationTypeByNameAndDatasetId` mutation. */
export type DeleteGeometryAnnotationTypeByNameAndDatasetIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  datasetId: Scalars['Int']
  name: Scalars['String']
}

/** All input for the `deleteGeometryAnnotationType` mutation. */
export type DeleteGeometryAnnotationTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `GeometryAnnotationType` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `GeometryAnnotationType` mutation. */
export type DeleteGeometryAnnotationTypePayload = {
  __typename?: 'DeleteGeometryAnnotationTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  deletedGeometryAnnotationTypeId: Maybe<Scalars['ID']>
  /** The `GeometryAnnotationType` that was deleted by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `GeometryAnnotationType` mutation. */
export type DeleteGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** All input for the `deleteGeometryByGeometrySeriesIdAndVersion` mutation. */
export type DeleteGeometryByGeometrySeriesIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometrySeriesId: Scalars['Int']
  version: Scalars['Int']
}

/** All input for the `deleteGeometryById` mutation. */
export type DeleteGeometryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteGeometry` mutation. */
export type DeleteGeometryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Geometry` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Geometry` mutation. */
export type DeleteGeometryPayload = {
  __typename?: 'DeleteGeometryPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedGeometryId: Maybe<Scalars['ID']>
  /** The `Geometry` that was deleted by this mutation. */
  geometry: Maybe<Geometry>
  /** An edge for our `Geometry`. May be used by Relay 1. */
  geometryEdge: Maybe<GeometriesEdge>
  /** Reads a single `GeometrySeries` that is related to this `Geometry`. */
  geometrySeriesByGeometrySeriesId: GeometrySeries
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Geometry` mutation. */
export type DeleteGeometryPayloadGeometryEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometriesOrderBy>>
}

/** All input for the `deleteGeometryScreenshotInvocationByGeometryIdAndViewIndex` mutation. */
export type DeleteGeometryScreenshotInvocationByGeometryIdAndViewIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
  viewIndex: Scalars['Int']
}

/** All input for the `deleteGeometryScreenshotInvocation` mutation. */
export type DeleteGeometryScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `GeometryScreenshotInvocation` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `GeometryScreenshotInvocation` mutation. */
export type DeleteGeometryScreenshotInvocationPayload = {
  __typename?: 'DeleteGeometryScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedGeometryScreenshotInvocationId: Maybe<Scalars['ID']>
  /** Reads a single `Geometry` that is related to this `GeometryScreenshotInvocation`. */
  geometryByGeometryId: Geometry
  /** The `GeometryScreenshotInvocation` that was deleted by this mutation. */
  geometryScreenshotInvocation: Maybe<GeometryScreenshotInvocation>
  /** An edge for our `GeometryScreenshotInvocation`. May be used by Relay 1. */
  geometryScreenshotInvocationEdge: Maybe<GeometryScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `GeometryScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `GeometryScreenshotInvocation` mutation. */
export type DeleteGeometryScreenshotInvocationPayloadGeometryScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
  }

/** All input for the `deleteGeometrySeriesById` mutation. */
export type DeleteGeometrySeriesByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteGeometrySeries` mutation. */
export type DeleteGeometrySeriesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `GeometrySeries` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `GeometrySeries` mutation. */
export type DeleteGeometrySeriesPayload = {
  __typename?: 'DeleteGeometrySeriesPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedGeometrySeryId: Maybe<Scalars['ID']>
  /** The `GeometrySeries` that was deleted by this mutation. */
  geometrySeries: Maybe<GeometrySeries>
  /** An edge for our `GeometrySeries`. May be used by Relay 1. */
  geometrySeriesEdge: Maybe<GeometrySeriesEdge>
  /** Reads a single `PoseType` that is related to this `GeometrySeries`. */
  poseTypeByPoseTypeId: PoseType
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `Subject` that is related to this `GeometrySeries`. */
  subjectBySubjectId: Subject
}

/** The output of our delete `GeometrySeries` mutation. */
export type DeleteGeometrySeriesPayloadGeometrySeriesEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

/** All input for the `deleteLabelById` mutation. */
export type DeleteLabelByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteLabel` mutation. */
export type DeleteLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Label` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Label` mutation. */
export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedLabelId: Maybe<Scalars['ID']>
  /** The `Label` that was deleted by this mutation. */
  label: Maybe<Label>
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge: Maybe<LabelsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Label` mutation. */
export type DeleteLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>
}

/** All input for the `deleteMeasurementInvocationById` mutation. */
export type DeleteMeasurementInvocationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationId` mutation. */
export type DeleteMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    invocationId: Scalars['Int']
    previousInvocationId: Scalars['Int']
  }

/** All input for the `deleteMeasurementInvocationDifference` mutation. */
export type DeleteMeasurementInvocationDifferenceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `MeasurementInvocationDifference` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `MeasurementInvocationDifference` mutation. */
export type DeleteMeasurementInvocationDifferencePayload = {
  __typename?: 'DeleteMeasurementInvocationDifferencePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedMeasurementInvocationDifferenceId: Maybe<Scalars['ID']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByInvocationId: MeasurementInvocation
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByPreviousInvocationId: MeasurementInvocation
  /** The `MeasurementInvocationDifference` that was deleted by this mutation. */
  measurementInvocationDifference: Maybe<MeasurementInvocationDifference>
  /** An edge for our `MeasurementInvocationDifference`. May be used by Relay 1. */
  measurementInvocationDifferenceEdge: Maybe<MeasurementInvocationDifferencesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `MeasurementInvocationDifference` mutation. */
export type DeleteMeasurementInvocationDifferencePayloadMeasurementInvocationDifferenceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
  }

/** All input for the `deleteMeasurementInvocation` mutation. */
export type DeleteMeasurementInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `MeasurementInvocation` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `MeasurementInvocation` mutation. */
export type DeleteMeasurementInvocationPayload = {
  __typename?: 'DeleteMeasurementInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `MeasurementInvocation`. */
  commitByCommitId: Commit
  /** Reads a single `CommitsGeometriesLookup` that is related to this `MeasurementInvocation`. */
  commitsGeometriesLookupByGeometryIdAndCommitId: CommitsGeometriesLookup
  /** Reads a single `Curve` that is related to this `MeasurementInvocation`. */
  curveByCurveId: Curve
  deletedMeasurementInvocationId: Maybe<Scalars['ID']>
  /** Reads a single `Geometry` that is related to this `MeasurementInvocation`. */
  geometryByGeometryId: Geometry
  /** The `MeasurementInvocation` that was deleted by this mutation. */
  measurementInvocation: Maybe<MeasurementInvocation>
  /** An edge for our `MeasurementInvocation`. May be used by Relay 1. */
  measurementInvocationEdge: Maybe<MeasurementInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `MeasurementInvocation` mutation. */
export type DeleteMeasurementInvocationPayloadMeasurementInvocationEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

/** All input for the `deleteMeasurementScreenshotInvocationById` mutation. */
export type DeleteMeasurementScreenshotInvocationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndex` mutation. */
export type DeleteMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    measurementInvocationId: Scalars['Int']
    viewIndex: Scalars['Int']
  }

/** All input for the `deleteMeasurementScreenshotInvocation` mutation. */
export type DeleteMeasurementScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `MeasurementScreenshotInvocation` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `MeasurementScreenshotInvocation` mutation. */
export type DeleteMeasurementScreenshotInvocationPayload = {
  __typename?: 'DeleteMeasurementScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedMeasurementScreenshotInvocationId: Maybe<Scalars['ID']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementScreenshotInvocation`. */
  measurementInvocationByMeasurementInvocationId: MeasurementInvocation
  /** The `MeasurementScreenshotInvocation` that was deleted by this mutation. */
  measurementScreenshotInvocation: Maybe<MeasurementScreenshotInvocation>
  /** An edge for our `MeasurementScreenshotInvocation`. May be used by Relay 1. */
  measurementScreenshotInvocationEdge: Maybe<MeasurementScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `TranslatedView` that is related to this `MeasurementScreenshotInvocation`. */
  translatedViewByTranslatedViewId: TranslatedView
}

/** The output of our delete `MeasurementScreenshotInvocation` mutation. */
export type DeleteMeasurementScreenshotInvocationPayloadMeasurementScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

/** All input for the `deleteMeasurerById` mutation. */
export type DeleteMeasurerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteMeasurerByName` mutation. */
export type DeleteMeasurerByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

/** All input for the `deleteMeasurer` mutation. */
export type DeleteMeasurerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Measurer` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Measurer` mutation. */
export type DeleteMeasurerPayload = {
  __typename?: 'DeleteMeasurerPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedMeasurerId: Maybe<Scalars['ID']>
  /** The `Measurer` that was deleted by this mutation. */
  measurer: Maybe<Measurer>
  /** An edge for our `Measurer`. May be used by Relay 1. */
  measurerEdge: Maybe<MeasurersEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `Measurer` mutation. */
export type DeleteMeasurerPayloadMeasurerEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurersOrderBy>>
}

/** All input for the `deleteMeasurerVersionById` mutation. */
export type DeleteMeasurerVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteMeasurerVersionByMeasurerIdAndTagName` mutation. */
export type DeleteMeasurerVersionByMeasurerIdAndTagNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  measurerId: Scalars['Int']
  tagName: Scalars['String']
}

/** All input for the `deleteMeasurerVersion` mutation. */
export type DeleteMeasurerVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `MeasurerVersion` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `MeasurerVersion` mutation. */
export type DeleteMeasurerVersionPayload = {
  __typename?: 'DeleteMeasurerVersionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedMeasurerVersionId: Maybe<Scalars['ID']>
  /** Reads a single `Measurer` that is related to this `MeasurerVersion`. */
  measurerByMeasurerId: Measurer
  /** The `MeasurerVersion` that was deleted by this mutation. */
  measurerVersion: Maybe<MeasurerVersion>
  /** An edge for our `MeasurerVersion`. May be used by Relay 1. */
  measurerVersionEdge: Maybe<MeasurerVersionsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurerVersion`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `MeasurerVersion` mutation. */
export type DeleteMeasurerVersionPayloadMeasurerVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
}

/** All input for the `deletePoseTypeById` mutation. */
export type DeletePoseTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deletePoseType` mutation. */
export type DeletePoseTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PoseType` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `PoseType` mutation. */
export type DeletePoseTypePayload = {
  __typename?: 'DeletePoseTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedPoseTypeId: Maybe<Scalars['ID']>
  /** The `PoseType` that was deleted by this mutation. */
  poseType: Maybe<PoseType>
  /** An edge for our `PoseType`. May be used by Relay 1. */
  poseTypeEdge: Maybe<PoseTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `PoseType` mutation. */
export type DeletePoseTypePayloadPoseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<PoseTypesOrderBy>>
}

/** All input for the `deletePresetViewByMeasurementNameAndViewIndex` mutation. */
export type DeletePresetViewByMeasurementNameAndViewIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  measurementName: Scalars['String']
  viewIndex: Scalars['Int']
}

/** All input for the `deletePresetView` mutation. */
export type DeletePresetViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PresetView` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `PresetView` mutation. */
export type DeletePresetViewPayload = {
  __typename?: 'DeletePresetViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Curve` that is related to this `PresetView`. */
  curveByReferenceCurveId: Curve
  deletedPresetViewId: Maybe<Scalars['ID']>
  /** The `PresetView` that was deleted by this mutation. */
  presetView: Maybe<PresetView>
  /** An edge for our `PresetView`. May be used by Relay 1. */
  presetViewEdge: Maybe<PresetViewsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `View` that is related to this `PresetView`. */
  viewByReferenceViewId: View
}

/** The output of our delete `PresetView` mutation. */
export type DeletePresetViewPayloadPresetViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

/** All input for the `deletePromiseStateMachineStatesEnumByName` mutation. */
export type DeletePromiseStateMachineStatesEnumByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

/** All input for the `deletePromiseStateMachineStatesEnum` mutation. */
export type DeletePromiseStateMachineStatesEnumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PromiseStateMachineStatesEnum` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `PromiseStateMachineStatesEnum` mutation. */
export type DeletePromiseStateMachineStatesEnumPayload = {
  __typename?: 'DeletePromiseStateMachineStatesEnumPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedPromiseStateMachineStatesEnumId: Maybe<Scalars['ID']>
  /** The `PromiseStateMachineStatesEnum` that was deleted by this mutation. */
  promiseStateMachineStatesEnum: Maybe<PromiseStateMachineStatesEnum>
  /** An edge for our `PromiseStateMachineStatesEnum`. May be used by Relay 1. */
  promiseStateMachineStatesEnumEdge: Maybe<PromiseStateMachineStatesEnumsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete `PromiseStateMachineStatesEnum` mutation. */
export type DeletePromiseStateMachineStatesEnumPayloadPromiseStateMachineStatesEnumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PromiseStateMachineStatesEnumsOrderBy>>
  }

/** All input for the `deleteReferenceGeometryForViewByGeometryId` mutation. */
export type DeleteReferenceGeometryForViewByGeometryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
}

/** All input for the `deleteReferenceGeometryForView` mutation. */
export type DeleteReferenceGeometryForViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `ReferenceGeometryForView` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `ReferenceGeometryForView` mutation. */
export type DeleteReferenceGeometryForViewPayload = {
  __typename?: 'DeleteReferenceGeometryForViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedReferenceGeometryForViewId: Maybe<Scalars['ID']>
  /** Reads a single `Geometry` that is related to this `ReferenceGeometryForView`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `ReferenceGeometryForView` that was deleted by this mutation. */
  referenceGeometryForView: Maybe<ReferenceGeometryForView>
  /** An edge for our `ReferenceGeometryForView`. May be used by Relay 1. */
  referenceGeometryForViewEdge: Maybe<ReferenceGeometryForViewsEdge>
}

/** The output of our delete `ReferenceGeometryForView` mutation. */
export type DeleteReferenceGeometryForViewPayloadReferenceGeometryForViewEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ReferenceGeometryForViewsOrderBy>>
  }

/** All input for the `deleteSubjectByDatasetIdAndName` mutation. */
export type DeleteSubjectByDatasetIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  datasetId: Scalars['Int']
  name: Scalars['String']
}

/** All input for the `deleteSubjectById` mutation. */
export type DeleteSubjectByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteSubject` mutation. */
export type DeleteSubjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Subject` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `Subject` mutation. */
export type DeleteSubjectPayload = {
  __typename?: 'DeleteSubjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `Subject`. */
  datasetByDatasetId: Dataset
  deletedSubjectId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `Subject` that was deleted by this mutation. */
  subject: Maybe<Subject>
  /** An edge for our `Subject`. May be used by Relay 1. */
  subjectEdge: Maybe<SubjectsEdge>
}

/** The output of our delete `Subject` mutation. */
export type DeleteSubjectPayloadSubjectEdgeArgs = {
  orderBy?: InputMaybe<Array<SubjectsOrderBy>>
}

/** All input for the `deleteTranslatedViewById` mutation. */
export type DeleteTranslatedViewByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteTranslatedView` mutation. */
export type DeleteTranslatedViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `TranslatedView` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `TranslatedView` mutation. */
export type DeleteTranslatedViewPayload = {
  __typename?: 'DeleteTranslatedViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedTranslatedViewId: Maybe<Scalars['ID']>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `TranslatedView`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `TranslatedView` that was deleted by this mutation. */
  translatedView: Maybe<TranslatedView>
  /** An edge for our `TranslatedView`. May be used by Relay 1. */
  translatedViewEdge: Maybe<TranslatedViewsEdge>
  /** Reads a single `View` that is related to this `TranslatedView`. */
  viewByTargetViewId: View
}

/** The output of our delete `TranslatedView` mutation. */
export type DeleteTranslatedViewPayloadTranslatedViewEdgeArgs = {
  orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
}

/** All input for the `deleteViewById` mutation. */
export type DeleteViewByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `deleteView` mutation. */
export type DeleteViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `View` to be deleted. */
  nodeId: Scalars['ID']
}

/** The output of our delete `View` mutation. */
export type DeleteViewPayload = {
  __typename?: 'DeleteViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  deletedViewId: Maybe<Scalars['ID']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `View` that was deleted by this mutation. */
  view: Maybe<View>
  /** An edge for our `View`. May be used by Relay 1. */
  viewEdge: Maybe<ViewsEdge>
}

/** The output of our delete `View` mutation. */
export type DeleteViewPayloadViewEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewsOrderBy>>
}

export type DeployableMeasurerVersion = {
  __typename?: 'DeployableMeasurerVersion'
  measurerName: DataLayerMeasurerName
  tagName: Scalars['String']
}

export type DeployableMeasurerVersions = {
  __typename?: 'DeployableMeasurerVersions'
  versions: Array<DeployableMeasurerVersion>
}

export type DownloaderResponseRow = {
  __typename?: 'DownloaderResponseRow'
  datasetName: Scalars['String']
  geometryId: Scalars['Int']
  poseName: Scalars['String']
  signedUrl: Scalars['String']
  subjectName: Scalars['String']
}

export type DownloaderScriptInput = {
  checkoutId: Scalars['Int']
}

export type DownloaderScriptResponse = {
  __typename?: 'DownloaderScriptResponse'
  geometryMetadata: Array<DownloaderResponseRow>
}

export type DuplicateMeasurementStudyInput = {
  measurementStudyId: Scalars['Int']
  name: Scalars['String']
}

export type FeatureConstraints = {
  __typename?: 'FeatureConstraints'
  gender: Maybe<DataLayerGender>
}

export type FlattenedCommit = {
  __typename?: 'FlattenedCommit'
  /** Reads a single `Commit` that is related to this `FlattenedCommit`. */
  commitByCommitId: Commit
  /** Reads a single `Commit` that is related to this `FlattenedCommit`. */
  commitByHeadCommitId: Commit
  /** Reads a single `Commit` that is related to this `FlattenedCommit`. */
  commitByParentCommitId: Commit
  commitId: Maybe<Scalars['Int']>
  headCommitId: Maybe<Scalars['Int']>
  /** Reads a single `MeasurementStudy` that is related to this `FlattenedCommit`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
  parentCommitId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `FlattenedCommit` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FlattenedCommitCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `headCommitId` field. */
  headCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `parentCommitId` field. */
  parentCommitId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `FlattenedCommit` values. */
export type FlattenedCommitsConnection = {
  __typename?: 'FlattenedCommitsConnection'
  /** A list of edges which contains the `FlattenedCommit` and cursor to aid in pagination. */
  edges: Array<FlattenedCommitsEdge>
  /** A list of `FlattenedCommit` objects. */
  nodes: Array<FlattenedCommit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `FlattenedCommit` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `FlattenedCommit` edge in the connection. */
export type FlattenedCommitsEdge = {
  __typename?: 'FlattenedCommitsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `FlattenedCommit` at the end of the edge. */
  node: FlattenedCommit
}

/** Methods to use when ordering `FlattenedCommit`. */
export enum FlattenedCommitsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  HeadCommitIdAsc = 'HEAD_COMMIT_ID_ASC',
  HeadCommitIdDesc = 'HEAD_COMMIT_ID_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  ParentCommitIdAsc = 'PARENT_COMMIT_ID_ASC',
  ParentCommitIdDesc = 'PARENT_COMMIT_ID_DESC',
}

export type FlattenedIncompleteMeasurementInvocation = {
  __typename?: 'FlattenedIncompleteMeasurementInvocation'
  durationSeconds: Maybe<Scalars['Float']>
  error: Maybe<Array<Maybe<Scalars['String']>>>
  gender: Maybe<Scalars['String']>
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  poseName: Maybe<Scalars['String']>
  resultUnits: Maybe<ResultValueType>
  resultValue: Maybe<Scalars['Float']>
  stateMachineStateId: Maybe<Scalars['String']>
  subjectId: Maybe<Scalars['Int']>
  subjectName: Maybe<Scalars['String']>
  topology: Maybe<TopologyType>
}

/**
 * A condition to be used against `FlattenedIncompleteMeasurementInvocation` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FlattenedIncompleteMeasurementInvocationCondition = {
  /** Checks for equality with the object’s `durationSeconds` field. */
  durationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `poseName` field. */
  poseName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `resultUnits` field. */
  resultUnits?: InputMaybe<ResultValueType>
  /** Checks for equality with the object’s `resultValue` field. */
  resultValue?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `subjectName` field. */
  subjectName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `topology` field. */
  topology?: InputMaybe<TopologyType>
}

/** A connection to a list of `FlattenedIncompleteMeasurementInvocation` values. */
export type FlattenedIncompleteMeasurementInvocationsConnection = {
  __typename?: 'FlattenedIncompleteMeasurementInvocationsConnection'
  /** A list of edges which contains the `FlattenedIncompleteMeasurementInvocation` and cursor to aid in pagination. */
  edges: Array<FlattenedIncompleteMeasurementInvocationsEdge>
  /** A list of `FlattenedIncompleteMeasurementInvocation` objects. */
  nodes: Array<FlattenedIncompleteMeasurementInvocation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `FlattenedIncompleteMeasurementInvocation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `FlattenedIncompleteMeasurementInvocation` edge in the connection. */
export type FlattenedIncompleteMeasurementInvocationsEdge = {
  __typename?: 'FlattenedIncompleteMeasurementInvocationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `FlattenedIncompleteMeasurementInvocation` at the end of the edge. */
  node: FlattenedIncompleteMeasurementInvocation
}

/** Methods to use when ordering `FlattenedIncompleteMeasurementInvocation`. */
export enum FlattenedIncompleteMeasurementInvocationsOrderBy {
  DurationSecondsAsc = 'DURATION_SECONDS_ASC',
  DurationSecondsDesc = 'DURATION_SECONDS_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  PoseNameAsc = 'POSE_NAME_ASC',
  PoseNameDesc = 'POSE_NAME_DESC',
  ResultUnitsAsc = 'RESULT_UNITS_ASC',
  ResultUnitsDesc = 'RESULT_UNITS_DESC',
  ResultValueAsc = 'RESULT_VALUE_ASC',
  ResultValueDesc = 'RESULT_VALUE_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  SubjectNameAsc = 'SUBJECT_NAME_ASC',
  SubjectNameDesc = 'SUBJECT_NAME_DESC',
  TopologyAsc = 'TOPOLOGY_ASC',
  TopologyDesc = 'TOPOLOGY_DESC',
}

export type FlattenedMeasurementInvocation = {
  __typename?: 'FlattenedMeasurementInvocation'
  durationSeconds: Maybe<Scalars['Float']>
  error: Maybe<Array<Maybe<Scalars['String']>>>
  gender: Maybe<Scalars['String']>
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  poseName: Maybe<Scalars['String']>
  resultUnits: Maybe<ResultValueType>
  resultValue: Maybe<Scalars['Float']>
  stateMachineStateId: Maybe<Scalars['String']>
  subjectId: Maybe<Scalars['Int']>
  subjectName: Maybe<Scalars['String']>
  topology: Maybe<TopologyType>
}

/**
 * A condition to be used against `FlattenedMeasurementInvocation` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FlattenedMeasurementInvocationCondition = {
  /** Checks for equality with the object’s `durationSeconds` field. */
  durationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `poseName` field. */
  poseName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `resultUnits` field. */
  resultUnits?: InputMaybe<ResultValueType>
  /** Checks for equality with the object’s `resultValue` field. */
  resultValue?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `subjectName` field. */
  subjectName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `topology` field. */
  topology?: InputMaybe<TopologyType>
}

/** A connection to a list of `FlattenedMeasurementInvocationCountByStateMachineStateRecord` values. */
export type FlattenedMeasurementInvocationCountByStateMachineStateConnection = {
  __typename?: 'FlattenedMeasurementInvocationCountByStateMachineStateConnection'
  /**
   * A list of edges which contains the
   * `FlattenedMeasurementInvocationCountByStateMachineStateRecord` and cursor to
   * aid in pagination.
   */
  edges: Array<FlattenedMeasurementInvocationCountByStateMachineStateEdge>
  /** A list of `FlattenedMeasurementInvocationCountByStateMachineStateRecord` objects. */
  nodes: Array<FlattenedMeasurementInvocationCountByStateMachineStateRecord>
  /** The count of *all* `FlattenedMeasurementInvocationCountByStateMachineStateRecord` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `FlattenedMeasurementInvocationCountByStateMachineStateRecord` edge in the connection. */
export type FlattenedMeasurementInvocationCountByStateMachineStateEdge = {
  __typename?: 'FlattenedMeasurementInvocationCountByStateMachineStateEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `FlattenedMeasurementInvocationCountByStateMachineStateRecord` at the end of the edge. */
  node: FlattenedMeasurementInvocationCountByStateMachineStateRecord
}

/** The return type of our `flattenedMeasurementInvocationCountByStateMachineState` query. */
export type FlattenedMeasurementInvocationCountByStateMachineStateRecord = {
  __typename?: 'FlattenedMeasurementInvocationCountByStateMachineStateRecord'
  stateMachineStateId: Maybe<Scalars['String']>
  stateMachineStateIdCount: Maybe<Scalars['Int']>
}

/** A connection to a list of `FlattenedMeasurementInvocation` values. */
export type FlattenedMeasurementInvocationsConnection = {
  __typename?: 'FlattenedMeasurementInvocationsConnection'
  /** A list of edges which contains the `FlattenedMeasurementInvocation` and cursor to aid in pagination. */
  edges: Array<FlattenedMeasurementInvocationsEdge>
  /** A list of `FlattenedMeasurementInvocation` objects. */
  nodes: Array<FlattenedMeasurementInvocation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `FlattenedMeasurementInvocation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `FlattenedMeasurementInvocation` edge in the connection. */
export type FlattenedMeasurementInvocationsEdge = {
  __typename?: 'FlattenedMeasurementInvocationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `FlattenedMeasurementInvocation` at the end of the edge. */
  node: FlattenedMeasurementInvocation
}

/** Methods to use when ordering `FlattenedMeasurementInvocation`. */
export enum FlattenedMeasurementInvocationsOrderBy {
  DurationSecondsAsc = 'DURATION_SECONDS_ASC',
  DurationSecondsDesc = 'DURATION_SECONDS_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  PoseNameAsc = 'POSE_NAME_ASC',
  PoseNameDesc = 'POSE_NAME_DESC',
  ResultUnitsAsc = 'RESULT_UNITS_ASC',
  ResultUnitsDesc = 'RESULT_UNITS_DESC',
  ResultValueAsc = 'RESULT_VALUE_ASC',
  ResultValueDesc = 'RESULT_VALUE_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  SubjectNameAsc = 'SUBJECT_NAME_ASC',
  SubjectNameDesc = 'SUBJECT_NAME_DESC',
  TopologyAsc = 'TOPOLOGY_ASC',
  TopologyDesc = 'TOPOLOGY_DESC',
}

/** A connection to a list of `Geometry` values. */
export type GeometriesConnection = {
  __typename?: 'GeometriesConnection'
  /** A list of edges which contains the `Geometry` and cursor to aid in pagination. */
  edges: Array<GeometriesEdge>
  /** A list of `Geometry` objects. */
  nodes: Array<Geometry>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Geometry` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Geometry` edge in the connection. */
export type GeometriesEdge = {
  __typename?: 'GeometriesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Geometry` at the end of the edge. */
  node: Geometry
}

/** Methods to use when ordering `Geometry`. */
export enum GeometriesOrderBy {
  GeometrySeriesIdAsc = 'GEOMETRY_SERIES_ID_ASC',
  GeometrySeriesIdDesc = 'GEOMETRY_SERIES_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OriginalTextureS3KeyAsc = 'ORIGINAL_TEXTURE_S3_KEY_ASC',
  OriginalTextureS3KeyDesc = 'ORIGINAL_TEXTURE_S3_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  TextureS3KeyAsc = 'TEXTURE_S3_KEY_ASC',
  TextureS3KeyDesc = 'TEXTURE_S3_KEY_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
}

export type Geometry = Node & {
  __typename?: 'Geometry'
  /** Reads and enables pagination through a set of `CheckoutsGeometriesLookup`. */
  checkoutsGeometriesLookupsByGeometryId: CheckoutsGeometriesLookupsConnection
  /** Reads and enables pagination through a set of `CommitsGeometriesLookup`. */
  commitsGeometriesLookupsByGeometryId: CommitsGeometriesLookupsConnection
  datasetId: Maybe<Scalars['Int']>
  /** Reads and enables pagination through a set of `GeometryAnnotation`. */
  geometryAnnotationsByGeometryId: GeometryAnnotationsConnection
  /** Reads and enables pagination through a set of `GeometryScreenshotInvocation`. */
  geometryScreenshotInvocationsByGeometryId: GeometryScreenshotInvocationsConnection
  /** Reads a single `GeometrySeries` that is related to this `Geometry`. */
  geometrySeriesByGeometrySeriesId: GeometrySeries
  geometrySeriesId: Scalars['Int']
  id: Scalars['Int']
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  measurementInvocationsByGeometryId: MeasurementInvocationsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  originalTextureS3Key: Maybe<Scalars['String']>
  originalTextureSignedURL: Maybe<Scalars['String']>
  /** Reads a single `ReferenceGeometryForView` that is related to this `Geometry`. */
  referenceGeometryForViewByGeometryId: ReferenceGeometryForView
  /**
   * Reads and enables pagination through a set of `ReferenceGeometryForView`.
   * @deprecated Please use referenceGeometryForViewByGeometryId instead
   */
  referenceGeometryForViewsByGeometryId: ReferenceGeometryForViewsConnection
  s3Key: Scalars['String']
  signedURL: Scalars['String']
  textureS3Key: Maybe<Scalars['String']>
  textureSignedURL: Maybe<Scalars['String']>
  version: Scalars['Int']
}

export type GeometryCheckoutsGeometriesLookupsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
}

export type GeometryCommitsGeometriesLookupsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
}

export type GeometryGeometryAnnotationsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryAnnotationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

export type GeometryGeometryScreenshotInvocationsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryScreenshotInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
}

export type GeometryMeasurementInvocationsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

export type GeometryReferenceGeometryForViewsByGeometryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<ReferenceGeometryForViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferenceGeometryForViewsOrderBy>>
}

export type GeometryAnnotation = Node & {
  __typename?: 'GeometryAnnotation'
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  geometryAnnotationTypeId: Scalars['Int']
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  id: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  point: Maybe<Array<Scalars['Float']>>
}

/**
 * A condition to be used against `GeometryAnnotation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GeometryAnnotationCondition = {
  /** Checks for equality with the object’s `geometryAnnotationTypeId` field. */
  geometryAnnotationTypeId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `point` field. */
  point?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** An input for mutations affecting `GeometryAnnotation` */
export type GeometryAnnotationInput = {
  geometryAnnotationTypeId: Scalars['Int']
  geometryId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  point?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** Represents an update to a `GeometryAnnotation`. Fields that are set will be updated. */
export type GeometryAnnotationPatch = {
  geometryAnnotationTypeId?: InputMaybe<Scalars['Int']>
  geometryId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  point?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** A connection to a list of `GeometryAnnotation` values. */
export type GeometryAnnotationsConnection = {
  __typename?: 'GeometryAnnotationsConnection'
  /** A list of edges which contains the `GeometryAnnotation` and cursor to aid in pagination. */
  edges: Array<GeometryAnnotationsEdge>
  /** A list of `GeometryAnnotation` objects. */
  nodes: Array<GeometryAnnotation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `GeometryAnnotation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `GeometryAnnotation` edge in the connection. */
export type GeometryAnnotationsEdge = {
  __typename?: 'GeometryAnnotationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `GeometryAnnotation` at the end of the edge. */
  node: GeometryAnnotation
}

/** Methods to use when ordering `GeometryAnnotation`. */
export enum GeometryAnnotationsOrderBy {
  GeometryAnnotationTypeIdAsc = 'GEOMETRY_ANNOTATION_TYPE_ID_ASC',
  GeometryAnnotationTypeIdDesc = 'GEOMETRY_ANNOTATION_TYPE_ID_DESC',
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PointAsc = 'POINT_ASC',
  PointDesc = 'POINT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type GeometryAnnotationType = Node & {
  __typename?: 'GeometryAnnotationType'
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  datasetId: Scalars['Int']
  /** Reads and enables pagination through a set of `GeometryAnnotation`. */
  geometryAnnotationsByGeometryAnnotationTypeId: GeometryAnnotationsConnection
  id: Scalars['Int']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type GeometryAnnotationTypeGeometryAnnotationsByGeometryAnnotationTypeIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<GeometryAnnotationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
  }

/**
 * A condition to be used against `GeometryAnnotationType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type GeometryAnnotationTypeCondition = {
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `GeometryAnnotationType` */
export type GeometryAnnotationTypeInput = {
  datasetId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

/** Represents an update to a `GeometryAnnotationType`. Fields that are set will be updated. */
export type GeometryAnnotationTypePatch = {
  datasetId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `GeometryAnnotationType` values. */
export type GeometryAnnotationTypesConnection = {
  __typename?: 'GeometryAnnotationTypesConnection'
  /** A list of edges which contains the `GeometryAnnotationType` and cursor to aid in pagination. */
  edges: Array<GeometryAnnotationTypesEdge>
  /** A list of `GeometryAnnotationType` objects. */
  nodes: Array<GeometryAnnotationType>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `GeometryAnnotationType` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `GeometryAnnotationType` edge in the connection. */
export type GeometryAnnotationTypesEdge = {
  __typename?: 'GeometryAnnotationTypesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `GeometryAnnotationType` at the end of the edge. */
  node: GeometryAnnotationType
}

/** Methods to use when ordering `GeometryAnnotationType`. */
export enum GeometryAnnotationTypesOrderBy {
  DatasetIdAsc = 'DATASET_ID_ASC',
  DatasetIdDesc = 'DATASET_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/**
 * A condition to be used against `Geometry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GeometryCondition = {
  /** Checks for equality with the object’s `geometrySeriesId` field. */
  geometrySeriesId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `originalTextureS3Key` field. */
  originalTextureS3Key?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `textureS3Key` field. */
  textureS3Key?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `Geometry` */
export type GeometryInput = {
  geometrySeriesId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  originalTextureS3Key?: InputMaybe<Scalars['String']>
  s3Key: Scalars['String']
  textureS3Key?: InputMaybe<Scalars['String']>
  version: Scalars['Int']
}

/** Represents an update to a `Geometry`. Fields that are set will be updated. */
export type GeometryPatch = {
  geometrySeriesId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  originalTextureS3Key?: InputMaybe<Scalars['String']>
  s3Key?: InputMaybe<Scalars['String']>
  textureS3Key?: InputMaybe<Scalars['String']>
  version?: InputMaybe<Scalars['Int']>
}

export type GeometryScreenshotInvocation = Node & {
  __typename?: 'GeometryScreenshotInvocation'
  error: Maybe<Array<Maybe<Scalars['String']>>>
  /** Reads a single `Geometry` that is related to this `GeometryScreenshotInvocation`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `GeometryScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  s3Key: Maybe<Scalars['String']>
  signedURL: Maybe<Scalars['String']>
  stateMachineStateId: Scalars['String']
  viewIndex: Scalars['Int']
}

/**
 * A condition to be used against `GeometryScreenshotInvocation` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type GeometryScreenshotInvocationCondition = {
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `viewIndex` field. */
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `GeometryScreenshotInvocation` */
export type GeometryScreenshotInvocationInput = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  geometryId: Scalars['Int']
  s3Key?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  viewIndex: Scalars['Int']
}

/** Represents an update to a `GeometryScreenshotInvocation`. Fields that are set will be updated. */
export type GeometryScreenshotInvocationPatch = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  geometryId?: InputMaybe<Scalars['Int']>
  s3Key?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `GeometryScreenshotInvocation` values. */
export type GeometryScreenshotInvocationsConnection = {
  __typename?: 'GeometryScreenshotInvocationsConnection'
  /** A list of edges which contains the `GeometryScreenshotInvocation` and cursor to aid in pagination. */
  edges: Array<GeometryScreenshotInvocationsEdge>
  /** A list of `GeometryScreenshotInvocation` objects. */
  nodes: Array<GeometryScreenshotInvocation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `GeometryScreenshotInvocation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `GeometryScreenshotInvocation` edge in the connection. */
export type GeometryScreenshotInvocationsEdge = {
  __typename?: 'GeometryScreenshotInvocationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `GeometryScreenshotInvocation` at the end of the edge. */
  node: GeometryScreenshotInvocation
}

/** Methods to use when ordering `GeometryScreenshotInvocation`. */
export enum GeometryScreenshotInvocationsOrderBy {
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  ViewIndexAsc = 'VIEW_INDEX_ASC',
  ViewIndexDesc = 'VIEW_INDEX_DESC',
}

export type GeometrySeries = Node & {
  __typename?: 'GeometrySeries'
  /** Reads and enables pagination through a set of `Geometry`. */
  geometriesByGeometrySeriesId: GeometriesConnection
  id: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PoseType` that is related to this `GeometrySeries`. */
  poseTypeByPoseTypeId: PoseType
  poseTypeId: Scalars['Int']
  side: Maybe<Scalars['String']>
  /** Reads a single `Subject` that is related to this `GeometrySeries`. */
  subjectBySubjectId: Subject
  subjectId: Scalars['Int']
  topology: Maybe<TopologyType>
}

export type GeometrySeriesGeometriesByGeometrySeriesIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometriesOrderBy>>
}

/**
 * A condition to be used against `GeometrySeries` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GeometrySeriesCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `poseTypeId` field. */
  poseTypeId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `side` field. */
  side?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `topology` field. */
  topology?: InputMaybe<TopologyType>
}

/** A connection to a list of `GeometrySeries` values. */
export type GeometrySeriesConnection = {
  __typename?: 'GeometrySeriesConnection'
  /** A list of edges which contains the `GeometrySeries` and cursor to aid in pagination. */
  edges: Array<GeometrySeriesEdge>
  /** A list of `GeometrySeries` objects. */
  nodes: Array<GeometrySeries>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `GeometrySeries` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `GeometrySeries` edge in the connection. */
export type GeometrySeriesEdge = {
  __typename?: 'GeometrySeriesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `GeometrySeries` at the end of the edge. */
  node: GeometrySeries
}

/** An input for mutations affecting `GeometrySeries` */
export type GeometrySeriesInput = {
  id?: InputMaybe<Scalars['Int']>
  poseTypeId: Scalars['Int']
  side?: InputMaybe<Scalars['String']>
  subjectId: Scalars['Int']
  topology?: InputMaybe<TopologyType>
}

/** Methods to use when ordering `GeometrySeries`. */
export enum GeometrySeriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PoseTypeIdAsc = 'POSE_TYPE_ID_ASC',
  PoseTypeIdDesc = 'POSE_TYPE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SideAsc = 'SIDE_ASC',
  SideDesc = 'SIDE_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  TopologyAsc = 'TOPOLOGY_ASC',
  TopologyDesc = 'TOPOLOGY_DESC',
}

/** Represents an update to a `GeometrySeries`. Fields that are set will be updated. */
export type GeometrySeriesPatch = {
  id?: InputMaybe<Scalars['Int']>
  poseTypeId?: InputMaybe<Scalars['Int']>
  side?: InputMaybe<Scalars['String']>
  subjectId?: InputMaybe<Scalars['Int']>
  topology?: InputMaybe<TopologyType>
}

/** All input for the `getAllParentCommits` mutation. */
export type GetAllParentCommitsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  headCommitId?: InputMaybe<Scalars['Int']>
}

/** The output of our `getAllParentCommits` mutation. */
export type GetAllParentCommitsPayload = {
  __typename?: 'GetAllParentCommitsPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  results: Maybe<Array<Maybe<GetAllParentCommitsRecord>>>
}

/** All input for the `getAllParentCommitsPreservingInputCommitId` mutation. */
export type GetAllParentCommitsPreservingInputCommitIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  hci?: InputMaybe<Scalars['Int']>
}

/** The output of our `getAllParentCommitsPreservingInputCommitId` mutation. */
export type GetAllParentCommitsPreservingInputCommitIdPayload = {
  __typename?: 'GetAllParentCommitsPreservingInputCommitIdPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  results: Maybe<Array<Maybe<GetAllParentCommitsPreservingInputCommitIdRecord>>>
}

/** The return type of our `getAllParentCommitsPreservingInputCommitId` mutation. */
export type GetAllParentCommitsPreservingInputCommitIdRecord = {
  __typename?: 'GetAllParentCommitsPreservingInputCommitIdRecord'
  headCommitId: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  parentCommitId: Maybe<Scalars['Int']>
}

/** The return type of our `getAllParentCommits` mutation. */
export type GetAllParentCommitsRecord = {
  __typename?: 'GetAllParentCommitsRecord'
  id: Maybe<Scalars['Int']>
  parentCommitId: Maybe<Scalars['Int']>
}

export type GithubBranchesResponse = {
  __typename?: 'GithubBranchesResponse'
  branchName: Scalars['String']
  measurerName: DataLayerMeasurerName
  repoSlug: Scalars['String']
  sha1: Scalars['String']
}

export type GithubResponse = {
  __typename?: 'GithubResponse'
  branches: Array<GithubBranchesResponse>
}

export type GoldilocksUploadBucketListResponse = {
  __typename?: 'GoldilocksUploadBucketListResponse'
  Contents: Array<GoldilocksUploadBucketListResponseContents>
  IsTruncated: Scalars['Boolean']
  Marker: Scalars['String']
  MaxKeys: Scalars['Int']
  Name: Scalars['String']
  Prefix: Scalars['String']
}

export type GoldilocksUploadBucketListResponseContents = {
  __typename?: 'GoldilocksUploadBucketListResponseContents'
  ETag: Scalars['String']
  Key: Scalars['String']
  LastModified: Scalars['Date']
  Size: Scalars['Int']
  StorageClass: Scalars['String']
}

export type HeadCommit = {
  __typename?: 'HeadCommit'
  /** Reads a single `Commit` that is related to this `HeadCommit`. */
  commitByCommitId: Commit
  /** Reads a single `Commit` that is related to this `HeadCommit`. */
  commitByHeadCommitId: Commit
  /** Reads a single `Commit` that is related to this `HeadCommit`. */
  commitByParentCommitId: Commit
  commitId: Maybe<Scalars['Int']>
  headCommitId: Maybe<Scalars['Int']>
  /** Reads a single `MeasurementStudy` that is related to this `HeadCommit`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
  parentCommitId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `HeadCommit` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type HeadCommitCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `headCommitId` field. */
  headCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `parentCommitId` field. */
  parentCommitId?: InputMaybe<Scalars['Int']>
}

export type HeadCommitIdForEachMeasurementStudyXInvocationPair = {
  __typename?: 'HeadCommitIdForEachMeasurementStudyXInvocationPair'
  headCommitId: Maybe<Scalars['Int']>
  measurementInvocationId: Maybe<Scalars['Int']>
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against
 * `HeadCommitIdForEachMeasurementStudyXInvocationPair` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type HeadCommitIdForEachMeasurementStudyXInvocationPairCondition = {
  /** Checks for equality with the object’s `headCommitId` field. */
  headCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `HeadCommitIdForEachMeasurementStudyXInvocationPair` values. */
export type HeadCommitIdForEachMeasurementStudyXInvocationPairsConnection = {
  __typename?: 'HeadCommitIdForEachMeasurementStudyXInvocationPairsConnection'
  /** A list of edges which contains the `HeadCommitIdForEachMeasurementStudyXInvocationPair` and cursor to aid in pagination. */
  edges: Array<HeadCommitIdForEachMeasurementStudyXInvocationPairsEdge>
  /** A list of `HeadCommitIdForEachMeasurementStudyXInvocationPair` objects. */
  nodes: Array<HeadCommitIdForEachMeasurementStudyXInvocationPair>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `HeadCommitIdForEachMeasurementStudyXInvocationPair` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `HeadCommitIdForEachMeasurementStudyXInvocationPair` edge in the connection. */
export type HeadCommitIdForEachMeasurementStudyXInvocationPairsEdge = {
  __typename?: 'HeadCommitIdForEachMeasurementStudyXInvocationPairsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `HeadCommitIdForEachMeasurementStudyXInvocationPair` at the end of the edge. */
  node: HeadCommitIdForEachMeasurementStudyXInvocationPair
}

/** Methods to use when ordering `HeadCommitIdForEachMeasurementStudyXInvocationPair`. */
export enum HeadCommitIdForEachMeasurementStudyXInvocationPairsOrderBy {
  HeadCommitIdAsc = 'HEAD_COMMIT_ID_ASC',
  HeadCommitIdDesc = 'HEAD_COMMIT_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

/** A connection to a list of `HeadCommit` values. */
export type HeadCommitsConnection = {
  __typename?: 'HeadCommitsConnection'
  /** A list of edges which contains the `HeadCommit` and cursor to aid in pagination. */
  edges: Array<HeadCommitsEdge>
  /** A list of `HeadCommit` objects. */
  nodes: Array<HeadCommit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `HeadCommit` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `HeadCommit` edge in the connection. */
export type HeadCommitsEdge = {
  __typename?: 'HeadCommitsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `HeadCommit` at the end of the edge. */
  node: HeadCommit
}

/** Methods to use when ordering `HeadCommit`. */
export enum HeadCommitsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  HeadCommitIdAsc = 'HEAD_COMMIT_ID_ASC',
  HeadCommitIdDesc = 'HEAD_COMMIT_ID_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  ParentCommitIdAsc = 'PARENT_COMMIT_ID_ASC',
  ParentCommitIdDesc = 'PARENT_COMMIT_ID_DESC',
}

export type ImportBodyGeometryInput = {
  datasetId: Scalars['Int']
  eTag: Scalars['String']
  gender: Scalars['String']
  poseTypeId: Scalars['Int']
  s3Key: Scalars['String']
  subjectName: Scalars['String']
  textureS3Key?: InputMaybe<Scalars['String']>
}

export type ImportGeometryResponse = {
  __typename?: 'ImportGeometryResponse'
  geometry: Maybe<Geometry>
}

export type ImportHandGeometryInput = {
  datasetId: Scalars['Int']
  eTag: Scalars['String']
  poseTypeId: Scalars['Int']
  s3Key: Scalars['String']
  side: Scalars['String']
  subjectName: Scalars['String']
  textureS3Key?: InputMaybe<Scalars['String']>
}

export type IncompleteMeasurementInvocationsIteration = {
  __typename?: 'IncompleteMeasurementInvocationsIteration'
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `IncompleteMeasurementInvocationsIteration`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type IncompleteMeasurementInvocationsIterationCondition = {
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `IncompleteMeasurementInvocationsIteration` values. */
export type IncompleteMeasurementInvocationsIterationsConnection = {
  __typename?: 'IncompleteMeasurementInvocationsIterationsConnection'
  /** A list of edges which contains the `IncompleteMeasurementInvocationsIteration` and cursor to aid in pagination. */
  edges: Array<IncompleteMeasurementInvocationsIterationsEdge>
  /** A list of `IncompleteMeasurementInvocationsIteration` objects. */
  nodes: Array<IncompleteMeasurementInvocationsIteration>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `IncompleteMeasurementInvocationsIteration` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `IncompleteMeasurementInvocationsIteration` edge in the connection. */
export type IncompleteMeasurementInvocationsIterationsEdge = {
  __typename?: 'IncompleteMeasurementInvocationsIterationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `IncompleteMeasurementInvocationsIteration` at the end of the edge. */
  node: IncompleteMeasurementInvocationsIteration
}

/** Methods to use when ordering `IncompleteMeasurementInvocationsIteration`. */
export enum IncompleteMeasurementInvocationsIterationsOrderBy {
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

export type IncompleteMeasurementInvocationsMeasurementName = {
  __typename?: 'IncompleteMeasurementInvocationsMeasurementName'
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `IncompleteMeasurementInvocationsMeasurementName`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type IncompleteMeasurementInvocationsMeasurementNameCondition = {
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `IncompleteMeasurementInvocationsMeasurementName` values. */
export type IncompleteMeasurementInvocationsMeasurementNamesConnection = {
  __typename?: 'IncompleteMeasurementInvocationsMeasurementNamesConnection'
  /** A list of edges which contains the `IncompleteMeasurementInvocationsMeasurementName` and cursor to aid in pagination. */
  edges: Array<IncompleteMeasurementInvocationsMeasurementNamesEdge>
  /** A list of `IncompleteMeasurementInvocationsMeasurementName` objects. */
  nodes: Array<IncompleteMeasurementInvocationsMeasurementName>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `IncompleteMeasurementInvocationsMeasurementName` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `IncompleteMeasurementInvocationsMeasurementName` edge in the connection. */
export type IncompleteMeasurementInvocationsMeasurementNamesEdge = {
  __typename?: 'IncompleteMeasurementInvocationsMeasurementNamesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `IncompleteMeasurementInvocationsMeasurementName` at the end of the edge. */
  node: IncompleteMeasurementInvocationsMeasurementName
}

/** Methods to use when ordering `IncompleteMeasurementInvocationsMeasurementName`. */
export enum IncompleteMeasurementInvocationsMeasurementNamesOrderBy {
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

export type IncompleteMeasurementInvocationsSubjectName = {
  __typename?: 'IncompleteMeasurementInvocationsSubjectName'
  measurementStudyId: Maybe<Scalars['Int']>
  subjectName: Maybe<Scalars['String']>
}

/**
 * A condition to be used against `IncompleteMeasurementInvocationsSubjectName`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type IncompleteMeasurementInvocationsSubjectNameCondition = {
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `subjectName` field. */
  subjectName?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `IncompleteMeasurementInvocationsSubjectName` values. */
export type IncompleteMeasurementInvocationsSubjectNamesConnection = {
  __typename?: 'IncompleteMeasurementInvocationsSubjectNamesConnection'
  /** A list of edges which contains the `IncompleteMeasurementInvocationsSubjectName` and cursor to aid in pagination. */
  edges: Array<IncompleteMeasurementInvocationsSubjectNamesEdge>
  /** A list of `IncompleteMeasurementInvocationsSubjectName` objects. */
  nodes: Array<IncompleteMeasurementInvocationsSubjectName>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `IncompleteMeasurementInvocationsSubjectName` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `IncompleteMeasurementInvocationsSubjectName` edge in the connection. */
export type IncompleteMeasurementInvocationsSubjectNamesEdge = {
  __typename?: 'IncompleteMeasurementInvocationsSubjectNamesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `IncompleteMeasurementInvocationsSubjectName` at the end of the edge. */
  node: IncompleteMeasurementInvocationsSubjectName
}

/** Methods to use when ordering `IncompleteMeasurementInvocationsSubjectName`. */
export enum IncompleteMeasurementInvocationsSubjectNamesOrderBy {
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  SubjectNameAsc = 'SUBJECT_NAME_ASC',
  SubjectNameDesc = 'SUBJECT_NAME_DESC',
}

export type IterationPerformance = {
  __typename?: 'IterationPerformance'
  leftMaxDurationSeconds: Maybe<Scalars['Float']>
  leftMeasurementInvocationIteration: Maybe<Scalars['Int']>
  leftMedianDurationSeconds: Maybe<Scalars['Float']>
  leftNinetiethPctDurationSeconds: Maybe<Scalars['Float']>
  leftNumInvocations: Maybe<Scalars['BigInt']>
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `IterationPerformance`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
  rightMaxDurationSeconds: Maybe<Scalars['Float']>
  rightMeasurementInvocationIteration: Maybe<Scalars['Int']>
  rightMedianDurationSeconds: Maybe<Scalars['Float']>
  rightNinetiethPctDurationSeconds: Maybe<Scalars['Float']>
  rightNumInvocations: Maybe<Scalars['BigInt']>
}

/**
 * A condition to be used against `IterationPerformance` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IterationPerformanceCondition = {
  /** Checks for equality with the object’s `leftMaxDurationSeconds` field. */
  leftMaxDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftMeasurementInvocationIteration` field. */
  leftMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `leftMedianDurationSeconds` field. */
  leftMedianDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftNinetiethPctDurationSeconds` field. */
  leftNinetiethPctDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftNumInvocations` field. */
  leftNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightMaxDurationSeconds` field. */
  rightMaxDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightMeasurementInvocationIteration` field. */
  rightMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightMedianDurationSeconds` field. */
  rightMedianDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightNinetiethPctDurationSeconds` field. */
  rightNinetiethPctDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightNumInvocations` field. */
  rightNumInvocations?: InputMaybe<Scalars['BigInt']>
}

/** A connection to a list of `IterationPerformance` values. */
export type IterationPerformancesConnection = {
  __typename?: 'IterationPerformancesConnection'
  /** A list of edges which contains the `IterationPerformance` and cursor to aid in pagination. */
  edges: Array<IterationPerformancesEdge>
  /** A list of `IterationPerformance` objects. */
  nodes: Array<IterationPerformance>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `IterationPerformance` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `IterationPerformance` edge in the connection. */
export type IterationPerformancesEdge = {
  __typename?: 'IterationPerformancesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `IterationPerformance` at the end of the edge. */
  node: IterationPerformance
}

/** Methods to use when ordering `IterationPerformance`. */
export enum IterationPerformancesOrderBy {
  LeftMaxDurationSecondsAsc = 'LEFT_MAX_DURATION_SECONDS_ASC',
  LeftMaxDurationSecondsDesc = 'LEFT_MAX_DURATION_SECONDS_DESC',
  LeftMeasurementInvocationIterationAsc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  LeftMeasurementInvocationIterationDesc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  LeftMedianDurationSecondsAsc = 'LEFT_MEDIAN_DURATION_SECONDS_ASC',
  LeftMedianDurationSecondsDesc = 'LEFT_MEDIAN_DURATION_SECONDS_DESC',
  LeftNinetiethPctDurationSecondsAsc = 'LEFT_NINETIETH_PCT_DURATION_SECONDS_ASC',
  LeftNinetiethPctDurationSecondsDesc = 'LEFT_NINETIETH_PCT_DURATION_SECONDS_DESC',
  LeftNumInvocationsAsc = 'LEFT_NUM_INVOCATIONS_ASC',
  LeftNumInvocationsDesc = 'LEFT_NUM_INVOCATIONS_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  RightMaxDurationSecondsAsc = 'RIGHT_MAX_DURATION_SECONDS_ASC',
  RightMaxDurationSecondsDesc = 'RIGHT_MAX_DURATION_SECONDS_DESC',
  RightMeasurementInvocationIterationAsc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  RightMeasurementInvocationIterationDesc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  RightMedianDurationSecondsAsc = 'RIGHT_MEDIAN_DURATION_SECONDS_ASC',
  RightMedianDurationSecondsDesc = 'RIGHT_MEDIAN_DURATION_SECONDS_DESC',
  RightNinetiethPctDurationSecondsAsc = 'RIGHT_NINETIETH_PCT_DURATION_SECONDS_ASC',
  RightNinetiethPctDurationSecondsDesc = 'RIGHT_NINETIETH_PCT_DURATION_SECONDS_DESC',
  RightNumInvocationsAsc = 'RIGHT_NUM_INVOCATIONS_ASC',
  RightNumInvocationsDesc = 'RIGHT_NUM_INVOCATIONS_DESC',
}

export type Label = Node & {
  __typename?: 'Label'
  commitId: Scalars['Int']
  id: Scalars['Int']
  isFailure: Scalars['Boolean']
  /** Reads and enables pagination through a set of `LabelsOfHeadCommit`. */
  labelsOfHeadCommitsByLabelId: LabelsOfHeadCommitsConnection
  measurementInvocationId: Scalars['Int']
  name: Maybe<Scalars['String']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type LabelLabelsOfHeadCommitsByLabelIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelsOfHeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOfHeadCommitsOrderBy>>
}

/** A condition to be used against `Label` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LabelCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `isFailure` field. */
  isFailure?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

export type LabelDelta = {
  added: Array<ShortLabel>
  removed: Array<ShortLabel>
}

/** An input for mutations affecting `Label` */
export type LabelInput = {
  commitId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  isFailure: Scalars['Boolean']
  measurementInvocationId: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

/** Represents an update to a `Label`. Fields that are set will be updated. */
export type LabelPatch = {
  commitId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  isFailure?: InputMaybe<Scalars['Boolean']>
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `Label` values. */
export type LabelsConnection = {
  __typename?: 'LabelsConnection'
  /** A list of edges which contains the `Label` and cursor to aid in pagination. */
  edges: Array<LabelsEdge>
  /** A list of `Label` objects. */
  nodes: Array<Label>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Label` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Label` edge in the connection. */
export type LabelsEdge = {
  __typename?: 'LabelsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Label` at the end of the edge. */
  node: Label
}

export type LabelsOfFlattenedCommit = {
  __typename?: 'LabelsOfFlattenedCommit'
  commitId: Maybe<Scalars['Int']>
  labelId: Maybe<Scalars['Int']>
  measurementInvocationId: Maybe<Scalars['Int']>
  measurementStudyHeadCommitId: Maybe<Scalars['Int']>
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `LabelsOfFlattenedCommit` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LabelsOfFlattenedCommitCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `labelId` field. */
  labelId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyHeadCommitId` field. */
  measurementStudyHeadCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `LabelsOfFlattenedCommit` values. */
export type LabelsOfFlattenedCommitsConnection = {
  __typename?: 'LabelsOfFlattenedCommitsConnection'
  /** A list of edges which contains the `LabelsOfFlattenedCommit` and cursor to aid in pagination. */
  edges: Array<LabelsOfFlattenedCommitsEdge>
  /** A list of `LabelsOfFlattenedCommit` objects. */
  nodes: Array<LabelsOfFlattenedCommit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `LabelsOfFlattenedCommit` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `LabelsOfFlattenedCommit` edge in the connection. */
export type LabelsOfFlattenedCommitsEdge = {
  __typename?: 'LabelsOfFlattenedCommitsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `LabelsOfFlattenedCommit` at the end of the edge. */
  node: LabelsOfFlattenedCommit
}

/** Methods to use when ordering `LabelsOfFlattenedCommit`. */
export enum LabelsOfFlattenedCommitsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementStudyHeadCommitIdAsc = 'MEASUREMENT_STUDY_HEAD_COMMIT_ID_ASC',
  MeasurementStudyHeadCommitIdDesc = 'MEASUREMENT_STUDY_HEAD_COMMIT_ID_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

export type LabelsOfHeadCommit = {
  __typename?: 'LabelsOfHeadCommit'
  /** Reads a single `Commit` that is related to this `LabelsOfHeadCommit`. */
  commitByCommitId: Commit
  commitId: Maybe<Scalars['Int']>
  /** Reads a single `Label` that is related to this `LabelsOfHeadCommit`. */
  labelByLabelId: Label
  labelId: Maybe<Scalars['Int']>
  /** Reads a single `MeasurementInvocation` that is related to this `LabelsOfHeadCommit`. */
  measurementInvocationByMeasurementInvocationId: MeasurementInvocation
  measurementInvocationId: Maybe<Scalars['Int']>
  /** Reads a single `MeasurementStudy` that is related to this `LabelsOfHeadCommit`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `LabelsOfHeadCommit` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LabelsOfHeadCommitCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `labelId` field. */
  labelId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `LabelsOfHeadCommit` values. */
export type LabelsOfHeadCommitsConnection = {
  __typename?: 'LabelsOfHeadCommitsConnection'
  /** A list of edges which contains the `LabelsOfHeadCommit` and cursor to aid in pagination. */
  edges: Array<LabelsOfHeadCommitsEdge>
  /** A list of `LabelsOfHeadCommit` objects. */
  nodes: Array<LabelsOfHeadCommit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `LabelsOfHeadCommit` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `LabelsOfHeadCommit` edge in the connection. */
export type LabelsOfHeadCommitsEdge = {
  __typename?: 'LabelsOfHeadCommitsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `LabelsOfHeadCommit` at the end of the edge. */
  node: LabelsOfHeadCommit
}

/** Methods to use when ordering `LabelsOfHeadCommit`. */
export enum LabelsOfHeadCommitsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

/** Methods to use when ordering `Label`. */
export enum LabelsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsFailureAsc = 'IS_FAILURE_ASC',
  IsFailureDesc = 'IS_FAILURE_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type LatestIterationPerformance = {
  __typename?: 'LatestIterationPerformance'
  leftMaxDurationSeconds: Maybe<Scalars['Float']>
  leftMeasurementInvocationIteration: Maybe<Scalars['Int']>
  leftMedianDurationSeconds: Maybe<Scalars['Float']>
  leftNinetiethPctDurationSeconds: Maybe<Scalars['Float']>
  leftNumInvocations: Maybe<Scalars['BigInt']>
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `LatestIterationPerformance`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
  rightMaxDurationSeconds: Maybe<Scalars['Float']>
  rightMeasurementInvocationIteration: Maybe<Scalars['Int']>
  rightMedianDurationSeconds: Maybe<Scalars['Float']>
  rightNinetiethPctDurationSeconds: Maybe<Scalars['Float']>
  rightNumInvocations: Maybe<Scalars['BigInt']>
}

/**
 * A condition to be used against `LatestIterationPerformance` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LatestIterationPerformanceCondition = {
  /** Checks for equality with the object’s `leftMaxDurationSeconds` field. */
  leftMaxDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftMeasurementInvocationIteration` field. */
  leftMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `leftMedianDurationSeconds` field. */
  leftMedianDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftNinetiethPctDurationSeconds` field. */
  leftNinetiethPctDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftNumInvocations` field. */
  leftNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightMaxDurationSeconds` field. */
  rightMaxDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightMeasurementInvocationIteration` field. */
  rightMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightMedianDurationSeconds` field. */
  rightMedianDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightNinetiethPctDurationSeconds` field. */
  rightNinetiethPctDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightNumInvocations` field. */
  rightNumInvocations?: InputMaybe<Scalars['BigInt']>
}

/** A connection to a list of `LatestIterationPerformance` values. */
export type LatestIterationPerformancesConnection = {
  __typename?: 'LatestIterationPerformancesConnection'
  /** A list of edges which contains the `LatestIterationPerformance` and cursor to aid in pagination. */
  edges: Array<LatestIterationPerformancesEdge>
  /** A list of `LatestIterationPerformance` objects. */
  nodes: Array<LatestIterationPerformance>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `LatestIterationPerformance` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `LatestIterationPerformance` edge in the connection. */
export type LatestIterationPerformancesEdge = {
  __typename?: 'LatestIterationPerformancesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `LatestIterationPerformance` at the end of the edge. */
  node: LatestIterationPerformance
}

/** Methods to use when ordering `LatestIterationPerformance`. */
export enum LatestIterationPerformancesOrderBy {
  LeftMaxDurationSecondsAsc = 'LEFT_MAX_DURATION_SECONDS_ASC',
  LeftMaxDurationSecondsDesc = 'LEFT_MAX_DURATION_SECONDS_DESC',
  LeftMeasurementInvocationIterationAsc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  LeftMeasurementInvocationIterationDesc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  LeftMedianDurationSecondsAsc = 'LEFT_MEDIAN_DURATION_SECONDS_ASC',
  LeftMedianDurationSecondsDesc = 'LEFT_MEDIAN_DURATION_SECONDS_DESC',
  LeftNinetiethPctDurationSecondsAsc = 'LEFT_NINETIETH_PCT_DURATION_SECONDS_ASC',
  LeftNinetiethPctDurationSecondsDesc = 'LEFT_NINETIETH_PCT_DURATION_SECONDS_DESC',
  LeftNumInvocationsAsc = 'LEFT_NUM_INVOCATIONS_ASC',
  LeftNumInvocationsDesc = 'LEFT_NUM_INVOCATIONS_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  RightMaxDurationSecondsAsc = 'RIGHT_MAX_DURATION_SECONDS_ASC',
  RightMaxDurationSecondsDesc = 'RIGHT_MAX_DURATION_SECONDS_DESC',
  RightMeasurementInvocationIterationAsc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  RightMeasurementInvocationIterationDesc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  RightMedianDurationSecondsAsc = 'RIGHT_MEDIAN_DURATION_SECONDS_ASC',
  RightMedianDurationSecondsDesc = 'RIGHT_MEDIAN_DURATION_SECONDS_DESC',
  RightNinetiethPctDurationSecondsAsc = 'RIGHT_NINETIETH_PCT_DURATION_SECONDS_ASC',
  RightNinetiethPctDurationSecondsDesc = 'RIGHT_NINETIETH_PCT_DURATION_SECONDS_DESC',
  RightNumInvocationsAsc = 'RIGHT_NUM_INVOCATIONS_ASC',
  RightNumInvocationsDesc = 'RIGHT_NUM_INVOCATIONS_DESC',
}

export type LatestIterationsByMeasurement = {
  __typename?: 'LatestIterationsByMeasurement'
  /** Reads a single `Commit` that is related to this `LatestIterationsByMeasurement`. */
  commitByMeasurementInvocationCommitId: Commit
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `LatestIterationsByMeasurement`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `LatestIterationsByMeasurement` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LatestIterationsByMeasurementCondition = {
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `LatestIterationsByMeasurement` values. */
export type LatestIterationsByMeasurementsConnection = {
  __typename?: 'LatestIterationsByMeasurementsConnection'
  /** A list of edges which contains the `LatestIterationsByMeasurement` and cursor to aid in pagination. */
  edges: Array<LatestIterationsByMeasurementsEdge>
  /** A list of `LatestIterationsByMeasurement` objects. */
  nodes: Array<LatestIterationsByMeasurement>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `LatestIterationsByMeasurement` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `LatestIterationsByMeasurement` edge in the connection. */
export type LatestIterationsByMeasurementsEdge = {
  __typename?: 'LatestIterationsByMeasurementsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `LatestIterationsByMeasurement` at the end of the edge. */
  node: LatestIterationsByMeasurement
}

/** Methods to use when ordering `LatestIterationsByMeasurement`. */
export enum LatestIterationsByMeasurementsOrderBy {
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

export type LatestSubjectChange = {
  __typename?: 'LatestSubjectChange'
  leftMeasurementInvocationIteration: Maybe<Scalars['Int']>
  leftNumInvocations: Maybe<Scalars['BigInt']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  numMissingComparisonMetrics: Maybe<Scalars['BigInt']>
  pathDifferenceRatios: Maybe<Array<Maybe<Scalars['Float']>>>
  rightMeasurementInvocationIteration: Maybe<Scalars['Int']>
  rightNumInvocations: Maybe<Scalars['BigInt']>
  valueDifferenceRatios: Maybe<Array<Maybe<Scalars['Float']>>>
}

/**
 * A condition to be used against `LatestSubjectChange` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LatestSubjectChangeCondition = {
  /** Checks for equality with the object’s `leftMeasurementInvocationIteration` field. */
  leftMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `leftNumInvocations` field. */
  leftNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `numMissingComparisonMetrics` field. */
  numMissingComparisonMetrics?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `pathDifferenceRatios` field. */
  pathDifferenceRatios?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  /** Checks for equality with the object’s `rightMeasurementInvocationIteration` field. */
  rightMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightNumInvocations` field. */
  rightNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `valueDifferenceRatios` field. */
  valueDifferenceRatios?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** A connection to a list of `LatestSubjectChange` values. */
export type LatestSubjectChangesConnection = {
  __typename?: 'LatestSubjectChangesConnection'
  /** A list of edges which contains the `LatestSubjectChange` and cursor to aid in pagination. */
  edges: Array<LatestSubjectChangesEdge>
  /** A list of `LatestSubjectChange` objects. */
  nodes: Array<LatestSubjectChange>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `LatestSubjectChange` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `LatestSubjectChange` edge in the connection. */
export type LatestSubjectChangesEdge = {
  __typename?: 'LatestSubjectChangesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `LatestSubjectChange` at the end of the edge. */
  node: LatestSubjectChange
}

/** Methods to use when ordering `LatestSubjectChange`. */
export enum LatestSubjectChangesOrderBy {
  LeftMeasurementInvocationIterationAsc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  LeftMeasurementInvocationIterationDesc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  LeftNumInvocationsAsc = 'LEFT_NUM_INVOCATIONS_ASC',
  LeftNumInvocationsDesc = 'LEFT_NUM_INVOCATIONS_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  NumMissingComparisonMetricsAsc = 'NUM_MISSING_COMPARISON_METRICS_ASC',
  NumMissingComparisonMetricsDesc = 'NUM_MISSING_COMPARISON_METRICS_DESC',
  PathDifferenceRatiosAsc = 'PATH_DIFFERENCE_RATIOS_ASC',
  PathDifferenceRatiosDesc = 'PATH_DIFFERENCE_RATIOS_DESC',
  RightMeasurementInvocationIterationAsc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  RightMeasurementInvocationIterationDesc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  RightNumInvocationsAsc = 'RIGHT_NUM_INVOCATIONS_ASC',
  RightNumInvocationsDesc = 'RIGHT_NUM_INVOCATIONS_DESC',
  ValueDifferenceRatiosAsc = 'VALUE_DIFFERENCE_RATIOS_ASC',
  ValueDifferenceRatiosDesc = 'VALUE_DIFFERENCE_RATIOS_DESC',
}

export type MeasurementInvocation = Node & {
  __typename?: 'MeasurementInvocation'
  /** Reads a single `Commit` that is related to this `MeasurementInvocation`. */
  commitByCommitId: Commit
  commitId: Scalars['Int']
  /** Reads a single `CommitsGeometriesLookup` that is related to this `MeasurementInvocation`. */
  commitsGeometriesLookupByGeometryIdAndCommitId: CommitsGeometriesLookup
  /** Reads a single `Curve` that is related to this `MeasurementInvocation`. */
  curveByCurveId: Maybe<Curve>
  curveId: Maybe<Scalars['Int']>
  durationSeconds: Maybe<Scalars['Float']>
  error: Maybe<Array<Maybe<Scalars['String']>>>
  /** Reads a single `Geometry` that is related to this `MeasurementInvocation`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  id: Scalars['Int']
  /** Reads and enables pagination through a set of `LabelsOfHeadCommit`. */
  labelsOfHeadCommitsByMeasurementInvocationId: LabelsOfHeadCommitsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationDifference`. */
  measurementInvocationDifferencesByInvocationId: MeasurementInvocationDifferencesConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationDifference`. */
  measurementInvocationDifferencesByPreviousInvocationId: MeasurementInvocationDifferencesConnection
  measurementName: Scalars['String']
  /** Reads and enables pagination through a set of `MeasurementScreenshotInvocation`. */
  measurementScreenshotInvocationsByMeasurementInvocationId: MeasurementScreenshotInvocationsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  resultUnits: Maybe<ResultValueType>
  resultValue: Maybe<Scalars['Float']>
  stateMachineStateId: Scalars['String']
}

export type MeasurementInvocationLabelsOfHeadCommitsByMeasurementInvocationIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<LabelsOfHeadCommitCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<LabelsOfHeadCommitsOrderBy>>
  }

export type MeasurementInvocationMeasurementInvocationDifferencesByInvocationIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationDifferenceCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
  }

export type MeasurementInvocationMeasurementInvocationDifferencesByPreviousInvocationIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationDifferenceCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
  }

export type MeasurementInvocationMeasurementScreenshotInvocationsByMeasurementInvocationIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementScreenshotInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

export type MeasurementInvocationAndLabels = {
  labelDelta: LabelDelta
  measurementInvocationId: Scalars['Int']
}

/**
 * A condition to be used against `MeasurementInvocation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementInvocationCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `curveId` field. */
  curveId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `durationSeconds` field. */
  durationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `resultUnits` field. */
  resultUnits?: InputMaybe<ResultValueType>
  /** Checks for equality with the object’s `resultValue` field. */
  resultValue?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
}

export type MeasurementInvocationDifference = Node & {
  __typename?: 'MeasurementInvocationDifference'
  error: Maybe<Array<Maybe<Scalars['String']>>>
  invocationId: Scalars['Int']
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByInvocationId: MeasurementInvocation
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByPreviousInvocationId: MeasurementInvocation
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  pathDifferenceRatio: Maybe<Scalars['Float']>
  previousInvocationId: Scalars['Int']
  stateMachineStateId: Scalars['String']
  valueDifferenceRatio: Maybe<Scalars['Float']>
}

/**
 * A condition to be used against `MeasurementInvocationDifference` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementInvocationDifferenceCondition = {
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `invocationId` field. */
  invocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `pathDifferenceRatio` field. */
  pathDifferenceRatio?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `previousInvocationId` field. */
  previousInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `valueDifferenceRatio` field. */
  valueDifferenceRatio?: InputMaybe<Scalars['Float']>
}

/** An input for mutations affecting `MeasurementInvocationDifference` */
export type MeasurementInvocationDifferenceInput = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  invocationId: Scalars['Int']
  pathDifferenceRatio?: InputMaybe<Scalars['Float']>
  previousInvocationId: Scalars['Int']
  stateMachineStateId?: InputMaybe<Scalars['String']>
  valueDifferenceRatio?: InputMaybe<Scalars['Float']>
}

/** Represents an update to a `MeasurementInvocationDifference`. Fields that are set will be updated. */
export type MeasurementInvocationDifferencePatch = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  invocationId?: InputMaybe<Scalars['Int']>
  pathDifferenceRatio?: InputMaybe<Scalars['Float']>
  previousInvocationId?: InputMaybe<Scalars['Int']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  valueDifferenceRatio?: InputMaybe<Scalars['Float']>
}

/** A connection to a list of `MeasurementInvocationDifference` values. */
export type MeasurementInvocationDifferencesConnection = {
  __typename?: 'MeasurementInvocationDifferencesConnection'
  /** A list of edges which contains the `MeasurementInvocationDifference` and cursor to aid in pagination. */
  edges: Array<MeasurementInvocationDifferencesEdge>
  /** A list of `MeasurementInvocationDifference` objects. */
  nodes: Array<MeasurementInvocationDifference>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementInvocationDifference` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementInvocationDifference` edge in the connection. */
export type MeasurementInvocationDifferencesEdge = {
  __typename?: 'MeasurementInvocationDifferencesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementInvocationDifference` at the end of the edge. */
  node: MeasurementInvocationDifference
}

/** Methods to use when ordering `MeasurementInvocationDifference`. */
export enum MeasurementInvocationDifferencesOrderBy {
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  InvocationIdAsc = 'INVOCATION_ID_ASC',
  InvocationIdDesc = 'INVOCATION_ID_DESC',
  Natural = 'NATURAL',
  PathDifferenceRatioAsc = 'PATH_DIFFERENCE_RATIO_ASC',
  PathDifferenceRatioDesc = 'PATH_DIFFERENCE_RATIO_DESC',
  PreviousInvocationIdAsc = 'PREVIOUS_INVOCATION_ID_ASC',
  PreviousInvocationIdDesc = 'PREVIOUS_INVOCATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  ValueDifferenceRatioAsc = 'VALUE_DIFFERENCE_RATIO_ASC',
  ValueDifferenceRatioDesc = 'VALUE_DIFFERENCE_RATIO_DESC',
}

/** An input for mutations affecting `MeasurementInvocation` */
export type MeasurementInvocationInput = {
  commitId: Scalars['Int']
  curveId?: InputMaybe<Scalars['Int']>
  durationSeconds?: InputMaybe<Scalars['Float']>
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  geometryId: Scalars['Int']
  id?: InputMaybe<Scalars['Int']>
  measurementName: Scalars['String']
  resultUnits?: InputMaybe<ResultValueType>
  resultValue?: InputMaybe<Scalars['Float']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
}

export type MeasurementInvocationIteration = {
  __typename?: 'MeasurementInvocationIteration'
  /** Reads a single `Commit` that is related to this `MeasurementInvocationIteration`. */
  commitByMeasurementInvocationCommitId: Commit
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  /** Reads a single `MeasurementStudy` that is related to this `MeasurementInvocationIteration`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `MeasurementInvocationIteration` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementInvocationIterationCondition = {
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `MeasurementInvocationIteration` values. */
export type MeasurementInvocationIterationsConnection = {
  __typename?: 'MeasurementInvocationIterationsConnection'
  /** A list of edges which contains the `MeasurementInvocationIteration` and cursor to aid in pagination. */
  edges: Array<MeasurementInvocationIterationsEdge>
  /** A list of `MeasurementInvocationIteration` objects. */
  nodes: Array<MeasurementInvocationIteration>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementInvocationIteration` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementInvocationIteration` edge in the connection. */
export type MeasurementInvocationIterationsEdge = {
  __typename?: 'MeasurementInvocationIterationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementInvocationIteration` at the end of the edge. */
  node: MeasurementInvocationIteration
}

/** Methods to use when ordering `MeasurementInvocationIteration`. */
export enum MeasurementInvocationIterationsOrderBy {
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

/** Represents an update to a `MeasurementInvocation`. Fields that are set will be updated. */
export type MeasurementInvocationPatch = {
  commitId?: InputMaybe<Scalars['Int']>
  curveId?: InputMaybe<Scalars['Int']>
  durationSeconds?: InputMaybe<Scalars['Float']>
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  geometryId?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  measurementName?: InputMaybe<Scalars['String']>
  resultUnits?: InputMaybe<ResultValueType>
  resultValue?: InputMaybe<Scalars['Float']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
}

export type MeasurementInvocationReviewState = {
  __typename?: 'MeasurementInvocationReviewState'
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  reviewState: Maybe<Scalars['String']>
}

/**
 * A condition to be used against `MeasurementInvocationReviewState` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementInvocationReviewStateCondition = {
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `reviewState` field. */
  reviewState?: InputMaybe<Scalars['String']>
}

export type MeasurementInvocationReviewStateCount = {
  __typename?: 'MeasurementInvocationReviewStateCount'
  errorCount: Maybe<Scalars['Int']>
  failureCount: Maybe<Scalars['Int']>
  gender: Maybe<Scalars['String']>
  measurementInvocationCommitId: Maybe<Scalars['Int']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `MeasurementInvocationReviewStateCount`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
  needReviewCount: Maybe<Scalars['Int']>
  newCount: Maybe<Scalars['Int']>
  startedCount: Maybe<Scalars['Int']>
  successCount: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `MeasurementInvocationReviewStateCount` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementInvocationReviewStateCountCondition = {
  /** Checks for equality with the object’s `errorCount` field. */
  errorCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `failureCount` field. */
  failureCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementInvocationCommitId` field. */
  measurementInvocationCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `needReviewCount` field. */
  needReviewCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `newCount` field. */
  newCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `startedCount` field. */
  startedCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `successCount` field. */
  successCount?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `MeasurementInvocationReviewStateCount` values. */
export type MeasurementInvocationReviewStateCountsConnection = {
  __typename?: 'MeasurementInvocationReviewStateCountsConnection'
  /** A list of edges which contains the `MeasurementInvocationReviewStateCount` and cursor to aid in pagination. */
  edges: Array<MeasurementInvocationReviewStateCountsEdge>
  /** A list of `MeasurementInvocationReviewStateCount` objects. */
  nodes: Array<MeasurementInvocationReviewStateCount>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementInvocationReviewStateCount` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementInvocationReviewStateCount` edge in the connection. */
export type MeasurementInvocationReviewStateCountsEdge = {
  __typename?: 'MeasurementInvocationReviewStateCountsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementInvocationReviewStateCount` at the end of the edge. */
  node: MeasurementInvocationReviewStateCount
}

/** Methods to use when ordering `MeasurementInvocationReviewStateCount`. */
export enum MeasurementInvocationReviewStateCountsOrderBy {
  ErrorCountAsc = 'ERROR_COUNT_ASC',
  ErrorCountDesc = 'ERROR_COUNT_DESC',
  FailureCountAsc = 'FAILURE_COUNT_ASC',
  FailureCountDesc = 'FAILURE_COUNT_DESC',
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  NeedReviewCountAsc = 'NEED_REVIEW_COUNT_ASC',
  NeedReviewCountDesc = 'NEED_REVIEW_COUNT_DESC',
  NewCountAsc = 'NEW_COUNT_ASC',
  NewCountDesc = 'NEW_COUNT_DESC',
  StartedCountAsc = 'STARTED_COUNT_ASC',
  StartedCountDesc = 'STARTED_COUNT_DESC',
  SuccessCountAsc = 'SUCCESS_COUNT_ASC',
  SuccessCountDesc = 'SUCCESS_COUNT_DESC',
}

/** A connection to a list of `MeasurementInvocationReviewState` values. */
export type MeasurementInvocationReviewStatesConnection = {
  __typename?: 'MeasurementInvocationReviewStatesConnection'
  /** A list of edges which contains the `MeasurementInvocationReviewState` and cursor to aid in pagination. */
  edges: Array<MeasurementInvocationReviewStatesEdge>
  /** A list of `MeasurementInvocationReviewState` objects. */
  nodes: Array<MeasurementInvocationReviewState>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementInvocationReviewState` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementInvocationReviewState` edge in the connection. */
export type MeasurementInvocationReviewStatesEdge = {
  __typename?: 'MeasurementInvocationReviewStatesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementInvocationReviewState` at the end of the edge. */
  node: MeasurementInvocationReviewState
}

/** Methods to use when ordering `MeasurementInvocationReviewState`. */
export enum MeasurementInvocationReviewStatesOrderBy {
  MeasurementInvocationCommitIdAsc = 'MEASUREMENT_INVOCATION_COMMIT_ID_ASC',
  MeasurementInvocationCommitIdDesc = 'MEASUREMENT_INVOCATION_COMMIT_ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  ReviewStateAsc = 'REVIEW_STATE_ASC',
  ReviewStateDesc = 'REVIEW_STATE_DESC',
}

/** A connection to a list of `MeasurementInvocation` values. */
export type MeasurementInvocationsConnection = {
  __typename?: 'MeasurementInvocationsConnection'
  /** A list of edges which contains the `MeasurementInvocation` and cursor to aid in pagination. */
  edges: Array<MeasurementInvocationsEdge>
  /** A list of `MeasurementInvocation` objects. */
  nodes: Array<MeasurementInvocation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementInvocation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementInvocation` edge in the connection. */
export type MeasurementInvocationsEdge = {
  __typename?: 'MeasurementInvocationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementInvocation` at the end of the edge. */
  node: MeasurementInvocation
}

/** Methods to use when ordering `MeasurementInvocation`. */
export enum MeasurementInvocationsOrderBy {
  CommitIdAsc = 'COMMIT_ID_ASC',
  CommitIdDesc = 'COMMIT_ID_DESC',
  CurveIdAsc = 'CURVE_ID_ASC',
  CurveIdDesc = 'CURVE_ID_DESC',
  DurationSecondsAsc = 'DURATION_SECONDS_ASC',
  DurationSecondsDesc = 'DURATION_SECONDS_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ResultUnitsAsc = 'RESULT_UNITS_ASC',
  ResultUnitsDesc = 'RESULT_UNITS_DESC',
  ResultValueAsc = 'RESULT_VALUE_ASC',
  ResultValueDesc = 'RESULT_VALUE_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
}

export type MeasurementPerformance = {
  __typename?: 'MeasurementPerformance'
  maxDurationSeconds: Maybe<Scalars['Float']>
  measurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  medianDurationSeconds: Maybe<Scalars['Float']>
  ninetiethPctDurationSeconds: Maybe<Scalars['Float']>
  numInvocations: Maybe<Scalars['BigInt']>
}

/**
 * A condition to be used against `MeasurementPerformance` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementPerformanceCondition = {
  /** Checks for equality with the object’s `maxDurationSeconds` field. */
  maxDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `measurementInvocationIteration` field. */
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `medianDurationSeconds` field. */
  medianDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `ninetiethPctDurationSeconds` field. */
  ninetiethPctDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `numInvocations` field. */
  numInvocations?: InputMaybe<Scalars['BigInt']>
}

/** A connection to a list of `MeasurementPerformance` values. */
export type MeasurementPerformancesConnection = {
  __typename?: 'MeasurementPerformancesConnection'
  /** A list of edges which contains the `MeasurementPerformance` and cursor to aid in pagination. */
  edges: Array<MeasurementPerformancesEdge>
  /** A list of `MeasurementPerformance` objects. */
  nodes: Array<MeasurementPerformance>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementPerformance` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementPerformance` edge in the connection. */
export type MeasurementPerformancesEdge = {
  __typename?: 'MeasurementPerformancesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementPerformance` at the end of the edge. */
  node: MeasurementPerformance
}

/** Methods to use when ordering `MeasurementPerformance`. */
export enum MeasurementPerformancesOrderBy {
  MaxDurationSecondsAsc = 'MAX_DURATION_SECONDS_ASC',
  MaxDurationSecondsDesc = 'MAX_DURATION_SECONDS_DESC',
  MeasurementInvocationIterationAsc = 'MEASUREMENT_INVOCATION_ITERATION_ASC',
  MeasurementInvocationIterationDesc = 'MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  MedianDurationSecondsAsc = 'MEDIAN_DURATION_SECONDS_ASC',
  MedianDurationSecondsDesc = 'MEDIAN_DURATION_SECONDS_DESC',
  Natural = 'NATURAL',
  NinetiethPctDurationSecondsAsc = 'NINETIETH_PCT_DURATION_SECONDS_ASC',
  NinetiethPctDurationSecondsDesc = 'NINETIETH_PCT_DURATION_SECONDS_DESC',
  NumInvocationsAsc = 'NUM_INVOCATIONS_ASC',
  NumInvocationsDesc = 'NUM_INVOCATIONS_DESC',
}

export type MeasurementScreenshotInvocation = Node & {
  __typename?: 'MeasurementScreenshotInvocation'
  error: Maybe<Array<Maybe<Scalars['String']>>>
  id: Scalars['Int']
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementScreenshotInvocation`. */
  measurementInvocationByMeasurementInvocationId: MeasurementInvocation
  measurementInvocationId: Maybe<Scalars['Int']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  s3Key: Maybe<Scalars['String']>
  signedURL: Maybe<Scalars['String']>
  stateMachineStateId: Scalars['String']
  /** Reads a single `TranslatedView` that is related to this `MeasurementScreenshotInvocation`. */
  translatedViewByTranslatedViewId: TranslatedView
  translatedViewId: Maybe<Scalars['Int']>
  viewIndex: Scalars['Int']
}

/**
 * A condition to be used against `MeasurementScreenshotInvocation` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementScreenshotInvocationCondition = {
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementInvocationId` field. */
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `translatedViewId` field. */
  translatedViewId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `viewIndex` field. */
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `MeasurementScreenshotInvocation` */
export type MeasurementScreenshotInvocationInput = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  s3Key?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  translatedViewId?: InputMaybe<Scalars['Int']>
  viewIndex: Scalars['Int']
}

/** Represents an update to a `MeasurementScreenshotInvocation`. Fields that are set will be updated. */
export type MeasurementScreenshotInvocationPatch = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  measurementInvocationId?: InputMaybe<Scalars['Int']>
  s3Key?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  translatedViewId?: InputMaybe<Scalars['Int']>
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `MeasurementScreenshotInvocation` values. */
export type MeasurementScreenshotInvocationsConnection = {
  __typename?: 'MeasurementScreenshotInvocationsConnection'
  /** A list of edges which contains the `MeasurementScreenshotInvocation` and cursor to aid in pagination. */
  edges: Array<MeasurementScreenshotInvocationsEdge>
  /** A list of `MeasurementScreenshotInvocation` objects. */
  nodes: Array<MeasurementScreenshotInvocation>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementScreenshotInvocation` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementScreenshotInvocation` edge in the connection. */
export type MeasurementScreenshotInvocationsEdge = {
  __typename?: 'MeasurementScreenshotInvocationsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementScreenshotInvocation` at the end of the edge. */
  node: MeasurementScreenshotInvocation
}

/** Methods to use when ordering `MeasurementScreenshotInvocation`. */
export enum MeasurementScreenshotInvocationsOrderBy {
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeasurementInvocationIdAsc = 'MEASUREMENT_INVOCATION_ID_ASC',
  MeasurementInvocationIdDesc = 'MEASUREMENT_INVOCATION_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  TranslatedViewIdAsc = 'TRANSLATED_VIEW_ID_ASC',
  TranslatedViewIdDesc = 'TRANSLATED_VIEW_ID_DESC',
  ViewIndexAsc = 'VIEW_INDEX_ASC',
  ViewIndexDesc = 'VIEW_INDEX_DESC',
}

/** A connection to a list of `MeasurementStudy` values. */
export type MeasurementStudiesConnection = {
  __typename?: 'MeasurementStudiesConnection'
  /** A list of edges which contains the `MeasurementStudy` and cursor to aid in pagination. */
  edges: Array<MeasurementStudiesEdge>
  /** A list of `MeasurementStudy` objects. */
  nodes: Array<MeasurementStudy>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementStudy` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementStudy` edge in the connection. */
export type MeasurementStudiesEdge = {
  __typename?: 'MeasurementStudiesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementStudy` at the end of the edge. */
  node: MeasurementStudy
}

/** Methods to use when ordering `MeasurementStudy`. */
export enum MeasurementStudiesOrderBy {
  HeadCommitIdAsc = 'HEAD_COMMIT_ID_ASC',
  HeadCommitIdDesc = 'HEAD_COMMIT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type MeasurementStudy = Node & {
  __typename?: 'MeasurementStudy'
  /** Reads a single `Commit` that is related to this `MeasurementStudy`. */
  commitByHeadCommitId: Commit
  /** Reads and enables pagination through a set of `FlattenedCommit`. */
  flattenedCommitsByMeasurementStudyId: FlattenedCommitsConnection
  headCommitId: Maybe<Scalars['Int']>
  /** Reads and enables pagination through a set of `HeadCommit`. */
  headCommitsByMeasurementStudyId: HeadCommitsConnection
  id: Scalars['Int']
  isArchived: Scalars['Boolean']
  /** Reads and enables pagination through a set of `IterationPerformance`. */
  iterationPerformancesByMeasurementStudyId: IterationPerformancesConnection
  /** Reads and enables pagination through a set of `LabelsOfHeadCommit`. */
  labelsOfHeadCommitsByMeasurementStudyId: LabelsOfHeadCommitsConnection
  /** Reads and enables pagination through a set of `LatestIterationPerformance`. */
  latestIterationPerformancesByMeasurementStudyId: LatestIterationPerformancesConnection
  /** Reads and enables pagination through a set of `LatestIterationsByMeasurement`. */
  latestIterationsByMeasurementsByMeasurementStudyId: LatestIterationsByMeasurementsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationIteration`. */
  measurementInvocationIterationsByMeasurementStudyId: MeasurementInvocationIterationsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationReviewStateCount`. */
  measurementInvocationReviewStateCountsByMeasurementStudyId: MeasurementInvocationReviewStateCountsConnection
  /** Reads and enables pagination through a set of `MeasurementStudyLatestLabelCount`. */
  measurementStudyLatestLabelCountsByMeasurementStudyId: MeasurementStudyLatestLabelCountsConnection
  /** Reads and enables pagination through a set of `MeasurementStudyMeasurement`. */
  measurementStudyMeasurementsByMeasurementStudyId: MeasurementStudyMeasurementsConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type MeasurementStudyFlattenedCommitsByMeasurementStudyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedCommitsOrderBy>>
}

export type MeasurementStudyHeadCommitsByMeasurementStudyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<HeadCommitsOrderBy>>
}

export type MeasurementStudyIterationPerformancesByMeasurementStudyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<IterationPerformanceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<IterationPerformancesOrderBy>>
}

export type MeasurementStudyLabelsOfHeadCommitsByMeasurementStudyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelsOfHeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOfHeadCommitsOrderBy>>
}

export type MeasurementStudyLatestIterationPerformancesByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<LatestIterationPerformanceCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<LatestIterationPerformancesOrderBy>>
  }

export type MeasurementStudyLatestIterationsByMeasurementsByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<LatestIterationsByMeasurementCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<LatestIterationsByMeasurementsOrderBy>>
  }

export type MeasurementStudyMeasurementInvocationIterationsByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationIterationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationIterationsOrderBy>>
  }

export type MeasurementStudyMeasurementInvocationReviewStateCountsByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationReviewStateCountCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationReviewStateCountsOrderBy>>
  }

export type MeasurementStudyMeasurementStudyLatestLabelCountsByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementStudyLatestLabelCountCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementStudyLatestLabelCountsOrderBy>>
  }

export type MeasurementStudyMeasurementStudyMeasurementsByMeasurementStudyIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementStudyMeasurementCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementStudyMeasurementsOrderBy>>
  }

/**
 * A condition to be used against `MeasurementStudy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MeasurementStudyCondition = {
  /** Checks for equality with the object’s `headCommitId` field. */
  headCommitId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

export type MeasurementStudyLatestLabelCount = {
  __typename?: 'MeasurementStudyLatestLabelCount'
  gender: Maybe<Scalars['String']>
  isFailure: Maybe<Scalars['Boolean']>
  labelCount: Maybe<Scalars['Int']>
  labelName: Maybe<Scalars['String']>
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `MeasurementStudyLatestLabelCount`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `MeasurementStudyLatestLabelCount` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementStudyLatestLabelCountCondition = {
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `isFailure` field. */
  isFailure?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `labelCount` field. */
  labelCount?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `labelName` field. */
  labelName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `MeasurementStudyLatestLabelCount` values. */
export type MeasurementStudyLatestLabelCountsConnection = {
  __typename?: 'MeasurementStudyLatestLabelCountsConnection'
  /** A list of edges which contains the `MeasurementStudyLatestLabelCount` and cursor to aid in pagination. */
  edges: Array<MeasurementStudyLatestLabelCountsEdge>
  /** A list of `MeasurementStudyLatestLabelCount` objects. */
  nodes: Array<MeasurementStudyLatestLabelCount>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementStudyLatestLabelCount` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementStudyLatestLabelCount` edge in the connection. */
export type MeasurementStudyLatestLabelCountsEdge = {
  __typename?: 'MeasurementStudyLatestLabelCountsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementStudyLatestLabelCount` at the end of the edge. */
  node: MeasurementStudyLatestLabelCount
}

/** Methods to use when ordering `MeasurementStudyLatestLabelCount`. */
export enum MeasurementStudyLatestLabelCountsOrderBy {
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  IsFailureAsc = 'IS_FAILURE_ASC',
  IsFailureDesc = 'IS_FAILURE_DESC',
  LabelCountAsc = 'LABEL_COUNT_ASC',
  LabelCountDesc = 'LABEL_COUNT_DESC',
  LabelNameAsc = 'LABEL_NAME_ASC',
  LabelNameDesc = 'LABEL_NAME_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

export type MeasurementStudyMeasurement = {
  __typename?: 'MeasurementStudyMeasurement'
  measurementName: Maybe<Scalars['String']>
  /** Reads a single `MeasurementStudy` that is related to this `MeasurementStudyMeasurement`. */
  measurementStudyByMeasurementStudyId: MeasurementStudy
  measurementStudyId: Maybe<Scalars['Int']>
}

/**
 * A condition to be used against `MeasurementStudyMeasurement` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MeasurementStudyMeasurementCondition = {
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `MeasurementStudyMeasurement` values. */
export type MeasurementStudyMeasurementsConnection = {
  __typename?: 'MeasurementStudyMeasurementsConnection'
  /** A list of edges which contains the `MeasurementStudyMeasurement` and cursor to aid in pagination. */
  edges: Array<MeasurementStudyMeasurementsEdge>
  /** A list of `MeasurementStudyMeasurement` objects. */
  nodes: Array<MeasurementStudyMeasurement>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurementStudyMeasurement` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurementStudyMeasurement` edge in the connection. */
export type MeasurementStudyMeasurementsEdge = {
  __typename?: 'MeasurementStudyMeasurementsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurementStudyMeasurement` at the end of the edge. */
  node: MeasurementStudyMeasurement
}

/** Methods to use when ordering `MeasurementStudyMeasurement`. */
export enum MeasurementStudyMeasurementsOrderBy {
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
}

/** Represents an update to a `MeasurementStudy`. Fields that are set will be updated. */
export type MeasurementStudyPatch = {
  id?: InputMaybe<Scalars['Int']>
  isArchived?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type Measurer = Node & {
  __typename?: 'Measurer'
  functionName: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** Reads and enables pagination through a set of `MeasurerVersion`. */
  measurerVersionsByMeasurerId: MeasurerVersionsConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  repoSlug: Maybe<Scalars['String']>
}

export type MeasurerMeasurerVersionsByMeasurerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurerVersionCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
}

/**
 * A condition to be used against `Measurer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MeasurerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `Measurer` */
export type MeasurerInput = {
  id?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

/** Represents an update to a `Measurer`. Fields that are set will be updated. */
export type MeasurerPatch = {
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `Measurer` values. */
export type MeasurersConnection = {
  __typename?: 'MeasurersConnection'
  /** A list of edges which contains the `Measurer` and cursor to aid in pagination. */
  edges: Array<MeasurersEdge>
  /** A list of `Measurer` objects. */
  nodes: Array<Measurer>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Measurer` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Measurer` edge in the connection. */
export type MeasurersEdge = {
  __typename?: 'MeasurersEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Measurer` at the end of the edge. */
  node: Measurer
}

/** Methods to use when ordering `Measurer`. */
export enum MeasurersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type MeasurerVersion = Node & {
  __typename?: 'MeasurerVersion'
  commitDate: Maybe<Scalars['Datetime']>
  commitMessage: Maybe<Scalars['String']>
  /** Reads and enables pagination through a set of `Commit`. */
  commitsByMeasurerVersionId: CommitsConnection
  error: Maybe<Array<Maybe<Scalars['String']>>>
  id: Scalars['Int']
  /** Reads a single `Measurer` that is related to this `MeasurerVersion`. */
  measurerByMeasurerId: Measurer
  measurerId: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurerVersion`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  sha1: Maybe<Scalars['String']>
  stateMachineStateId: Scalars['String']
  tagName: Maybe<Scalars['String']>
}

export type MeasurerVersionCommitsByMeasurerVersionIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

/**
 * A condition to be used against `MeasurerVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MeasurerVersionCondition = {
  /** Checks for equality with the object’s `commitDate` field. */
  commitDate?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `commitMessage` field. */
  commitMessage?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurerId` field. */
  measurerId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `sha1` field. */
  sha1?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `tagName` field. */
  tagName?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `MeasurerVersion` */
export type MeasurerVersionInput = {
  commitDate?: InputMaybe<Scalars['Datetime']>
  commitMessage?: InputMaybe<Scalars['String']>
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  measurerId: Scalars['Int']
  sha1?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  tagName?: InputMaybe<Scalars['String']>
}

/** Represents an update to a `MeasurerVersion`. Fields that are set will be updated. */
export type MeasurerVersionPatch = {
  commitDate?: InputMaybe<Scalars['Datetime']>
  commitMessage?: InputMaybe<Scalars['String']>
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  measurerId?: InputMaybe<Scalars['Int']>
  sha1?: InputMaybe<Scalars['String']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  tagName?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `MeasurerVersion` values. */
export type MeasurerVersionsConnection = {
  __typename?: 'MeasurerVersionsConnection'
  /** A list of edges which contains the `MeasurerVersion` and cursor to aid in pagination. */
  edges: Array<MeasurerVersionsEdge>
  /** A list of `MeasurerVersion` objects. */
  nodes: Array<MeasurerVersion>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `MeasurerVersion` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `MeasurerVersion` edge in the connection. */
export type MeasurerVersionsEdge = {
  __typename?: 'MeasurerVersionsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `MeasurerVersion` at the end of the edge. */
  node: MeasurerVersion
}

/** Methods to use when ordering `MeasurerVersion`. */
export enum MeasurerVersionsOrderBy {
  CommitDateAsc = 'COMMIT_DATE_ASC',
  CommitDateDesc = 'COMMIT_DATE_DESC',
  CommitMessageAsc = 'COMMIT_MESSAGE_ASC',
  CommitMessageDesc = 'COMMIT_MESSAGE_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeasurerIdAsc = 'MEASURER_ID_ASC',
  MeasurerIdDesc = 'MEASURER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  Sha1Asc = 'SHA1_ASC',
  Sha1Desc = 'SHA1_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  TagNameAsc = 'TAG_NAME_ASC',
  TagNameDesc = 'TAG_NAME_DESC',
}

/** All input for the create mn`GeometryAnnotation` mutation. */
export type MnCreateGeometryAnnotationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotation` to be created by this mutation. */
  mnGeometryAnnotation?: InputMaybe<Array<GeometryAnnotationInput>>
}

/** The output of our many create `GeometryAnnotation` mutation. */
export type MnCreateGeometryAnnotationPayload = {
  __typename?: 'mnCreateGeometryAnnotationPayload'
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometryAnnotation` that was created by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our many create `GeometryAnnotation` mutation. */
export type MnCreateGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the create mn`GeometryAnnotationType` mutation. */
export type MnCreateGeometryAnnotationTypeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotationType` to be created by this mutation. */
  mnGeometryAnnotationType?: InputMaybe<Array<GeometryAnnotationTypeInput>>
}

/** The output of our many create `GeometryAnnotationType` mutation. */
export type MnCreateGeometryAnnotationTypePayload = {
  __typename?: 'mnCreateGeometryAnnotationTypePayload'
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  /** The `GeometryAnnotationType` that was created by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our many create `GeometryAnnotationType` mutation. */
export type MnCreateGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** All input for the delete `mnDeleteGeometryAnnotationById` mutation. */
export type MnDeleteGeometryAnnotationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotation` to be deleted. You must provide the PK values! */
  mnGeometryAnnotationPatch?: InputMaybe<Array<GeometryAnnotationPatch>>
}

/** The output of our delete mn `GeometryAnnotation` mutation. */
export type MnDeleteGeometryAnnotationPayload = {
  __typename?: 'mnDeleteGeometryAnnotationPayload'
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  deletedGeometryAnnotationId: Maybe<Scalars['ID']>
  /** The `GeometryAnnotation` that was deleted by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete mn `GeometryAnnotation` mutation. */
export type MnDeleteGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the delete `mnDeleteGeometryAnnotationTypeById` mutation. */
export type MnDeleteGeometryAnnotationTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotationType` to be deleted. You must provide the PK values! */
  mnGeometryAnnotationTypePatch?: InputMaybe<Array<GeometryAnnotationTypePatch>>
}

/** The output of our delete mn `GeometryAnnotationType` mutation. */
export type MnDeleteGeometryAnnotationTypePayload = {
  __typename?: 'mnDeleteGeometryAnnotationTypePayload'
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  deletedGeometryAnnotationTypeId: Maybe<Scalars['ID']>
  /** The `GeometryAnnotationType` that was deleted by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our delete mn `GeometryAnnotationType` mutation. */
export type MnDeleteGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** All input for the update `mnUpdateGeometryAnnotationById` mutation. */
export type MnUpdateGeometryAnnotationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotation` to be updated. */
  mnGeometryAnnotationPatch?: InputMaybe<Array<GeometryAnnotationPatch>>
}

/** The output of our update mn `GeometryAnnotation` mutation. */
export type MnUpdateGeometryAnnotationPayload = {
  __typename?: 'mnUpdateGeometryAnnotationPayload'
  /** The exact same `clientMutationId` that was provided in the mutation input,                 unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometryAnnotation` that was updated by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update mn `GeometryAnnotation` mutation. */
export type MnUpdateGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the update `mnUpdateGeometryAnnotationTypeById` mutation. */
export type MnUpdateGeometryAnnotationTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The one or many `GeometryAnnotationType` to be updated. */
  mnGeometryAnnotationTypePatch?: InputMaybe<Array<GeometryAnnotationTypePatch>>
}

/** The output of our update mn `GeometryAnnotationType` mutation. */
export type MnUpdateGeometryAnnotationTypePayload = {
  __typename?: 'mnUpdateGeometryAnnotationTypePayload'
  /** The exact same `clientMutationId` that was provided in the mutation input,                 unchanged and unused. May be used by a client to track mutations. */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  /** The `GeometryAnnotationType` that was updated by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update mn `GeometryAnnotationType` mutation. */
export type MnUpdateGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation'
  addRemoveGeometriesToMeasurementStudy: Maybe<AddRemoveGeometriesToMeasurementStudyResponse>
  /** Creates a single `CheckedUpload`. */
  createCheckedUpload: Maybe<CreateCheckedUploadPayload>
  createCheckout: Maybe<CreateCheckoutResponse>
  /** Creates a single `CheckoutAccess`. */
  createCheckoutAccess: Maybe<CreateCheckoutAccessPayload>
  /** Creates a single `CheckoutsGeometriesLookup`. */
  createCheckoutsGeometriesLookup: Maybe<CreateCheckoutsGeometriesLookupPayload>
  /** Creates a single `Commit`. */
  createCommit: Maybe<CreateCommitPayload>
  /** Creates a single `CommitsGeometriesLookup`. */
  createCommitsGeometriesLookup: Maybe<CreateCommitsGeometriesLookupPayload>
  /** Creates a single `Curve`. */
  createCurve: Maybe<CreateCurvePayload>
  /** Creates a single `Dataset`. */
  createDataset: Maybe<CreateDatasetPayload>
  /** Creates a single `Geometry`. */
  createGeometry: Maybe<CreateGeometryPayload>
  /** Creates a single `GeometryAnnotation`. */
  createGeometryAnnotation: Maybe<CreateGeometryAnnotationPayload>
  /** Creates a single `GeometryAnnotationType`. */
  createGeometryAnnotationType: Maybe<CreateGeometryAnnotationTypePayload>
  /** Creates a single `GeometryScreenshotInvocation`. */
  createGeometryScreenshotInvocation: Maybe<CreateGeometryScreenshotInvocationPayload>
  /** Creates a single `GeometrySeries`. */
  createGeometrySeries: Maybe<CreateGeometrySeriesPayload>
  /** Creates a single `Label`. */
  createLabel: Maybe<CreateLabelPayload>
  /** Creates a single `MeasurementInvocation`. */
  createMeasurementInvocation: Maybe<CreateMeasurementInvocationPayload>
  /** Creates a single `MeasurementInvocationDifference`. */
  createMeasurementInvocationDifference: Maybe<CreateMeasurementInvocationDifferencePayload>
  /** Creates a single `MeasurementScreenshotInvocation`. */
  createMeasurementScreenshotInvocation: Maybe<CreateMeasurementScreenshotInvocationPayload>
  createMeasurementStudy: Maybe<CreateMeasurementStudyResponse>
  createMeasurementStudyUsingTag: Maybe<CreateMeasurementStudyResponse>
  /** Creates a single `Measurer`. */
  createMeasurer: Maybe<CreateMeasurerPayload>
  /** Creates a single `MeasurerVersion`. */
  createMeasurerVersion: Maybe<CreateMeasurerVersionPayload>
  /** Creates a single `PoseType`. */
  createPoseType: Maybe<CreatePoseTypePayload>
  /** Creates a single `PresetView`. */
  createPresetView: Maybe<CreatePresetViewPayload>
  /** Creates a single `PromiseStateMachineStatesEnum`. */
  createPromiseStateMachineStatesEnum: Maybe<CreatePromiseStateMachineStatesEnumPayload>
  /** Creates a single `ReferenceGeometryForView`. */
  createReferenceGeometryForView: Maybe<CreateReferenceGeometryForViewPayload>
  /** Creates a single `Subject`. */
  createSubject: Maybe<CreateSubjectPayload>
  /** Creates a single `TranslatedView`. */
  createTranslatedView: Maybe<CreateTranslatedViewPayload>
  /** Creates a single `View`. */
  createView: Maybe<CreateViewPayload>
  /** Deletes a single `CheckedUpload` using its globally unique id. */
  deleteCheckedUpload: Maybe<DeleteCheckedUploadPayload>
  /** Deletes a single `CheckedUpload` using a unique key. */
  deleteCheckedUploadByETag: Maybe<DeleteCheckedUploadPayload>
  /** Deletes a single `CheckoutAccess` using its globally unique id. */
  deleteCheckoutAccess: Maybe<DeleteCheckoutAccessPayload>
  /** Deletes a single `CheckoutAccess` using a unique key. */
  deleteCheckoutAccessById: DeleteCheckoutAccessPayload
  /** Deletes a single `CheckoutsGeometriesLookup` using its globally unique id. */
  deleteCheckoutsGeometriesLookup: Maybe<DeleteCheckoutsGeometriesLookupPayload>
  /** Deletes a single `CheckoutsGeometriesLookup` using a unique key. */
  deleteCheckoutsGeometriesLookupByCheckoutIdAndGeometryId: DeleteCheckoutsGeometriesLookupPayload
  /** Deletes a single `Commit` using its globally unique id. */
  deleteCommit: Maybe<DeleteCommitPayload>
  /** Deletes a single `Commit` using a unique key. */
  deleteCommitById: DeleteCommitPayload
  /** Deletes a single `CommitsGeometriesLookup` using its globally unique id. */
  deleteCommitsGeometriesLookup: Maybe<DeleteCommitsGeometriesLookupPayload>
  /** Deletes a single `CommitsGeometriesLookup` using a unique key. */
  deleteCommitsGeometriesLookupByGeometryIdAndCommitId: DeleteCommitsGeometriesLookupPayload
  /** Deletes a single `Curve` using its globally unique id. */
  deleteCurve: Maybe<DeleteCurvePayload>
  /** Deletes a single `Curve` using a unique key. */
  deleteCurveById: DeleteCurvePayload
  /** Deletes a single `Dataset` using its globally unique id. */
  deleteDataset: Maybe<DeleteDatasetPayload>
  /** Deletes a single `Dataset` using a unique key. */
  deleteDatasetById: DeleteDatasetPayload
  /** Deletes a single `Geometry` using its globally unique id. */
  deleteGeometry: Maybe<DeleteGeometryPayload>
  /** Deletes a single `GeometryAnnotation` using its globally unique id. */
  deleteGeometryAnnotation: Maybe<DeleteGeometryAnnotationPayload>
  /** Deletes a single `GeometryAnnotation` using a unique key. */
  deleteGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeId: DeleteGeometryAnnotationPayload
  /** Deletes a single `GeometryAnnotation` using a unique key. */
  deleteGeometryAnnotationById: DeleteGeometryAnnotationPayload
  /** Deletes a single `GeometryAnnotationType` using its globally unique id. */
  deleteGeometryAnnotationType: Maybe<DeleteGeometryAnnotationTypePayload>
  /** Deletes a single `GeometryAnnotationType` using a unique key. */
  deleteGeometryAnnotationTypeById: DeleteGeometryAnnotationTypePayload
  /** Deletes a single `GeometryAnnotationType` using a unique key. */
  deleteGeometryAnnotationTypeByNameAndDatasetId: DeleteGeometryAnnotationTypePayload
  /** Deletes a single `Geometry` using a unique key. */
  deleteGeometryByGeometrySeriesIdAndVersion: Maybe<DeleteGeometryPayload>
  /** Deletes a single `Geometry` using a unique key. */
  deleteGeometryById: DeleteGeometryPayload
  /** Deletes a single `GeometryScreenshotInvocation` using its globally unique id. */
  deleteGeometryScreenshotInvocation: Maybe<DeleteGeometryScreenshotInvocationPayload>
  /** Deletes a single `GeometryScreenshotInvocation` using a unique key. */
  deleteGeometryScreenshotInvocationByGeometryIdAndViewIndex: Maybe<DeleteGeometryScreenshotInvocationPayload>
  /** Deletes a single `GeometrySeries` using its globally unique id. */
  deleteGeometrySeries: Maybe<DeleteGeometrySeriesPayload>
  /** Deletes a single `GeometrySeries` using a unique key. */
  deleteGeometrySeriesById: DeleteGeometrySeriesPayload
  /** Deletes a single `Label` using its globally unique id. */
  deleteLabel: Maybe<DeleteLabelPayload>
  /** Deletes a single `Label` using a unique key. */
  deleteLabelById: DeleteLabelPayload
  /** Deletes a single `MeasurementInvocation` using its globally unique id. */
  deleteMeasurementInvocation: Maybe<DeleteMeasurementInvocationPayload>
  /** Deletes a single `MeasurementInvocation` using a unique key. */
  deleteMeasurementInvocationById: DeleteMeasurementInvocationPayload
  /** Deletes a single `MeasurementInvocationDifference` using its globally unique id. */
  deleteMeasurementInvocationDifference: Maybe<DeleteMeasurementInvocationDifferencePayload>
  /** Deletes a single `MeasurementInvocationDifference` using a unique key. */
  deleteMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationId: DeleteMeasurementInvocationDifferencePayload
  /** Deletes a single `MeasurementScreenshotInvocation` using its globally unique id. */
  deleteMeasurementScreenshotInvocation: Maybe<DeleteMeasurementScreenshotInvocationPayload>
  /** Deletes a single `MeasurementScreenshotInvocation` using a unique key. */
  deleteMeasurementScreenshotInvocationById: DeleteMeasurementScreenshotInvocationPayload
  /** Deletes a single `MeasurementScreenshotInvocation` using a unique key. */
  deleteMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndex: Maybe<DeleteMeasurementScreenshotInvocationPayload>
  /** Deletes a single `Measurer` using its globally unique id. */
  deleteMeasurer: Maybe<DeleteMeasurerPayload>
  /** Deletes a single `Measurer` using a unique key. */
  deleteMeasurerById: DeleteMeasurerPayload
  /** Deletes a single `Measurer` using a unique key. */
  deleteMeasurerByName: Maybe<DeleteMeasurerPayload>
  /** Deletes a single `MeasurerVersion` using its globally unique id. */
  deleteMeasurerVersion: Maybe<DeleteMeasurerVersionPayload>
  /** Deletes a single `MeasurerVersion` using a unique key. */
  deleteMeasurerVersionById: DeleteMeasurerVersionPayload
  /** Deletes a single `MeasurerVersion` using a unique key. */
  deleteMeasurerVersionByMeasurerIdAndTagName: Maybe<DeleteMeasurerVersionPayload>
  /** Deletes a single `PoseType` using its globally unique id. */
  deletePoseType: Maybe<DeletePoseTypePayload>
  /** Deletes a single `PoseType` using a unique key. */
  deletePoseTypeById: DeletePoseTypePayload
  /** Deletes a single `PresetView` using its globally unique id. */
  deletePresetView: Maybe<DeletePresetViewPayload>
  /** Deletes a single `PresetView` using a unique key. */
  deletePresetViewByMeasurementNameAndViewIndex: Maybe<DeletePresetViewPayload>
  /** Deletes a single `PromiseStateMachineStatesEnum` using its globally unique id. */
  deletePromiseStateMachineStatesEnum: Maybe<DeletePromiseStateMachineStatesEnumPayload>
  /** Deletes a single `PromiseStateMachineStatesEnum` using a unique key. */
  deletePromiseStateMachineStatesEnumByName: Maybe<DeletePromiseStateMachineStatesEnumPayload>
  /** Deletes a single `ReferenceGeometryForView` using its globally unique id. */
  deleteReferenceGeometryForView: Maybe<DeleteReferenceGeometryForViewPayload>
  /** Deletes a single `ReferenceGeometryForView` using a unique key. */
  deleteReferenceGeometryForViewByGeometryId: DeleteReferenceGeometryForViewPayload
  /** Deletes a single `Subject` using its globally unique id. */
  deleteSubject: Maybe<DeleteSubjectPayload>
  /** Deletes a single `Subject` using a unique key. */
  deleteSubjectByDatasetIdAndName: Maybe<DeleteSubjectPayload>
  /** Deletes a single `Subject` using a unique key. */
  deleteSubjectById: DeleteSubjectPayload
  /** Deletes a single `TranslatedView` using its globally unique id. */
  deleteTranslatedView: Maybe<DeleteTranslatedViewPayload>
  /** Deletes a single `TranslatedView` using a unique key. */
  deleteTranslatedViewById: DeleteTranslatedViewPayload
  /** Deletes a single `View` using its globally unique id. */
  deleteView: Maybe<DeleteViewPayload>
  /** Deletes a single `View` using a unique key. */
  deleteViewById: DeleteViewPayload
  duplicateMeasurementStudy: Maybe<CreateMeasurementStudyResponse>
  getAllParentCommits: Maybe<GetAllParentCommitsPayload>
  getAllParentCommitsPreservingInputCommitId: Maybe<GetAllParentCommitsPreservingInputCommitIdPayload>
  getSignedUrlsForDownloaderScript: Maybe<DownloaderScriptResponse>
  importBodyGeometry: Maybe<ImportGeometryResponse>
  importHandGeometry: Maybe<ImportGeometryResponse>
  /** Creates one or many `GeometryAnnotation`. */
  mnCreateGeometryAnnotation: Maybe<MnCreateGeometryAnnotationPayload>
  /** Creates one or many `GeometryAnnotationType`. */
  mnCreateGeometryAnnotationType: Maybe<MnCreateGeometryAnnotationTypePayload>
  /** Deletes one or many `GeometryAnnotation` a unique key via a patch. */
  mnDeleteGeometryAnnotationById: MnDeleteGeometryAnnotationPayload
  /** Deletes one or many `GeometryAnnotationType` a unique key via a patch. */
  mnDeleteGeometryAnnotationTypeById: MnDeleteGeometryAnnotationTypePayload
  /** Updates one or many `GeometryAnnotation` using a unique key and a patch. */
  mnUpdateGeometryAnnotationById: MnUpdateGeometryAnnotationPayload
  /** Updates one or many `GeometryAnnotationType` using a unique key and a patch. */
  mnUpdateGeometryAnnotationTypeById: MnUpdateGeometryAnnotationTypePayload
  performCommit: Maybe<CommitResponse>
  regenerateViews: Maybe<RegenerateViewsResponse>
  remeasureUsingCommit: Maybe<RemeasureResponse>
  remeasureUsingTag: Maybe<RemeasureResponse>
  rerunFailedOrStuckMeasurementInvocations: Maybe<RerunFailedOrStuckMeasurementInvocationsPayload>
  /** Updates a single `CheckedUpload` using its globally unique id and a patch. */
  updateCheckedUpload: Maybe<UpdateCheckedUploadPayload>
  /** Updates a single `CheckedUpload` using a unique key and a patch. */
  updateCheckedUploadByETag: Maybe<UpdateCheckedUploadPayload>
  /** Updates a single `Checkout` using its globally unique id and a patch. */
  updateCheckout: Maybe<UpdateCheckoutPayload>
  /** Updates a single `CheckoutAccess` using its globally unique id and a patch. */
  updateCheckoutAccess: Maybe<UpdateCheckoutAccessPayload>
  /** Updates a single `CheckoutAccess` using a unique key and a patch. */
  updateCheckoutAccessById: UpdateCheckoutAccessPayload
  /** Updates a single `Checkout` using a unique key and a patch. */
  updateCheckoutById: UpdateCheckoutPayload
  /** Updates a single `CheckoutsGeometriesLookup` using its globally unique id and a patch. */
  updateCheckoutsGeometriesLookup: Maybe<UpdateCheckoutsGeometriesLookupPayload>
  /** Updates a single `CheckoutsGeometriesLookup` using a unique key and a patch. */
  updateCheckoutsGeometriesLookupByCheckoutIdAndGeometryId: UpdateCheckoutsGeometriesLookupPayload
  /** Updates a single `Commit` using its globally unique id and a patch. */
  updateCommit: Maybe<UpdateCommitPayload>
  /** Updates a single `Commit` using a unique key and a patch. */
  updateCommitById: UpdateCommitPayload
  /** Updates a single `CommitsGeometriesLookup` using its globally unique id and a patch. */
  updateCommitsGeometriesLookup: Maybe<UpdateCommitsGeometriesLookupPayload>
  /** Updates a single `CommitsGeometriesLookup` using a unique key and a patch. */
  updateCommitsGeometriesLookupByGeometryIdAndCommitId: UpdateCommitsGeometriesLookupPayload
  /** Updates a single `Curve` using its globally unique id and a patch. */
  updateCurve: Maybe<UpdateCurvePayload>
  /** Updates a single `Curve` using a unique key and a patch. */
  updateCurveById: UpdateCurvePayload
  /** Updates a single `Dataset` using its globally unique id and a patch. */
  updateDataset: Maybe<UpdateDatasetPayload>
  /** Updates a single `Dataset` using a unique key and a patch. */
  updateDatasetById: UpdateDatasetPayload
  /** Updates a single `Geometry` using its globally unique id and a patch. */
  updateGeometry: Maybe<UpdateGeometryPayload>
  /** Updates a single `GeometryAnnotation` using its globally unique id and a patch. */
  updateGeometryAnnotation: Maybe<UpdateGeometryAnnotationPayload>
  /** Updates a single `GeometryAnnotation` using a unique key and a patch. */
  updateGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeId: UpdateGeometryAnnotationPayload
  /** Updates a single `GeometryAnnotation` using a unique key and a patch. */
  updateGeometryAnnotationById: UpdateGeometryAnnotationPayload
  /** Updates a single `GeometryAnnotationType` using its globally unique id and a patch. */
  updateGeometryAnnotationType: Maybe<UpdateGeometryAnnotationTypePayload>
  /** Updates a single `GeometryAnnotationType` using a unique key and a patch. */
  updateGeometryAnnotationTypeById: UpdateGeometryAnnotationTypePayload
  /** Updates a single `GeometryAnnotationType` using a unique key and a patch. */
  updateGeometryAnnotationTypeByNameAndDatasetId: UpdateGeometryAnnotationTypePayload
  /** Updates a single `Geometry` using a unique key and a patch. */
  updateGeometryByGeometrySeriesIdAndVersion: Maybe<UpdateGeometryPayload>
  /** Updates a single `Geometry` using a unique key and a patch. */
  updateGeometryById: UpdateGeometryPayload
  /** Updates a single `GeometryScreenshotInvocation` using its globally unique id and a patch. */
  updateGeometryScreenshotInvocation: Maybe<UpdateGeometryScreenshotInvocationPayload>
  /** Updates a single `GeometryScreenshotInvocation` using a unique key and a patch. */
  updateGeometryScreenshotInvocationByGeometryIdAndViewIndex: Maybe<UpdateGeometryScreenshotInvocationPayload>
  /** Updates a single `GeometrySeries` using its globally unique id and a patch. */
  updateGeometrySeries: Maybe<UpdateGeometrySeriesPayload>
  /** Updates a single `GeometrySeries` using a unique key and a patch. */
  updateGeometrySeriesById: UpdateGeometrySeriesPayload
  /** Updates a single `Label` using its globally unique id and a patch. */
  updateLabel: Maybe<UpdateLabelPayload>
  /** Updates a single `Label` using a unique key and a patch. */
  updateLabelById: UpdateLabelPayload
  /** Updates a single `MeasurementInvocation` using its globally unique id and a patch. */
  updateMeasurementInvocation: Maybe<UpdateMeasurementInvocationPayload>
  /** Updates a single `MeasurementInvocation` using a unique key and a patch. */
  updateMeasurementInvocationById: UpdateMeasurementInvocationPayload
  /** Updates a single `MeasurementInvocationDifference` using its globally unique id and a patch. */
  updateMeasurementInvocationDifference: Maybe<UpdateMeasurementInvocationDifferencePayload>
  /** Updates a single `MeasurementInvocationDifference` using a unique key and a patch. */
  updateMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationId: UpdateMeasurementInvocationDifferencePayload
  /** Updates a single `MeasurementScreenshotInvocation` using its globally unique id and a patch. */
  updateMeasurementScreenshotInvocation: Maybe<UpdateMeasurementScreenshotInvocationPayload>
  /** Updates a single `MeasurementScreenshotInvocation` using a unique key and a patch. */
  updateMeasurementScreenshotInvocationById: UpdateMeasurementScreenshotInvocationPayload
  /** Updates a single `MeasurementScreenshotInvocation` using a unique key and a patch. */
  updateMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndex: Maybe<UpdateMeasurementScreenshotInvocationPayload>
  /** Updates a single `MeasurementStudy` using its globally unique id and a patch. */
  updateMeasurementStudy: Maybe<UpdateMeasurementStudyPayload>
  /** Updates a single `MeasurementStudy` using a unique key and a patch. */
  updateMeasurementStudyById: UpdateMeasurementStudyPayload
  /** Updates a single `MeasurementStudy` using a unique key and a patch. */
  updateMeasurementStudyByName: Maybe<UpdateMeasurementStudyPayload>
  updateMeasurementViews: Maybe<Scalars['Boolean']>
  /** Updates a single `Measurer` using its globally unique id and a patch. */
  updateMeasurer: Maybe<UpdateMeasurerPayload>
  /** Updates a single `Measurer` using a unique key and a patch. */
  updateMeasurerById: UpdateMeasurerPayload
  /** Updates a single `Measurer` using a unique key and a patch. */
  updateMeasurerByName: Maybe<UpdateMeasurerPayload>
  /** Updates a single `MeasurerVersion` using its globally unique id and a patch. */
  updateMeasurerVersion: Maybe<UpdateMeasurerVersionPayload>
  /** Updates a single `MeasurerVersion` using a unique key and a patch. */
  updateMeasurerVersionById: UpdateMeasurerVersionPayload
  /** Updates a single `MeasurerVersion` using a unique key and a patch. */
  updateMeasurerVersionByMeasurerIdAndTagName: Maybe<UpdateMeasurerVersionPayload>
  /** Updates a single `PoseType` using its globally unique id and a patch. */
  updatePoseType: Maybe<UpdatePoseTypePayload>
  /** Updates a single `PoseType` using a unique key and a patch. */
  updatePoseTypeById: UpdatePoseTypePayload
  /** Updates a single `PresetView` using its globally unique id and a patch. */
  updatePresetView: Maybe<UpdatePresetViewPayload>
  /** Updates a single `PresetView` using a unique key and a patch. */
  updatePresetViewByMeasurementNameAndViewIndex: Maybe<UpdatePresetViewPayload>
  /** Updates a single `PromiseStateMachineStatesEnum` using its globally unique id and a patch. */
  updatePromiseStateMachineStatesEnum: Maybe<UpdatePromiseStateMachineStatesEnumPayload>
  /** Updates a single `PromiseStateMachineStatesEnum` using a unique key and a patch. */
  updatePromiseStateMachineStatesEnumByName: Maybe<UpdatePromiseStateMachineStatesEnumPayload>
  /** Updates a single `ReferenceGeometryForView` using its globally unique id and a patch. */
  updateReferenceGeometryForView: Maybe<UpdateReferenceGeometryForViewPayload>
  /** Updates a single `ReferenceGeometryForView` using a unique key and a patch. */
  updateReferenceGeometryForViewByGeometryId: UpdateReferenceGeometryForViewPayload
  /** Updates a single `Subject` using its globally unique id and a patch. */
  updateSubject: Maybe<UpdateSubjectPayload>
  /** Updates a single `Subject` using a unique key and a patch. */
  updateSubjectByDatasetIdAndName: Maybe<UpdateSubjectPayload>
  /** Updates a single `Subject` using a unique key and a patch. */
  updateSubjectById: UpdateSubjectPayload
  /** Updates a single `TranslatedView` using its globally unique id and a patch. */
  updateTranslatedView: Maybe<UpdateTranslatedViewPayload>
  /** Updates a single `TranslatedView` using a unique key and a patch. */
  updateTranslatedViewById: UpdateTranslatedViewPayload
  /** Updates a single `View` using its globally unique id and a patch. */
  updateView: Maybe<UpdateViewPayload>
  /** Updates a single `View` using a unique key and a patch. */
  updateViewById: UpdateViewPayload
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddRemoveGeometriesToMeasurementStudyArgs = {
  input: AddRemoveGeometriesToMeasurementStudyInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCheckedUploadArgs = {
  input: CreateCheckedUploadInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCheckoutArgs = {
  input: NewCheckoutInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCheckoutAccessArgs = {
  input: CreateCheckoutAccessInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCheckoutsGeometriesLookupArgs = {
  input: CreateCheckoutsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommitArgs = {
  input: CreateCommitInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommitsGeometriesLookupArgs = {
  input: CreateCommitsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurveArgs = {
  input: CreateCurveInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetArgs = {
  input: CreateDatasetInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeometryArgs = {
  input: CreateGeometryInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeometryAnnotationArgs = {
  input: CreateGeometryAnnotationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeometryAnnotationTypeArgs = {
  input: CreateGeometryAnnotationTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeometryScreenshotInvocationArgs = {
  input: CreateGeometryScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeometrySeriesArgs = {
  input: CreateGeometrySeriesInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLabelArgs = {
  input: CreateLabelInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementInvocationArgs = {
  input: CreateMeasurementInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementInvocationDifferenceArgs = {
  input: CreateMeasurementInvocationDifferenceInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementScreenshotInvocationArgs = {
  input: CreateMeasurementScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementStudyArgs = {
  input: NewMeasurementStudyInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurementStudyUsingTagArgs = {
  input: NewMeasurementStudyTagInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurerArgs = {
  input: CreateMeasurerInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeasurerVersionArgs = {
  input: CreateMeasurerVersionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePoseTypeArgs = {
  input: CreatePoseTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePresetViewArgs = {
  input: CreatePresetViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePromiseStateMachineStatesEnumArgs = {
  input: CreatePromiseStateMachineStatesEnumInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReferenceGeometryForViewArgs = {
  input: CreateReferenceGeometryForViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSubjectArgs = {
  input: CreateSubjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTranslatedViewArgs = {
  input: CreateTranslatedViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateViewArgs = {
  input: CreateViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckedUploadArgs = {
  input: DeleteCheckedUploadInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckedUploadByETagArgs = {
  input: DeleteCheckedUploadByETagInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckoutAccessArgs = {
  input: DeleteCheckoutAccessInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckoutAccessByIdArgs = {
  input: DeleteCheckoutAccessByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckoutsGeometriesLookupArgs = {
  input: DeleteCheckoutsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdArgs =
  {
    input: DeleteCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommitArgs = {
  input: DeleteCommitInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommitByIdArgs = {
  input: DeleteCommitByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommitsGeometriesLookupArgs = {
  input: DeleteCommitsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommitsGeometriesLookupByGeometryIdAndCommitIdArgs = {
  input: DeleteCommitsGeometriesLookupByGeometryIdAndCommitIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCurveArgs = {
  input: DeleteCurveInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCurveByIdArgs = {
  input: DeleteCurveByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetArgs = {
  input: DeleteDatasetInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetByIdArgs = {
  input: DeleteDatasetByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryArgs = {
  input: DeleteGeometryInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationArgs = {
  input: DeleteGeometryAnnotationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdArgs =
  {
    input: DeleteGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationByIdArgs = {
  input: DeleteGeometryAnnotationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationTypeArgs = {
  input: DeleteGeometryAnnotationTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationTypeByIdArgs = {
  input: DeleteGeometryAnnotationTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryAnnotationTypeByNameAndDatasetIdArgs = {
  input: DeleteGeometryAnnotationTypeByNameAndDatasetIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryByGeometrySeriesIdAndVersionArgs = {
  input: DeleteGeometryByGeometrySeriesIdAndVersionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryByIdArgs = {
  input: DeleteGeometryByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryScreenshotInvocationArgs = {
  input: DeleteGeometryScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometryScreenshotInvocationByGeometryIdAndViewIndexArgs =
  {
    input: DeleteGeometryScreenshotInvocationByGeometryIdAndViewIndexInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometrySeriesArgs = {
  input: DeleteGeometrySeriesInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGeometrySeriesByIdArgs = {
  input: DeleteGeometrySeriesByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelByIdArgs = {
  input: DeleteLabelByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementInvocationArgs = {
  input: DeleteMeasurementInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementInvocationByIdArgs = {
  input: DeleteMeasurementInvocationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementInvocationDifferenceArgs = {
  input: DeleteMeasurementInvocationDifferenceInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdArgs =
  {
    input: DeleteMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementScreenshotInvocationArgs = {
  input: DeleteMeasurementScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementScreenshotInvocationByIdArgs = {
  input: DeleteMeasurementScreenshotInvocationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexArgs =
  {
    input: DeleteMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerArgs = {
  input: DeleteMeasurerInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerByIdArgs = {
  input: DeleteMeasurerByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerByNameArgs = {
  input: DeleteMeasurerByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerVersionArgs = {
  input: DeleteMeasurerVersionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerVersionByIdArgs = {
  input: DeleteMeasurerVersionByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeasurerVersionByMeasurerIdAndTagNameArgs = {
  input: DeleteMeasurerVersionByMeasurerIdAndTagNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePoseTypeArgs = {
  input: DeletePoseTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePoseTypeByIdArgs = {
  input: DeletePoseTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePresetViewArgs = {
  input: DeletePresetViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePresetViewByMeasurementNameAndViewIndexArgs = {
  input: DeletePresetViewByMeasurementNameAndViewIndexInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePromiseStateMachineStatesEnumArgs = {
  input: DeletePromiseStateMachineStatesEnumInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePromiseStateMachineStatesEnumByNameArgs = {
  input: DeletePromiseStateMachineStatesEnumByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReferenceGeometryForViewArgs = {
  input: DeleteReferenceGeometryForViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReferenceGeometryForViewByGeometryIdArgs = {
  input: DeleteReferenceGeometryForViewByGeometryIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubjectArgs = {
  input: DeleteSubjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubjectByDatasetIdAndNameArgs = {
  input: DeleteSubjectByDatasetIdAndNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubjectByIdArgs = {
  input: DeleteSubjectByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTranslatedViewArgs = {
  input: DeleteTranslatedViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTranslatedViewByIdArgs = {
  input: DeleteTranslatedViewByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteViewArgs = {
  input: DeleteViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteViewByIdArgs = {
  input: DeleteViewByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateMeasurementStudyArgs = {
  input: DuplicateMeasurementStudyInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetAllParentCommitsArgs = {
  input: GetAllParentCommitsInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetAllParentCommitsPreservingInputCommitIdArgs = {
  input: GetAllParentCommitsPreservingInputCommitIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetSignedUrlsForDownloaderScriptArgs = {
  input: DownloaderScriptInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationImportBodyGeometryArgs = {
  input: ImportBodyGeometryInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationImportHandGeometryArgs = {
  input: ImportHandGeometryInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreateGeometryAnnotationArgs = {
  input: MnCreateGeometryAnnotationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreateGeometryAnnotationTypeArgs = {
  input: MnCreateGeometryAnnotationTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnDeleteGeometryAnnotationByIdArgs = {
  input: MnDeleteGeometryAnnotationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnDeleteGeometryAnnotationTypeByIdArgs = {
  input: MnDeleteGeometryAnnotationTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnUpdateGeometryAnnotationByIdArgs = {
  input: MnUpdateGeometryAnnotationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnUpdateGeometryAnnotationTypeByIdArgs = {
  input: MnUpdateGeometryAnnotationTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPerformCommitArgs = {
  input: PerformCommitInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegenerateViewsArgs = {
  input: RegenerateViewsInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemeasureUsingCommitArgs = {
  input: RemeasureBranchInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemeasureUsingTagArgs = {
  input: RemeasureTagInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRerunFailedOrStuckMeasurementInvocationsArgs = {
  input: RerunFailedOrStuckMeasurementInvocationsInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckedUploadArgs = {
  input: UpdateCheckedUploadInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckedUploadByETagArgs = {
  input: UpdateCheckedUploadByETagInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutArgs = {
  input: UpdateCheckoutInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutAccessArgs = {
  input: UpdateCheckoutAccessInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutAccessByIdArgs = {
  input: UpdateCheckoutAccessByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutByIdArgs = {
  input: UpdateCheckoutByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutsGeometriesLookupArgs = {
  input: UpdateCheckoutsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdArgs =
  {
    input: UpdateCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommitArgs = {
  input: UpdateCommitInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommitByIdArgs = {
  input: UpdateCommitByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommitsGeometriesLookupArgs = {
  input: UpdateCommitsGeometriesLookupInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommitsGeometriesLookupByGeometryIdAndCommitIdArgs = {
  input: UpdateCommitsGeometriesLookupByGeometryIdAndCommitIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurveArgs = {
  input: UpdateCurveInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurveByIdArgs = {
  input: UpdateCurveByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetArgs = {
  input: UpdateDatasetInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetByIdArgs = {
  input: UpdateDatasetByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryArgs = {
  input: UpdateGeometryInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationArgs = {
  input: UpdateGeometryAnnotationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdArgs =
  {
    input: UpdateGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationByIdArgs = {
  input: UpdateGeometryAnnotationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationTypeArgs = {
  input: UpdateGeometryAnnotationTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationTypeByIdArgs = {
  input: UpdateGeometryAnnotationTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryAnnotationTypeByNameAndDatasetIdArgs = {
  input: UpdateGeometryAnnotationTypeByNameAndDatasetIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryByGeometrySeriesIdAndVersionArgs = {
  input: UpdateGeometryByGeometrySeriesIdAndVersionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryByIdArgs = {
  input: UpdateGeometryByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryScreenshotInvocationArgs = {
  input: UpdateGeometryScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometryScreenshotInvocationByGeometryIdAndViewIndexArgs =
  {
    input: UpdateGeometryScreenshotInvocationByGeometryIdAndViewIndexInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometrySeriesArgs = {
  input: UpdateGeometrySeriesInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGeometrySeriesByIdArgs = {
  input: UpdateGeometrySeriesByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelByIdArgs = {
  input: UpdateLabelByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementInvocationArgs = {
  input: UpdateMeasurementInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementInvocationByIdArgs = {
  input: UpdateMeasurementInvocationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementInvocationDifferenceArgs = {
  input: UpdateMeasurementInvocationDifferenceInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdArgs =
  {
    input: UpdateMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementScreenshotInvocationArgs = {
  input: UpdateMeasurementScreenshotInvocationInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementScreenshotInvocationByIdArgs = {
  input: UpdateMeasurementScreenshotInvocationByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexArgs =
  {
    input: UpdateMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexInput
  }

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementStudyArgs = {
  input: UpdateMeasurementStudyInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementStudyByIdArgs = {
  input: UpdateMeasurementStudyByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementStudyByNameArgs = {
  input: UpdateMeasurementStudyByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurementViewsArgs = {
  input: UpdateMeasurementViewsInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerArgs = {
  input: UpdateMeasurerInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerByIdArgs = {
  input: UpdateMeasurerByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerByNameArgs = {
  input: UpdateMeasurerByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerVersionArgs = {
  input: UpdateMeasurerVersionInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerVersionByIdArgs = {
  input: UpdateMeasurerVersionByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeasurerVersionByMeasurerIdAndTagNameArgs = {
  input: UpdateMeasurerVersionByMeasurerIdAndTagNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePoseTypeArgs = {
  input: UpdatePoseTypeInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePoseTypeByIdArgs = {
  input: UpdatePoseTypeByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePresetViewArgs = {
  input: UpdatePresetViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePresetViewByMeasurementNameAndViewIndexArgs = {
  input: UpdatePresetViewByMeasurementNameAndViewIndexInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePromiseStateMachineStatesEnumArgs = {
  input: UpdatePromiseStateMachineStatesEnumInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePromiseStateMachineStatesEnumByNameArgs = {
  input: UpdatePromiseStateMachineStatesEnumByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReferenceGeometryForViewArgs = {
  input: UpdateReferenceGeometryForViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReferenceGeometryForViewByGeometryIdArgs = {
  input: UpdateReferenceGeometryForViewByGeometryIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubjectArgs = {
  input: UpdateSubjectInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubjectByDatasetIdAndNameArgs = {
  input: UpdateSubjectByDatasetIdAndNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubjectByIdArgs = {
  input: UpdateSubjectByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTranslatedViewArgs = {
  input: UpdateTranslatedViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTranslatedViewByIdArgs = {
  input: UpdateTranslatedViewByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateViewArgs = {
  input: UpdateViewInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateViewByIdArgs = {
  input: UpdateViewByIdInput
}

export type NewCheckoutInput = {
  email: Scalars['String']
  geometryIds: Array<Scalars['Int']>
  purpose: Scalars['String']
}

export type NewMeasurementStudyInput = {
  geometryIds: Array<Scalars['Int']>
  initialCommitFromMeasurerVersionId: Scalars['Int']
  measurementNames: Array<Scalars['String']>
  name: Scalars['String']
}

export type NewMeasurementStudyTagInput = {
  geometryIds: Array<Scalars['Int']>
  measurementNames: Array<Scalars['String']>
  measurerId: Scalars['Int']
  name: Scalars['String']
  tagName: Scalars['String']
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['Cursor']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['Cursor']>
}

export type PerformCommitInput = {
  measurementInvocationLabelDeltas: Array<MeasurementInvocationAndLabels>
  measurementStudyId: Scalars['Int']
}

export type PoseType = Node & {
  __typename?: 'PoseType'
  bodyPart: BodyPartType
  /** Reads and enables pagination through a set of `GeometrySeries`. */
  geometrySeriesByPoseTypeId: GeometrySeriesConnection
  id: Scalars['Int']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type PoseTypeGeometrySeriesByPoseTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometrySeriesCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

/**
 * A condition to be used against `PoseType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PoseTypeCondition = {
  /** Checks for equality with the object’s `bodyPart` field. */
  bodyPart?: InputMaybe<BodyPartType>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `PoseType` */
export type PoseTypeInput = {
  bodyPart?: InputMaybe<BodyPartType>
  id: Scalars['Int']
  name: Scalars['String']
}

/** Represents an update to a `PoseType`. Fields that are set will be updated. */
export type PoseTypePatch = {
  bodyPart?: InputMaybe<BodyPartType>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `PoseType` values. */
export type PoseTypesConnection = {
  __typename?: 'PoseTypesConnection'
  /** A list of edges which contains the `PoseType` and cursor to aid in pagination. */
  edges: Array<PoseTypesEdge>
  /** A list of `PoseType` objects. */
  nodes: Array<PoseType>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `PoseType` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `PoseType` edge in the connection. */
export type PoseTypesEdge = {
  __typename?: 'PoseTypesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `PoseType` at the end of the edge. */
  node: PoseType
}

/** Methods to use when ordering `PoseType`. */
export enum PoseTypesOrderBy {
  BodyPartAsc = 'BODY_PART_ASC',
  BodyPartDesc = 'BODY_PART_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type PresetView = Node & {
  __typename?: 'PresetView'
  /** Reads a single `Curve` that is related to this `PresetView`. */
  curveByReferenceCurveId: Curve
  measurementName: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  referenceCurveId: Scalars['Int']
  referenceViewId: Scalars['Int']
  /** Reads a single `View` that is related to this `PresetView`. */
  viewByReferenceViewId: View
  viewIndex: Scalars['Int']
}

/**
 * A condition to be used against `PresetView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PresetViewCondition = {
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `referenceCurveId` field. */
  referenceCurveId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `referenceViewId` field. */
  referenceViewId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `viewIndex` field. */
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `PresetView` */
export type PresetViewInput = {
  measurementName: Scalars['String']
  referenceCurveId: Scalars['Int']
  referenceViewId: Scalars['Int']
  viewIndex: Scalars['Int']
}

/** Represents an update to a `PresetView`. Fields that are set will be updated. */
export type PresetViewPatch = {
  measurementName?: InputMaybe<Scalars['String']>
  referenceCurveId?: InputMaybe<Scalars['Int']>
  referenceViewId?: InputMaybe<Scalars['Int']>
  viewIndex?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `PresetView` values. */
export type PresetViewsConnection = {
  __typename?: 'PresetViewsConnection'
  /** A list of edges which contains the `PresetView` and cursor to aid in pagination. */
  edges: Array<PresetViewsEdge>
  /** A list of `PresetView` objects. */
  nodes: Array<PresetView>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `PresetView` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `PresetView` edge in the connection. */
export type PresetViewsEdge = {
  __typename?: 'PresetViewsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `PresetView` at the end of the edge. */
  node: PresetView
}

/** Methods to use when ordering `PresetView`. */
export enum PresetViewsOrderBy {
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferenceCurveIdAsc = 'REFERENCE_CURVE_ID_ASC',
  ReferenceCurveIdDesc = 'REFERENCE_CURVE_ID_DESC',
  ReferenceViewIdAsc = 'REFERENCE_VIEW_ID_ASC',
  ReferenceViewIdDesc = 'REFERENCE_VIEW_ID_DESC',
  ViewIndexAsc = 'VIEW_INDEX_ASC',
  ViewIndexDesc = 'VIEW_INDEX_DESC',
}

export type PromiseStateMachineStatesEnum = Node & {
  __typename?: 'PromiseStateMachineStatesEnum'
  /** Reads and enables pagination through a set of `GeometryScreenshotInvocation`. */
  geometryScreenshotInvocationsByStateMachineStateId: GeometryScreenshotInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  measurementInvocationsByStateMachineStateId: MeasurementInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurementScreenshotInvocation`. */
  measurementScreenshotInvocationsByStateMachineStateId: MeasurementScreenshotInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurerVersion`. */
  measurerVersionsByStateMachineStateId: MeasurerVersionsConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads and enables pagination through a set of `TranslatedView`. */
  translatedViewsByStateMachineStateId: TranslatedViewsConnection
}

export type PromiseStateMachineStatesEnumGeometryScreenshotInvocationsByStateMachineStateIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<GeometryScreenshotInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
  }

export type PromiseStateMachineStatesEnumMeasurementInvocationsByStateMachineStateIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
  }

export type PromiseStateMachineStatesEnumMeasurementScreenshotInvocationsByStateMachineStateIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementScreenshotInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

export type PromiseStateMachineStatesEnumMeasurerVersionsByStateMachineStateIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurerVersionCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
  }

export type PromiseStateMachineStatesEnumTranslatedViewsByStateMachineStateIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<TranslatedViewCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
  }

/**
 * A condition to be used against `PromiseStateMachineStatesEnum` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type PromiseStateMachineStatesEnumCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `PromiseStateMachineStatesEnum` */
export type PromiseStateMachineStatesEnumInput = {
  name: Scalars['String']
}

/** Represents an update to a `PromiseStateMachineStatesEnum`. Fields that are set will be updated. */
export type PromiseStateMachineStatesEnumPatch = {
  name?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `PromiseStateMachineStatesEnum` values. */
export type PromiseStateMachineStatesEnumsConnection = {
  __typename?: 'PromiseStateMachineStatesEnumsConnection'
  /** A list of edges which contains the `PromiseStateMachineStatesEnum` and cursor to aid in pagination. */
  edges: Array<PromiseStateMachineStatesEnumsEdge>
  /** A list of `PromiseStateMachineStatesEnum` objects. */
  nodes: Array<PromiseStateMachineStatesEnum>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `PromiseStateMachineStatesEnum` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `PromiseStateMachineStatesEnum` edge in the connection. */
export type PromiseStateMachineStatesEnumsEdge = {
  __typename?: 'PromiseStateMachineStatesEnumsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `PromiseStateMachineStatesEnum` at the end of the edge. */
  node: PromiseStateMachineStatesEnum
}

/** Methods to use when ordering `PromiseStateMachineStatesEnum`. */
export enum PromiseStateMachineStatesEnumsOrderBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query'
  /** Reads and enables pagination through a set of `CheckedUpload`. */
  allCheckedUploads: CheckedUploadsConnection
  /** Reads and enables pagination through a set of `CheckoutAccess`. */
  allCheckoutAccesses: CheckoutAccessesConnection
  /** Reads and enables pagination through a set of `Checkout`. */
  allCheckouts: CheckoutsConnection
  /** Reads and enables pagination through a set of `CheckoutsGeometriesLookup`. */
  allCheckoutsGeometriesLookups: CheckoutsGeometriesLookupsConnection
  /** Reads and enables pagination through a set of `Commit`. */
  allCommits: CommitsConnection
  /** Reads and enables pagination through a set of `CommitsGeometriesLookup`. */
  allCommitsGeometriesLookups: CommitsGeometriesLookupsConnection
  /** Reads and enables pagination through a set of `Curve`. */
  allCurves: CurvesConnection
  /** Reads and enables pagination through a set of `Dataset`. */
  allDatasets: DatasetsConnection
  allDeployableMeasurerVersions: DeployableMeasurerVersions
  /** Reads and enables pagination through a set of `FlattenedCommit`. */
  allFlattenedCommits: FlattenedCommitsConnection
  /** Reads and enables pagination through a set of `FlattenedIncompleteMeasurementInvocation`. */
  allFlattenedIncompleteMeasurementInvocations: FlattenedIncompleteMeasurementInvocationsConnection
  /** Reads and enables pagination through a set of `FlattenedMeasurementInvocation`. */
  allFlattenedMeasurementInvocations: FlattenedMeasurementInvocationsConnection
  /** Reads and enables pagination through a set of `Geometry`. */
  allGeometries: GeometriesConnection
  /** Reads and enables pagination through a set of `GeometryAnnotation`. */
  allGeometryAnnotations: GeometryAnnotationsConnection
  /** Reads and enables pagination through a set of `GeometryAnnotationType`. */
  allGeometryAnnotationTypes: GeometryAnnotationTypesConnection
  /** Reads and enables pagination through a set of `GeometryScreenshotInvocation`. */
  allGeometryScreenshotInvocations: GeometryScreenshotInvocationsConnection
  /** Reads and enables pagination through a set of `GeometrySeries`. */
  allGeometrySeries: GeometrySeriesConnection
  /** Reads and enables pagination through a set of `HeadCommitIdForEachMeasurementStudyXInvocationPair`. */
  allHeadCommitIdForEachMeasurementStudyXInvocationPairs: HeadCommitIdForEachMeasurementStudyXInvocationPairsConnection
  /** Reads and enables pagination through a set of `HeadCommit`. */
  allHeadCommits: HeadCommitsConnection
  /** Reads and enables pagination through a set of `IncompleteMeasurementInvocationsIteration`. */
  allIncompleteMeasurementInvocationsIterations: IncompleteMeasurementInvocationsIterationsConnection
  /** Reads and enables pagination through a set of `IncompleteMeasurementInvocationsMeasurementName`. */
  allIncompleteMeasurementInvocationsMeasurementNames: IncompleteMeasurementInvocationsMeasurementNamesConnection
  /** Reads and enables pagination through a set of `IncompleteMeasurementInvocationsSubjectName`. */
  allIncompleteMeasurementInvocationsSubjectNames: IncompleteMeasurementInvocationsSubjectNamesConnection
  /** Reads and enables pagination through a set of `IterationPerformance`. */
  allIterationPerformances: IterationPerformancesConnection
  /** Reads and enables pagination through a set of `Label`. */
  allLabels: LabelsConnection
  /** Reads and enables pagination through a set of `LabelsOfFlattenedCommit`. */
  allLabelsOfFlattenedCommits: LabelsOfFlattenedCommitsConnection
  /** Reads and enables pagination through a set of `LabelsOfHeadCommit`. */
  allLabelsOfHeadCommits: LabelsOfHeadCommitsConnection
  /** Reads and enables pagination through a set of `LatestIterationPerformance`. */
  allLatestIterationPerformances: LatestIterationPerformancesConnection
  /** Reads and enables pagination through a set of `LatestIterationsByMeasurement`. */
  allLatestIterationsByMeasurements: LatestIterationsByMeasurementsConnection
  /** Reads and enables pagination through a set of `LatestSubjectChange`. */
  allLatestSubjectChanges: LatestSubjectChangesConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationDifference`. */
  allMeasurementInvocationDifferences: MeasurementInvocationDifferencesConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationIteration`. */
  allMeasurementInvocationIterations: MeasurementInvocationIterationsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationReviewStateCount`. */
  allMeasurementInvocationReviewStateCounts: MeasurementInvocationReviewStateCountsConnection
  /** Reads and enables pagination through a set of `MeasurementInvocationReviewState`. */
  allMeasurementInvocationReviewStates: MeasurementInvocationReviewStatesConnection
  /** Reads and enables pagination through a set of `MeasurementInvocation`. */
  allMeasurementInvocations: MeasurementInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurementPerformance`. */
  allMeasurementPerformances: MeasurementPerformancesConnection
  /** Reads and enables pagination through a set of `MeasurementScreenshotInvocation`. */
  allMeasurementScreenshotInvocations: MeasurementScreenshotInvocationsConnection
  /** Reads and enables pagination through a set of `MeasurementStudy`. */
  allMeasurementStudies: MeasurementStudiesConnection
  /** Reads and enables pagination through a set of `MeasurementStudyLatestLabelCount`. */
  allMeasurementStudyLatestLabelCounts: MeasurementStudyLatestLabelCountsConnection
  /** Reads and enables pagination through a set of `MeasurementStudyMeasurement`. */
  allMeasurementStudyMeasurements: MeasurementStudyMeasurementsConnection
  /** Reads and enables pagination through a set of `Measurer`. */
  allMeasurers: MeasurersConnection
  /** Reads and enables pagination through a set of `MeasurerVersion`. */
  allMeasurerVersions: MeasurerVersionsConnection
  /** Reads and enables pagination through a set of `PoseType`. */
  allPoseTypes: PoseTypesConnection
  /** Reads and enables pagination through a set of `PresetView`. */
  allPresetViews: PresetViewsConnection
  /** Reads and enables pagination through a set of `PromiseStateMachineStatesEnum`. */
  allPromiseStateMachineStatesEnums: PromiseStateMachineStatesEnumsConnection
  /** Reads and enables pagination through a set of `ReferenceGeometryForView`. */
  allReferenceGeometryForViews: ReferenceGeometryForViewsConnection
  /** Reads and enables pagination through a set of `SubjectChange`. */
  allSubjectChanges: SubjectChangesConnection
  /** Reads and enables pagination through a set of `SubjectPerformance`. */
  allSubjectPerformances: SubjectPerformancesConnection
  /** Reads and enables pagination through a set of `Subject`. */
  allSubjects: SubjectsConnection
  /** Reads and enables pagination through a set of `TranslatedView`. */
  allTranslatedViews: TranslatedViewsConnection
  /** Reads and enables pagination through a set of `View`. */
  allViews: ViewsConnection
  /** Reads a single `CheckedUpload` using its globally unique `ID`. */
  checkedUpload: Maybe<CheckedUpload>
  checkedUploadByETag: Maybe<CheckedUpload>
  /** Reads a single `Checkout` using its globally unique `ID`. */
  checkout: Maybe<Checkout>
  /** Reads a single `CheckoutAccess` using its globally unique `ID`. */
  checkoutAccess: Maybe<CheckoutAccess>
  checkoutAccessById: CheckoutAccess
  checkoutById: Checkout
  /** Reads a single `CheckoutsGeometriesLookup` using its globally unique `ID`. */
  checkoutsGeometriesLookup: Maybe<CheckoutsGeometriesLookup>
  checkoutsGeometriesLookupByCheckoutIdAndGeometryId: CheckoutsGeometriesLookup
  /** Reads a single `Commit` using its globally unique `ID`. */
  commit: Maybe<Commit>
  commitById: Commit
  /** Reads a single `CommitsGeometriesLookup` using its globally unique `ID`. */
  commitsGeometriesLookup: Maybe<CommitsGeometriesLookup>
  commitsGeometriesLookupByGeometryIdAndCommitId: CommitsGeometriesLookup
  /** Reads a single `Curve` using its globally unique `ID`. */
  curve: Maybe<Curve>
  curveById: Curve
  /** Reads a single `Dataset` using its globally unique `ID`. */
  dataset: Maybe<Dataset>
  datasetById: Dataset
  datasetIdForGeometryId: Maybe<Scalars['Int']>
  flattenedMeasurementInvocationCountByStateMachineState: Maybe<FlattenedMeasurementInvocationCountByStateMachineStateConnection>
  /** Reads a single `Geometry` using its globally unique `ID`. */
  geometry: Maybe<Geometry>
  /** Reads a single `GeometryAnnotation` using its globally unique `ID`. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  geometryAnnotationByGeometryIdAndGeometryAnnotationTypeId: GeometryAnnotation
  geometryAnnotationById: GeometryAnnotation
  /** Reads a single `GeometryAnnotationType` using its globally unique `ID`. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  geometryAnnotationTypeById: GeometryAnnotationType
  geometryAnnotationTypeByNameAndDatasetId: GeometryAnnotationType
  geometryByGeometrySeriesIdAndVersion: Maybe<Geometry>
  geometryById: Geometry
  /** Reads a single `GeometryScreenshotInvocation` using its globally unique `ID`. */
  geometryScreenshotInvocation: Maybe<GeometryScreenshotInvocation>
  geometryScreenshotInvocationByGeometryIdAndViewIndex: Maybe<GeometryScreenshotInvocation>
  /** Reads a single `GeometrySeries` using its globally unique `ID`. */
  geometrySeries: Maybe<GeometrySeries>
  geometrySeriesById: GeometrySeries
  github: GithubResponse
  /** Reads a single `Label` using its globally unique `ID`. */
  label: Maybe<Label>
  labelById: Label
  /** Reads a single `MeasurementInvocation` using its globally unique `ID`. */
  measurementInvocation: Maybe<MeasurementInvocation>
  measurementInvocationById: MeasurementInvocation
  /** Reads a single `MeasurementInvocationDifference` using its globally unique `ID`. */
  measurementInvocationDifference: Maybe<MeasurementInvocationDifference>
  measurementInvocationDifferenceByInvocationIdAndPreviousInvocationId: MeasurementInvocationDifference
  /** Reads a single `MeasurementScreenshotInvocation` using its globally unique `ID`. */
  measurementScreenshotInvocation: Maybe<MeasurementScreenshotInvocation>
  measurementScreenshotInvocationById: MeasurementScreenshotInvocation
  measurementScreenshotInvocationByMeasurementInvocationIdAndViewIndex: Maybe<MeasurementScreenshotInvocation>
  /** Reads a single `MeasurementStudy` using its globally unique `ID`. */
  measurementStudy: Maybe<MeasurementStudy>
  measurementStudyById: MeasurementStudy
  measurementStudyByName: Maybe<MeasurementStudy>
  /** Reads a single `Measurer` using its globally unique `ID`. */
  measurer: Maybe<Measurer>
  measurerById: Measurer
  measurerByName: Maybe<Measurer>
  /** Reads a single `MeasurerVersion` using its globally unique `ID`. */
  measurerVersion: Maybe<MeasurerVersion>
  measurerVersionById: MeasurerVersion
  measurerVersionByMeasurerIdAndTagName: Maybe<MeasurerVersion>
  /** Fetches an object given its globally unique `ID`. */
  node: Maybe<Node>
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']
  /** Reads a single `PoseType` using its globally unique `ID`. */
  poseType: Maybe<PoseType>
  poseTypeById: PoseType
  /** Reads a single `PresetView` using its globally unique `ID`. */
  presetView: Maybe<PresetView>
  presetViewByMeasurementNameAndViewIndex: Maybe<PresetView>
  /** Reads a single `PromiseStateMachineStatesEnum` using its globally unique `ID`. */
  promiseStateMachineStatesEnum: Maybe<PromiseStateMachineStatesEnum>
  promiseStateMachineStatesEnumByName: Maybe<PromiseStateMachineStatesEnum>
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query
  /** Reads a single `ReferenceGeometryForView` using its globally unique `ID`. */
  referenceGeometryForView: Maybe<ReferenceGeometryForView>
  referenceGeometryForViewByGeometryId: ReferenceGeometryForView
  serverSettings: UploadBuckeMetadata
  /** Reads a single `Subject` using its globally unique `ID`. */
  subject: Maybe<Subject>
  subjectByDatasetIdAndName: Maybe<Subject>
  subjectById: Subject
  supportedMeasurementsByMeasurerSha1: Maybe<SupportedMeasurements>
  supportedMeasurementsByMeasurerTagName: Maybe<SupportedMeasurements>
  /** Reads a single `TranslatedView` using its globally unique `ID`. */
  translatedView: Maybe<TranslatedView>
  translatedViewById: TranslatedView
  uploadBucketList: Maybe<GoldilocksUploadBucketListResponse>
  /** Reads a single `View` using its globally unique `ID`. */
  view: Maybe<View>
  viewById: View
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCheckedUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckedUploadCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckedUploadsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCheckoutAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutAccessCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutAccessesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCheckoutsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCheckoutsGeometriesLookupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CheckoutsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCommitsGeometriesLookupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CommitsGeometriesLookupCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllCurvesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<CurveCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CurvesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<DatasetCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllFlattenedCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedCommitsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllFlattenedIncompleteMeasurementInvocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedIncompleteMeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedIncompleteMeasurementInvocationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllFlattenedMeasurementInvocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<FlattenedMeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FlattenedMeasurementInvocationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllGeometriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometriesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllGeometryAnnotationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryAnnotationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllGeometryAnnotationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryAnnotationTypeCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllGeometryScreenshotInvocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometryScreenshotInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllGeometrySeriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometrySeriesCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllHeadCommitIdForEachMeasurementStudyXInvocationPairsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitIdForEachMeasurementStudyXInvocationPairCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    Array<HeadCommitIdForEachMeasurementStudyXInvocationPairsOrderBy>
  >
}

/** The root query type which gives access points into the data universe. */
export type QueryAllHeadCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<HeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<HeadCommitsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllIncompleteMeasurementInvocationsIterationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<IncompleteMeasurementInvocationsIterationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<IncompleteMeasurementInvocationsIterationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllIncompleteMeasurementInvocationsMeasurementNamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<IncompleteMeasurementInvocationsMeasurementNameCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    Array<IncompleteMeasurementInvocationsMeasurementNamesOrderBy>
  >
}

/** The root query type which gives access points into the data universe. */
export type QueryAllIncompleteMeasurementInvocationsSubjectNamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<IncompleteMeasurementInvocationsSubjectNameCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    Array<IncompleteMeasurementInvocationsSubjectNamesOrderBy>
  >
}

/** The root query type which gives access points into the data universe. */
export type QueryAllIterationPerformancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<IterationPerformanceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<IterationPerformancesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLabelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLabelsOfFlattenedCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelsOfFlattenedCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOfFlattenedCommitsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLabelsOfHeadCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LabelsOfHeadCommitCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LabelsOfHeadCommitsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLatestIterationPerformancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LatestIterationPerformanceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LatestIterationPerformancesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLatestIterationsByMeasurementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LatestIterationsByMeasurementCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LatestIterationsByMeasurementsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllLatestSubjectChangesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<LatestSubjectChangeCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LatestSubjectChangesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementInvocationDifferencesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationDifferenceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementInvocationIterationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationIterationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationIterationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementInvocationReviewStateCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationReviewStateCountCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationReviewStateCountsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementInvocationReviewStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationReviewStateCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationReviewStatesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementInvocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementPerformancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementPerformanceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementPerformancesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementScreenshotInvocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementScreenshotInvocationCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementStudiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementStudyCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementStudiesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementStudyLatestLabelCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementStudyLatestLabelCountCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementStudyLatestLabelCountsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurementStudyMeasurementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurementStudyMeasurementCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurementStudyMeasurementsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurerCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurersOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllMeasurerVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<MeasurerVersionCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllPoseTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<PoseTypeCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PoseTypesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllPresetViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<PresetViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllPromiseStateMachineStatesEnumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<PromiseStateMachineStatesEnumCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PromiseStateMachineStatesEnumsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllReferenceGeometryForViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<ReferenceGeometryForViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferenceGeometryForViewsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllSubjectChangesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<SubjectChangeCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SubjectChangesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllSubjectPerformancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<SubjectPerformanceCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SubjectPerformancesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllSubjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<SubjectCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SubjectsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllTranslatedViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<TranslatedViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryAllViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<ViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ViewsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckedUploadArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckedUploadByETagArgs = {
  eTag: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutAccessArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutAccessByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutsGeometriesLookupArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdArgs = {
  checkoutId: Scalars['Int']
  geometryId: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommitArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommitByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommitsGeometriesLookupArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCommitsGeometriesLookupByGeometryIdAndCommitIdArgs = {
  commitId: Scalars['Int']
  geometryId: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryCurveArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryCurveByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryDatasetArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryDatasetByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryDatasetIdForGeometryIdArgs = {
  geometryId?: InputMaybe<Scalars['Int']>
}

/** The root query type which gives access points into the data universe. */
export type QueryFlattenedMeasurementInvocationCountByStateMachineStateArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  measurementName?: InputMaybe<Scalars['String']>
  measurementStudyId?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  subjectName?: InputMaybe<Scalars['String']>
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdArgs =
  {
    geometryAnnotationTypeId: Scalars['Int']
    geometryId: Scalars['Int']
  }

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationTypeArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationTypeByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryAnnotationTypeByNameAndDatasetIdArgs = {
  datasetId: Scalars['Int']
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryByGeometrySeriesIdAndVersionArgs = {
  geometrySeriesId: Scalars['Int']
  version: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryScreenshotInvocationArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometryScreenshotInvocationByGeometryIdAndViewIndexArgs = {
  geometryId: Scalars['Int']
  viewIndex: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometrySeriesArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryGeometrySeriesByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryLabelArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryLabelByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementInvocationArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementInvocationByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementInvocationDifferenceArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdArgs =
  {
    invocationId: Scalars['Int']
    previousInvocationId: Scalars['Int']
  }

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementScreenshotInvocationArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementScreenshotInvocationByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexArgs =
  {
    measurementInvocationId: Scalars['Int']
    viewIndex: Scalars['Int']
  }

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementStudyArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementStudyByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurementStudyByNameArgs = {
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerByNameArgs = {
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerVersionArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerVersionByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryMeasurerVersionByMeasurerIdAndTagNameArgs = {
  measurerId: Scalars['Int']
  tagName: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPoseTypeArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPoseTypeByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryPresetViewArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPresetViewByMeasurementNameAndViewIndexArgs = {
  measurementName: Scalars['String']
  viewIndex: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryPromiseStateMachineStatesEnumArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryPromiseStateMachineStatesEnumByNameArgs = {
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryReferenceGeometryForViewArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryReferenceGeometryForViewByGeometryIdArgs = {
  geometryId: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QuerySubjectArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QuerySubjectByDatasetIdAndNameArgs = {
  datasetId: Scalars['Int']
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QuerySubjectByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupportedMeasurementsByMeasurerSha1Args = {
  measurerName: DataLayerMeasurerName
  sha1: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupportedMeasurementsByMeasurerTagNameArgs = {
  measurerName: DataLayerMeasurerName
  tagName: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QueryTranslatedViewArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryTranslatedViewByIdArgs = {
  id: Scalars['Int']
}

/** The root query type which gives access points into the data universe. */
export type QueryViewArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryViewByIdArgs = {
  id: Scalars['Int']
}

export type ReferenceGeometryForView = Node & {
  __typename?: 'ReferenceGeometryForView'
  /** Reads a single `Geometry` that is related to this `ReferenceGeometryForView`. */
  geometryByGeometryId: Geometry
  geometryId: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

/**
 * A condition to be used against `ReferenceGeometryForView` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ReferenceGeometryForViewCondition = {
  /** Checks for equality with the object’s `geometryId` field. */
  geometryId?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `ReferenceGeometryForView` */
export type ReferenceGeometryForViewInput = {
  geometryId: Scalars['Int']
}

/** Represents an update to a `ReferenceGeometryForView`. Fields that are set will be updated. */
export type ReferenceGeometryForViewPatch = {
  geometryId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `ReferenceGeometryForView` values. */
export type ReferenceGeometryForViewsConnection = {
  __typename?: 'ReferenceGeometryForViewsConnection'
  /** A list of edges which contains the `ReferenceGeometryForView` and cursor to aid in pagination. */
  edges: Array<ReferenceGeometryForViewsEdge>
  /** A list of `ReferenceGeometryForView` objects. */
  nodes: Array<ReferenceGeometryForView>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `ReferenceGeometryForView` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `ReferenceGeometryForView` edge in the connection. */
export type ReferenceGeometryForViewsEdge = {
  __typename?: 'ReferenceGeometryForViewsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `ReferenceGeometryForView` at the end of the edge. */
  node: ReferenceGeometryForView
}

/** Methods to use when ordering `ReferenceGeometryForView`. */
export enum ReferenceGeometryForViewsOrderBy {
  GeometryIdAsc = 'GEOMETRY_ID_ASC',
  GeometryIdDesc = 'GEOMETRY_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type RegenerateViewsInput = {
  measurementInvocationIteration: Scalars['Int']
  measurementNames?: InputMaybe<Array<Scalars['String']>>
  measurementStudyId: Scalars['Int']
}

export type RegenerateViewsResponse = {
  __typename?: 'RegenerateViewsResponse'
  numberOfTranslatedViewsDeleted: Scalars['Int']
}

export type RemeasureBranchInput = {
  geometryIds?: InputMaybe<Array<Scalars['Int']>>
  measurementNames?: InputMaybe<Array<Scalars['String']>>
  measurementStudyId: Scalars['Int']
  measurerId: Scalars['Int']
  sha1: Scalars['String']
}

export type RemeasureResponse = {
  __typename?: 'RemeasureResponse'
  commit: Maybe<Commit>
}

export type RemeasureTagInput = {
  geometryIds?: InputMaybe<Array<Scalars['Int']>>
  measurementNames?: InputMaybe<Array<Scalars['String']>>
  measurementStudyId: Scalars['Int']
  measurerId: Scalars['Int']
  tagName: Scalars['String']
}

/** All input for the `rerunFailedOrStuckMeasurementInvocations` mutation. */
export type RerunFailedOrStuckMeasurementInvocationsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  measurementInvocationIteration: Scalars['Int']
  measurementStudyId: Scalars['Int']
}

/** The output of our `rerunFailedOrStuckMeasurementInvocations` mutation. */
export type RerunFailedOrStuckMeasurementInvocationsPayload = {
  __typename?: 'RerunFailedOrStuckMeasurementInvocationsPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  integer: Maybe<Scalars['Int']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

export enum ResultValueType {
  Cm = 'CM',
  Deg = 'DEG',
  Mm = 'MM',
}

export type ShortLabel = {
  isFailure: Scalars['Boolean']
  name?: InputMaybe<Scalars['String']>
}

export type Subject = Node & {
  __typename?: 'Subject'
  /** Reads a single `Dataset` that is related to this `Subject`. */
  datasetByDatasetId: Dataset
  datasetId: Scalars['Int']
  gender: Maybe<Scalars['String']>
  /** Reads and enables pagination through a set of `GeometrySeries`. */
  geometrySeriesBySubjectId: GeometrySeriesConnection
  id: Scalars['Int']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

export type SubjectGeometrySeriesBySubjectIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<GeometrySeriesCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

export type SubjectChange = {
  __typename?: 'SubjectChange'
  leftMeasurementInvocationIteration: Maybe<Scalars['Int']>
  leftNumInvocations: Maybe<Scalars['BigInt']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  numMissingComparisonMetrics: Maybe<Scalars['BigInt']>
  pathDifferenceRatios: Maybe<Array<Maybe<Scalars['Float']>>>
  rightMeasurementInvocationIteration: Maybe<Scalars['Int']>
  rightNumInvocations: Maybe<Scalars['BigInt']>
  valueDifferenceRatios: Maybe<Array<Maybe<Scalars['Float']>>>
}

/**
 * A condition to be used against `SubjectChange` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubjectChangeCondition = {
  /** Checks for equality with the object’s `leftMeasurementInvocationIteration` field. */
  leftMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `leftNumInvocations` field. */
  leftNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `numMissingComparisonMetrics` field. */
  numMissingComparisonMetrics?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `pathDifferenceRatios` field. */
  pathDifferenceRatios?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  /** Checks for equality with the object’s `rightMeasurementInvocationIteration` field. */
  rightMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightNumInvocations` field. */
  rightNumInvocations?: InputMaybe<Scalars['BigInt']>
  /** Checks for equality with the object’s `valueDifferenceRatios` field. */
  valueDifferenceRatios?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** A connection to a list of `SubjectChange` values. */
export type SubjectChangesConnection = {
  __typename?: 'SubjectChangesConnection'
  /** A list of edges which contains the `SubjectChange` and cursor to aid in pagination. */
  edges: Array<SubjectChangesEdge>
  /** A list of `SubjectChange` objects. */
  nodes: Array<SubjectChange>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `SubjectChange` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `SubjectChange` edge in the connection. */
export type SubjectChangesEdge = {
  __typename?: 'SubjectChangesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `SubjectChange` at the end of the edge. */
  node: SubjectChange
}

/** Methods to use when ordering `SubjectChange`. */
export enum SubjectChangesOrderBy {
  LeftMeasurementInvocationIterationAsc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  LeftMeasurementInvocationIterationDesc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  LeftNumInvocationsAsc = 'LEFT_NUM_INVOCATIONS_ASC',
  LeftNumInvocationsDesc = 'LEFT_NUM_INVOCATIONS_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  NumMissingComparisonMetricsAsc = 'NUM_MISSING_COMPARISON_METRICS_ASC',
  NumMissingComparisonMetricsDesc = 'NUM_MISSING_COMPARISON_METRICS_DESC',
  PathDifferenceRatiosAsc = 'PATH_DIFFERENCE_RATIOS_ASC',
  PathDifferenceRatiosDesc = 'PATH_DIFFERENCE_RATIOS_DESC',
  RightMeasurementInvocationIterationAsc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  RightMeasurementInvocationIterationDesc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  RightNumInvocationsAsc = 'RIGHT_NUM_INVOCATIONS_ASC',
  RightNumInvocationsDesc = 'RIGHT_NUM_INVOCATIONS_DESC',
  ValueDifferenceRatiosAsc = 'VALUE_DIFFERENCE_RATIOS_ASC',
  ValueDifferenceRatiosDesc = 'VALUE_DIFFERENCE_RATIOS_DESC',
}

/** A condition to be used against `Subject` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SubjectCondition = {
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
}

/** An input for mutations affecting `Subject` */
export type SubjectInput = {
  datasetId: Scalars['Int']
  gender?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
}

/** Represents an update to a `Subject`. Fields that are set will be updated. */
export type SubjectPatch = {
  datasetId?: InputMaybe<Scalars['Int']>
  gender?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type SubjectPerformance = {
  __typename?: 'SubjectPerformance'
  leftDurationSeconds: Maybe<Scalars['Float']>
  leftMeasurementInvocationId: Maybe<Scalars['Int']>
  leftMeasurementInvocationIteration: Maybe<Scalars['Int']>
  measurementName: Maybe<Scalars['String']>
  measurementStudyId: Maybe<Scalars['Int']>
  rightDurationSeconds: Maybe<Scalars['Float']>
  rightMeasurementInvocationId: Maybe<Scalars['Int']>
  rightMeasurementInvocationIteration: Maybe<Scalars['Int']>
  subjectId: Maybe<Scalars['Int']>
  subjectName: Maybe<Scalars['String']>
}

/**
 * A condition to be used against `SubjectPerformance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubjectPerformanceCondition = {
  /** Checks for equality with the object’s `leftDurationSeconds` field. */
  leftDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `leftMeasurementInvocationId` field. */
  leftMeasurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `leftMeasurementInvocationIteration` field. */
  leftMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `measurementName` field. */
  measurementName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `measurementStudyId` field. */
  measurementStudyId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightDurationSeconds` field. */
  rightDurationSeconds?: InputMaybe<Scalars['Float']>
  /** Checks for equality with the object’s `rightMeasurementInvocationId` field. */
  rightMeasurementInvocationId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `rightMeasurementInvocationIteration` field. */
  rightMeasurementInvocationIteration?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `subjectName` field. */
  subjectName?: InputMaybe<Scalars['String']>
}

/** A connection to a list of `SubjectPerformance` values. */
export type SubjectPerformancesConnection = {
  __typename?: 'SubjectPerformancesConnection'
  /** A list of edges which contains the `SubjectPerformance` and cursor to aid in pagination. */
  edges: Array<SubjectPerformancesEdge>
  /** A list of `SubjectPerformance` objects. */
  nodes: Array<SubjectPerformance>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `SubjectPerformance` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `SubjectPerformance` edge in the connection. */
export type SubjectPerformancesEdge = {
  __typename?: 'SubjectPerformancesEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `SubjectPerformance` at the end of the edge. */
  node: SubjectPerformance
}

/** Methods to use when ordering `SubjectPerformance`. */
export enum SubjectPerformancesOrderBy {
  LeftDurationSecondsAsc = 'LEFT_DURATION_SECONDS_ASC',
  LeftDurationSecondsDesc = 'LEFT_DURATION_SECONDS_DESC',
  LeftMeasurementInvocationIdAsc = 'LEFT_MEASUREMENT_INVOCATION_ID_ASC',
  LeftMeasurementInvocationIdDesc = 'LEFT_MEASUREMENT_INVOCATION_ID_DESC',
  LeftMeasurementInvocationIterationAsc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  LeftMeasurementInvocationIterationDesc = 'LEFT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  MeasurementNameAsc = 'MEASUREMENT_NAME_ASC',
  MeasurementNameDesc = 'MEASUREMENT_NAME_DESC',
  MeasurementStudyIdAsc = 'MEASUREMENT_STUDY_ID_ASC',
  MeasurementStudyIdDesc = 'MEASUREMENT_STUDY_ID_DESC',
  Natural = 'NATURAL',
  RightDurationSecondsAsc = 'RIGHT_DURATION_SECONDS_ASC',
  RightDurationSecondsDesc = 'RIGHT_DURATION_SECONDS_DESC',
  RightMeasurementInvocationIdAsc = 'RIGHT_MEASUREMENT_INVOCATION_ID_ASC',
  RightMeasurementInvocationIdDesc = 'RIGHT_MEASUREMENT_INVOCATION_ID_DESC',
  RightMeasurementInvocationIterationAsc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_ASC',
  RightMeasurementInvocationIterationDesc = 'RIGHT_MEASUREMENT_INVOCATION_ITERATION_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  SubjectNameAsc = 'SUBJECT_NAME_ASC',
  SubjectNameDesc = 'SUBJECT_NAME_DESC',
}

/** A connection to a list of `Subject` values. */
export type SubjectsConnection = {
  __typename?: 'SubjectsConnection'
  /** A list of edges which contains the `Subject` and cursor to aid in pagination. */
  edges: Array<SubjectsEdge>
  /** A list of `Subject` objects. */
  nodes: Array<Subject>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Subject` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Subject` edge in the connection. */
export type SubjectsEdge = {
  __typename?: 'SubjectsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `Subject` at the end of the edge. */
  node: Subject
}

/** Methods to use when ordering `Subject`. */
export enum SubjectsOrderBy {
  DatasetIdAsc = 'DATASET_ID_ASC',
  DatasetIdDesc = 'DATASET_ID_DESC',
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type SupportedMeasurement = {
  __typename?: 'SupportedMeasurement'
  measurements: Maybe<Array<SupportedMeasurementsCurves>>
}

export type SupportedMeasurements = {
  __typename?: 'SupportedMeasurements'
  supportedMeasurements: Maybe<SupportedMeasurement>
}

export type SupportedMeasurementsCurves = {
  __typename?: 'SupportedMeasurementsCurves'
  featureConstraints: FeatureConstraints
  measurementName: Scalars['String']
}

export enum TopologyType {
  MeshcapadeSm4Mid = 'MESHCAPADE_SM4_MID',
  MpiiHumanShape_1_0 = 'MPII_HUMAN_SHAPE_1_0',
}

export type TranslatedView = Node & {
  __typename?: 'TranslatedView'
  error: Maybe<Array<Maybe<Scalars['String']>>>
  id: Scalars['Int']
  /** Reads and enables pagination through a set of `MeasurementScreenshotInvocation`. */
  measurementScreenshotInvocationsByTranslatedViewId: MeasurementScreenshotInvocationsConnection
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `TranslatedView`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  stateMachineStateId: Scalars['String']
  targetViewId: Maybe<Scalars['Int']>
  /** Reads a single `View` that is related to this `TranslatedView`. */
  viewByTargetViewId: Maybe<View>
}

export type TranslatedViewMeasurementScreenshotInvocationsByTranslatedViewIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>
    before?: InputMaybe<Scalars['Cursor']>
    condition?: InputMaybe<MeasurementScreenshotInvocationCondition>
    first?: InputMaybe<Scalars['Int']>
    last?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

/**
 * A condition to be used against `TranslatedView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TranslatedViewCondition = {
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `stateMachineStateId` field. */
  stateMachineStateId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `targetViewId` field. */
  targetViewId?: InputMaybe<Scalars['Int']>
}

/** An input for mutations affecting `TranslatedView` */
export type TranslatedViewInput = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  targetViewId?: InputMaybe<Scalars['Int']>
}

/** Represents an update to a `TranslatedView`. Fields that are set will be updated. */
export type TranslatedViewPatch = {
  error?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['Int']>
  stateMachineStateId?: InputMaybe<Scalars['String']>
  targetViewId?: InputMaybe<Scalars['Int']>
}

/** A connection to a list of `TranslatedView` values. */
export type TranslatedViewsConnection = {
  __typename?: 'TranslatedViewsConnection'
  /** A list of edges which contains the `TranslatedView` and cursor to aid in pagination. */
  edges: Array<TranslatedViewsEdge>
  /** A list of `TranslatedView` objects. */
  nodes: Array<TranslatedView>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `TranslatedView` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `TranslatedView` edge in the connection. */
export type TranslatedViewsEdge = {
  __typename?: 'TranslatedViewsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `TranslatedView` at the end of the edge. */
  node: TranslatedView
}

/** Methods to use when ordering `TranslatedView`. */
export enum TranslatedViewsOrderBy {
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateMachineStateIdAsc = 'STATE_MACHINE_STATE_ID_ASC',
  StateMachineStateIdDesc = 'STATE_MACHINE_STATE_ID_DESC',
  TargetViewIdAsc = 'TARGET_VIEW_ID_ASC',
  TargetViewIdDesc = 'TARGET_VIEW_ID_DESC',
}

export enum UnitsType {
  Cm = 'CM',
  In = 'IN',
  M = 'M',
  Mm = 'MM',
}

/** All input for the `updateCheckedUploadByETag` mutation. */
export type UpdateCheckedUploadByETagInput = {
  /** An object where the defined keys will be set on the `CheckedUpload` being updated. */
  checkedUploadPatch: CheckedUploadPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  eTag: Scalars['String']
}

/** All input for the `updateCheckedUpload` mutation. */
export type UpdateCheckedUploadInput = {
  /** An object where the defined keys will be set on the `CheckedUpload` being updated. */
  checkedUploadPatch: CheckedUploadPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckedUpload` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `CheckedUpload` mutation. */
export type UpdateCheckedUploadPayload = {
  __typename?: 'UpdateCheckedUploadPayload'
  /** The `CheckedUpload` that was updated by this mutation. */
  checkedUpload: Maybe<CheckedUpload>
  /** An edge for our `CheckedUpload`. May be used by Relay 1. */
  checkedUploadEdge: Maybe<CheckedUploadsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `CheckedUpload` mutation. */
export type UpdateCheckedUploadPayloadCheckedUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckedUploadsOrderBy>>
}

/** All input for the `updateCheckoutAccessById` mutation. */
export type UpdateCheckoutAccessByIdInput = {
  /** An object where the defined keys will be set on the `CheckoutAccess` being updated. */
  checkoutAccessPatch: CheckoutAccessPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `updateCheckoutAccess` mutation. */
export type UpdateCheckoutAccessInput = {
  /** An object where the defined keys will be set on the `CheckoutAccess` being updated. */
  checkoutAccessPatch: CheckoutAccessPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckoutAccess` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `CheckoutAccess` mutation. */
export type UpdateCheckoutAccessPayload = {
  __typename?: 'UpdateCheckoutAccessPayload'
  /** The `CheckoutAccess` that was updated by this mutation. */
  checkoutAccess: Maybe<CheckoutAccess>
  /** An edge for our `CheckoutAccess`. May be used by Relay 1. */
  checkoutAccessEdge: Maybe<CheckoutAccessesEdge>
  /** Reads a single `Checkout` that is related to this `CheckoutAccess`. */
  checkoutByCheckoutId: Checkout
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `CheckoutAccess` mutation. */
export type UpdateCheckoutAccessPayloadCheckoutAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckoutAccessesOrderBy>>
}

/** All input for the `updateCheckoutById` mutation. */
export type UpdateCheckoutByIdInput = {
  /** An object where the defined keys will be set on the `Checkout` being updated. */
  checkoutPatch: CheckoutPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
}

/** All input for the `updateCheckout` mutation. */
export type UpdateCheckoutInput = {
  /** An object where the defined keys will be set on the `Checkout` being updated. */
  checkoutPatch: CheckoutPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Checkout` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Checkout` mutation. */
export type UpdateCheckoutPayload = {
  __typename?: 'UpdateCheckoutPayload'
  /** The `Checkout` that was updated by this mutation. */
  checkout: Maybe<Checkout>
  /** An edge for our `Checkout`. May be used by Relay 1. */
  checkoutEdge: Maybe<CheckoutsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Checkout` mutation. */
export type UpdateCheckoutPayloadCheckoutEdgeArgs = {
  orderBy?: InputMaybe<Array<CheckoutsOrderBy>>
}

/** All input for the `updateCheckoutsGeometriesLookupByCheckoutIdAndGeometryId` mutation. */
export type UpdateCheckoutsGeometriesLookupByCheckoutIdAndGeometryIdInput = {
  checkoutId: Scalars['Int']
  /** An object where the defined keys will be set on the `CheckoutsGeometriesLookup` being updated. */
  checkoutsGeometriesLookupPatch: CheckoutsGeometriesLookupPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
}

/** All input for the `updateCheckoutsGeometriesLookup` mutation. */
export type UpdateCheckoutsGeometriesLookupInput = {
  /** An object where the defined keys will be set on the `CheckoutsGeometriesLookup` being updated. */
  checkoutsGeometriesLookupPatch: CheckoutsGeometriesLookupPatch
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `CheckoutsGeometriesLookup` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `CheckoutsGeometriesLookup` mutation. */
export type UpdateCheckoutsGeometriesLookupPayload = {
  __typename?: 'UpdateCheckoutsGeometriesLookupPayload'
  /** Reads a single `Checkout` that is related to this `CheckoutsGeometriesLookup`. */
  checkoutByCheckoutId: Checkout
  /** The `CheckoutsGeometriesLookup` that was updated by this mutation. */
  checkoutsGeometriesLookup: Maybe<CheckoutsGeometriesLookup>
  /** An edge for our `CheckoutsGeometriesLookup`. May be used by Relay 1. */
  checkoutsGeometriesLookupEdge: Maybe<CheckoutsGeometriesLookupsEdge>
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `CheckoutsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `CheckoutsGeometriesLookup` mutation. */
export type UpdateCheckoutsGeometriesLookupPayloadCheckoutsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CheckoutsGeometriesLookupsOrderBy>>
  }

/** All input for the `updateCommitById` mutation. */
export type UpdateCommitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Commit` being updated. */
  commitPatch: CommitPatch
  id: Scalars['Int']
}

/** All input for the `updateCommit` mutation. */
export type UpdateCommitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Commit` being updated. */
  commitPatch: CommitPatch
  /** The globally unique `ID` which will identify a single `Commit` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Commit` mutation. */
export type UpdateCommitPayload = {
  __typename?: 'UpdateCommitPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Commit` that was updated by this mutation. */
  commit: Maybe<Commit>
  /** Reads a single `Commit` that is related to this `Commit`. */
  commitByParentCommitId: Commit
  /** An edge for our `Commit`. May be used by Relay 1. */
  commitEdge: Maybe<CommitsEdge>
  /** Reads a single `MeasurerVersion` that is related to this `Commit`. */
  measurerVersionByMeasurerVersionId: MeasurerVersion
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Commit` mutation. */
export type UpdateCommitPayloadCommitEdgeArgs = {
  orderBy?: InputMaybe<Array<CommitsOrderBy>>
}

/** All input for the `updateCommitsGeometriesLookupByGeometryIdAndCommitId` mutation. */
export type UpdateCommitsGeometriesLookupByGeometryIdAndCommitIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  commitId: Scalars['Int']
  /** An object where the defined keys will be set on the `CommitsGeometriesLookup` being updated. */
  commitsGeometriesLookupPatch: CommitsGeometriesLookupPatch
  geometryId: Scalars['Int']
}

/** All input for the `updateCommitsGeometriesLookup` mutation. */
export type UpdateCommitsGeometriesLookupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `CommitsGeometriesLookup` being updated. */
  commitsGeometriesLookupPatch: CommitsGeometriesLookupPatch
  /** The globally unique `ID` which will identify a single `CommitsGeometriesLookup` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `CommitsGeometriesLookup` mutation. */
export type UpdateCommitsGeometriesLookupPayload = {
  __typename?: 'UpdateCommitsGeometriesLookupPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `CommitsGeometriesLookup`. */
  commitByCommitId: Commit
  /** The `CommitsGeometriesLookup` that was updated by this mutation. */
  commitsGeometriesLookup: Maybe<CommitsGeometriesLookup>
  /** An edge for our `CommitsGeometriesLookup`. May be used by Relay 1. */
  commitsGeometriesLookupEdge: Maybe<CommitsGeometriesLookupsEdge>
  /** Reads a single `Geometry` that is related to this `CommitsGeometriesLookup`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `CommitsGeometriesLookup` mutation. */
export type UpdateCommitsGeometriesLookupPayloadCommitsGeometriesLookupEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CommitsGeometriesLookupsOrderBy>>
  }

/** All input for the `updateCurveById` mutation. */
export type UpdateCurveByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Curve` being updated. */
  curvePatch: CurvePatch
  id: Scalars['Int']
}

/** All input for the `updateCurve` mutation. */
export type UpdateCurveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Curve` being updated. */
  curvePatch: CurvePatch
  /** The globally unique `ID` which will identify a single `Curve` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Curve` mutation. */
export type UpdateCurvePayload = {
  __typename?: 'UpdateCurvePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Curve` that was updated by this mutation. */
  curve: Maybe<Curve>
  /** An edge for our `Curve`. May be used by Relay 1. */
  curveEdge: Maybe<CurvesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Curve` mutation. */
export type UpdateCurvePayloadCurveEdgeArgs = {
  orderBy?: InputMaybe<Array<CurvesOrderBy>>
}

/** All input for the `updateDatasetById` mutation. */
export type UpdateDatasetByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch
  id: Scalars['Int']
}

/** All input for the `updateDataset` mutation. */
export type UpdateDatasetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch
  /** The globally unique `ID` which will identify a single `Dataset` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Dataset` mutation. */
export type UpdateDatasetPayload = {
  __typename?: 'UpdateDatasetPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Dataset` that was updated by this mutation. */
  dataset: Maybe<Dataset>
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Dataset` mutation. */
export type UpdateDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>
}

/** All input for the `updateGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeId` mutation. */
export type UpdateGeometryAnnotationByGeometryIdAndGeometryAnnotationTypeIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    /** An object where the defined keys will be set on the `GeometryAnnotation` being updated. */
    geometryAnnotationPatch: GeometryAnnotationPatch
    geometryAnnotationTypeId: Scalars['Int']
    geometryId: Scalars['Int']
  }

/** All input for the `updateGeometryAnnotationById` mutation. */
export type UpdateGeometryAnnotationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometryAnnotation` being updated. */
  geometryAnnotationPatch: GeometryAnnotationPatch
  id: Scalars['Int']
}

/** All input for the `updateGeometryAnnotation` mutation. */
export type UpdateGeometryAnnotationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometryAnnotation` being updated. */
  geometryAnnotationPatch: GeometryAnnotationPatch
  /** The globally unique `ID` which will identify a single `GeometryAnnotation` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `GeometryAnnotation` mutation. */
export type UpdateGeometryAnnotationPayload = {
  __typename?: 'UpdateGeometryAnnotationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometryAnnotation` that was updated by this mutation. */
  geometryAnnotation: Maybe<GeometryAnnotation>
  /** An edge for our `GeometryAnnotation`. May be used by Relay 1. */
  geometryAnnotationEdge: Maybe<GeometryAnnotationsEdge>
  /** Reads a single `GeometryAnnotationType` that is related to this `GeometryAnnotation`. */
  geometryAnnotationTypeByGeometryAnnotationTypeId: GeometryAnnotationType
  /** Reads a single `Geometry` that is related to this `GeometryAnnotation`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `GeometryAnnotation` mutation. */
export type UpdateGeometryAnnotationPayloadGeometryAnnotationEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometryAnnotationsOrderBy>>
}

/** All input for the `updateGeometryAnnotationTypeById` mutation. */
export type UpdateGeometryAnnotationTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometryAnnotationType` being updated. */
  geometryAnnotationTypePatch: GeometryAnnotationTypePatch
  id: Scalars['Int']
}

/** All input for the `updateGeometryAnnotationTypeByNameAndDatasetId` mutation. */
export type UpdateGeometryAnnotationTypeByNameAndDatasetIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  datasetId: Scalars['Int']
  /** An object where the defined keys will be set on the `GeometryAnnotationType` being updated. */
  geometryAnnotationTypePatch: GeometryAnnotationTypePatch
  name: Scalars['String']
}

/** All input for the `updateGeometryAnnotationType` mutation. */
export type UpdateGeometryAnnotationTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometryAnnotationType` being updated. */
  geometryAnnotationTypePatch: GeometryAnnotationTypePatch
  /** The globally unique `ID` which will identify a single `GeometryAnnotationType` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `GeometryAnnotationType` mutation. */
export type UpdateGeometryAnnotationTypePayload = {
  __typename?: 'UpdateGeometryAnnotationTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `GeometryAnnotationType`. */
  datasetByDatasetId: Dataset
  /** The `GeometryAnnotationType` that was updated by this mutation. */
  geometryAnnotationType: Maybe<GeometryAnnotationType>
  /** An edge for our `GeometryAnnotationType`. May be used by Relay 1. */
  geometryAnnotationTypeEdge: Maybe<GeometryAnnotationTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `GeometryAnnotationType` mutation. */
export type UpdateGeometryAnnotationTypePayloadGeometryAnnotationTypeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryAnnotationTypesOrderBy>>
  }

/** All input for the `updateGeometryByGeometrySeriesIdAndVersion` mutation. */
export type UpdateGeometryByGeometrySeriesIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Geometry` being updated. */
  geometryPatch: GeometryPatch
  geometrySeriesId: Scalars['Int']
  version: Scalars['Int']
}

/** All input for the `updateGeometryById` mutation. */
export type UpdateGeometryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Geometry` being updated. */
  geometryPatch: GeometryPatch
  id: Scalars['Int']
}

/** All input for the `updateGeometry` mutation. */
export type UpdateGeometryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Geometry` being updated. */
  geometryPatch: GeometryPatch
  /** The globally unique `ID` which will identify a single `Geometry` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Geometry` mutation. */
export type UpdateGeometryPayload = {
  __typename?: 'UpdateGeometryPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Geometry` that was updated by this mutation. */
  geometry: Maybe<Geometry>
  /** An edge for our `Geometry`. May be used by Relay 1. */
  geometryEdge: Maybe<GeometriesEdge>
  /** Reads a single `GeometrySeries` that is related to this `Geometry`. */
  geometrySeriesByGeometrySeriesId: GeometrySeries
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Geometry` mutation. */
export type UpdateGeometryPayloadGeometryEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometriesOrderBy>>
}

/** All input for the `updateGeometryScreenshotInvocationByGeometryIdAndViewIndex` mutation. */
export type UpdateGeometryScreenshotInvocationByGeometryIdAndViewIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
  /** An object where the defined keys will be set on the `GeometryScreenshotInvocation` being updated. */
  geometryScreenshotInvocationPatch: GeometryScreenshotInvocationPatch
  viewIndex: Scalars['Int']
}

/** All input for the `updateGeometryScreenshotInvocation` mutation. */
export type UpdateGeometryScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometryScreenshotInvocation` being updated. */
  geometryScreenshotInvocationPatch: GeometryScreenshotInvocationPatch
  /** The globally unique `ID` which will identify a single `GeometryScreenshotInvocation` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `GeometryScreenshotInvocation` mutation. */
export type UpdateGeometryScreenshotInvocationPayload = {
  __typename?: 'UpdateGeometryScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `GeometryScreenshotInvocation`. */
  geometryByGeometryId: Geometry
  /** The `GeometryScreenshotInvocation` that was updated by this mutation. */
  geometryScreenshotInvocation: Maybe<GeometryScreenshotInvocation>
  /** An edge for our `GeometryScreenshotInvocation`. May be used by Relay 1. */
  geometryScreenshotInvocationEdge: Maybe<GeometryScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `GeometryScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `GeometryScreenshotInvocation` mutation. */
export type UpdateGeometryScreenshotInvocationPayloadGeometryScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<GeometryScreenshotInvocationsOrderBy>>
  }

/** All input for the `updateGeometrySeriesById` mutation. */
export type UpdateGeometrySeriesByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometrySeries` being updated. */
  geometrySeriesPatch: GeometrySeriesPatch
  id: Scalars['Int']
}

/** All input for the `updateGeometrySeries` mutation. */
export type UpdateGeometrySeriesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `GeometrySeries` being updated. */
  geometrySeriesPatch: GeometrySeriesPatch
  /** The globally unique `ID` which will identify a single `GeometrySeries` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `GeometrySeries` mutation. */
export type UpdateGeometrySeriesPayload = {
  __typename?: 'UpdateGeometrySeriesPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `GeometrySeries` that was updated by this mutation. */
  geometrySeries: Maybe<GeometrySeries>
  /** An edge for our `GeometrySeries`. May be used by Relay 1. */
  geometrySeriesEdge: Maybe<GeometrySeriesEdge>
  /** Reads a single `PoseType` that is related to this `GeometrySeries`. */
  poseTypeByPoseTypeId: PoseType
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `Subject` that is related to this `GeometrySeries`. */
  subjectBySubjectId: Subject
}

/** The output of our update `GeometrySeries` mutation. */
export type UpdateGeometrySeriesPayloadGeometrySeriesEdgeArgs = {
  orderBy?: InputMaybe<Array<GeometrySeriesOrderBy>>
}

/** All input for the `updateLabelById` mutation. */
export type UpdateLabelByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `Label` being updated. */
  labelPatch: LabelPatch
}

/** All input for the `updateLabel` mutation. */
export type UpdateLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Label` being updated. */
  labelPatch: LabelPatch
  /** The globally unique `ID` which will identify a single `Label` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Label` mutation. */
export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Label` that was updated by this mutation. */
  label: Maybe<Label>
  /** An edge for our `Label`. May be used by Relay 1. */
  labelEdge: Maybe<LabelsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Label` mutation. */
export type UpdateLabelPayloadLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<LabelsOrderBy>>
}

/** All input for the `updateMeasurementInvocationById` mutation. */
export type UpdateMeasurementInvocationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `MeasurementInvocation` being updated. */
  measurementInvocationPatch: MeasurementInvocationPatch
}

/** All input for the `updateMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationId` mutation. */
export type UpdateMeasurementInvocationDifferenceByInvocationIdAndPreviousInvocationIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    invocationId: Scalars['Int']
    /** An object where the defined keys will be set on the `MeasurementInvocationDifference` being updated. */
    measurementInvocationDifferencePatch: MeasurementInvocationDifferencePatch
    previousInvocationId: Scalars['Int']
  }

/** All input for the `updateMeasurementInvocationDifference` mutation. */
export type UpdateMeasurementInvocationDifferenceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurementInvocationDifference` being updated. */
  measurementInvocationDifferencePatch: MeasurementInvocationDifferencePatch
  /** The globally unique `ID` which will identify a single `MeasurementInvocationDifference` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `MeasurementInvocationDifference` mutation. */
export type UpdateMeasurementInvocationDifferencePayload = {
  __typename?: 'UpdateMeasurementInvocationDifferencePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByInvocationId: MeasurementInvocation
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementInvocationDifference`. */
  measurementInvocationByPreviousInvocationId: MeasurementInvocation
  /** The `MeasurementInvocationDifference` that was updated by this mutation. */
  measurementInvocationDifference: Maybe<MeasurementInvocationDifference>
  /** An edge for our `MeasurementInvocationDifference`. May be used by Relay 1. */
  measurementInvocationDifferenceEdge: Maybe<MeasurementInvocationDifferencesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `MeasurementInvocationDifference` mutation. */
export type UpdateMeasurementInvocationDifferencePayloadMeasurementInvocationDifferenceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementInvocationDifferencesOrderBy>>
  }

/** All input for the `updateMeasurementInvocation` mutation. */
export type UpdateMeasurementInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurementInvocation` being updated. */
  measurementInvocationPatch: MeasurementInvocationPatch
  /** The globally unique `ID` which will identify a single `MeasurementInvocation` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `MeasurementInvocation` mutation. */
export type UpdateMeasurementInvocationPayload = {
  __typename?: 'UpdateMeasurementInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `MeasurementInvocation`. */
  commitByCommitId: Commit
  /** Reads a single `CommitsGeometriesLookup` that is related to this `MeasurementInvocation`. */
  commitsGeometriesLookupByGeometryIdAndCommitId: CommitsGeometriesLookup
  /** Reads a single `Curve` that is related to this `MeasurementInvocation`. */
  curveByCurveId: Curve
  /** Reads a single `Geometry` that is related to this `MeasurementInvocation`. */
  geometryByGeometryId: Geometry
  /** The `MeasurementInvocation` that was updated by this mutation. */
  measurementInvocation: Maybe<MeasurementInvocation>
  /** An edge for our `MeasurementInvocation`. May be used by Relay 1. */
  measurementInvocationEdge: Maybe<MeasurementInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `MeasurementInvocation` mutation. */
export type UpdateMeasurementInvocationPayloadMeasurementInvocationEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurementInvocationsOrderBy>>
}

/** All input for the `updateMeasurementScreenshotInvocationById` mutation. */
export type UpdateMeasurementScreenshotInvocationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `MeasurementScreenshotInvocation` being updated. */
  measurementScreenshotInvocationPatch: MeasurementScreenshotInvocationPatch
}

/** All input for the `updateMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndex` mutation. */
export type UpdateMeasurementScreenshotInvocationByMeasurementInvocationIdAndViewIndexInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']>
    measurementInvocationId: Scalars['Int']
    /** An object where the defined keys will be set on the `MeasurementScreenshotInvocation` being updated. */
    measurementScreenshotInvocationPatch: MeasurementScreenshotInvocationPatch
    viewIndex: Scalars['Int']
  }

/** All input for the `updateMeasurementScreenshotInvocation` mutation. */
export type UpdateMeasurementScreenshotInvocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurementScreenshotInvocation` being updated. */
  measurementScreenshotInvocationPatch: MeasurementScreenshotInvocationPatch
  /** The globally unique `ID` which will identify a single `MeasurementScreenshotInvocation` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `MeasurementScreenshotInvocation` mutation. */
export type UpdateMeasurementScreenshotInvocationPayload = {
  __typename?: 'UpdateMeasurementScreenshotInvocationPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `MeasurementInvocation` that is related to this `MeasurementScreenshotInvocation`. */
  measurementInvocationByMeasurementInvocationId: MeasurementInvocation
  /** The `MeasurementScreenshotInvocation` that was updated by this mutation. */
  measurementScreenshotInvocation: Maybe<MeasurementScreenshotInvocation>
  /** An edge for our `MeasurementScreenshotInvocation`. May be used by Relay 1. */
  measurementScreenshotInvocationEdge: Maybe<MeasurementScreenshotInvocationsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurementScreenshotInvocation`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `TranslatedView` that is related to this `MeasurementScreenshotInvocation`. */
  translatedViewByTranslatedViewId: TranslatedView
}

/** The output of our update `MeasurementScreenshotInvocation` mutation. */
export type UpdateMeasurementScreenshotInvocationPayloadMeasurementScreenshotInvocationEdgeArgs =
  {
    orderBy?: InputMaybe<Array<MeasurementScreenshotInvocationsOrderBy>>
  }

/** All input for the `updateMeasurementStudyById` mutation. */
export type UpdateMeasurementStudyByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `MeasurementStudy` being updated. */
  measurementStudyPatch: MeasurementStudyPatch
}

/** All input for the `updateMeasurementStudyByName` mutation. */
export type UpdateMeasurementStudyByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurementStudy` being updated. */
  measurementStudyPatch: MeasurementStudyPatch
  name: Scalars['String']
}

/** All input for the `updateMeasurementStudy` mutation. */
export type UpdateMeasurementStudyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurementStudy` being updated. */
  measurementStudyPatch: MeasurementStudyPatch
  /** The globally unique `ID` which will identify a single `MeasurementStudy` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `MeasurementStudy` mutation. */
export type UpdateMeasurementStudyPayload = {
  __typename?: 'UpdateMeasurementStudyPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Commit` that is related to this `MeasurementStudy`. */
  commitByHeadCommitId: Commit
  /** The `MeasurementStudy` that was updated by this mutation. */
  measurementStudy: Maybe<MeasurementStudy>
  /** An edge for our `MeasurementStudy`. May be used by Relay 1. */
  measurementStudyEdge: Maybe<MeasurementStudiesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `MeasurementStudy` mutation. */
export type UpdateMeasurementStudyPayloadMeasurementStudyEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurementStudiesOrderBy>>
}

export type UpdateMeasurementViewsInput = {
  referenceMeasurementJson: Scalars['String']
  referenceViewJson: Scalars['String']
}

/** All input for the `updateMeasurerById` mutation. */
export type UpdateMeasurerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `Measurer` being updated. */
  measurerPatch: MeasurerPatch
}

/** All input for the `updateMeasurerByName` mutation. */
export type UpdateMeasurerByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Measurer` being updated. */
  measurerPatch: MeasurerPatch
  name: Scalars['String']
}

/** All input for the `updateMeasurer` mutation. */
export type UpdateMeasurerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Measurer` being updated. */
  measurerPatch: MeasurerPatch
  /** The globally unique `ID` which will identify a single `Measurer` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `Measurer` mutation. */
export type UpdateMeasurerPayload = {
  __typename?: 'UpdateMeasurerPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `Measurer` that was updated by this mutation. */
  measurer: Maybe<Measurer>
  /** An edge for our `Measurer`. May be used by Relay 1. */
  measurerEdge: Maybe<MeasurersEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `Measurer` mutation. */
export type UpdateMeasurerPayloadMeasurerEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurersOrderBy>>
}

/** All input for the `updateMeasurerVersionById` mutation. */
export type UpdateMeasurerVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `MeasurerVersion` being updated. */
  measurerVersionPatch: MeasurerVersionPatch
}

/** All input for the `updateMeasurerVersionByMeasurerIdAndTagName` mutation. */
export type UpdateMeasurerVersionByMeasurerIdAndTagNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  measurerId: Scalars['Int']
  /** An object where the defined keys will be set on the `MeasurerVersion` being updated. */
  measurerVersionPatch: MeasurerVersionPatch
  tagName: Scalars['String']
}

/** All input for the `updateMeasurerVersion` mutation. */
export type UpdateMeasurerVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `MeasurerVersion` being updated. */
  measurerVersionPatch: MeasurerVersionPatch
  /** The globally unique `ID` which will identify a single `MeasurerVersion` to be updated. */
  nodeId: Scalars['ID']
}

/** The output of our update `MeasurerVersion` mutation. */
export type UpdateMeasurerVersionPayload = {
  __typename?: 'UpdateMeasurerVersionPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Measurer` that is related to this `MeasurerVersion`. */
  measurerByMeasurerId: Measurer
  /** The `MeasurerVersion` that was updated by this mutation. */
  measurerVersion: Maybe<MeasurerVersion>
  /** An edge for our `MeasurerVersion`. May be used by Relay 1. */
  measurerVersionEdge: Maybe<MeasurerVersionsEdge>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `MeasurerVersion`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `MeasurerVersion` mutation. */
export type UpdateMeasurerVersionPayloadMeasurerVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<MeasurerVersionsOrderBy>>
}

/** All input for the `updatePoseTypeById` mutation. */
export type UpdatePoseTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `PoseType` being updated. */
  poseTypePatch: PoseTypePatch
}

/** All input for the `updatePoseType` mutation. */
export type UpdatePoseTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PoseType` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `PoseType` being updated. */
  poseTypePatch: PoseTypePatch
}

/** The output of our update `PoseType` mutation. */
export type UpdatePoseTypePayload = {
  __typename?: 'UpdatePoseTypePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `PoseType` that was updated by this mutation. */
  poseType: Maybe<PoseType>
  /** An edge for our `PoseType`. May be used by Relay 1. */
  poseTypeEdge: Maybe<PoseTypesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `PoseType` mutation. */
export type UpdatePoseTypePayloadPoseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<PoseTypesOrderBy>>
}

/** All input for the `updatePresetViewByMeasurementNameAndViewIndex` mutation. */
export type UpdatePresetViewByMeasurementNameAndViewIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  measurementName: Scalars['String']
  /** An object where the defined keys will be set on the `PresetView` being updated. */
  presetViewPatch: PresetViewPatch
  viewIndex: Scalars['Int']
}

/** All input for the `updatePresetView` mutation. */
export type UpdatePresetViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PresetView` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `PresetView` being updated. */
  presetViewPatch: PresetViewPatch
}

/** The output of our update `PresetView` mutation. */
export type UpdatePresetViewPayload = {
  __typename?: 'UpdatePresetViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Curve` that is related to this `PresetView`. */
  curveByReferenceCurveId: Curve
  /** The `PresetView` that was updated by this mutation. */
  presetView: Maybe<PresetView>
  /** An edge for our `PresetView`. May be used by Relay 1. */
  presetViewEdge: Maybe<PresetViewsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** Reads a single `View` that is related to this `PresetView`. */
  viewByReferenceViewId: View
}

/** The output of our update `PresetView` mutation. */
export type UpdatePresetViewPayloadPresetViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

/** All input for the `updatePromiseStateMachineStatesEnumByName` mutation. */
export type UpdatePromiseStateMachineStatesEnumByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  /** An object where the defined keys will be set on the `PromiseStateMachineStatesEnum` being updated. */
  promiseStateMachineStatesEnumPatch: PromiseStateMachineStatesEnumPatch
}

/** All input for the `updatePromiseStateMachineStatesEnum` mutation. */
export type UpdatePromiseStateMachineStatesEnumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `PromiseStateMachineStatesEnum` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `PromiseStateMachineStatesEnum` being updated. */
  promiseStateMachineStatesEnumPatch: PromiseStateMachineStatesEnumPatch
}

/** The output of our update `PromiseStateMachineStatesEnum` mutation. */
export type UpdatePromiseStateMachineStatesEnumPayload = {
  __typename?: 'UpdatePromiseStateMachineStatesEnumPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** The `PromiseStateMachineStatesEnum` that was updated by this mutation. */
  promiseStateMachineStatesEnum: Maybe<PromiseStateMachineStatesEnum>
  /** An edge for our `PromiseStateMachineStatesEnum`. May be used by Relay 1. */
  promiseStateMachineStatesEnumEdge: Maybe<PromiseStateMachineStatesEnumsEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
}

/** The output of our update `PromiseStateMachineStatesEnum` mutation. */
export type UpdatePromiseStateMachineStatesEnumPayloadPromiseStateMachineStatesEnumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PromiseStateMachineStatesEnumsOrderBy>>
  }

/** All input for the `updateReferenceGeometryForViewByGeometryId` mutation. */
export type UpdateReferenceGeometryForViewByGeometryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  geometryId: Scalars['Int']
  /** An object where the defined keys will be set on the `ReferenceGeometryForView` being updated. */
  referenceGeometryForViewPatch: ReferenceGeometryForViewPatch
}

/** All input for the `updateReferenceGeometryForView` mutation. */
export type UpdateReferenceGeometryForViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `ReferenceGeometryForView` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `ReferenceGeometryForView` being updated. */
  referenceGeometryForViewPatch: ReferenceGeometryForViewPatch
}

/** The output of our update `ReferenceGeometryForView` mutation. */
export type UpdateReferenceGeometryForViewPayload = {
  __typename?: 'UpdateReferenceGeometryForViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Geometry` that is related to this `ReferenceGeometryForView`. */
  geometryByGeometryId: Geometry
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `ReferenceGeometryForView` that was updated by this mutation. */
  referenceGeometryForView: Maybe<ReferenceGeometryForView>
  /** An edge for our `ReferenceGeometryForView`. May be used by Relay 1. */
  referenceGeometryForViewEdge: Maybe<ReferenceGeometryForViewsEdge>
}

/** The output of our update `ReferenceGeometryForView` mutation. */
export type UpdateReferenceGeometryForViewPayloadReferenceGeometryForViewEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ReferenceGeometryForViewsOrderBy>>
  }

/** All input for the `updateSubjectByDatasetIdAndName` mutation. */
export type UpdateSubjectByDatasetIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  datasetId: Scalars['Int']
  name: Scalars['String']
  /** An object where the defined keys will be set on the `Subject` being updated. */
  subjectPatch: SubjectPatch
}

/** All input for the `updateSubjectById` mutation. */
export type UpdateSubjectByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `Subject` being updated. */
  subjectPatch: SubjectPatch
}

/** All input for the `updateSubject` mutation. */
export type UpdateSubjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Subject` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Subject` being updated. */
  subjectPatch: SubjectPatch
}

/** The output of our update `Subject` mutation. */
export type UpdateSubjectPayload = {
  __typename?: 'UpdateSubjectPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `Dataset` that is related to this `Subject`. */
  datasetByDatasetId: Dataset
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `Subject` that was updated by this mutation. */
  subject: Maybe<Subject>
  /** An edge for our `Subject`. May be used by Relay 1. */
  subjectEdge: Maybe<SubjectsEdge>
}

/** The output of our update `Subject` mutation. */
export type UpdateSubjectPayloadSubjectEdgeArgs = {
  orderBy?: InputMaybe<Array<SubjectsOrderBy>>
}

/** All input for the `updateTranslatedViewById` mutation. */
export type UpdateTranslatedViewByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `TranslatedView` being updated. */
  translatedViewPatch: TranslatedViewPatch
}

/** All input for the `updateTranslatedView` mutation. */
export type UpdateTranslatedViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `TranslatedView` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `TranslatedView` being updated. */
  translatedViewPatch: TranslatedViewPatch
}

/** The output of our update `TranslatedView` mutation. */
export type UpdateTranslatedViewPayload = {
  __typename?: 'UpdateTranslatedViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Reads a single `PromiseStateMachineStatesEnum` that is related to this `TranslatedView`. */
  promiseStateMachineStatesEnumByStateMachineStateId: PromiseStateMachineStatesEnum
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `TranslatedView` that was updated by this mutation. */
  translatedView: Maybe<TranslatedView>
  /** An edge for our `TranslatedView`. May be used by Relay 1. */
  translatedViewEdge: Maybe<TranslatedViewsEdge>
  /** Reads a single `View` that is related to this `TranslatedView`. */
  viewByTargetViewId: View
}

/** The output of our update `TranslatedView` mutation. */
export type UpdateTranslatedViewPayloadTranslatedViewEdgeArgs = {
  orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
}

/** All input for the `updateViewById` mutation. */
export type UpdateViewByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['Int']
  /** An object where the defined keys will be set on the `View` being updated. */
  viewPatch: ViewPatch
}

/** All input for the `updateView` mutation. */
export type UpdateViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `View` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `View` being updated. */
  viewPatch: ViewPatch
}

/** The output of our update `View` mutation. */
export type UpdateViewPayload = {
  __typename?: 'UpdateViewPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>
  /** The `View` that was updated by this mutation. */
  view: Maybe<View>
  /** An edge for our `View`. May be used by Relay 1. */
  viewEdge: Maybe<ViewsEdge>
}

/** The output of our update `View` mutation. */
export type UpdateViewPayloadViewEdgeArgs = {
  orderBy?: InputMaybe<Array<ViewsOrderBy>>
}

export type UploadBuckeMetadata = {
  __typename?: 'UploadBuckeMetadata'
  awsConsoleSignInUrl: Scalars['String']
  uploadBucketName: Scalars['String']
  uploadBucketS3ConsoleUploadUrl: Scalars['String']
}

export type View = Node & {
  __typename?: 'View'
  id: Scalars['Int']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  position: Array<Scalars['Float']>
  /** Reads and enables pagination through a set of `PresetView`. */
  presetViewsByReferenceViewId: PresetViewsConnection
  target: Array<Scalars['Float']>
  /** Reads and enables pagination through a set of `TranslatedView`. */
  translatedViewsByTargetViewId: TranslatedViewsConnection
  zoom: Scalars['Float']
}

export type ViewPresetViewsByReferenceViewIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<PresetViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PresetViewsOrderBy>>
}

export type ViewTranslatedViewsByTargetViewIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<TranslatedViewCondition>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TranslatedViewsOrderBy>>
}

/** A condition to be used against `View` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `position` field. */
  position?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  /** Checks for equality with the object’s `target` field. */
  target?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  /** Checks for equality with the object’s `zoom` field. */
  zoom?: InputMaybe<Scalars['Float']>
}

/** An input for mutations affecting `View` */
export type ViewInput = {
  id?: InputMaybe<Scalars['Int']>
  position: Array<InputMaybe<Scalars['Float']>>
  target: Array<InputMaybe<Scalars['Float']>>
  zoom: Scalars['Float']
}

/** Represents an update to a `View`. Fields that are set will be updated. */
export type ViewPatch = {
  id?: InputMaybe<Scalars['Int']>
  position?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  target?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  zoom?: InputMaybe<Scalars['Float']>
}

/** A connection to a list of `View` values. */
export type ViewsConnection = {
  __typename?: 'ViewsConnection'
  /** A list of edges which contains the `View` and cursor to aid in pagination. */
  edges: Array<ViewsEdge>
  /** A list of `View` objects. */
  nodes: Array<View>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `View` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `View` edge in the connection. */
export type ViewsEdge = {
  __typename?: 'ViewsEdge'
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>
  /** The `View` at the end of the edge. */
  node: View
}

/** Methods to use when ordering `View`. */
export enum ViewsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TargetAsc = 'TARGET_ASC',
  TargetDesc = 'TARGET_DESC',
  ZoomAsc = 'ZOOM_ASC',
  ZoomDesc = 'ZOOM_DESC',
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason: Maybe<Scalars['String']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  args: Array<__InputValue>
  type: __Type
  isDeprecated: Scalars['Boolean']
  deprecationReason: Maybe<Scalars['String']>
}

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  type: __Type
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue: Maybe<Scalars['String']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type'
  kind: __TypeKind
  name: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  specifiedByUrl: Maybe<Scalars['String']>
  fields: Maybe<Array<__Field>>
  interfaces: Maybe<Array<__Type>>
  possibleTypes: Maybe<Array<__Type>>
  enumValues: Maybe<Array<__EnumValue>>
  inputFields: Maybe<Array<__InputValue>>
  ofType: Maybe<__Type>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL',
}

export type ChooseDatasetsQueryVariables = Exact<{ [key: string]: never }>

export type ChooseDatasetsQuery = {
  __typename?: 'Query'
  allDatasets: {
    __typename?: 'DatasetsConnection'
    nodes: Array<{
      __typename?: 'Dataset'
      id: number
      name: string
      bodyPart: BodyPartType
      subjectsByDatasetId: {
        __typename?: 'SubjectsConnection'
        nodes: Array<{
          __typename?: 'Subject'
          id: number
          name: string
          gender: string | null
          geometrySeriesBySubjectId: {
            __typename?: 'GeometrySeriesConnection'
            nodes: Array<{
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              poseTypeByPoseTypeId: {
                __typename?: 'PoseType'
                id: number
                name: string
              }
              geometriesByGeometrySeriesId: {
                __typename?: 'GeometriesConnection'
                nodes: Array<{ __typename?: 'Geometry'; id: number }>
              }
            }>
          }
        }>
      }
    }>
  }
}

export type CommitAnnotationsMutationVariables = Exact<{
  input: MnUpdateGeometryAnnotationByIdInput
}>

export type CommitAnnotationsMutation = {
  __typename?: 'Mutation'
  mnUpdateGeometryAnnotationById: {
    __typename?: 'mnUpdateGeometryAnnotationPayload'
    clientMutationId: string | null
    geometryAnnotation: {
      __typename?: 'GeometryAnnotation'
      geometryId: number
      id: number
      nodeId: string
      point: Array<number> | null
    } | null
  }
}

export type ViewAnnotationsDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type ViewAnnotationsDetailQuery = {
  __typename?: 'Query'
  geometryById: { __typename?: 'Geometry'; signedURL: string; s3Key: string }
}

export type ViewDatasetAnnotationsQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type ViewDatasetAnnotationsQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    name: string
    superiorDirectionCoordinates: Array<number> | null
    anteriorDirectionCoordinates: Array<number> | null
    units: UnitsType
    geometryAnnotationTypesByDatasetId: {
      __typename?: 'GeometryAnnotationTypesConnection'
      nodes: Array<{
        __typename?: 'GeometryAnnotationType'
        name: string
        id: number
      }>
    }
    subjectsByDatasetId: {
      __typename?: 'SubjectsConnection'
      nodes: Array<{
        __typename?: 'Subject'
        name: string
        geometrySeriesBySubjectId: {
          __typename?: 'GeometrySeriesConnection'
          nodes: Array<{
            __typename?: 'GeometrySeries'
            poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            geometriesByGeometrySeriesId: {
              __typename?: 'GeometriesConnection'
              nodes: Array<{
                __typename?: 'Geometry'
                id: number
                geometryAnnotationsByGeometryId: {
                  __typename?: 'GeometryAnnotationsConnection'
                  nodes: Array<{
                    __typename?: 'GeometryAnnotation'
                    point: Array<number> | null
                    geometryAnnotationTypeId: number
                    id: number
                    geometryAnnotationTypeByGeometryAnnotationTypeId: {
                      __typename?: 'GeometryAnnotationType'
                      name: string
                    }
                  }>
                }
              }>
            }
          }>
        }
      }>
    }
  }
}

export type ViewGeometriesFromDatasetQueryVariables = Exact<{
  datasetId: Scalars['Int']
}>

export type ViewGeometriesFromDatasetQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    bodyPart: BodyPartType
    id: number
    name: string
    activeTopology: TopologyType | null
    subjectsByDatasetId: {
      __typename?: 'SubjectsConnection'
      nodes: Array<{
        __typename?: 'Subject'
        gender: string | null
        name: string
        id: number
        geometrySeriesBySubjectId: {
          __typename?: 'GeometrySeriesConnection'
          nodes: Array<{
            __typename?: 'GeometrySeries'
            topology: TopologyType | null
            poseTypeId: number
            poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            geometriesByGeometrySeriesId: {
              __typename?: 'GeometriesConnection'
              nodes: Array<{
                __typename?: 'Geometry'
                version: number
                id: number
              }>
            }
          }>
        }
      }>
    }
  }
}

export type CreateCheckoutMutationVariables = Exact<{
  input: NewCheckoutInput
}>

export type CreateCheckoutMutation = {
  __typename?: 'Mutation'
  createCheckout: {
    __typename?: 'CreateCheckoutResponse'
    checkout: {
      __typename?: 'Checkout'
      id: number
      checkoutsGeometriesLookupsByCheckoutId: {
        __typename?: 'CheckoutsGeometriesLookupsConnection'
        totalCount: number
      }
    } | null
  } | null
}

export type DatasetDetailsForViewSubjectsNavigationQueryVariables = Exact<{
  datasetId: Scalars['Int']
}>

export type DatasetDetailsForViewSubjectsNavigationQuery = {
  __typename?: 'Query'
  datasetById: { __typename?: 'Dataset'; bodyPart: BodyPartType }
  allSubjects: {
    __typename?: 'SubjectsConnection'
    nodes: Array<{
      __typename?: 'Subject'
      gender: string | null
      name: string
      id: number
      geometrySeriesBySubjectId: {
        __typename?: 'GeometrySeriesConnection'
        nodes: Array<{
          __typename?: 'GeometrySeries'
          poseTypeId: number
          poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
        }>
      }
    }>
  }
}

export type ViewSubjectGeometryS3KeyQueryVariables = Exact<{
  datasetId: Scalars['Int']
  selectedSubjectName: Scalars['String']
  poseId: Scalars['Int']
}>

export type ViewSubjectGeometryS3KeyQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    units: UnitsType
    superiorDirectionCoordinates: Array<number> | null
    anteriorDirectionCoordinates: Array<number> | null
    bodyPart: BodyPartType
  }
  subjectByDatasetIdAndName: {
    __typename?: 'Subject'
    id: number
    selectedPose: {
      __typename?: 'GeometrySeriesConnection'
      nodes: Array<{
        __typename?: 'GeometrySeries'
        geometriesByGeometrySeriesId: {
          __typename?: 'GeometriesConnection'
          nodes: Array<{
            __typename?: 'Geometry'
            s3Key: string
            signedURL: string
            textureS3Key: string | null
            textureSignedURL: string | null
            geometryScreenshotInvocationsByGeometryId: {
              __typename?: 'GeometryScreenshotInvocationsConnection'
              nodes: Array<{
                __typename?: 'GeometryScreenshotInvocation'
                signedURL: string | null
                s3Key: string | null
                viewIndex: number
              }>
            }
          }>
        }
      }>
    }
  } | null
}

export type ListDatasetsWithDetailsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListDatasetsWithDetailsQuery = {
  __typename?: 'Query'
  allDatasets: {
    __typename?: 'DatasetsConnection'
    nodes: Array<{
      __typename?: 'Dataset'
      id: number
      name: string
      bodyPart: BodyPartType
      lastModified: any
      subjectsByDatasetId: {
        __typename?: 'SubjectsConnection'
        totalCount: number
        nodes: Array<{
          __typename?: 'Subject'
          geometrySeriesBySubjectId: {
            __typename?: 'GeometrySeriesConnection'
            nodes: Array<{ __typename?: 'GeometrySeries'; poseTypeId: number }>
          }
        }>
      }
    }>
  }
}

export type CreateNewAnnotationTypesMutationVariables = Exact<{
  createInput: MnCreateGeometryAnnotationTypeInput
}>

export type CreateNewAnnotationTypesMutation = {
  __typename?: 'Mutation'
  mnCreateGeometryAnnotationType: {
    __typename: 'mnCreateGeometryAnnotationTypePayload'
  } | null
}

export type DeleteAnnotationTypesMutationVariables = Exact<{
  deleteInput: MnDeleteGeometryAnnotationTypeByIdInput
}>

export type DeleteAnnotationTypesMutation = {
  __typename?: 'Mutation'
  mnDeleteGeometryAnnotationTypeById: {
    __typename: 'mnDeleteGeometryAnnotationTypePayload'
  }
}

export type DeleteAndCreateNewAnnotationTypesMutationVariables = Exact<{
  deleteInput: MnDeleteGeometryAnnotationTypeByIdInput
  createInput: MnCreateGeometryAnnotationTypeInput
}>

export type DeleteAndCreateNewAnnotationTypesMutation = {
  __typename?: 'Mutation'
  mnDeleteGeometryAnnotationTypeById: {
    __typename: 'mnDeleteGeometryAnnotationTypePayload'
  }
  mnCreateGeometryAnnotationType: {
    __typename: 'mnCreateGeometryAnnotationTypePayload'
  } | null
}

export type ExportDatasetAnnotationsQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type ExportDatasetAnnotationsQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    id: number
    name: string
    subjectsByDatasetId: {
      __typename?: 'SubjectsConnection'
      nodes: Array<{
        __typename?: 'Subject'
        name: string
        geometrySeriesBySubjectId: {
          __typename?: 'GeometrySeriesConnection'
          nodes: Array<{
            __typename?: 'GeometrySeries'
            poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            geometriesByGeometrySeriesId: {
              __typename?: 'GeometriesConnection'
              nodes: Array<{
                __typename?: 'Geometry'
                id: number
                geometryAnnotationsByGeometryId: {
                  __typename?: 'GeometryAnnotationsConnection'
                  nodes: Array<{
                    __typename?: 'GeometryAnnotation'
                    point: Array<number> | null
                    geometryAnnotationTypeByGeometryAnnotationTypeId: {
                      __typename?: 'GeometryAnnotationType'
                      name: string
                    }
                  }>
                }
              }>
            }
          }>
        }
      }>
    }
  }
}

export type ViewDataSetsWithDetailsQueryVariables = Exact<{
  datasetId: Scalars['Int']
}>

export type ViewDataSetsWithDetailsQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    superiorDirection: AxisType | null
    anteriorDirection: AxisType | null
    bodyPart: BodyPartType
    id: number
    name: string
    units: UnitsType
    activeTopology: TopologyType | null
    lastModified: any
    subjectsByDatasetId: {
      __typename?: 'SubjectsConnection'
      nodes: Array<{
        __typename?: 'Subject'
        gender: string | null
        name: string
        id: number
        geometrySeriesBySubjectId: {
          __typename?: 'GeometrySeriesConnection'
          nodes: Array<{
            __typename?: 'GeometrySeries'
            poseTypeId: number
            topology: TopologyType | null
            poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            geometriesByGeometrySeriesId: {
              __typename?: 'GeometriesConnection'
              nodes: Array<{
                __typename?: 'Geometry'
                version: number
                geometryScreenshotInvocationsByGeometryId: {
                  __typename?: 'GeometryScreenshotInvocationsConnection'
                  nodes: Array<{
                    __typename?: 'GeometryScreenshotInvocation'
                    s3Key: string | null
                    signedURL: string | null
                  }>
                }
              }>
            }
          }>
        }
      }>
    }
    geometryAnnotationTypesByDatasetId: {
      __typename?: 'GeometryAnnotationTypesConnection'
      nodes: Array<{
        __typename?: 'GeometryAnnotationType'
        id: number
        name: string
        geometryAnnotationsByGeometryAnnotationTypeId: {
          __typename?: 'GeometryAnnotationsConnection'
          nodes: Array<{
            __typename?: 'GeometryAnnotation'
            id: number
            point: Array<number> | null
          }>
        }
      }>
    }
  }
}

export type ViewUploadContentsAndDatabaseNameQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type ViewUploadContentsAndDatabaseNameQuery = {
  __typename?: 'Query'
  datasetById: { __typename?: 'Dataset'; name: string }
  uploadBucketList: {
    __typename?: 'GoldilocksUploadBucketListResponse'
    Contents: Array<{
      __typename?: 'GoldilocksUploadBucketListResponseContents'
      Key: string
      LastModified: any
      ETag: string
    }>
  } | null
}

export type ListDatasetsAndUploadBucketContentsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListDatasetsAndUploadBucketContentsQuery = {
  __typename?: 'Query'
  allDatasets: {
    __typename?: 'DatasetsConnection'
    nodes: Array<{
      __typename?: 'Dataset'
      id: number
      name: string
      subjectsByDatasetId: {
        __typename?: 'SubjectsConnection'
        totalCount: number
        nodes: Array<{
          __typename?: 'Subject'
          geometrySeriesBySubjectId: {
            __typename?: 'GeometrySeriesConnection'
            nodes: Array<{
              __typename?: 'GeometrySeries'
              poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            }>
          }
        }>
      }
    }>
  }
  uploadBucketList: {
    __typename?: 'GoldilocksUploadBucketListResponse'
    Contents: Array<{
      __typename?: 'GoldilocksUploadBucketListResponseContents'
      Key: string
    }>
  } | null
  serverSettings: {
    __typename?: 'UploadBuckeMetadata'
    uploadBucketName: string
    uploadBucketS3ConsoleUploadUrl: string
    awsConsoleSignInUrl: string
  }
}

export type DatasetVarTypesQueryVariables = Exact<{ [key: string]: never }>

export type DatasetVarTypesQuery = {
  __typename?: 'Query'
  bodyPartType: {
    __typename?: '__Type'
    name: string | null
    description: string | null
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
  unitsType: {
    __typename?: '__Type'
    name: string | null
    description: string | null
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
  TopologyType: {
    __typename?: '__Type'
    name: string | null
    description: string | null
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
}

export type CreateDatasetMutationVariables = Exact<{
  input: CreateDatasetInput
}>

export type CreateDatasetMutation = {
  __typename?: 'Mutation'
  createDataset: {
    __typename?: 'CreateDatasetPayload'
    dataset: { __typename?: 'Dataset'; id: number } | null
  } | null
}

export type ImportBodyGeometryMutationVariables = Exact<{
  datasetId: Scalars['Int']
  subjectName: Scalars['String']
  gender: Scalars['String']
  poseTypeId: Scalars['Int']
  s3Key: Scalars['String']
  eTag: Scalars['String']
  textureS3Key?: InputMaybe<Scalars['String']>
}>

export type ImportBodyGeometryMutation = {
  __typename?: 'Mutation'
  importBodyGeometry: {
    __typename?: 'ImportGeometryResponse'
    geometry: { __typename?: 'Geometry'; id: number } | null
  } | null
}

export type ImportHandGeometryMutationVariables = Exact<{
  datasetId: Scalars['Int']
  subjectName: Scalars['String']
  side: Scalars['String']
  poseTypeId: Scalars['Int']
  s3Key: Scalars['String']
  eTag: Scalars['String']
  textureS3Key?: InputMaybe<Scalars['String']>
}>

export type ImportHandGeometryMutation = {
  __typename?: 'Mutation'
  importHandGeometry: {
    __typename?: 'ImportGeometryResponse'
    geometry: { __typename?: 'Geometry'; id: number } | null
  } | null
}

export type ContentPartsFragment = {
  __typename?: 'GoldilocksUploadBucketListResponseContents'
  Key: string
  LastModified: any
  ETag: string
}

export type SetPropertiesQueryVariables = Exact<{
  datasetId: Scalars['Int']
}>

export type SetPropertiesQuery = {
  __typename?: 'Query'
  datasetById: {
    __typename?: 'Dataset'
    name: string
    activeTopology: TopologyType | null
    units: UnitsType
    bodyPart: BodyPartType
    subjectsByDatasetId: {
      __typename?: 'SubjectsConnection'
      nodes: Array<{
        __typename?: 'Subject'
        name: string
        gender: string | null
        geometrySeriesBySubjectId: {
          __typename?: 'GeometrySeriesConnection'
          nodes: Array<{
            __typename?: 'GeometrySeries'
            poseTypeId: number
            side: string | null
          }>
        }
      }>
    }
  }
  uploadBucketList: {
    __typename?: 'GoldilocksUploadBucketListResponse'
    Contents: Array<{
      __typename?: 'GoldilocksUploadBucketListResponseContents'
      Key: string
      LastModified: any
      ETag: string
    }>
  } | null
  allPoseTypes: {
    __typename?: 'PoseTypesConnection'
    nodes: Array<{
      __typename?: 'PoseType'
      id: number
      name: string
      bodyPart: BodyPartType
    }>
  }
  allCheckedUploads: {
    __typename?: 'CheckedUploadsConnection'
    nodes: Array<{
      __typename?: 'CheckedUpload'
      nodeId: string
      eTag: string
      isValidObj: boolean
      topology: TopologyType | null
      predictedBodyUnits: UnitsType | null
      predictedHandUnits: UnitsType | null
    }>
  }
}

export type AddRemoveGeometriesToMeasurementStudyMutationVariables = Exact<{
  measurementStudyId: Scalars['Int']
  geometryIdsToAdd: Array<Scalars['Int']> | Scalars['Int']
  geometryIdsToRemove: Array<Scalars['Int']> | Scalars['Int']
}>

export type AddRemoveGeometriesToMeasurementStudyMutation = {
  __typename?: 'Mutation'
  addRemoveGeometriesToMeasurementStudy: {
    __typename?: 'AddRemoveGeometriesToMeasurementStudyResponse'
    commit: { __typename?: 'Commit'; id: number } | null
  } | null
}

export type AddGeometriesToStudyQueryVariables = Exact<{
  selectedMeasurementStudy: Scalars['Int']
}>

export type AddGeometriesToStudyQuery = {
  __typename?: 'Query'
  allDatasets: {
    __typename?: 'DatasetsConnection'
    nodes: Array<{
      __typename?: 'Dataset'
      id: number
      name: string
      bodyPart: BodyPartType
      subjectsByDatasetId: {
        __typename?: 'SubjectsConnection'
        nodes: Array<{
          __typename?: 'Subject'
          id: number
          name: string
          gender: string | null
          geometrySeriesBySubjectId: {
            __typename?: 'GeometrySeriesConnection'
            nodes: Array<{
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              poseTypeByPoseTypeId: {
                __typename?: 'PoseType'
                id: number
                name: string
              }
              geometriesByGeometrySeriesId: {
                __typename?: 'GeometriesConnection'
                nodes: Array<{ __typename?: 'Geometry'; id: number }>
              }
            }>
          }
        }>
      }
    }>
  }
  measurementStudyById: {
    __typename?: 'MeasurementStudy'
    name: string
    commitByHeadCommitId: {
      __typename?: 'Commit'
      commitsGeometriesLookupsByCommitId: {
        __typename?: 'CommitsGeometriesLookupsConnection'
        nodes: Array<{
          __typename?: 'CommitsGeometriesLookup'
          geometryId: number
          geometryByGeometryId: {
            __typename?: 'Geometry'
            geometrySeriesByGeometrySeriesId: {
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              poseTypeByPoseTypeId: {
                __typename?: 'PoseType'
                bodyPart: BodyPartType
                name: string
              }
              subjectBySubjectId: {
                __typename?: 'Subject'
                gender: string | null
              }
            }
          }
        }>
      }
    }
  }
}

export type MeasurementStudyNameQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type MeasurementStudyNameQuery = {
  __typename?: 'Query'
  measurementStudyById: {
    __typename?: 'MeasurementStudy'
    id: number
    name: string
  }
}

export type ComparePerformanceHeaderQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type ComparePerformanceHeaderQuery = {
  __typename?: 'Query'
  measurementStudyById: { __typename?: 'MeasurementStudy'; name: string }
}

export type ComparePerformanceLatestIterationQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  rightMeasurementInvocationIteration: Scalars['Int']
}>

export type ComparePerformanceLatestIterationQuery = {
  __typename?: 'Query'
  allLatestIterationPerformances: {
    __typename?: 'LatestIterationPerformancesConnection'
    nodes: Array<{
      __typename?: 'LatestIterationPerformance'
      measurementName: string | null
      leftMeasurementInvocationIteration: number | null
      leftNumInvocations: any | null
      leftMedianDurationSeconds: number | null
      leftNinetiethPctDurationSeconds: number | null
      leftMaxDurationSeconds: number | null
      rightMeasurementInvocationIteration: number | null
      rightNumInvocations: any | null
      rightMedianDurationSeconds: number | null
      rightNinetiethPctDurationSeconds: number | null
      rightMaxDurationSeconds: number | null
    }>
  }
}

export type ComparePerformanceSpecificIterationQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  leftMeasurementInvocationIteration: Scalars['Int']
  rightMeasurementInvocationIteration: Scalars['Int']
}>

export type ComparePerformanceSpecificIterationQuery = {
  __typename?: 'Query'
  allIterationPerformances: {
    __typename?: 'IterationPerformancesConnection'
    nodes: Array<{
      __typename?: 'IterationPerformance'
      measurementName: string | null
      leftMeasurementInvocationIteration: number | null
      leftNumInvocations: any | null
      leftMedianDurationSeconds: number | null
      leftNinetiethPctDurationSeconds: number | null
      leftMaxDurationSeconds: number | null
      rightMeasurementInvocationIteration: number | null
      rightNumInvocations: any | null
      rightMedianDurationSeconds: number | null
      rightNinetiethPctDurationSeconds: number | null
      rightMaxDurationSeconds: number | null
    }>
  }
}

export type SubjectPerformanceQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementName: Scalars['String']
  leftMeasurementInvocationIteration: Scalars['Int']
  rightMeasurementInvocationIteration: Scalars['Int']
}>

export type SubjectPerformanceQuery = {
  __typename?: 'Query'
  allSubjectPerformances: {
    __typename?: 'SubjectPerformancesConnection'
    nodes: Array<{
      __typename?: 'SubjectPerformance'
      subjectName: string | null
      leftDurationSeconds: number | null
      rightDurationSeconds: number | null
    }>
  }
}

export type DownloadMeasurementsQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementInvocationIteration: Scalars['Int']
}>

export type DownloadMeasurementsQuery = {
  __typename?: 'Query'
  allMeasurementInvocationIterations: {
    __typename?: 'MeasurementInvocationIterationsConnection'
    nodes: Array<{
      __typename?: 'MeasurementInvocationIteration'
      commitByMeasurementInvocationCommitId: {
        __typename?: 'Commit'
        measurementInvocationsByCommitId: {
          __typename?: 'MeasurementInvocationsConnection'
          nodes: Array<{
            __typename?: 'MeasurementInvocation'
            measurementName: string
            resultUnits: ResultValueType | null
            resultValue: number | null
            geometryByGeometryId: {
              __typename?: 'Geometry'
              geometrySeriesByGeometrySeriesId: {
                __typename?: 'GeometrySeries'
                subjectBySubjectId: {
                  __typename?: 'Subject'
                  name: string
                  gender: string | null
                }
              }
            }
          }>
        }
      }
    }>
  }
}

export type DuplicateStudyMutationVariables = Exact<{
  input: DuplicateMeasurementStudyInput
}>

export type DuplicateStudyMutation = {
  __typename?: 'Mutation'
  duplicateMeasurementStudy: {
    __typename?: 'CreateMeasurementStudyResponse'
    measurementStudy: { __typename?: 'MeasurementStudy'; id: number } | null
  } | null
}

export type ListMeasurementStudiesQueryVariables = Exact<{
  [key: string]: never
}>

export type ListMeasurementStudiesQuery = {
  __typename?: 'Query'
  allMeasurementStudies: {
    __typename?: 'MeasurementStudiesConnection'
    nodes: Array<{
      __typename?: 'MeasurementStudy'
      id: number
      name: string
      isArchived: boolean
      commitByHeadCommitId: {
        __typename?: 'Commit'
        created: any
        commitsGeometriesLookupsByCommitId: {
          __typename?: 'CommitsGeometriesLookupsConnection'
          totalCount: number
        }
      }
    }>
  }
}

export type RemeasureTagMutationVariables = Exact<{
  input: RemeasureTagInput
}>

export type RemeasureTagMutation = {
  __typename?: 'Mutation'
  remeasureUsingTag: {
    __typename?: 'RemeasureResponse'
    commit: { __typename?: 'Commit'; id: number } | null
  } | null
}

export type RemeasureBranchMutationVariables = Exact<{
  input: RemeasureBranchInput
}>

export type RemeasureBranchMutation = {
  __typename?: 'Mutation'
  remeasureUsingCommit: {
    __typename?: 'RemeasureResponse'
    commit: { __typename?: 'Commit'; id: number } | null
  } | null
}

export type MeasureAgainQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type MeasureAgainQuery = {
  __typename?: 'Query'
  measurementStudyById: {
    __typename?: 'MeasurementStudy'
    name: string
    measurementStudyMeasurementsByMeasurementStudyId: {
      __typename?: 'MeasurementStudyMeasurementsConnection'
      nodes: Array<{
        __typename?: 'MeasurementStudyMeasurement'
        measurementName: string | null
      }>
    }
    commitByHeadCommitId: {
      __typename?: 'Commit'
      commitsGeometriesLookupsByCommitId: {
        __typename?: 'CommitsGeometriesLookupsConnection'
        edges: Array<{
          __typename?: 'CommitsGeometriesLookupsEdge'
          node: {
            __typename?: 'CommitsGeometriesLookup'
            geometryId: number
            geometryByGeometryId: {
              __typename?: 'Geometry'
              geometrySeriesByGeometrySeriesId: {
                __typename?: 'GeometrySeries'
                topology: TopologyType | null
                poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
              }
            }
          }
        }>
        nodes: Array<{
          __typename?: 'CommitsGeometriesLookup'
          geometryByGeometryId: {
            __typename?: 'Geometry'
            geometrySeriesByGeometrySeriesId: {
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              poseTypeByPoseTypeId: { __typename?: 'PoseType'; name: string }
            }
          }
        }>
      }
    }
  }
  allMeasurers: {
    __typename?: 'MeasurersConnection'
    nodes: Array<{ __typename?: 'Measurer'; id: number; name: string }>
  }
  github: {
    __typename?: 'GithubResponse'
    branches: Array<{
      __typename?: 'GithubBranchesResponse'
      branchName: string
      sha1: string
      measurerName: DataLayerMeasurerName
    }>
  }
  allDeployableMeasurerVersions: {
    __typename?: 'DeployableMeasurerVersions'
    versions: Array<{
      __typename?: 'DeployableMeasurerVersion'
      tagName: string
      measurerName: DataLayerMeasurerName
    }>
  }
}

export type SupportedMeasurementsByMeasurerTagNameQueryVariables = Exact<{
  tagName: Scalars['String']
  measurerName: DataLayerMeasurerName
}>

export type SupportedMeasurementsByMeasurerTagNameQuery = {
  __typename?: 'Query'
  supportedMeasurementsByMeasurerTagName: {
    __typename?: 'SupportedMeasurements'
    supportedMeasurements: {
      __typename?: 'SupportedMeasurement'
      measurements: Array<{
        __typename?: 'SupportedMeasurementsCurves'
        measurementName: string
      }> | null
    } | null
  } | null
}

export type SupportedMeasurementsByMeasurerSha1QueryVariables = Exact<{
  sha1: Scalars['String']
  measurerName: DataLayerMeasurerName
}>

export type SupportedMeasurementsByMeasurerSha1Query = {
  __typename?: 'Query'
  supportedMeasurementsByMeasurerSha1: {
    __typename?: 'SupportedMeasurements'
    supportedMeasurements: {
      __typename?: 'SupportedMeasurement'
      measurements: Array<{
        __typename?: 'SupportedMeasurementsCurves'
        measurementName: string
      }> | null
    } | null
  } | null
}

export type SelectQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type SelectQuery = {
  __typename?: 'Query'
  measurementStudyById: { __typename?: 'MeasurementStudy'; name: string }
  allIncompleteMeasurementInvocationsSubjectNames: {
    __typename?: 'IncompleteMeasurementInvocationsSubjectNamesConnection'
    nodes: Array<{
      __typename?: 'IncompleteMeasurementInvocationsSubjectName'
      subjectName: string | null
    }>
  }
  allIncompleteMeasurementInvocationsMeasurementNames: {
    __typename?: 'IncompleteMeasurementInvocationsMeasurementNamesConnection'
    nodes: Array<{
      __typename?: 'IncompleteMeasurementInvocationsMeasurementName'
      measurementName: string | null
    }>
  }
  allIncompleteMeasurementInvocationsIterations: {
    __typename?: 'IncompleteMeasurementInvocationsIterationsConnection'
    nodes: Array<{
      __typename?: 'IncompleteMeasurementInvocationsIteration'
      measurementInvocationIteration: number | null
    }>
  }
}

export type ErrorInvocationsQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  measurementName?: InputMaybe<Scalars['String']>
  subjectName?: InputMaybe<Scalars['String']>
}>

export type ErrorInvocationsQuery = {
  __typename?: 'Query'
  allFlattenedIncompleteMeasurementInvocations: {
    __typename?: 'FlattenedIncompleteMeasurementInvocationsConnection'
    nodes: Array<{
      __typename?: 'FlattenedIncompleteMeasurementInvocation'
      measurementInvocationIteration: number | null
      measurementStudyId: number | null
      measurementInvocationId: number | null
      measurementName: string | null
      stateMachineStateId: string | null
      subjectName: string | null
      error: Array<string | null> | null
      poseName: string | null
      topology: TopologyType | null
    }>
  }
}

export type AggregateQueryVariables = Exact<{
  measurementStudyId?: InputMaybe<Scalars['Int']>
  measurementInvocationIteration?: InputMaybe<Scalars['Int']>
  measurementName?: InputMaybe<Scalars['String']>
  subjectName?: InputMaybe<Scalars['String']>
}>

export type AggregateQuery = {
  __typename?: 'Query'
  flattenedMeasurementInvocationCountByStateMachineState: {
    __typename?: 'FlattenedMeasurementInvocationCountByStateMachineStateConnection'
    nodes: Array<{
      __typename?: 'FlattenedMeasurementInvocationCountByStateMachineStateRecord'
      stateMachineStateId: string | null
      stateMachineStateIdCount: number | null
    }>
  } | null
}

export type RerunFailedOrStuckMeasurementInvocationsMutationVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementInvocationIteration: Scalars['Int']
}>

export type RerunFailedOrStuckMeasurementInvocationsMutation = {
  __typename?: 'Mutation'
  rerunFailedOrStuckMeasurementInvocations: {
    __typename?: 'RerunFailedOrStuckMeasurementInvocationsPayload'
    integer: number | null
  } | null
}

export type CreateNewMeasurementStudyChooseMeasurementsQueryVariables = Exact<{
  tagName: Scalars['String']
  measurerName: DataLayerMeasurerName
}>

export type CreateNewMeasurementStudyChooseMeasurementsQuery = {
  __typename?: 'Query'
  supportedMeasurementsByMeasurerTagName: {
    __typename?: 'SupportedMeasurements'
    supportedMeasurements: {
      __typename?: 'SupportedMeasurement'
      measurements: Array<{
        __typename?: 'SupportedMeasurementsCurves'
        measurementName: string
      }> | null
    } | null
  } | null
}

export type CreateNewMeasurementStudyChooseSubjectsQueryVariables = Exact<{
  [key: string]: never
}>

export type CreateNewMeasurementStudyChooseSubjectsQuery = {
  __typename?: 'Query'
  allDatasets: {
    __typename?: 'DatasetsConnection'
    nodes: Array<{
      __typename?: 'Dataset'
      id: number
      name: string
      bodyPart: BodyPartType
      subjectsByDatasetId: {
        __typename?: 'SubjectsConnection'
        nodes: Array<{
          __typename?: 'Subject'
          id: number
          name: string
          gender: string | null
          geometrySeriesBySubjectId: {
            __typename?: 'GeometrySeriesConnection'
            nodes: Array<{
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              poseTypeByPoseTypeId: {
                __typename?: 'PoseType'
                id: number
                name: string
              }
              geometriesByGeometrySeriesId: {
                __typename?: 'GeometriesConnection'
                nodes: Array<{ __typename?: 'Geometry'; id: number }>
              }
            }>
          }
        }>
      }
    }>
  }
}

export type CreateNewMeasurementStudyQueryVariables = Exact<{
  [key: string]: never
}>

export type CreateNewMeasurementStudyQuery = {
  __typename?: 'Query'
  bodyPartType: {
    __typename?: '__Type'
    enumValues: Array<{ __typename?: '__EnumValue'; name: string }> | null
  } | null
  allDeployableMeasurerVersions: {
    __typename?: 'DeployableMeasurerVersions'
    versions: Array<{
      __typename?: 'DeployableMeasurerVersion'
      measurerName: DataLayerMeasurerName
      tagName: string
    }>
  }
  allMeasurers: {
    __typename?: 'MeasurersConnection'
    nodes: Array<{
      __typename?: 'Measurer'
      id: number
      repoSlug: string | null
      functionName: string | null
      measurerName: string
    }>
  }
  allPoseTypes: {
    __typename?: 'PoseTypesConnection'
    nodes: Array<{ __typename?: 'PoseType'; name: string }>
  }
  topologyType: {
    __typename?: '__Type'
    enumValues: Array<{ __typename?: '__EnumValue'; name: string }> | null
  } | null
}

export type CreateMeasurementStudyTagMutationVariables = Exact<{
  name: Scalars['String']
  geometryIds: Array<Scalars['Int']> | Scalars['Int']
  measurementNames: Array<Scalars['String']> | Scalars['String']
  tagName: Scalars['String']
  measurerId: Scalars['Int']
}>

export type CreateMeasurementStudyTagMutation = {
  __typename?: 'Mutation'
  createMeasurementStudyUsingTag: {
    __typename?: 'CreateMeasurementStudyResponse'
    measurementStudy: { __typename?: 'MeasurementStudy'; id: number } | null
  } | null
}

export type DoRegenerateViewsMutationVariables = Exact<{
  input: RegenerateViewsInput
}>

export type DoRegenerateViewsMutation = {
  __typename?: 'Mutation'
  regenerateViews: {
    __typename?: 'RegenerateViewsResponse'
    numberOfTranslatedViewsDeleted: number
  } | null
}

export type RegenerateViewsQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type RegenerateViewsQuery = {
  __typename?: 'Query'
  measurementStudy: {
    __typename?: 'MeasurementStudy'
    name: string
    id: number
    measurementInvocationIterationsByMeasurementStudyId: {
      __typename?: 'MeasurementInvocationIterationsConnection'
      nodes: Array<{
        __typename?: 'MeasurementInvocationIteration'
        measurementInvocationIteration: number | null
        commitByMeasurementInvocationCommitId: {
          __typename?: 'Commit'
          measurementInvocationsByCommitId: {
            __typename?: 'MeasurementInvocationsConnection'
            nodes: Array<{
              __typename?: 'MeasurementInvocation'
              measurementName: string
              measurementScreenshotInvocationsByMeasurementInvocationId: {
                __typename?: 'MeasurementScreenshotInvocationsConnection'
                nodes: Array<{
                  __typename?: 'MeasurementScreenshotInvocation'
                  id: number
                }>
              }
            }>
          }
        }
      }>
    }
  }
}

export type RenameMeasurementStudyMutationVariables = Exact<{
  input: UpdateMeasurementStudyByIdInput
}>

export type RenameMeasurementStudyMutation = {
  __typename?: 'Mutation'
  updateMeasurementStudyById: {
    __typename?: 'UpdateMeasurementStudyPayload'
    measurementStudy: { __typename?: 'MeasurementStudy'; name: string } | null
  }
}

export type CommitLabelsMutationVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementInvocationLabelDeltas:
    | Array<MeasurementInvocationAndLabels>
    | MeasurementInvocationAndLabels
}>

export type CommitLabelsMutation = {
  __typename?: 'Mutation'
  performCommit: {
    __typename?: 'CommitResponse'
    commit: {
      __typename?: 'Commit'
      id: number
      parentCommitId: number | null
    } | null
  } | null
}

export type ReviewMeasurementsMeasurementInvocationCurveQueryVariables = Exact<{
  measurementInvocationId: Scalars['Int']
}>

export type ReviewMeasurementsMeasurementInvocationCurveQuery = {
  __typename?: 'Query'
  measurementInvocationById: {
    __typename?: 'MeasurementInvocation'
    id: number
    curveByCurveId: {
      __typename?: 'Curve'
      id: number
      tapeWidth: number
      isClosed: boolean
      vertices: Array<number>
    } | null
  }
}

export type ReviewMeasurementsHeaderQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type ReviewMeasurementsHeaderQuery = {
  __typename?: 'Query'
  measurementStudy: {
    __typename?: 'MeasurementStudy'
    name: string
    id: number
    allMeasurementNames: {
      __typename?: 'MeasurementStudyMeasurementsConnection'
      nodes: Array<{
        __typename?: 'MeasurementStudyMeasurement'
        measurementName: string | null
      }>
    }
  }
}

export type ReviewMeasurementsDetailQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  measurementName?: InputMaybe<Scalars['String']>
}>

export type ReviewMeasurementsDetailQuery = {
  __typename?: 'Query'
  measurementStudy: {
    __typename?: 'MeasurementStudy'
    commitByHeadCommitId: {
      __typename?: 'Commit'
      commitsGeometriesLookupsByCommitId: {
        __typename?: 'CommitsGeometriesLookupsConnection'
        nodes: Array<{
          __typename?: 'CommitsGeometriesLookup'
          geometryByGeometryId: {
            __typename?: 'Geometry'
            id: number
            s3Key: string
            signedURL: string
            geometrySeriesByGeometrySeriesId: {
              __typename?: 'GeometrySeries'
              topology: TopologyType | null
              subjectBySubjectId: {
                __typename?: 'Subject'
                id: number
                name: string
                gender: string | null
                datasetByDatasetId: {
                  __typename?: 'Dataset'
                  anteriorDirectionCoordinates: Array<number> | null
                  superiorDirectionCoordinates: Array<number> | null
                  units: UnitsType
                }
              }
              poseTypeByPoseTypeId: {
                __typename?: 'PoseType'
                name: string
                id: number
              }
            }
          }
        }>
      }
    }
    measurementInvocationIterationsByMeasurementStudyId: {
      __typename?: 'MeasurementInvocationIterationsConnection'
      nodes: Array<{
        __typename?: 'MeasurementInvocationIteration'
        measurementInvocationIteration: number | null
        commitByMeasurementInvocationCommitId: {
          __typename?: 'Commit'
          measurerVersionByMeasurerVersionId: {
            __typename?: 'MeasurerVersion'
            sha1: string | null
            tagName: string | null
            commitDate: any | null
            commitMessage: string | null
            measurerByMeasurerId: {
              __typename?: 'Measurer'
              name: string
              functionName: string | null
              repoSlug: string | null
            }
          }
          measurementInvocationsByCommitId: {
            __typename?: 'MeasurementInvocationsConnection'
            nodes: Array<{
              __typename?: 'MeasurementInvocation'
              id: number
              stateMachineStateId: string
              measurementName: string
              geometryId: number
              resultValue: number | null
              resultUnits: ResultValueType | null
              measurementScreenshotInvocationsByMeasurementInvocationId: {
                __typename?: 'MeasurementScreenshotInvocationsConnection'
                nodes: Array<{
                  __typename?: 'MeasurementScreenshotInvocation'
                  viewIndex: number
                  s3Key: string | null
                  signedURL: string | null
                  translatedViewByTranslatedViewId: {
                    __typename?: 'TranslatedView'
                    viewByTargetViewId: {
                      __typename?: 'View'
                      id: number
                      position: Array<number>
                      target: Array<number>
                      zoom: number
                    } | null
                  }
                }>
              }
              measurementInvocationDifferencesByInvocationId: {
                __typename?: 'MeasurementInvocationDifferencesConnection'
                nodes: Array<{
                  __typename?: 'MeasurementInvocationDifference'
                  pathDifferenceRatio: number | null
                  valueDifferenceRatio: number | null
                  previousInvocationId: number
                }>
              }
            }>
          }
        }
      }>
    }
    labels: {
      __typename?: 'LabelsOfHeadCommitsConnection'
      nodes: Array<{
        __typename?: 'LabelsOfHeadCommit'
        measurementInvocationId: number | null
        labelByLabelId: {
          __typename?: 'Label'
          name: string | null
          isFailure: boolean
        }
      }>
    }
  }
}

export type IterationChoicesQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type IterationChoicesQuery = {
  __typename?: 'Query'
  allMeasurementInvocationIterations: {
    __typename?: 'MeasurementInvocationIterationsConnection'
    nodes: Array<{
      __typename?: 'MeasurementInvocationIteration'
      measurementInvocationIteration: number | null
    }>
  }
}

export type ArchiveStudyMutationVariables = Exact<{
  input: UpdateMeasurementStudyByIdInput
}>

export type ArchiveStudyMutation = {
  __typename?: 'Mutation'
  updateMeasurementStudyById: {
    __typename?: 'UpdateMeasurementStudyPayload'
    measurementStudy: {
      __typename?: 'MeasurementStudy'
      isArchived: boolean
    } | null
  }
}

export type ViewMeasurementStudyQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type ViewMeasurementStudyQuery = {
  __typename?: 'Query'
  measurementStudyById: {
    __typename?: 'MeasurementStudy'
    id: number
    name: string
    isArchived: boolean
    commitByHeadCommitId: { __typename?: 'Commit'; created: any }
    reviewStateCounts: {
      __typename?: 'MeasurementInvocationReviewStateCountsConnection'
      nodes: Array<{
        __typename?: 'MeasurementInvocationReviewStateCount'
        measurementName: string | null
        measurementInvocationIteration: number | null
        gender: string | null
        errorCount: number | null
        failureCount: number | null
        needReviewCount: number | null
        newCount: number | null
        startedCount: number | null
        successCount: number | null
      }>
    }
    labelCounts: {
      __typename?: 'MeasurementStudyLatestLabelCountsConnection'
      nodes: Array<{
        __typename?: 'MeasurementStudyLatestLabelCount'
        gender: string | null
        measurementName: string | null
        labelCount: number | null
        isFailure: boolean | null
        name: string | null
      }>
    }
  }
}

export type ViewMeasurementStudyIterationQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type ViewMeasurementStudyIterationQuery = {
  __typename?: 'Query'
  measurementStudyById: {
    __typename?: 'MeasurementStudy'
    id: number
    iterations: {
      __typename?: 'MeasurementInvocationIterationsConnection'
      nodes: Array<{
        __typename?: 'MeasurementInvocationIteration'
        measurementInvocationIteration: number | null
        commitByMeasurementInvocationCommitId: {
          __typename?: 'Commit'
          created: any
          measurerVersionByMeasurerVersionId: {
            __typename?: 'MeasurerVersion'
            sha1: string | null
            tagName: string | null
            commitDate: any | null
            commitMessage: string | null
            measurerByMeasurerId: {
              __typename?: 'Measurer'
              name: string
              functionName: string | null
              repoSlug: string | null
            }
          }
        }
      }>
    }
  }
}

export type WhatsChangedHeaderQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
}>

export type WhatsChangedHeaderQuery = {
  __typename?: 'Query'
  measurementStudyById: { __typename?: 'MeasurementStudy'; name: string }
}

export type WhatsChangedLatestIterationQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  rightMeasurementInvocationIteration: Scalars['Int']
}>

export type WhatsChangedLatestIterationQuery = {
  __typename?: 'Query'
  iterations: {
    __typename?: 'LatestIterationPerformancesConnection'
    nodes: Array<{
      __typename?: 'LatestIterationPerformance'
      measurementName: string | null
      leftMeasurementInvocationIteration: number | null
      leftNumInvocations: any | null
      leftMedianDurationSeconds: number | null
      leftNinetiethPctDurationSeconds: number | null
      leftMaxDurationSeconds: number | null
      rightMeasurementInvocationIteration: number | null
      rightNumInvocations: any | null
      rightMedianDurationSeconds: number | null
      rightNinetiethPctDurationSeconds: number | null
      rightMaxDurationSeconds: number | null
    }>
  }
  subjects: {
    __typename?: 'LatestSubjectChangesConnection'
    nodes: Array<{
      __typename?: 'LatestSubjectChange'
      measurementName: string | null
      valueDifferenceRatios: Array<number | null> | null
      pathDifferenceRatios: Array<number | null> | null
      numMissingComparisonMetrics: any | null
    }>
  }
}

export type WhatsChangedSpecificIterationQueryVariables = Exact<{
  measurementStudyId: Scalars['Int']
  leftMeasurementInvocationIteration: Scalars['Int']
  rightMeasurementInvocationIteration: Scalars['Int']
}>

export type WhatsChangedSpecificIterationQuery = {
  __typename?: 'Query'
  iterations: {
    __typename?: 'IterationPerformancesConnection'
    nodes: Array<{
      __typename?: 'IterationPerformance'
      measurementName: string | null
      leftMeasurementInvocationIteration: number | null
      leftNumInvocations: any | null
      leftMedianDurationSeconds: number | null
      leftNinetiethPctDurationSeconds: number | null
      leftMaxDurationSeconds: number | null
      rightMeasurementInvocationIteration: number | null
      rightNumInvocations: any | null
      rightMedianDurationSeconds: number | null
      rightNinetiethPctDurationSeconds: number | null
      rightMaxDurationSeconds: number | null
    }>
  }
  subjects: {
    __typename?: 'SubjectChangesConnection'
    nodes: Array<{
      __typename?: 'SubjectChange'
      measurementName: string | null
      valueDifferenceRatios: Array<number | null> | null
      pathDifferenceRatios: Array<number | null> | null
      numMissingComparisonMetrics: any | null
    }>
  }
}

export type UpdateMeasurementViewsMutationVariables = Exact<{
  referenceMeasurementJson: Scalars['String']
  referenceViewJson: Scalars['String']
}>

export type UpdateMeasurementViewsMutation = {
  __typename?: 'Mutation'
  updateMeasurementViews: boolean | null
}

export type ViewsQueryVariables = Exact<{ [key: string]: never }>

export type ViewsQuery = {
  __typename?: 'Query'
  allPresetViews: {
    __typename?: 'PresetViewsConnection'
    nodes: Array<{
      __typename?: 'PresetView'
      measurementName: string
      viewIndex: number
      viewByReferenceViewId: {
        __typename?: 'View'
        position: Array<number>
        target: Array<number>
        zoom: number
        translatedViewsByTargetViewId: {
          __typename?: 'TranslatedViewsConnection'
          nodes: Array<{
            __typename?: 'TranslatedView'
            viewByTargetViewId: {
              __typename?: 'View'
              position: Array<number>
              target: Array<number>
              zoom: number
              id: number
            } | null
          }>
        }
      }
      curveByReferenceCurveId: {
        __typename?: 'Curve'
        isClosed: boolean
        tapeWidth: number
        vertices: Array<number>
      }
    }>
  }
  allReferenceGeometryForViews: {
    __typename?: 'ReferenceGeometryForViewsConnection'
    nodes: Array<{
      __typename?: 'ReferenceGeometryForView'
      geometryByGeometryId: {
        __typename?: 'Geometry'
        s3Key: string
        signedURL: string
      }
    }>
  }
}
