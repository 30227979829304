import { useQuery } from '@apollo/client'
import { Button } from '@unpublished/common-components'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  NextButton,
  Table,
} from '../../../common/common-components'
import { CreateNewMeasurementStudyChooseMeasurementsQuery } from '../../../common/generated'
import { ToggleableButtonList } from '../../../common/toggleable-button-list'
import { MeasurerVersionDisplay } from '../../measurer-version-display'
import {
  ChooseSubjectsPagePayload,
  HomePagePayload,
  PageTransitionActionType,
  TransitionAction,
} from '..'
import {
  CREATE_NEW_MEASUREMENT_STUDY_QUERY,
  createViewModel,
} from './view-model'

const NoMarginUL = styled.ul`
  margin: 0;
`

export function CreateNewMeasurementStudyChooseMeasurements({
  dispatchPageTransitionAction,
  homePagePayload: { studyName, studyBodyPart, measurerVersion },
  chooseSubjectsPagePayload: { pose, topology, femaleSubjects, maleSubjects },
}: {
  dispatchPageTransitionAction: React.Dispatch<TransitionAction>
  homePagePayload: HomePagePayload
  chooseSubjectsPagePayload: ChooseSubjectsPagePayload
}): JSX.Element {
  const navigate = useNavigate()

  const [selectedMeasurements, setSelectedMeasurements] = useState<Set<string>>(
    new Set()
  )

  const { measurerName, tagName } = measurerVersion

  const { loading, error, data } =
    useQuery<CreateNewMeasurementStudyChooseMeasurementsQuery>(
      CREATE_NEW_MEASUREMENT_STUDY_QUERY,
      { variables: { measurerName, tagName } }
    )

  const viewModel = createViewModel({ data })

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link> {'>'}{' '}
        <a // eslint-disable-line jsx-a11y/anchor-is-valid
          href="" // TODO: clean this up - use a reusable, styled <span> instead
          onClick={() => {
            dispatchPageTransitionAction({
              type: PageTransitionActionType.GO_HOME,
            })
          }}
        >
          New Measurement Study
        </a>
        {'>'} <span>Choose Measurements</span>
      </Breadcrumb>
      <h1>New measurement study</h1>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <Table>
        <thead>
          <tr>
            <th>Measurement Study</th>
            <th>Body part</th>
            <th>Measurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{studyName}</td>
            <td>{studyBodyPart}</td>
            <td>
              {measurerVersion.measurerName} @{' '}
              <MeasurerVersionDisplay measurerVersion={measurerVersion} />
            </td>
          </tr>
          <thead>
            <tr>
              <th>Pose</th>
              <th>Topology</th>
              <th>Subjects</th>
            </tr>
          </thead>
          <tr>
            <td>{pose}</td>
            <td>{topology}</td>
            <td>
              <NoMarginUL>
                <li>Female: {femaleSubjects.length}</li>
                <li>Male: {maleSubjects.length}</li>
              </NoMarginUL>
            </td>
          </tr>
        </tbody>
      </Table>
      <div>
        <h2>Choose measurements</h2>
        <ToggleableButtonList
          options={viewModel.measurementOptions}
          onChange={setSelectedMeasurements}
          selectedOptionValues={selectedMeasurements}
        />

        <FixedWidthFooterNavContainer>
          <Button
            onClick={() => {
              dispatchPageTransitionAction({
                type: PageTransitionActionType.CHOOSE_MEASUREMENTS_GO_BACK,
              })
            }}
          >
            Back
          </Button>
          <Button onClick={() => navigate('/studies')}>Cancel</Button>
          <NextButton
            onClick={() =>
              dispatchPageTransitionAction({
                type: PageTransitionActionType.SUBMIT_CREATE_MEASUREMENT_STUDY,
                payload: {
                  selectedMeasurements: Array.from(selectedMeasurements),
                },
              })
            }
            disabled={!selectedMeasurements.size}
          >
            Create Measurement Study
          </NextButton>
        </FixedWidthFooterNavContainer>
      </div>
    </FixedWidthPageContainer>
  )
}
