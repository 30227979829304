import { CameraState, LengthUnits } from '@curvewise/common-types'
import { Html } from '@react-three/drei'
import { FlexRow, STATISTICAL_TEXT } from '@unpublished/common-components'
import { PartsToShow } from '@unpublished/four'
import {
  Canvas,
  PointsWithRenderDetails,
  Scene,
  sceneAppearance,
  useCanvasEvent,
  useComputeInitialView,
  useControls,
} from '@unpublished/scene'
import { Polyline, Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { ReactComponent as _DownloadIcon } from '../common/images/download.svg'
import { ReactComponent as LoadingIcon } from '../common/images/loading.svg'
import { ReactComponent as ToggleCombinedViewIcon } from '../common/images/toggle-combined-view.svg'
import { ReactComponent as _CancelIcon } from '../common/images/x-icon.svg'
import resetView from './images/reset-view.svg'
import screenshot from './images/screenshot.svg'
import { StyledIcon } from './styled-icon'

const SCENE_APPEARANCE_FOR_RENDERING = sceneAppearance.getPreset('Gray fabric')
const ViewerWithContainer = styled.div<{
  CSS?: FlattenSimpleInterpolation
}>`
  width: 100%;
  ${({ CSS }) => CSS && CSS};
`
const DownloadIcon = styled(_DownloadIcon)`
  cursor: pointer;
`
const CancelIcon = styled(_CancelIcon)`
  cursor: pointer;
`
const LeftControls = styled(FlexRow)`
  position: absolute;
  left: 0px;
  justify-content: center;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`
const RightControls = styled(FlexRow)`
  position: absolute;
  right: 0px;
  justify-content: center;
  align-items: center;
  > * {
    margin-left: 10px;
  }
`
const ViewerContainerWithControls = styled.div<{
  CSS?: FlattenSimpleInterpolation
}>`
  display: flex;
  flex-direction: column;
  align-items: left;
  ${({ CSS }) => CSS && CSS};
`
const ViewerControlsContainer = styled(FlexRow)`
  justify-content: center;
  padding: 7px 2px 0px;
  height: 27px;
  position: relative;
`
const Image2D = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
`
const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// Html from drei doesn't interpolate the size of this box
// on it's own, so it is wrapped in a div with the window's
// dimensions.
const CanvasSizedBox = styled.div`
  width: 423px;
  height: 378px;
`
const LoadingText = styled.p`
  ${STATISTICAL_TEXT};
`

const StyledToggleCombinedViewIcon = styled(ToggleCombinedViewIcon)<{
  isActive: boolean
}>`
  cursor: pointer;
  ${({ isActive }) =>
    isActive
      ? css`
          fill: #5c80bc;
          opacity: 0.9;
        `
      : css`
          fill: black;
          opacity: 0.62;
        `}
`

function Loading(): JSX.Element {
  return (
    <LoadingContainer>
      <LoadingIcon />
      <LoadingText>Loading 3D view</LoadingText>
    </LoadingContainer>
  )
}

function View3D({
  body,
  curve,
  compareToCurve,
  tapeWidth,
  compareCurvesUsingColorGradient,
  loadedTexture,
  parts,
  pointsWithRenderDetails,
  pickPointRadiusCm,
  onMouseMove,
  onDoubleClick,
  cursorPosition,
  withStats,
  superiorAxis,
  anteriorAxis,
  units,
}: {
  body: THREE.BufferGeometry | undefined
  curve?: Polyline
  compareToCurve?: Polyline
  tapeWidth: number
  compareCurvesUsingColorGradient?: boolean
  loadedTexture?: THREE.Texture
  parts?: PartsToShow[]
  pointsWithRenderDetails?: PointsWithRenderDetails
  pickPointRadiusCm?: number
  onMouseMove?: (evt: MouseEvent) => void
  onDoubleClick?: (evt: MouseEvent) => void
  cursorPosition?: Vector3
  withStats?: { withStats: boolean; statsClassName: string }
  superiorAxis?: Vector
  anteriorAxis?: Vector
  units?: LengthUnits
}): JSX.Element {
  return (
    <Canvas
      onMouseMove={onMouseMove}
      onDoubleClick={onDoubleClick}
      controlKind="orbit"
      render={() => (
        <Suspense
          fallback={
            <Html center={true}>
              <CanvasSizedBox>
                <Loading />
              </CanvasSizedBox>
            </Html>
          }
        >
          {body && (
            <Scene
              parts={parts}
              body={body}
              mainCurve={curve}
              compareToCurve={compareToCurve}
              tapeWidth={tapeWidth}
              texture={SCENE_APPEARANCE_FOR_RENDERING.texture}
              textureRepeats={SCENE_APPEARANCE_FOR_RENDERING.textureRepeats}
              lightingIntensity={
                SCENE_APPEARANCE_FOR_RENDERING.lightingIntensity
              }
              bump={SCENE_APPEARANCE_FOR_RENDERING.bump}
              bumpRepeats={SCENE_APPEARANCE_FOR_RENDERING.bumpRepeats}
              bumpScale={SCENE_APPEARANCE_FOR_RENDERING.bumpScale}
              tapeColor={SCENE_APPEARANCE_FOR_RENDERING.tapeColor}
              flatShading={false}
              compareCurvesUsingColorGradient={compareCurvesUsingColorGradient}
              loadedTexture={loadedTexture}
              pointsWithRenderDetails={pointsWithRenderDetails}
              pickPointRadiusCm={pickPointRadiusCm}
              cursorPosition={cursorPosition}
              withStats={withStats && withStats.withStats}
              statsClassName={withStats && withStats.statsClassName}
              superiorAxis={superiorAxis}
              anteriorAxis={anteriorAxis}
              units={units}
            />
          )}
        </Suspense>
      )}
    />
  )
}

export function Viewer({
  body,
  bodyS3Key,
  initialView,
  flatViewUrl,
  url,
  show3DView,
  curve,
  compareToCurve,
  tapeWidth,
  containerCSS,
  viewerCSS,
  disabled = false,
  inCombinedViewMode = false,
  renderHeader,
  renderFooter,
  onDownload,
  setShow3DView,
  onRequestClose,
  toggleCombinedView,
  showToggleCombinedViewControls = false,
  showViewerControls = true,
  compareCurvesUsingColorGradient,
  loadedTexture,
  parts,
  pointsWithRenderDetails,
  pickPointRadiusCm,
  onMouseMove,
  onDoubleClick,
  cursorPosition,
  withStats,
  superiorAxis,
  anteriorAxis,
  units,
}: {
  body?: THREE.BufferGeometry
  bodyS3Key?: string
  initialView?: CameraState
  flatViewUrl?: string
  url?: string
  show3DView: boolean
  curve?: Polyline
  compareToCurve?: Polyline
  tapeWidth?: number
  containerCSS?: FlattenSimpleInterpolation
  viewerCSS?: FlattenSimpleInterpolation
  disabled?: boolean
  inCombinedViewMode?: boolean
  renderHeader?: () => JSX.Element | null
  renderFooter?: () => JSX.Element | null
  onDownload?: () => void
  onRequestClose?: () => void
  setShow3DView: (value: boolean) => void
  toggleCombinedView?: (e: any) => void
  showToggleCombinedViewControls?: boolean
  showViewerControls?: boolean
  compareCurvesUsingColorGradient?: boolean
  loadedTexture?: THREE.Texture
  parts?: PartsToShow[]
  pointsWithRenderDetails?: PointsWithRenderDetails
  pickPointRadiusCm?: number
  onMouseMove?: (evt: MouseEvent) => void
  onDoubleClick?: (evt: MouseEvent) => void
  cursorPosition?: Vector3
  withStats?: { withStats: boolean; statsClassName: string }
  superiorAxis?: Vector
  anteriorAxis?: Vector
  units?: LengthUnits
}): JSX.Element {
  const controls = useControls()

  const [loadedBodyS3Key, setLoadedBodyS3Key] = useState<string>()

  useEffect(() => {
    // unload the loaded body when show3DView is set to false
    // this is to fix a bug where the view was not being set when the same body was loaded a second time
    if (loadedBodyS3Key && !show3DView) {
      setLoadedBodyS3Key(undefined)
    }
  }, [loadedBodyS3Key, show3DView])

  useEffect(() => {
    if (url && !bodyS3Key) {
      throw Error('bodyS3Key must be provided with url')
    }
    if (show3DView && controls && !disabled && bodyS3Key !== loadedBodyS3Key) {
      setLoadedBodyS3Key(bodyS3Key)
    }
  }, [controls, disabled, bodyS3Key, loadedBodyS3Key, url, show3DView])

  initialView = useComputeInitialView(initialView)

  useEffect(() => {
    if (controls && initialView) {
      controls.goToView(
        { ...initialView, up: [0, 1, 0] },
        { enableTransition: false }
      )
    }
  }, [initialView, controls])

  const [cameraHasMoved, setCameraHasMoved] = useState(false)
  useCanvasEvent(
    'controlDidStart',
    useCallback(() => {
      setCameraHasMoved(true)
    }, [])
  )

  return (
    <ViewerContainerWithControls CSS={containerCSS}>
      {renderHeader && renderHeader()}
      <ViewerWithContainer CSS={viewerCSS}>
        {!body && url && show3DView && !disabled && <Loading />}
        {show3DView && !disabled && (
          <View3D
            onDoubleClick={onDoubleClick}
            onMouseMove={onMouseMove}
            curve={curve}
            compareToCurve={compareToCurve}
            tapeWidth={tapeWidth ?? 1.0}
            body={body}
            compareCurvesUsingColorGradient={compareCurvesUsingColorGradient}
            parts={parts}
            pointsWithRenderDetails={pointsWithRenderDetails}
            cursorPosition={cursorPosition}
            withStats={withStats}
            loadedTexture={loadedTexture}
            pickPointRadiusCm={pickPointRadiusCm}
            superiorAxis={superiorAxis}
            anteriorAxis={anteriorAxis}
            units={units}
          />
        )}
        {!show3DView && (
          <Image2D src={flatViewUrl} onClick={() => setShow3DView(true)} />
        )}
      </ViewerWithContainer>
      {showViewerControls && (
        <ViewerControlsContainer>
          {show3DView && (
            <LeftControls>
              {flatViewUrl && (
                <StyledIcon
                  src={screenshot}
                  disabled={disabled}
                  onClick={() => setShow3DView(false)}
                />
              )}
              <StyledIcon
                src={resetView}
                disabled={!initialView || !cameraHasMoved || disabled}
                onClick={() => {
                  if (!controls || !initialView) {
                    throw Error('Expected controls and initialView to be set')
                  }
                  controls.goToView({ ...initialView, up: [0, 1, 0] })
                  setCameraHasMoved(false)
                }}
              />
            </LeftControls>
          )}
          {renderFooter && renderFooter()}
          <RightControls>
            {!disabled && onDownload && <DownloadIcon onClick={onDownload} />}
            {showToggleCombinedViewControls && (
              <StyledToggleCombinedViewIcon
                onClick={toggleCombinedView}
                isActive={inCombinedViewMode}
              />
            )}
            {onRequestClose && <CancelIcon onClick={onRequestClose} />}
          </RightControls>
        </ViewerControlsContainer>
      )}
    </ViewerContainerWithControls>
  )
}
