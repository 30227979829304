import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { FixedWidthPageContainer } from '../../common/common-components'
import {
  CreateMeasurementStudyTagMutation,
  CreateMeasurementStudyTagMutationVariables,
} from '../../common/generated'
import { LIST_MEASUREMENT_STUDIES_QUERY } from '../list-studies-table'
import { MeasurementName } from '../measure-again/view-model'
import { MeasurerVersion } from '../measurer-version'
import { CreateNewMeasurementStudyChooseMeasurements } from './choose-measurements'
import { CreateNewMeasurementStudyChooseSubjects } from './choose-subjects'
import { CreateNewMeasurementStudyHome } from './home'

export type MeasurerVersionTagId = MeasurerVersion & {
  id: number
  kind: 'tag'
  tagName: string
}

export interface HomePagePayload {
  studyName: string
  studyBodyPart: string
  measurerVersion: MeasurerVersionTagId
  pose: string
  topology: string
}

export interface ChooseSubjectsPagePayload {
  geometryIds: number[]
  femaleSubjects: number[]
  maleSubjects: number[]
  pose: string
  topology: string
}

export interface ChooseMeasurementsPagePayload {
  selectedMeasurements: MeasurementName[]
}

enum Pages {
  HOME,
  CHOOSE_SUBJECTS,
  CHOOSE_MEASUREMENTS,
}

export interface CreateNewMeasurementStudyPageState {
  homePagePayload?: HomePagePayload
  chooseSubjectsPagePayload?: ChooseSubjectsPagePayload
  chooseMeasurementsPagePayload?: ChooseMeasurementsPagePayload
  currentPage: Pages
  isReadyToSubmit?: boolean
}

export enum PageTransitionActionType {
  HOME_GO_NEXT,
  CHOOSE_SUBJECTS_GO_NEXT,
  SUBMIT_CREATE_MEASUREMENT_STUDY,
  CHOOSE_SUBJECTS_GO_BACK,
  CHOOSE_MEASUREMENTS_GO_BACK,
  GO_HOME,
  GO_BACK,
}

interface HomeGoNextAction {
  type: PageTransitionActionType.HOME_GO_NEXT
  payload: HomePagePayload
}

interface ChooseSubjectsGoNextNextAction {
  type: PageTransitionActionType.CHOOSE_SUBJECTS_GO_NEXT
  payload: ChooseSubjectsPagePayload
}

interface ChooseMeasurementsGoNextNextAction {
  type: PageTransitionActionType.SUBMIT_CREATE_MEASUREMENT_STUDY
  payload: ChooseMeasurementsPagePayload
}

interface OtherTransitionAction {
  type:
    | PageTransitionActionType.GO_HOME
    | PageTransitionActionType.CHOOSE_SUBJECTS_GO_BACK
    | PageTransitionActionType.CHOOSE_MEASUREMENTS_GO_BACK
}

export type TransitionAction =
  | HomeGoNextAction
  | ChooseSubjectsGoNextNextAction
  | ChooseMeasurementsGoNextNextAction
  | OtherTransitionAction

function reducer(
  state: CreateNewMeasurementStudyPageState,
  action: TransitionAction
): CreateNewMeasurementStudyPageState {
  switch (action.type) {
    case PageTransitionActionType.GO_HOME:
      return {
        ...state,
        currentPage: Pages.HOME,
      }
    case PageTransitionActionType.HOME_GO_NEXT:
      return {
        ...state,
        homePagePayload: action.payload,
        currentPage: Pages.CHOOSE_SUBJECTS,
      }
    case PageTransitionActionType.CHOOSE_SUBJECTS_GO_NEXT:
      return {
        ...state,
        chooseSubjectsPagePayload: action.payload,
        currentPage: Pages.CHOOSE_MEASUREMENTS,
      }
    case PageTransitionActionType.CHOOSE_SUBJECTS_GO_BACK:
      return {
        ...state,
        currentPage: Pages.HOME,
      }
    case PageTransitionActionType.CHOOSE_MEASUREMENTS_GO_BACK:
      return {
        ...state,
        currentPage: Pages.CHOOSE_SUBJECTS,
      }
    case PageTransitionActionType.SUBMIT_CREATE_MEASUREMENT_STUDY:
      return {
        ...state,
        chooseMeasurementsPagePayload: action.payload,
        isReadyToSubmit: true,
      }
    default:
      throw new Error('Unexpected value for action.type')
  }
}

export function CreateNewMeasurementStudy(): JSX.Element {
  const navigate = useNavigate()
  const [pageState, dispatchPageTransitionAction] = useReducer(reducer, {
    currentPage: Pages.HOME,
  } as CreateNewMeasurementStudyPageState)

  const [createMeasurementStudy, { error: createMeasurementStudyError }] =
    useMutation<
      CreateMeasurementStudyTagMutation,
      CreateMeasurementStudyTagMutationVariables
    >(
      gql`
        mutation CreateMeasurementStudyTag(
          $name: String!
          $geometryIds: [Int!]!
          $measurementNames: [String!]!
          $tagName: String!
          $measurerId: Int!
        ) {
          createMeasurementStudyUsingTag(
            input: {
              name: $name
              geometryIds: $geometryIds
              measurementNames: $measurementNames
              tagName: $tagName
              measurerId: $measurerId
            }
          ) {
            measurementStudy {
              id
            }
          }
        }
      `,
      {
        variables: {
          name: pageState.homePagePayload?.studyName as string,
          geometryIds: pageState.chooseSubjectsPagePayload
            ?.geometryIds as number[],
          measurementNames: pageState.chooseMeasurementsPagePayload
            ?.selectedMeasurements as string[],
          tagName: pageState.homePagePayload?.measurerVersion.tagName as string,
          measurerId: pageState.homePagePayload?.measurerVersion.id as number,
        },
        refetchQueries: [{ query: LIST_MEASUREMENT_STUDIES_QUERY }],
        onCompleted(responseData) {
          // on success, transition to home
          navigate(
            `/studies/${responseData.createMeasurementStudyUsingTag?.measurementStudy?.id}`
          )
        },
        onError(error) {
          // display errors
          console.error(error.message)
        },
      }
    )

  const currentPage = pageState.currentPage

  useEffect(() => {
    if (pageState.isReadyToSubmit) {
      createMeasurementStudy()
    }
  }, [pageState.isReadyToSubmit, createMeasurementStudy])

  return (
    <FixedWidthPageContainer>
      {currentPage === Pages.HOME && (
        <CreateNewMeasurementStudyHome
          dispatchPageTransitionAction={dispatchPageTransitionAction}
        />
      )}
      {currentPage === Pages.CHOOSE_SUBJECTS && pageState.homePagePayload && (
        <CreateNewMeasurementStudyChooseSubjects
          dispatchPageTransitionAction={dispatchPageTransitionAction}
          homePagePayload={pageState.homePagePayload}
        />
      )}
      {currentPage === Pages.CHOOSE_MEASUREMENTS &&
        pageState.homePagePayload &&
        pageState.chooseSubjectsPagePayload && (
          <CreateNewMeasurementStudyChooseMeasurements
            dispatchPageTransitionAction={dispatchPageTransitionAction}
            homePagePayload={pageState.homePagePayload}
            chooseSubjectsPagePayload={pageState.chooseSubjectsPagePayload}
          />
        )}
      {createMeasurementStudyError && (
        <div>
          <h2>Error</h2>
          <div>{createMeasurementStudyError.message}</div>
        </div>
      )}
    </FixedWidthPageContainer>
  )
}
