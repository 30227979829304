import { KnownBodyTopology } from '@curvewise/common-types'
import { mapN } from '@unpublished/victorinox'
import humanizeDuration from 'humanize-duration'
import { Vector } from 'hyla'
import { uniq } from 'lodash'
import { Vector3 } from 'polliwog-types'

import {
  AxisType,
  BodyPartType,
  TopologyType,
  UnitsType,
} from '../common/generated'
import { Option } from './toggleable-button-list'

export type Gender = 'F' | 'M'
export const GENDER_OPTIONS: Record<Gender, string> = { F: 'Female', M: 'Male' }
export function humanizeGender(gender: Gender): string {
  return GENDER_OPTIONS[gender] || gender
}
const BODY_PART_OPTIONS: { [key in BodyPartType]: string } = {
  BODY: 'Bodies',
  FOOT: 'Feet',
  HAND: 'Hands',
}
export function humanizeAndPluralizeBodyType(bodyType: BodyPartType): string {
  return BODY_PART_OPTIONS[bodyType]
}
export function topologyTypeFromData(
  topology: TopologyType
): KnownBodyTopology {
  switch (topology) {
    case TopologyType.MeshcapadeSm4Mid: {
      return 'meshcapade-sm4-mid'
    }
    case TopologyType.MpiiHumanShape_1_0: {
      return 'mpii-human-shape-1.0'
    }
    default:
      return topology
  }
}
export function mapGenders<T>(fn: (gender: Gender) => T): T[] {
  return [fn('F'), fn('M')]
}
export function mapGenderOptions<T>(fn: (gender: Gender) => T): { F: T; M: T } {
  return { F: fn('F'), M: fn('M') }
}

export const mappedAxes: { [key: string]: AxisType } = {
  '+x': AxisType.X,
  '+y': AxisType.Y,
  '+z': AxisType.Z,
  '-x': AxisType.NegX,
  '-y': AxisType.NegY,
  '-z': AxisType.NegZ,
  '-x + -y': AxisType.NegXNegY,
}
export function humanizeAxis(key: AxisType | null | undefined): string {
  if (key === null || key === undefined) {
    return 'N/A'
  } else {
    const axis = key.toString().toLowerCase()
    if (axis === 'neg_x_neg_y') {
      return '-x + -y'
    } else return axis.includes('neg') ? `-${axis.slice(-1)}` : `+${axis}`
  }
}

export function humanizeTopology(string: string | null | undefined): string {
  if (string === null || string === undefined) {
    return 'Topology-free'
  } else if (string === 'MPII_HUMAN_SHAPE_1_0') {
    return 'MPII Human Shape 1.0'
  } else {
    return string
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^\w/, chr => chr.toUpperCase())
      .replace(/\b\w{1,3}\b/, chr => chr.toUpperCase())
  }
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function uniqueValues<
  ValueType extends
    | number
    | string
    | (number | undefined)
    | (string | undefined)
>(items: ValueType[] | undefined): ValueType[] {
  return items === undefined
    ? []
    : Array.from(new Set(items.filter(x => x !== null) as ValueType[]))
}

export function uniqueNonNullValues<ValueType extends number | string>(
  items: (ValueType | null)[] | undefined
): ValueType[] {
  return items === undefined
    ? []
    : Array.from(new Set(items.filter(x => x !== null) as ValueType[]))
}

export function unabbreviateUnits(units: UnitsType): string {
  switch (units) {
    case UnitsType.Cm:
      return 'centimeters'
    case UnitsType.Mm:
      return 'millimeters'
    case UnitsType.M:
      return 'meters'
    case UnitsType.In:
      return 'inches'
    default:
      return units
  }
}

export function countUnique(iterable: any): number {
  return new Set(iterable).size
}

export function humanizedDurationFromNow(initialTime: any): string {
  const interval = humanizeDuration(
    Date.now() - new Date(initialTime).getTime(),
    { largest: 1 }
  )
  return `${interval} ago`
}

export function sortAlphaNum(a: string, b: string): number {
  return a.localeCompare(b, 'en', { numeric: true })
}

export function unflattenVertices(vertices: number[]): Vector3[] {
  if (vertices.length % 3 !== 0) {
    throw Error('number of vertices must be divisible by 3.')
  }
  return mapN(vertices, 3, (input: number[]) => input) as Vector3[]
}

export const toggleableButtonListGenderOptions = Object.entries(
  GENDER_OPTIONS
).map(([k, v]) => ({
  label: v,
  value: k as Gender,
}))

export function initSetOfGenderOptions(): Set<Gender> {
  return new Set(Object.keys(GENDER_OPTIONS) as Gender[])
}

export function transformCoordinatesToTHREEVector3(
  coordinates: number[]
): Vector | undefined {
  return coordinates?.length === 3
    ? new Vector(coordinates as [number, number, number])
    : undefined
}

export function mapNumberNodeToOption({
  name,
  id,
}: {
  name: string
  id: number
}): Option<number> {
  return { label: name, value: id }
}

// TODO: We can use the generated type
interface SubjectNode {
  id: number
  gender: string | null
  geometrySeriesBySubjectId: {
    nodes: {
      topology: string | null
      poseTypeByPoseTypeId: { name: string }
      geometriesByGeometrySeriesId: { nodes: { id: number }[] }
    }[]
  }
}

export function deriveGeometryIdsFromSubjects({
  subjects,
  selectedMaleSubjects,
  selectedFemaleSubjects,
  selectedPoses,
  selectedTopologies,
}: {
  subjects: SubjectNode[]
  selectedMaleSubjects: Set<number>
  selectedFemaleSubjects: Set<number>
  selectedPoses: Set<string>
  selectedTopologies?: Set<string>
}): number[] {
  return (
    subjects
      .filter(
        subject =>
          selectedMaleSubjects.has(subject.id) ||
          selectedFemaleSubjects.has(subject.id)
      )
      .flatMap(subject =>
        subject.geometrySeriesBySubjectId.nodes
          .filter(geometrySeries =>
            selectedPoses.has(geometrySeries.poseTypeByPoseTypeId.name)
          )
          .filter(
            geometrySeries =>
              !selectedTopologies ||
              !geometrySeries.topology ||
              selectedTopologies.has(geometrySeries.topology)
          )
          .flatMap(geometrySeries =>
            geometrySeries.geometriesByGeometrySeriesId.nodes.map(
              geometry => geometry.id
            )
          )
      ) ?? []
  )
}

interface Dataset {
  subjectsByDatasetId: {
    nodes: {
      geometrySeriesBySubjectId: {
        nodes: {
          poseTypeByPoseTypeId: { name: string }
          geometriesByGeometrySeriesId: { nodes: { id: number }[] }
        }[]
      }
    }[]
  }
}

export function getUniquePoseNamesFromDataset(dataset: Dataset): string[] {
  return uniq(
    dataset.subjectsByDatasetId.nodes.flatMap(subject =>
      subject.geometrySeriesBySubjectId.nodes.map(
        geometrySeries => geometrySeries.poseTypeByPoseTypeId.name
      )
    )
  )
}
