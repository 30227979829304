import { gql, useQuery } from '@apollo/client'
import { CameraState, LengthUnits } from '@curvewise/common-types'
import { Button, FlexRow, Messages } from '@unpublished/common-components'
import {
  adaptView,
  CanvasContextProvider,
  PRESET_VIEWS,
  useObjLoader,
  useTextureLoader,
} from '@unpublished/scene'
import { downloadContent } from '@unpublished/victorinox'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  Breadcrumb,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
} from '../common/common-components'
import { transformCoordinatesToTHREEVector3 } from '../common/data-transforms'
import {
  BodyPartType,
  ViewSubjectGeometryS3KeyQuery,
  ViewSubjectGeometryS3KeyQueryVariables,
} from '../common/generated'
import { ReactComponent as _Settings } from '../common/images/settings.svg'
import { ReactComponent as Timer } from '../common/images/timer.svg'
import { useNumericParam } from '../common/use-numeric-param'
import { useSafeParam } from '../common/use-safe-param'
import { Viewer } from '../common/viewer'
import { DatasetSubjectNavigation } from './dataset-navigation'

const ConstrainedPageContainer = styled(FixedWidthPageContainer)`
  width: 950px;
  margin-top: 0px;
`
const UpperNavigation = styled.div`
  position: -webkit-sticky;
  position: sticky;
  padding-top: 10px;
  top: 0px;
  background: white;
  padding-bottom: 10px;
  z-index: 1;
  opacity: 0.8;
`
const Settings = styled(_Settings)`
  position: absolute;
  top: 5px;
  right: 0px;
`
const SubjectViewContainer = styled(FlexRow)`
  flex-wrap: wrap;
  align-content: space-between;
  width: 860px;
  justify-content: center;
  column-gap: 68px;
  row-gap: 30px;
  margin: 50px 13px;
  > * {
    width: 393px;
  }
`

export function InspectSubjects(): JSX.Element {
  const selectedSubjectName = useSafeParam('selectedSubjectName')
  const selectedDataset = useNumericParam('selectedDataset')
  const selectedPoseId = useNumericParam('selectedPoseId')
  const navigate = useNavigate()
  const { loading, error, data, refetch } = useQuery<
    ViewSubjectGeometryS3KeyQuery,
    ViewSubjectGeometryS3KeyQueryVariables
  >(
    gql`
      query ViewSubjectGeometryS3Key(
        $datasetId: Int!
        $selectedSubjectName: String!
        $poseId: Int!
      ) {
        datasetById(id: $datasetId) {
          units
          superiorDirectionCoordinates
          anteriorDirectionCoordinates
          bodyPart
        }
        subjectByDatasetIdAndName(
          datasetId: $datasetId
          name: $selectedSubjectName
        ) {
          id
          selectedPose: geometrySeriesBySubjectId(
            condition: { poseTypeId: $poseId }
          ) {
            nodes {
              geometriesByGeometrySeriesId {
                nodes {
                  s3Key
                  signedURL
                  textureS3Key
                  textureSignedURL
                  geometryScreenshotInvocationsByGeometryId {
                    nodes {
                      signedURL
                      s3Key
                      viewIndex
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        datasetId: selectedDataset,
        selectedSubjectName,
        poseId: selectedPoseId,
      },
    }
  )
  const [show3DView, setShow3DView] = useState(false)

  function onNavChange({
    updatedPose,
    updatedSubjectName,
  }: {
    updatedPose: number
    updatedSubjectName: string
  }): void {
    navigate(
      `/datasets/${selectedDataset}/subjects/${updatedSubjectName}/${updatedPose}`
    )
    if (
      updatedPose !== selectedPoseId ||
      updatedSubjectName !== selectedSubjectName
    ) {
      setShow3DView(false)
    }
  }

  const { s3Key, signedURL, textureSignedURL } =
    data?.subjectByDatasetIdAndName?.selectedPose.nodes[0]
      ?.geometriesByGeometrySeriesId.nodes[0] ?? {}

  const screenshots =
    data?.subjectByDatasetIdAndName?.selectedPose.nodes[0]
      ?.geometriesByGeometrySeriesId.nodes[0]
      .geometryScreenshotInvocationsByGeometryId.nodes

  const { body, errorMessage: objLoadingErrorMessage } = useObjLoader({
    s3Key,
    signedURL,
    enabled: show3DView,
  })

  const loadedTexture = useTextureLoader(
    show3DView && textureSignedURL ? textureSignedURL : undefined
  )

  let views: CameraState[] | [undefined]
  switch (data?.datasetById.bodyPart) {
    case BodyPartType.Body:
      views = PRESET_VIEWS.views
      break
    case BodyPartType.Hand:
      views = [undefined]
      break
    default:
      views = []
  }

  return (
    <ConstrainedPageContainer>
      <UpperNavigation>
        <Breadcrumb>
          <Link to="/">Home</Link> {'>'} <Link to="/datasets">Datasets</Link>{' '}
          {'>'}{' '}
          <Link to={`/datasets/${selectedDataset}/subjects`}>Subjects</Link>{' '}
          {'>'} Inspect
        </Breadcrumb>
        <DatasetSubjectNavigation
          selectedDataset={selectedDataset}
          selectedSubjectName={selectedSubjectName}
          selectedPoseId={selectedPoseId}
          onNavChange={onNavChange}
        />
        <Settings />
      </UpperNavigation>
      {error && <p>Oh no! {error.message}</p>}
      {loading && <p>Loading ...</p>}
      <div>
        {objLoadingErrorMessage ? (
          <Messages messages={[objLoadingErrorMessage]} />
        ) : (
          <SubjectViewContainer>
            {views.map((view, index) => (
              <CanvasContextProvider key={index}>
                <Viewer
                  viewerCSS={css`
                    height: 380px;
                    border: 1px solid grey;
                  `}
                  onDownload={
                    index === 0
                      ? async () => {
                          const { data } = await refetch()
                          const url =
                            data.subjectByDatasetIdAndName?.selectedPose
                              .nodes[0].geometriesByGeometrySeriesId.nodes[0]
                              .signedURL
                          if (!url) {
                            throw Error('Url must exist')
                          }
                          downloadContent({
                            contents: await (await fetch(url)).text(),
                            filename: `${selectedSubjectName}.obj`,
                            contentType: 'text/prs.wavefront-obj',
                          })
                        }
                      : undefined
                  }
                  body={body}
                  flatViewUrl={
                    screenshots?.find(node => node.viewIndex === index)
                      ?.signedURL ?? undefined
                  }
                  // Since the views are based on 800x600, constrain only the
                  // height to avoid shrinking the view more than necessary.
                  initialView={view && adaptView({ view, height: 378 })}
                  url={signedURL}
                  key={index}
                  bodyS3Key={s3Key}
                  show3DView={show3DView}
                  loadedTexture={loadedTexture}
                  setShow3DView={setShow3DView}
                  superiorAxis={
                    data?.datasetById.superiorDirectionCoordinates
                      ? transformCoordinatesToTHREEVector3(
                          data.datasetById.superiorDirectionCoordinates
                        )
                      : undefined
                  }
                  anteriorAxis={
                    data?.datasetById.anteriorDirectionCoordinates
                      ? transformCoordinatesToTHREEVector3(
                          data.datasetById.anteriorDirectionCoordinates
                        )
                      : undefined
                  }
                  units={data?.datasetById.units.toLowerCase() as LengthUnits}
                />
              </CanvasContextProvider>
            ))}
          </SubjectViewContainer>
        )}
        <FixedWidthFooterNavContainer>
          <Button
            onClick={() => navigate(`/datasets/${selectedDataset}/subjects`)}
          >
            Back
          </Button>
          <Button disabled={true}>Edit Subject</Button>
          <Button disabled={true}>Replace Geometry</Button>
          <Button disabled={true}>
            <Timer /> Compare Versions
          </Button>
        </FixedWidthFooterNavContainer>
      </div>
    </ConstrainedPageContainer>
  )
}
