import {
  AnyMeasurementDocument,
  JSON_SCHEMA as COMMON_TYPES_JSON_SCHEMA,
  LegacyMeasuredBody,
  MeasuredBodyViews,
  NamedPoints,
} from '@curvewise/common-types'
import Ajv from 'ajv'

import { JSON_SCHEMA } from '.'
import { WerkitLegacyMeasuredBody } from './werkit-legacy-measured-body.schema'

const commonTypeAjv = new Ajv({ removeAdditional: true }).addSchema(
  COMMON_TYPES_JSON_SCHEMA
)

export function isLegacyMeasuredBody(data: any): data is LegacyMeasuredBody {
  return commonTypeAjv.validate(
    '#/definitions/LegacyMeasuredBody',
    data
  ) as boolean
}

export function assertLegacyMeasuredBody(data: any): void {
  if (!isLegacyMeasuredBody(data)) {
    throw Error(commonTypeAjv.errorsText(commonTypeAjv.errors))
  }
}

export function isMeasuredBodyViews(data: any): data is MeasuredBodyViews {
  return commonTypeAjv.validate(
    '#/definitions/MeasuredBodyViews',
    data
  ) as boolean
}

export function assertMeasuredBodyViews(data: any): void {
  if (!isMeasuredBodyViews(data)) {
    throw Error(commonTypeAjv.errorsText(commonTypeAjv.errors))
  }
}

export function isNamedPoints(data: any): data is NamedPoints {
  return commonTypeAjv.validate('#/definitions/NamedPoints', data) as boolean
}

export function assertNamedPoints(data: any): void {
  if (!isNamedPoints(data)) {
    throw Error(commonTypeAjv.errorsText(commonTypeAjv.errors))
  }
}

export function isMeasurementDocument(
  data: any
): data is AnyMeasurementDocument {
  return commonTypeAjv.validate(
    '#/definitions/AnyMeasurementDocument',
    data
  ) as boolean
}

export function assertMeasurementDocument(data: any): void {
  if (!isMeasurementDocument(data)) {
    throw Error(commonTypeAjv.errorsText(commonTypeAjv.errors))
  }
}

const werkitLegacyMeasuredBodyAjv = new Ajv({
  removeAdditional: true,
}).addSchema(JSON_SCHEMA)

export function isWerkitLegacyMeasuredBody(
  data: any
): data is WerkitLegacyMeasuredBody {
  return werkitLegacyMeasuredBodyAjv.validate(
    '#/definitions/WerkitLegacyMeasuredBody',
    data
  ) as boolean
}

export function assertWerkitLegacyMeasuredBody(data: any): void {
  if (!isWerkitLegacyMeasuredBody(data)) {
    throw Error(
      werkitLegacyMeasuredBodyAjv.errorsText(werkitLegacyMeasuredBodyAjv.errors)
    )
  }
}
