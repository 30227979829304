import { JSON_SCHEMA, MeasurementCapabilities } from '@curvewise/common-types'
import Ajv from 'ajv'

const ajv = new Ajv({ removeAdditional: true }).addSchema(JSON_SCHEMA)

export function isMeasurementCapabilities(
  data: any
): data is MeasurementCapabilities {
  return ajv.validate('#/definitions/MeasurementCapabilities', data) as boolean
}

export function assertMeasurementCapabilities(data: any): void {
  if (!isMeasurementCapabilities(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}
