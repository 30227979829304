import {
  MeasurementCapabilities,
  SubjectConstraints,
} from '@curvewise/common-types'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { setDifference, setUnion } from '@unpublished/victorinox'
import { sortBy } from 'lodash'
import styled from 'styled-components'

function findSidedMeasurements(measurementNames: string[]): {
  matched: string[]
  unmatched: string[]
} {
  const left = measurementNames.filter(name => name.endsWith('_left'))
  const right = measurementNames.filter(name => name.endsWith('_right'))

  const leftStripped = new Set(left.map(name => name.replace(/_left$/, '')))
  const rightStripped = new Set(right.map(name => name.replace(/_right$/, '')))

  const matched = Array.from(setUnion(leftStripped, rightStripped)).sort()

  const unmatched = Array.from(
    setDifference(new Set(left), new Set(matched.map(name => `${name}_left`)))
  )
    .concat(
      Array.from(
        setDifference(
          new Set(right),
          new Set(matched.map(name => `${name}_right`))
        )
      )
    )
    .sort()

  return { matched, unmatched }
}

interface Measurement {
  name: string
  isSided: boolean
  constraints: SubjectConstraints
}

interface Counts {
  sided: number
  genderConstrained: number
}

function createViewModel(supportedFeatures: MeasurementCapabilities): {
  measurements: Measurement[]
  counts: Counts
  unmatchedSidedMeasurements: Measurement[]
} {
  const allMeasurementNames = Object.keys(supportedFeatures.measurements)

  const unsidedMeasurements = allMeasurementNames
    .filter(name => !name.endsWith('_left') && !name.endsWith('_right'))
    .sort()

  const sidedMeasurements = findSidedMeasurements(allMeasurementNames)

  const measurements: Measurement[] = sortBy(
    unsidedMeasurements
      .map(name => ({
        name,
        isSided: false,
        constraints: supportedFeatures.measurements[name],
      }))
      .concat(
        sidedMeasurements.matched.map(name => ({
          name,
          isSided: true,
          constraints: supportedFeatures.measurements[`${name}_left`],
        }))
      ),
    'name'
  )

  return {
    measurements,
    counts: {
      sided: sidedMeasurements.matched.length,
      genderConstrained: measurements.filter(
        item => item.constraints.gender !== undefined
      ).length,
    },
    unmatchedSidedMeasurements: sidedMeasurements.unmatched.map(name => ({
      name,
      isSided: false,
      constraints: supportedFeatures.measurements[name],
    })),
  }
}

const Table = styled.table`
  border-collapse: collapse;

  td {
    border: 1px solid #ccc;
    padding: 3px 5px;
  }
`

function MeasurementTable({
  measurements,
  counts,
}: {
  measurements: Measurement[]
  counts?: Counts
}): JSX.Element {
  if (measurements.length === 0) {
    return <p>None</p>
  } else {
    return (
      <Table>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Sides</th>
            <th scope="col">Gender</th>
          </tr>
        </thead>
        {measurements.map(item => (
          <tr>
            <td>{humanizeMeasurementName({ name: item.name, index: 0 })}</td>
            <td>{item.isSided ? 'L & R' : ''}</td>
            <td>
              {item.constraints.gender ? `${item.constraints.gender} only` : ''}
            </td>
          </tr>
        ))}
        <tfoot>
          <tr>
            <th>Count</th>
          </tr>
          <tr>
            <td>{measurements.length}</td>
            <td>{counts?.sided}</td>
            <td>{counts?.genderConstrained}</td>
          </tr>
        </tfoot>
      </Table>
    )
  }
}

export function DisplaySupportedFeatures({
  supportedFeatures,
}: {
  supportedFeatures: MeasurementCapabilities
}): JSX.Element {
  const viewModel = createViewModel(supportedFeatures)

  return (
    <section>
      <h2>Measurements</h2>
      <MeasurementTable
        measurements={viewModel.measurements}
        counts={viewModel.counts}
      />
      <h2>Unmatched sided measurements</h2>
      <MeasurementTable measurements={viewModel.unmatchedSidedMeasurements} />
    </section>
  )
}
