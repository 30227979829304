import { MeasurementCapabilities } from '@curvewise/common-types'
import {
  Dropzone,
  DropzoneState,
  LinkButton,
} from '@unpublished/common-components'
import { readJsonFromFile } from '@unpublished/victorinox'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Breadcrumb,
  FixedWidthPageContainer,
} from '../../common/common-components'
import { DisplaySupportedFeatures } from './supported-features'
import { assertMeasurementCapabilities } from './validation'

export function SupportedFeaturePage(): JSX.Element {
  const [supportedFeatures, setSupportedFeatures] =
    useState<MeasurementCapabilities>()

  const handleDropzoneChange = useCallback(
    async (dropzoneState: DropzoneState) => {
      const { supportedFeatures: inSupportedFeatures } = dropzoneState
      if (inSupportedFeatures) {
        const supportedFeatures = await readJsonFromFile(inSupportedFeatures)
        try {
          assertMeasurementCapabilities(supportedFeatures)
        } catch (e) {
          setSupportedFeatures(undefined)
          throw e
        }
        setSupportedFeatures(supportedFeatures)
      } else {
        setSupportedFeatures(undefined)
      }
    },
    []
  )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'} <Link to="/extras">Extras</Link> {'>'}{' '}
        Supported features
      </Breadcrumb>
      <h1>Supported features</h1>
      {supportedFeatures && (
        <DisplaySupportedFeatures supportedFeatures={supportedFeatures} />
      )}
      <h2>Load</h2>
      <Dropzone onChange={handleDropzoneChange} enableSupportedFeatures />
      <LinkButton href="/">Close</LinkButton>
    </FixedWidthPageContainer>
  )
}
