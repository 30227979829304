import { gql, useMutation, useQuery } from '@apollo/client'
import { humanizeMeasurementName } from '@curvewise/measured-body'
import { Button, StatisticalDiv } from '@unpublished/common-components'
import { maybePluralize } from '@unpublished/victorinox'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Breadcrumb,
  Confirm,
  ErrorText,
  FixedWidthFooterNavContainer,
  FixedWidthPageContainer,
  IterationDisplay,
} from '../../common/common-components'
import {
  humanizedDurationFromNow,
  initSetOfGenderOptions,
  toggleableButtonListGenderOptions,
} from '../../common/data-transforms'
import {
  FixedWidthTable,
  FullWidthRow,
  NoWrapFlexDiv,
  TableRow,
  WrappingFlexDiv,
} from '../../common/flex-table'
import {
  ArchiveStudyMutation,
  ArchiveStudyMutationVariables,
  ViewMeasurementStudyIterationQuery,
  ViewMeasurementStudyIterationQueryVariables,
  ViewMeasurementStudyQuery,
  ViewMeasurementStudyQueryVariables,
} from '../../common/generated'
import thumbsUp from '../../common/images/thumbs-up.svg'
import { ToggleableButtonList } from '../../common/toggleable-button-list'
import { useNumericParam } from '../../common/use-numeric-param'
import { LIST_MEASUREMENT_STUDIES_QUERY } from '../list-studies-table'
import { MeasurerVersionDisplay } from '../measurer-version-display'
import { StyledLabel } from '../review-measurements/display-label'
import {
  createViewModel,
  VIEW_MEASUREMENT_STUDY_ITERATION_QUERY,
  VIEW_MEASUREMENT_STUDY_QUERY,
} from './view-model'

const LatestRevisionRow = styled(FullWidthRow)`
  text-align: left;
  background: #c4c4c40d;
  padding: 10px;
`

const Count = styled.div`
  background: white;
  margin-right: 10px;
  padding: 0px 4px;
`

const NoWrapStatsticalDiv = styled(StatisticalDiv)`
  white-space: nowrap;
`

const RemeasureRow = styled(FullWidthRow)`
  background: #c4c4c40d;
  padding: 5px;
`

const CommitDetailContainer = styled(StatisticalDiv)`
  min-width: 300px;
`

const ControlsContainer = styled.div`
  float: right;
`

const MeasurementStatusH2 = styled.h2`
  float: left;
`

const ClearBothContainer = styled.div`
  clear: both;
`

export function ViewMeasurementStudy(): JSX.Element {
  const navigate = useNavigate()
  const selectedStudy = useNumericParam('selectedStudy')
  const [isConfirmingArchive, setIsConfirmingArchive] = useState(false)
  const [isArchiving, setIsArchiving] = useState(false)
  const [selectedGenders, setSelectedGenders] = useState(
    initSetOfGenderOptions()
  )

  const mainQuery = useQuery<
    ViewMeasurementStudyQuery,
    ViewMeasurementStudyQueryVariables
  >(VIEW_MEASUREMENT_STUDY_QUERY, {
    variables: { measurementStudyId: selectedStudy },
  })
  const iterationQuery = useQuery<
    ViewMeasurementStudyIterationQuery,
    ViewMeasurementStudyIterationQueryVariables
  >(VIEW_MEASUREMENT_STUDY_ITERATION_QUERY, {
    variables: { measurementStudyId: selectedStudy },
  })

  const viewModel = createViewModel({
    data: mainQuery.data,
    iterationData: iterationQuery.data,
    selectedGenders,
  })

  const [archiveStudy, { error: archiveStudyError }] = useMutation<
    ArchiveStudyMutation,
    ArchiveStudyMutationVariables
  >(
    gql`
      mutation ArchiveStudy($input: UpdateMeasurementStudyByIdInput!) {
        updateMeasurementStudyById(input: $input) {
          measurementStudy {
            isArchived
          }
        }
      }
    `,
    {
      variables: {
        input: {
          id: selectedStudy,
          measurementStudyPatch: { isArchived: true },
        },
      },
      refetchQueries: [LIST_MEASUREMENT_STUDIES_QUERY],
      onCompleted() {
        setIsArchiving(false)
        navigate(`/studies`)
      },
      onError(error) {
        setIsArchiving(false)
      },
    }
  )

  return (
    <FixedWidthPageContainer>
      <Breadcrumb>
        <Link to="/">Home</Link> {'>'}{' '}
        <Link to="/studies">Measurement studies</Link>
        {viewModel && ` > ${viewModel.name}`}
      </Breadcrumb>
      <h1>{viewModel.name}</h1>
      <MeasurementStatusH2>Measurement status</MeasurementStatusH2>
      <ControlsContainer>
        <ToggleableButtonList
          options={toggleableButtonListGenderOptions}
          onChange={setSelectedGenders}
          selectedOptionValues={selectedGenders}
          showSelectAllNoneButtons={false}
          direction="row"
        />
      </ControlsContainer>
      <ClearBothContainer />
      {mainQuery.error && <p>Oh no! {mainQuery.error.message}</p>}
      {mainQuery.loading && <p>Loading&hellip;</p>}
      {viewModel.measurements.length > 0 && (
        <FixedWidthTable>
          <LatestRevisionRow position="top">
            <StatisticalDiv>
              Last revision: {humanizedDurationFromNow(viewModel.lastModified)}
            </StatisticalDiv>
          </LatestRevisionRow>
          {viewModel.measurements.length > 0 &&
            viewModel.measurements.map(
              ({
                measurementName,
                measurementInvocationIteration,
                reviewStateCounts: {
                  newCount,
                  startedCount,
                  errorCount,
                  needReviewCount,
                  successCount,
                  failureCount,
                },
                labels,
              }) => (
                <TableRow key={measurementName}>
                  <div>
                    {humanizeMeasurementName({
                      name: measurementName,
                      index: 0,
                    })}
                  </div>
                  <div>
                    {newCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {newCount} waiting to measure
                      </NoWrapStatsticalDiv>
                    )}
                    {startedCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {startedCount} measuring
                      </NoWrapStatsticalDiv>
                    )}
                    {errorCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {errorCount} {maybePluralize('error', errorCount)}
                      </NoWrapStatsticalDiv>
                    )}
                  </div>
                  <div>
                    {needReviewCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {needReviewCount}{' '}
                        {maybePluralize('needs', needReviewCount, 'need')}{' '}
                        review
                      </NoWrapStatsticalDiv>
                    )}
                  </div>
                  <div>
                    {successCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {successCount} approved
                      </NoWrapStatsticalDiv>
                    )}
                    {failureCount > 0 && (
                      <NoWrapStatsticalDiv>
                        {failureCount} {maybePluralize('failure', failureCount)}
                      </NoWrapStatsticalDiv>
                    )}
                  </div>
                  <div>
                    <WrappingFlexDiv>
                      {labels.map(label => (
                        <StyledLabel
                          key={`${label.name}`}
                          $isFailure={label.isFailure}
                          height={!label.name ? '25px' : undefined}
                          disabled
                        >
                          <Count>{label.labelCount}</Count>{' '}
                          {label.name ? (
                            label.name
                          ) : (
                            <img src={thumbsUp} alt="Approved" />
                          )}
                        </StyledLabel>
                      ))}
                    </WrappingFlexDiv>
                  </div>
                  <div>
                    <IterationDisplay
                      iteration={measurementInvocationIteration}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        navigate(
                          `/studies/${selectedStudy}/review-measurements/${measurementName}`
                        )
                      }
                    >
                      View
                    </Button>
                  </div>
                </TableRow>
              )
            )}
        </FixedWidthTable>
      )}
      <h2>Iterations</h2>
      {iterationQuery.error && <p>Oh no! {iterationQuery.error.message}</p>}
      {iterationQuery.loading && <p>Loading&hellip;</p>}
      {viewModel.iterations.length > 0 && (
        <FixedWidthTable>
          <RemeasureRow position="top">
            <Button
              onClick={() =>
                navigate(`/studies/${selectedStudy}/measure-again`)
              }
            >
              Measure Again
            </Button>
          </RemeasureRow>
          {viewModel.iterations.map(iteration => (
            <TableRow key={iteration.measurementInvocationIteration}>
              <StatisticalDiv>
                measured {humanizedDurationFromNow(iteration.created)}
              </StatisticalDiv>
              <div>
                <IterationDisplay
                  iteration={iteration.measurementInvocationIteration}
                />
              </div>
              <StatisticalDiv>
                <NoWrapFlexDiv>
                  <span>{iteration.measurerName}</span>
                  <span>@</span>
                  <MeasurerVersionDisplay
                    measurerVersion={iteration.measurerVersion}
                  />
                </NoWrapFlexDiv>
              </StatisticalDiv>
              <CommitDetailContainer>
                {iteration.measurerVersion.kind === 'commit' && (
                  <WrappingFlexDiv>
                    {iteration.measurerVersion.commitMessage}
                    <br />
                    {iteration.measurerVersion.commitDate && (
                      <>
                        <br />
                        committed{' '}
                        {humanizedDurationFromNow(
                          iteration.measurerVersion.commitDate
                        )}
                      </>
                    )}
                  </WrappingFlexDiv>
                )}
              </CommitDetailContainer>
            </TableRow>
          ))}
        </FixedWidthTable>
      )}
      <FixedWidthFooterNavContainer>
        {isConfirmingArchive ? (
          <Confirm
            message={`${viewModel.name} will be archived.`}
            actionLabel="Archive study"
            disabled={isArchiving}
            onConfirm={() => {
              setIsArchiving(true)
              archiveStudy()
            }}
            onCancel={() => setIsConfirmingArchive(false)}
          />
        ) : (
          <div>
            <Button
              onClick={() =>
                navigate(`/studies/${selectedStudy}/whats-changed`)
              }
            >
              What's changed
            </Button>
            <Button
              onClick={() => navigate(`/studies/${selectedStudy}/performance`)}
            >
              Compare performance
            </Button>
            <Button
              onClick={() =>
                navigate(`/studies/${selectedStudy}/regenerate-views`)
              }
            >
              Regenerate Views
            </Button>
            <Button
              onClick={() => navigate(`/studies/${selectedStudy}/download`)}
            >
              Download
            </Button>
            <Button
              onClick={() => navigate(`/studies/${selectedStudy}/errors`)}
            >
              View measurement errors
            </Button>
            <Button
              onClick={() => navigate(`/studies/${selectedStudy}/rename`)}
            >
              Rename
            </Button>
            <Button
              onClick={() => navigate(`/studies/${selectedStudy}/duplicate`)}
            >
              Duplicate
            </Button>
            {!viewModel.isArchived && (
              <Button onClick={() => setIsConfirmingArchive(true)}>
                Archive
              </Button>
            )}
            <Button
              onClick={() =>
                navigate(`/studies/${selectedStudy}/add-geometries`)
              }
            >
              Add geometries
            </Button>
          </div>
        )}
        {archiveStudyError && (
          <div>
            <ErrorText>{archiveStudyError.message}</ErrorText>
          </div>
        )}
      </FixedWidthFooterNavContainer>
    </FixedWidthPageContainer>
  )
}
