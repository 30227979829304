import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

import { IconOption } from '../common/common-components'
import {
  IterationChoicesQuery,
  IterationChoicesQueryVariables,
} from '../common/generated'
import { stopPropagationOfKeyboardShortcuts } from './review-measurements/use-keyboard-shortcuts'
import { ITERATION_CHOICES_QUERY } from './use-pick-compare-iterations'

export type MeasurementInvocationIteration = number

export const LATEST_ITERATION = -1

export function IterationPicker({
  iterationChoices,
  selectedIteration,
  onChange,
  onClear,
  isClearable = false,
  includeLatest = false,
}: {
  iterationChoices: MeasurementInvocationIteration[]
  selectedIteration?: MeasurementInvocationIteration
  onChange: (iteration: MeasurementInvocationIteration) => void
  onClear?: () => void
  isClearable?: boolean
  includeLatest?: boolean
}): JSX.Element {
  const choices = useMemo(
    () =>
      (includeLatest
        ? [{ value: LATEST_ITERATION, label: 'Latest' }]
        : []
      ).concat(
        iterationChoices.map((iteration: MeasurementInvocationIteration) => ({
          value: iteration,
          label: `i${iteration}`,
        }))
      ),
    [iterationChoices, includeLatest]
  )

  return (
    <Select
      isClearable={isClearable}
      onKeyDown={e => stopPropagationOfKeyboardShortcuts(e)}
      onChange={(option: any, { action }) => {
        if (option && action === 'select-option') {
          onChange(option.value)
        } else if (action === 'clear' && onClear !== undefined) {
          onClear()
        }
      }}
      value={choices.find(option => option.value === selectedIteration) ?? null}
      options={choices}
      components={{ Option: IconOption }}
    />
  )
}

export function useIterationPicker({
  measurementStudyId,
  isClearable,
  includeLatest,
}: {
  measurementStudyId?: MeasurementInvocationIteration
  isClearable?: boolean
  includeLatest?: boolean
}): {
  measurementInvocationIteration?: MeasurementInvocationIteration
  renderIterationPicker: () => JSX.Element
} {
  const [measurementInvocationIteration, setMeasurementInvocationIteration] =
    useState<MeasurementInvocationIteration>()

  const { data } = useQuery<
    IterationChoicesQuery,
    IterationChoicesQueryVariables
  >(ITERATION_CHOICES_QUERY, {
    variables: { measurementStudyId: measurementStudyId as number },
    skip: measurementStudyId === undefined,
  })

  const iterationChoices = useMemo(
    () =>
      (data?.allMeasurementInvocationIterations.nodes.map(
        item => item.measurementInvocationIteration
      ) ?? []) as number[],
    [data]
  )

  useEffect(() => {
    setMeasurementInvocationIteration(undefined)
  }, [measurementStudyId])

  useEffect(() => {
    if (
      iterationChoices.length > 0 &&
      measurementInvocationIteration === undefined
    ) {
      setMeasurementInvocationIteration(iterationChoices[0])
    }
  }, [iterationChoices, measurementInvocationIteration])

  function renderIterationPicker(): JSX.Element {
    return (
      <IterationPicker
        iterationChoices={iterationChoices}
        selectedIteration={measurementInvocationIteration}
        onChange={setMeasurementInvocationIteration}
        isClearable={isClearable}
        includeLatest={includeLatest}
      />
    )
  }

  return { measurementInvocationIteration, renderIterationPicker }
}
