import { useState } from 'react'

import { SubjectNameGeometryIdPair } from '../measurement-studies/add-geometries/view-model'
import { DatasetId } from './choose-datasets'
import { Table } from './common-components'
import { mapNumberNodeToOption } from './data-transforms'
import { Option, ToggleableButtonList } from './toggleable-button-list'

export type SelectedGeometriesState = Record<DatasetId, Set<number>>

export function flattenedState(state: SelectedGeometriesState): number[] {
  const result: number[] = []
  for (const datasetId in state) {
    state[datasetId].forEach(geometryId => {
      result.push(geometryId)
    })
  }
  return result
}

export function useMaleFemaleToggleableButtonListTableState(
  datasetIdBeingEdited?: DatasetId
): {
  maleState: SelectedGeometriesState
  setMaleState: React.Dispatch<React.SetStateAction<SelectedGeometriesState>>
  femaleState: SelectedGeometriesState
  setFemaleState: React.Dispatch<React.SetStateAction<SelectedGeometriesState>>
  updateSelectedMaleGeometries: (newSet: Set<number>) => void
  updateSelectedFemaleGeometries: (newSet: Set<number>) => void
} {
  const [maleState, setMaleState] = useState<Record<DatasetId, Set<number>>>(
    {} as Record<DatasetId, Set<number>>
  )
  const [femaleState, setFemaleState] = useState<
    Record<DatasetId, Set<number>>
  >({} as Record<DatasetId, Set<number>>)

  function updateStateByDatasetId<ValueType>(
    newValue: ValueType,
    selected: Record<DatasetId, ValueType>
  ): Record<DatasetId, ValueType> {
    if (datasetIdBeingEdited === undefined)
      throw new Error('Expected datasetIndexBeingEdited to be defined')
    return {
      ...selected,
      [datasetIdBeingEdited]: newValue,
    }
  }

  function updateSelectedMaleGeometries(newSet: Set<number>): void {
    setMaleState(updateStateByDatasetId<Set<number>>(newSet, maleState))
  }
  function updateSelectedFemaleGeometries(newSet: Set<number>): void {
    setFemaleState(updateStateByDatasetId<Set<number>>(newSet, femaleState))
  }
  return {
    maleState,
    setMaleState,
    femaleState,
    setFemaleState,
    updateSelectedMaleGeometries,
    updateSelectedFemaleGeometries,
  }
}

function mapSubjectNameGeometryIdPairsToOptions(
  subjectNameGeometryIdPair: SubjectNameGeometryIdPair[]
): Option<number>[] {
  return subjectNameGeometryIdPair.map(
    ([subjectName, geometryId]: SubjectNameGeometryIdPair) =>
      mapNumberNodeToOption({
        name: subjectName,
        id: geometryId,
      })
  )
}

export function MaleFemaleToggleableButtonListTable({
  currentFemaleSubjectNameGeometryIdPairs,
  currentMaleSubjectNameGeometryIdPairs,
  updateSelectedFemaleGeometries,
  updateSelectedMaleGeometries,
  selectedFemaleOptionValue,
  selectedMaleOptionValue,
}: {
  currentFemaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  currentMaleSubjectNameGeometryIdPairs: SubjectNameGeometryIdPair[]
  updateSelectedFemaleGeometries: (newSet: Set<number>) => void
  updateSelectedMaleGeometries: (newSet: Set<number>) => void
  selectedFemaleOptionValue: Set<number>
  selectedMaleOptionValue: Set<number>
}): JSX.Element {
  const currentFemaleSubjectOptions = mapSubjectNameGeometryIdPairsToOptions(
    currentFemaleSubjectNameGeometryIdPairs
  )
  const currentMaleSubjectOptions = mapSubjectNameGeometryIdPairsToOptions(
    currentMaleSubjectNameGeometryIdPairs
  )
  return (
    <Table>
      <thead>
        <tr>
          <th>Female</th>
          <th>Male</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ToggleableButtonList
              options={currentFemaleSubjectOptions}
              onChange={updateSelectedFemaleGeometries}
              selectedOptionValues={selectedFemaleOptionValue}
            />
          </td>
          <td>
            <ToggleableButtonList
              options={currentMaleSubjectOptions}
              onChange={updateSelectedMaleGeometries}
              selectedOptionValues={selectedMaleOptionValue}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
