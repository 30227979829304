import { LegacyMeasuredBody, MeasurementView } from '@curvewise/common-types'
import humanizeString from 'humanize-string'

import * as JSON_SCHEMA from './generated/werkit-legacy-measured-body.schema.json'
export * from './validation'

export { JSON_SCHEMA }

export const EMPTY_MEASURED_BODY: LegacyMeasuredBody = {
  values: {},
  curves: {},
  tape_width: 0.2,
  landmarks: {},
  computed_points: {},
}

export function compareMeasurementViews(
  first: MeasurementView,
  second: MeasurementView
): number {
  if (first.name < second.name) {
    return -1
  } else if (first.name > second.name) {
    return 1
  } else {
    return first.index - second.index
  }
}

export function humanizeMeasurementName({
  name,
  index,
}: {
  name: string
  index: number
}): string {
  return [humanizeString(name), index > 1 ? `${index}` : undefined].join(' ')
}

export function humanizeBodyPart(name: string): string {
  return humanizeString(name.toLowerCase())
}

export function qualifiedMeasurementName({
  name,
  index,
}: {
  name: string
  index: number
}): string {
  return [name, index > 1 ? `${index}` : undefined]
    .filter(segment => segment !== undefined)
    .join('_')
}
